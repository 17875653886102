import { generateDefaultStepsDefinition } from "./default";
import { generateIntrusionStepDefinition } from "./intrusion";
import { generateSmokeStepDefinition } from "./smoke";
import {
  sendProtocole,
  setNextStep,
  setStepValue,
} from "../../core/store/reducers/Protocole";
import store from "../../core/store/store";
import { generateMooringToConfirmStepDefinition } from "./mooring-to-confirm";
import dayjs from "dayjs";
import i18n from "../../utils/langs";
import FDK from "@wattsonelements/front-fdk";
import { IProtocol, ITicket, ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IPortAgent } from "@wattsonelements/front-fdk/dist/models/Port.models";
import { IAttachment } from "@wattsonelements/front-fdk/dist/models/Attachment.models";
import { generateTestStepsDefinition } from "./test";
import { COMPONENT_TYPE } from "./constants";
import { DraftPlaceIn } from "../../components/dockwalk/TemporarySheet";
import { PlaceInValue } from "../../components/protocole/PlaceInBoat.protocol";
import { PROTOCOLE_TYPE } from "@wattsonelements/front-fdk/dist/constants/protocol.constants";

const protocole = [
  "intrusion-ok",
  "mooring-to-confirm",
  "smoke",
  "bump-ok",
  "ongoing-consumption",
  "rolling-ok",
  "temperature",
  "electricity",
  "battery-level",
  "connected",
  "geofence-ok",
  "humidity",
  "moored",
];

const defaultProtocole = [
  "bump-ok",
  "ongoing-consumption",
  "rolling-ok",
  "temperature",
  "electricity",
  "battery-level",
  "connected",
  "geofence-ok",
  "humidity",
  "moored",
];

// default protocole is on what cat ? all vigie ?
export function isProtocole(categorySlug: string) {
  return protocole.includes(categorySlug);
}
// get the protocole needed for the category using slug
export async function getProtocole(
  ticket: ITicket,
  category: ITicketCategory,
) {
  // Promise<{ protocole: ProtocoleConfig; type: PROTOCOLE_TYPE }>
  const port: IPortAgent = await (await FDK.Agent.PortAPI.getPortById(ticket.port!)).data
  console.log(port);
  
  // return {
  //   protocole: generateTestStepsDefinition(ticket, category),
  //   type: PROTOCOLE_TYPE.DEFAULT,
  // };
  
  
  if (category.slug === "smoke") {
    return {
      protocole: generateSmokeStepDefinition(ticket, category, port),
      type: PROTOCOLE_TYPE.SMOKE,
    };
  }
  if (category.slug === "intrusion-ok") {
    return {
      protocole: generateIntrusionStepDefinition(ticket, category),
      type: PROTOCOLE_TYPE.INTRUSION,
    };
  }
  if (category.slug === "mooring-to-confirm") {
    const isDockwalkActive = port.config.modules?.dockwalk?.agent
    return {
      protocole: generateMooringToConfirmStepDefinition(ticket, category, isDockwalkActive),
      type: PROTOCOLE_TYPE.MOORING_TO_CONFIRM,
    };
  }

  return {
    protocole: generateDefaultStepsDefinition(ticket, category),
    type: PROTOCOLE_TYPE.DEFAULT,
  };
  // DO I DOUBLE CHECK ? or if unknown go default ?
  // if(defaultProtocole.includes(categorySlug)) {
  //     return generateTestStepsDefinition(ticket, category)
  // }
}

export function getNextStep(step: any) {
  return step.nextStep;
}

export function getNodeNextStep(step: any, value: any) {
  return step.answers.find((val: any) => val.value === value).nextStep;
}

export function goToNextStep(currentStep: any, value: any) {
  const { dispatch } = store;

  let nextStep;
  if (currentStep.isNode) {
    nextStep = getNodeNextStep(currentStep, value);
  } else {
    nextStep = getNextStep(currentStep);
  }

  dispatch(setStepValue({ ...currentStep, value }));

  // detect end of protocole
  if (!nextStep) {
    formatAndSendProtocole();
  } else {
    // set value
    // set history ?
    dispatch(setNextStep(nextStep));
  }

  //register value
}

export function formatAndSendProtocole() {
  const { dispatch, getState } = store;
  const { tickets, protocole, users } = getState();
  const currentUser = users.current

  if (!tickets.selected) return;
  let ticket = tickets.selected;

  // const ticketMessage = {
  //   ticket_message: formatDigestedDatas(protocole.values),
  //   ticket: ticket.id,
  //   message_from: currentUser?.id,
  //   files: formatAttachments(protocole.values).map(file => file.id!)
  // }
  interface IProtocolWithFile extends IProtocol {
    files: any
  }
  const toSend: IProtocolWithFile = {
    name: protocole.type || "",
    agent_in_charge: currentUser?.id || "",
    raw_data: protocole.values as any,
    digested_data: formatDigestedDatas(protocole.values),
    files: formatAttachments(protocole.values).map(file => file.linked_file)
  }

  dispatch(sendProtocole({
    id: ticket.id,
    field: toSend
  }));
}

const formatAttachments = (values: any) => {
  console.log("formatAttachments", values, COMPONENT_TYPE.ATTACHMENT);

  let attachment: IAttachment[] = [];
  values.map((val: any) => {
    if (!val.isNode) {
      val.value.map((v: any) => {
        if (!v) return;
        if (v.type === COMPONENT_TYPE.ATTACHMENT) {
          v.value.map((file: IAttachment) => {
            attachment.push({ linked_file: file.id });
          });
        }
      });
    }
  });
  return attachment;
};
const formatDigestedDatas = (values: any) => {
  let content = "";
  values.map((val: any) => {
    if (val.isNode) {
      // need to translate the value or use the component.answer value
      content += val.title + " " + val.value + "\n";
    } else if (val) {
      content += val.title + " : ";
      val.value.map((v: any) => {
        if (!v) return;
        switch (v.type) {
          case COMPONENT_TYPE.ATTACHMENT:
            break;
          case (COMPONENT_TYPE.CHECKLIST, COMPONENT_TYPE.RADIO):
            v.value.map((item: any) => {
              content += item.label + " " + (item.value ? "yes" : "no") + "\n";
            });
            break;
          case COMPONENT_TYPE.NOTE:
            content += v.value + "\n";
            break;
          
          case COMPONENT_TYPE.DOCKWALK_PLACEIN:
            const values = v.value as PlaceInValue 
            if(values.type==="contract"){
              content += values.boat_name + "\n";
              
            } else {
              content += values.passportEscale + "\n";
              content += values.informations + "\n";
              content += values.stayDuration + "nuit(s) \n";
            }
            
            break;

          case COMPONENT_TYPE.DATETIME_SELECTOR:
            content +=
              dayjs(v.value).format(i18n.t("dateFormat.longDate2")) + "\n";
            break;
          default:
            break;
        }
        content += "";
      });
    }
  });
  return content;
};
export function goToPreviousStep() { }
