export default function FieldItem({ field, filled = false, ...props }: any) {

  return (
    <div className={`block text-center flex-auto ${props.className || ''}`} {...props}>
      <span className="block font-bold text-FONT_DARK text-left mb-1 text-xs tracking-wide">{field.title}</span>
      <div className="w-full rounded-md bg-CALCITE p-2 drop-shadow-md text-left ">
        <div className={filled ? "p-2" : "rounded-md flex justify-center items-center border border-1 border-dashed border-SECONDARY"}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
