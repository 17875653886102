import { TicketPriority } from "@wattsonelements/front-fdk/dist/constants/ticket.constants";
import { IAssignee } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { RootState } from "../store";

/**
 * Categories state helper
 */
export const getCategories = ({ categories }: RootState) => categories.levels;

export const getCurrentCategory = ({ categories }: RootState) =>
  categories.selectedCategory;

export const getFlatCategories = ({ categories }: RootState) =>
  categories.levels.flat();

export const getCategoriesFromParentId = (
  categories: ITicketCategory[],
  parentCategoryId: string
) => {
  return categories
    .filter((cat) => cat.parent_category === parentCategoryId)
    .map((cat) => cat.id);
};
export const getCategory = (
  categories: ITicketCategory[],
  categoryId: string
) => {
  return categories.find((cat) => cat.id === categoryId);
};

export const getCatgoryFromSlug = (
  categories: ITicketCategory[],
  slug: string
) => {
  return categories.find((cat) => cat.slug === slug);
};
/**
 * Users state helper
 */
export const getUserID = ({ users }: RootState) =>
  users.current ? users.current.id : "";

/**
 * Tickets state helper
 */
export const getAssignedTicketsList = ({ tickets }: RootState) => tickets.list;

export const getBacklog = ({ tickets }: RootState) => tickets.list;

export const getTickets = ({ tickets }: RootState) => {
  return tickets.list;
  // if (tickets.currentList === "listOpens") {
  //   return tickets.listOpens;
  // } else {
  //   return tickets.listClosed;
  // }
};
export const getTicketsList = ({ tickets }: RootState) => tickets.list;
export const getOpensTicketsList = ({ tickets }: RootState) =>
  tickets.listOpens;

export const ticketsListHasCategories = (
  tickets: ITicket[],
  listCategoriesConcerned: string[]
) => {
  if (!tickets) return [];
  return tickets.filter((ticket) =>
    listCategoriesConcerned.includes(ticket.category)
  );
};

export const filterTicketsByPriority = (tickets: ITicket[]) => {
  let filtered: { [key in TicketPriority]?: ITicket[] } = {};
  let key: keyof typeof TicketPriority;
  for (key in TicketPriority) {
    const priority = TicketPriority[key];
    filtered[priority as TicketPriority] = tickets
      .filter((ticket: ITicket) => ticket.priority === priority)
      .sort((t1, t2) => {
        if (t1.date_created > t2.date_created) {
          return -1;
        } else if (t1.date_created < t2.date_created) {
          return 1;
        }
        return 0;
      });
  }
  return filtered;
};

export const getTicketsForUser = (tickets: ITicket[], userId: string) => {
  return tickets.filter((ticket) => {
    return (ticket?.assignees as IAssignee[])?.find(
      (user) => user.id === userId
    );
  });
};

export const getSelectedTicket = (state: RootState) => state.tickets.selected;
