import { useRef, useState } from "react";
import { colors } from "../../styles/colors";
import { DebugModal } from "../debug/DebugModal";
import { isProd } from "../../utils/env";
import { IonModal } from "@ionic/react";
import { createUseStyles } from "react-jss";
import narwhal from "../../assets/icons/narwhal.png";

export default function SystemMode() {
  const [devModal, setDevModal] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);
  const styles = useStyles();
  return (
    <>
      {!isProd() && (
        <>
          <IonModal
            ref={modal}
            isOpen={devModal}
          >
            <div className={styles.container}>
              <button onClick={() => { setDevModal(false) }}>
                <img alt="narwhal" className={styles.img} src={narwhal} />
              </button>
            </div>
            <DebugModal onClose={() => { setDevModal(false) }} />
          </IonModal>
          {!devModal && <div className={styles.container}>
            <button id="open-modal" onClick={() => setDevModal(true)}>
              <img alt="narwhal" className={styles.img} src={narwhal} />
            </button>
          </div>}
        </>
      )}
    </>
  );
}
const useStyles = createUseStyles({
  container: {
    zIndex: 100000,
    position: "absolute",
    top: 20,
    right: 0,
    padding: "10px",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: colors.WARN,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  img: {
    height: "26px",
    width: "26px",
  },
  text: {
    fontWeight: "bold",
  },
});
