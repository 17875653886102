import { IonButton, IonContent, IonModal, ModalOptions } from "@ionic/react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";


type ModalEditProps = {
  title: string,
  children: ReactNode,
  enableValidation?: boolean,
  open: boolean,
  setOpen: (val: boolean) => any
  save: (val: boolean) => any
  [key: string]: any
} & Partial<ModalOptions>

export default function ModalEdit(props: ModalEditProps) {
  const { t } = useTranslation()
  const { title, children, enableValidation = true, open = false, setOpen, save = () => { }, ...rest } = props;

  return (
    <IonModal
      // ref={modal}
      isOpen={open}
      backdropDismiss={false}

      onClick={(e) => e.stopPropagation()}
      {...rest}
    >
      <IonContent>
        <div className="flex flex-1 pt-5 w-full h-full flex-col">
          <div className="bg-CALCITE rounded-lg h-full p-3 justify-start flex flex-col">
            <div className="w-full mb-8 text-FONT_DARK flex items-center justify-between">
              <IonButton
                fill="clear"
                color="medium"
                className="psx-1"
                onClick={() => {
                  setOpen(false);
                  save(false);
                }}
              >
                <span className="text-center py-1 flex items-center justify-center">
                  {t("shared.cancel")}
                </span>
              </IonButton>
              <span className="flex text-FONT_DARK text-center font-bold items-center justify-center">{props.title}</span>
              <IonButton
                fill="outline"
                shape="round"
                disabled={!enableValidation}
                color="secondary"
                className="psx-1"
                onClick={() => {
                  setOpen(false);
                  save(true);
                }}
              >
                <span className="w-16 text-center py-1 flex items-center justify-center">{t("shared.ok")}</span>
              </IonButton>
            </div>
            {props.children}
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
}
