import {
  IonButton,
  IonContent,
  IonIcon,
  IonLoading,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  RefresherEventDetail,
} from "@ionic/react";
import FDK from "@wattsonelements/front-fdk";
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api";
import { TContractDockWalk } from "@wattsonelements/front-fdk/dist/models/Boats.models";
import { IGenericResponse, TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models";
import { close } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks";
import { getArrivingContract, getLeavingContract } from "../../../core/store/reducers/Dockwalk";
import { ActionStatus } from "../../UI/ActionStatus";
import { ContractItemSliding } from "../../UI/boats/ContractItem";
import { TabFilter } from "../../UI/tabs/TabFilter";
import { PlaceInBerth } from "../PlaceInBerth.modal";
import { useTranslation } from "react-i18next";

export enum TABS {
  ARRIVING,
  LEAVING,
}
export enum NAMED_TABS {
  ARRIVING = "arriving",
  LEAVING = "leaving",
}

export enum DOCKWALK_ACTION_TYPE {
  GONE = "gone",
  TOMORROW = "tomorrow",
}

const STATUS_I18N_KEY = {
  [FDK.MainAPI.REQUEST_STATUS.ERROR]: "error",
  [FDK.MainAPI.REQUEST_STATUS.PENDING]: "loading",
  [FDK.MainAPI.REQUEST_STATUS.SUCCESS]: "success",
  [FDK.MainAPI.REQUEST_STATUS.IDLE]: "",
};

export const DockwalkBooking = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { leaving, arriving, stats } = useAppSelector(state => state.dockWalk);
  const [tab, setTab] = useState(TABS.LEAVING);
  const [openPplaceInModal, setOpenPlaceInModal] = useState(false);
  const [selectedContract, setSelectedContract] = useState<TContractDockWalk | null>(null);
  const [listLoading, setListLoading] = useState(true);
  const [nextPage, setNextPage] = useState<number>(1);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const { t } = useTranslation()

  // MODAL SLIDE ACTION STATUS
  const [openActionModal, setOpenActionModal] = useState(false);
  const [actionStatus, setActionStatus] = useState<number>(FDK.MainAPI.REQUEST_STATUS.IDLE);
  const [actionType, setActionType] = useState<string | null>(null);
  const [actionContract, setActionContract] = useState<null | TContractDockWalk>(null);
  const [actionMessage, setActionMessage] = useState<IGenericResponse | null>(null);

  useEffect(() => {
    dispatch(getLeavingContract({ params: { page: 1 } }))
      .then((res: any) => {
        setNextPage(res.payload.next_page_number);
      })
      .finally(() => {
        setListLoading(false);
      });
    history.replace("?tab=" + NAMED_TABS.LEAVING);
  }, []);

  const loadData = (event?: CustomEvent<RefresherEventDetail>) => {
    if (!!!event || !nextPage) {
      setListLoading(true);
    }
    setNextPageLoading(true);

    const params: TParams = {
      page: !!!event || !nextPage ? 1 : nextPage,
    };
    if (tab === TABS.ARRIVING) {
      dispatch(getArrivingContract({ params }))
        .then(res => {
          setNextPage(res.payload.next_page_number);
        })
        .finally(() => {
          event && event.detail.complete();
          setListLoading(false);
          setNextPageLoading(false);
        });
    }
    if (tab === TABS.LEAVING) {
      dispatch(getLeavingContract({ params }))
        .then(res => {
          setNextPage(res.payload.next_page_number);
        })
        .finally(() => {
          event && event.detail.complete();
          setListLoading(false);
          setNextPageLoading(false);
        });
    }
  };

  const goToLeaving = () => {
    setTab(TABS.LEAVING);
    setListLoading(true);
    dispatch(getLeavingContract({ params: { page: 1 } }))
      .then((res: any) => {
        setNextPage(res.payload.next_page_number);
      })
      .finally(() => {
        setListLoading(false);
        setNextPageLoading(false);
      });
    history.push("?tab=" + NAMED_TABS.LEAVING);
  };
  const goToArriving = () => {
    setTab(TABS.ARRIVING);
    setListLoading(true);
    history.push("?tab=" + NAMED_TABS.ARRIVING);
    dispatch(getArrivingContract({ params: { page: 1 } }))
      .then((res: any) => {
        setNextPage(res.payload.next_page_number);
      })
      .finally(() => {
        setListLoading(false);
        setNextPageLoading(false);
      });
  };

  // LEAVE TOMORROW ACTION
  const onSlideContractArriving = (e: any, contract: TContractDockWalk) => {
    if (actionContract?.id !== contract.id) {
      setActionContract(contract);
    }
    setActionContract(contract);
    setActionStatus(FDK.MainAPI.REQUEST_STATUS.PENDING);
    setOpenActionModal(true);
    setActionType(DOCKWALK_ACTION_TYPE.TOMORROW);
    FDK.Agent.DockwalkModule.leaveTomorrow(contract.id).then(
      res => {
        setActionMessage(res.data);
        // remove from list
        setActionStatus(FDK.MainAPI.REQUEST_STATUS.SUCCESS);
        setTimeout(() => {
          setActionStatus(FDK.MainAPI.REQUEST_STATUS.IDLE);
          setOpenActionModal(false);
        }, 10000);
      },
      err => {
        // modal error
        setActionMessage(err.data || null);
        setActionStatus(FDK.MainAPI.REQUEST_STATUS.ERROR);
      }
    );
  };
  // GONE ACTION
  const onSlideContractLeaving = (e: any, contract: TContractDockWalk) => {
    if (actionContract?.id !== contract.id) {
      setActionContract(contract);
    }
    setActionStatus(FDK.MainAPI.REQUEST_STATUS.PENDING);
    setOpenActionModal(true);
    setActionType(DOCKWALK_ACTION_TYPE.GONE);
    FDK.Agent.DockwalkModule.gone(contract.id).then(
      res => {
        // remove from list
        setActionMessage(res.data);

        setActionStatus(FDK.MainAPI.REQUEST_STATUS.SUCCESS);
        setTimeout(() => {
          setActionStatus(FDK.MainAPI.REQUEST_STATUS.IDLE);
          setOpenActionModal(false);
        }, 10000);
      },
      err => {
        setActionMessage(err.data || null);
        setActionStatus(FDK.MainAPI.REQUEST_STATUS.ERROR);
        // modal error
      }
    );
  };

  return (
    <div className="h-full flex flex-col">
      <IonRefresher slot="fixed" onIonRefresh={loadData}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>

      <IonLoading showBackdrop={false} backdropDismiss={false} spinner="circles" isOpen={listLoading} />

      <div className="flex px-2 mb-3 pt-5 ">
        <TabFilter
          className="flex-1"
          selected={tab === TABS.LEAVING}
          onClick={goToLeaving}
          count={stats.leaving}
          label={t("dockwalk.dockwalk.leaving")}
        />
        <TabFilter
          className="flex-1"
          selected={tab === TABS.ARRIVING}
          onClick={goToArriving}
          count={stats.arriving}
          label={t("dockwalk.dockwalk.arriving")}
        />
      </div>
      <div className="px-2  overflow-auto pb-20">
        <div className="flex flex-col gap-3">
          {tab === TABS.LEAVING &&
            leaving &&
            leaving.map(contract => (
              <ContractItemSliding
                right={{
                  label: t("dockwalk.dockwalk.actions.gone.btn"),
                  onSlided: onSlideContractLeaving,
                }}
                key={contract.id}
                contract={contract}
              />
            ))}
          {tab === TABS.ARRIVING &&
            arriving &&
            arriving.map(contract => (
              <ContractItemSliding
                onClick={() => {
                  setSelectedContract(contract);
                  setOpenPlaceInModal(true);
                }}
                left={{
                  label: t("dockwalk.dockwalk.actions.tomorrow.btn"),
                  onSlided: onSlideContractArriving,
                }}
                key={contract.id}
                contract={contract}
              />
            ))}

          {!listLoading && nextPage && nextPage > 1 && (
            <IonButton
              onClick={() => {
                loadData();
              }}
              color="secondary"
              shape="round"
              fill="outline"
            >
              {nextPageLoading ? <IonSpinner color="secondary" /> : t("shared.loadmore")}
            </IonButton>
          )}
        </div>
      </div>

      <PlaceInBerth
        close={() => setOpenPlaceInModal(false)}
        contract={selectedContract!}
        isOpen={openPplaceInModal}
        onDidDismiss={() => {
          setOpenPlaceInModal(false);
          loadData();
        }}
      />
      <IonModal
        style={{ "--max-height": "600px", "--max-width": "90vw" }}
        isOpen={openActionModal}
        onDidDismiss={() => {
          setActionContract(null);
          setActionStatus(FDK.MainAPI.REQUEST_STATUS.IDLE);
          setActionType(null);
          setActionMessage(null);
          loadData();
        }}
      >
        <IonContent>
          <div className="flex flex-col gap-3 justify-center items-center h-full p-4">
            <ActionStatus
              title={actionMessage?.title || ""}
              message={
                actionMessage?.message || t(`dockwalk.dockwalk.actions.${actionType}.${(STATUS_I18N_KEY as any)[actionStatus]}`)
              }
              sub_message={actionMessage?.sub_message || ""}
              size={50}
              state={actionStatus}
            />
            {FDK.MainAPI.REQUEST_STATUS.ERROR === actionStatus && (
              <IonButton
                shape="round"
                color="danger"
                onClick={(e: any) => {
                  if (actionType === DOCKWALK_ACTION_TYPE.GONE) {
                    onSlideContractLeaving(e, actionContract!);
                  }
                  if (actionType === DOCKWALK_ACTION_TYPE.TOMORROW) {
                    onSlideContractArriving(e, actionContract!);
                  }
                }}
              >
                {t("shared.retry")}
              </IonButton>
            )}
            {[FDK.MainAPI.REQUEST_STATUS.ERROR, FDK.MainAPI.REQUEST_STATUS.SUCCESS].includes(actionStatus) && (
              <IonButton
                style={{
                  "--padding-start": "0",
                  "--padding-end": "0",
                  "--border-radius": "50%",
                  width: "40px",
                  height: "40px",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                color="secondary"
                fill="outline"
                onClick={() => {
                  setOpenActionModal(false);
                }}
              >
                <IonIcon color="secondary" className="min-w-4 min-h-4 font-bold" icon={close} />
              </IonButton>
            )}
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};
