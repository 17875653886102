import { IonHeader, IonIcon } from "@ionic/react";
import { arrowBackOutline } from 'ionicons/icons';
import { useHistory } from "react-router";

export default function BackHeader({
  title,
  children,
  ...props
}: {
  title?: string;
  children?: any;
  goBack?: () => any
}) {
  const history = useHistory()
  const { goBack = () => history.goBack() } = props
  return (
    <IonHeader mode="md" className="z-0">
      <div className="w-full px-4 flex justify-start items-center gap-2 pt-10 pb-9 bg-PRIMARY">
        <div className="flex justify-center align-center text-3xl">
          <IonIcon onClick={() => goBack()} color="secondary" style={{ fontSize: "1.4em" }} icon={arrowBackOutline} />
        </div>
        <div className="flex flex-col w-full">
          <p className="font-extrabold text-CALCITE text-xl">{title}</p>
          {children}
        </div>
      </div>
    </IonHeader>
  );
}

