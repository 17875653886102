import { FC, useMemo, useRef, useState } from "react"
import { dockwalkApi, useGetBerthsForPontoonQuery } from "../../../core/store/api/dockwalk.api"
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks"
import { skipToken } from "@reduxjs/toolkit/query"
import { IonButton, IonContent, IonIcon, IonModal, IonSpinner } from "@ionic/react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { arrowDown, arrowUp, close, notifications, person } from "ionicons/icons"
import { AdaptaterBerthWithBoatItem, BerthWithBoatItemAndSlidingActions } from "../../UI/berths/BerthWithBoat.item"
import { AvailableBerthItem } from "../../UI/berths/AvailableBerth.item"
import { IDockwalkBerth, IPontoonBerthList } from "@wattsonelements/front-fdk/dist/models/Dockwalk.models"
import { BerthDetailModal } from "../BerthDetail.modal"
import { PlaceInBoatModal } from "../PlaceInBoat.modal"
import { IGenericResponse } from "@wattsonelements/front-fdk/dist/models/Misc.models"
import FDK from "@wattsonelements/front-fdk"
import { ActionStatus } from "../../UI/ActionStatus"
import classNames from "classnames"
import { getIsYesterdayOrTomorrowOrToday } from "../../../_helpers_/datatime.helpers"
import { useResultToast } from "../../../_helpers_/useResultToast"
import { PlaceBoatInBerth } from "../actions/PlaceBoatInBerth"

const STATUS_I18N_KEY = {
  [FDK.MainAPI.REQUEST_STATUS.ERROR]: "error",
  [FDK.MainAPI.REQUEST_STATUS.PENDING]: "loading",
  [FDK.MainAPI.REQUEST_STATUS.SUCCESS]: "success",
  [FDK.MainAPI.REQUEST_STATUS.IDLE]: "",
};

const setList = (side: "left" | "right" | null, reverse: boolean, list: IPontoonBerthList | null | undefined) => {
  const tmp = [...(list && side && side in list) ? list[side].spots : []]
  if (reverse) {
    return tmp.reverse()
  }
  return tmp
}

type PontoonBerthsListProps = {
  pontoonId: string
  side: "left" | "right" | null
}

export const PontoonBerthsList: FC<PontoonBerthsListProps> = ({ pontoonId, side = "left" }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const port = useAppSelector(state => state.ports.current)
  const { isLoading, isFetching, data, refetch } = useGetBerthsForPontoonQuery(port && pontoonId ? { portId: port?.id || "", pontoonId } : skipToken, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  })
  // Reverse agent sens not side (linked to starting from the land or from open sea)
  const [reverse, setReverse] = useState(false)
  const reverseSide = useMemo(() => side === 'right', [side])

  const list: IDockwalkBerth[] = setList(side, reverse, data)
  const [openModal, setOpenModal] = useState(false)
  const [selected, setSelected] = useState<IDockwalkBerth | null>(null)

  const [openActionModal, setOpenActionModal] = useState(false);

  const [actionStatus, setActionStatus] = useState<number>(FDK.MainAPI.REQUEST_STATUS.IDLE);
  const [actionType, setActionType] = useState<"gone" | "replace" | "pontoon" | null>(null);
  // const [actionBerth, setActionBerth] = useState<null | IDockwalkBerth>(null);
  const [actionMessage, setActionMessage] = useState<IGenericResponse | null>(null);

  const scrollToTopContainer = useRef<any>()

  const [presentToaster, dismissToaster] = useResultToast(true)

  const onLeaveAction = async (berth: IDockwalkBerth, itemSliding?: HTMLIonItemSlidingElement) => {
    setTimeout(() => {
      itemSliding?.close()
    }, 400)
    setActionType("gone")
    setSelected(berth)
    await dismissToaster()
    presentToaster({
      message: t("dockwalk.dockwalk.actions.gone.loading"),
    }, "saving")

    FDK.Agent.DockwalkModule.noBoatAction(berth.id).then(
      async res => {
        await dismissToaster()
        presentToaster({
          header: res.data?.title || "",
          message: res.data?.message || t("dockwalk.dockwalk.actions.gone.success"),
        }, "success")
        setTimeout(() => {
          dismissToaster()
        }, 10000);
        dispatch(dockwalkApi.util.invalidateTags(["BoatAction"]))
      },
      async err => {
        // toast error
        await dismissToaster()
        presentToaster({
          message: err.data?.message || t("dockwalk.dockwalk.actions.gone.success"),
        }, "error")
      }
    ).finally(() => {

    })
  }

  const setPontoonDone = () => {
    if (!side) return
    setActionType("pontoon")
    setOpenActionModal(true)
    setActionStatus(FDK.MainAPI.REQUEST_STATUS.PENDING);
    FDK.Agent.DockwalkModule.pontoonDockwalkDoneAction(pontoonId, side).then(
      res => {
        setActionMessage(res.data);
        setActionStatus(FDK.MainAPI.REQUEST_STATUS.SUCCESS);
        setTimeout(() => {
          setActionStatus(FDK.MainAPI.REQUEST_STATUS.IDLE);
          setOpenActionModal(false);
        }, 10000);
      },
      err => {
        // modal error
        setActionMessage(err.data || null);
        setActionStatus(FDK.MainAPI.REQUEST_STATUS.ERROR);
      }
    )
  }

  const onReplaceAction = (berth: IDockwalkBerth, itemSliding?: HTMLIonItemSlidingElement) => {
    setTimeout(() => {
      itemSliding?.close()
    }, 400)
    setActionType("replace")
    setOpenActionModal(true)
    setSelected(berth)

  }

  const scrollToTop = () => {
    if (!scrollToTopContainer || !scrollToTopContainer.current) return
    scrollToTopContainer.current.parentElement.scrollTo({ top: 0, behaviour: "smooth" })
  }

  const lastDockwalkFormatter = () => {
    if (!data || !side || !(side in data)) return ""
    const date = dayjs(data[side].last_dockwalk_done)
    const day = getIsYesterdayOrTomorrowOrToday(date)
    if (date.hour() === 0 && date.minute() === 0) return date.format(t("dateFormat.daymonth"))
    if (day) return `${t(day)} ${t("dateFormat.at")} ${date.format(t("dateFormat.hours"))}`
    return date.format(t("dateFormat.dateHour"))
  }

  if (isLoading) return <IonSpinner className="mx-auto my-2 block" />

  return (
    <div className=" overflow-hidden h-full w-full">
      <div id="scrolltop" ref={scrollToTopContainer} className="overflow-auto h-full w-full">

        {
          (pontoonId === data?.id) && <PontonVisualisation
          className={classNames({
            "right-[8px]":reverseSide,
            "left-[8px]":!reverseSide
          })}
            onChangeReverse={(reverseDirection) => {
              setReverse(reverseDirection)
              scrollToTop()
            }}
            name={data?.name}
            reverse={reverse}
          />
        }
        {/* LIST CONTAINER */}
          <div className={classNames("ion-content-scroll-host  grid grid-cols-1 gap-2 w-full pb-10 overflow-auto", 
          //" bg-gradient-to-t from-rose-400 to-orange-300",
          {
            "pr-9": reverseSide,
            "pl-9": !reverseSide
          })}>
            {
              isFetching && <IonSpinner className="mx-auto my-2 block" />
            }
            {data && <span className="text-GREEN text-center flex justify-center gap-1 items-center">
              <IonIcon className="text-GREEN" icon={notifications} />
              {side && side in data && data[side].last_dockwalk_done ?
                <>
                  {t("dockwalk.berths.lastDockwalk")}{lastDockwalkFormatter()}
                </> :
                <>
                  {t("dockwalk.berths.lastDockwalk")} {t("dockwalk.berths.noDockwalk")}
                </>}
            </span>
            }
            {
              data?.id === pontoonId && list.map(item => item.is_available ?
                <AvailableBerthItem
                  key={"available" + item.id}
                  name={item.name!}
                  type={item.contract_type?.label}
                  arrivalDetectedAt={item.sensor_occupied_since}
                  availableUntil={item.available_until}
                  catway={item.catway}
                  category={item.category?.label}
                  reverse={reverseSide}
                  hasReservation={!!item.reserved}
                  onClick={() => {
                    setSelected(item);
                    setOpenModal(true);
                  }}
                /> : <BerthWithBoatItemAndSlidingActions
                  key={"notavailable" + item.id}
                  onLeaveAction={(itemSliding) => {
                    onLeaveAction(item, itemSliding)
                  }}
                  onReplaceAction={(itemSliding) => {
                    onReplaceAction(item, itemSliding)
                  }}
                >
                  <AdaptaterBerthWithBoatItem
                    berth={item}
                    reverse={reverseSide}
                    onClick={() => {
                      setSelected(item);
                      setOpenModal(true);
                    }} />

                </BerthWithBoatItemAndSlidingActions>)
            }
            {
              data?.id === pontoonId && list.length > 0 && <IonButton
                onClick={() => {
                  setPontoonDone()
                }}
                className=" my-4"
                size="default"
                shape="round"
                color="secondary">{t("dockwalk.berths.dockwalkDonePontoon")}
                &nbsp;
                {side === "left" && t("dockwalk.dockwalkSide.left")}
                {side === "right" && t("dockwalk.dockwalkSide.right")}
                &nbsp;
                {t("dockwalk.berths.dockwalkDone")}
              </IonButton>
            }
          </div>
        
        {
          selected && (!selected.is_available ?
            <IonModal
              isOpen={openModal}
              onDidDismiss={() => {
                setOpenModal(false)
                // dispatch(resetSelected())
                setSelected(null)
                // setSelectedContract(null)
              }}
              className="swipe-modal"
              initialBreakpoint={0.75}
              breakpoints={[0, .5, .75, .9]}
            >
              <IonContent>
                <BerthDetailModal boat={selected.boat} boatName={selected.boat?.name} berthId={selected.id} />
              </IonContent>
            </IonModal> : <PlaceInBoatModal
              berth={selected} isOpen={openModal}
              close={() => {
                setOpenModal(false)
                setSelected(null)
              }}
            />)
        }
        <IonModal
          isOpen={openActionModal && actionType === "replace"}
          onDidDismiss={() => {
            setActionType(null);
            setActionMessage(null);
          }}>
          <IonContent>
            {
              selected && !selected.is_available && actionType === "replace" && <PlaceBoatInBerth
                close={() => setOpenActionModal(false)}
                boat={selected.boat!}
                berthId={selected.id}
                berthCategoryId={selected.category?.id as any}
              />
            }
          </IonContent>
        </IonModal>

        <IonModal
          style={{ "--max-height": "600px", "--max-width": "90vw" }}
          isOpen={openActionModal && actionType !== "replace"}
          onDidDismiss={() => {
            setActionStatus(FDK.MainAPI.REQUEST_STATUS.IDLE);
            setActionType(null);
            setActionMessage(null);
            refetch()
            if (actionType === "pontoon" && actionStatus !== FDK.MainAPI.REQUEST_STATUS.ERROR) {
              setTimeout(scrollToTop, 500)
            }
          }}
        >
          <IonContent>
            <div className="flex flex-col gap-3 justify-center items-center h-full p-4">
              <ActionStatus
                title={actionMessage?.title || ""}
                message={
                  actionMessage?.message || t(`dockwalk.berths.actions.${actionType}.${(STATUS_I18N_KEY as any)[actionStatus]}`)
                }
                sub_message={actionMessage?.sub_message || ""}
                size={50}
                state={actionStatus}
              />
              {selected && FDK.MainAPI.REQUEST_STATUS.ERROR === actionStatus && (
                <IonButton
                  shape="round"
                  color="danger"
                  onClick={(e: any) => {
                    if (actionType === "gone") {
                      onLeaveAction(selected)
                    }
                    if (actionType === "replace") {
                      onReplaceAction(selected);
                    }
                  }}
                >
                  {t("shared.retry")}
                </IonButton>
              )}
              {[FDK.MainAPI.REQUEST_STATUS.ERROR, FDK.MainAPI.REQUEST_STATUS.SUCCESS].includes(actionStatus) && (
                <IonButton
                  style={{
                    "--padding-start": "0",
                    "--padding-end": "0",
                    "--border-radius": "50%",
                    width: "40px",
                    height: "40px",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                  color="secondary"
                  fill="outline"
                  onClick={() => {
                    setOpenActionModal(false);
                  }}
                >
                  <IonIcon color="secondary" className="min-w-4 min-h-4 font-bold" icon={close} />
                </IonButton>
              )}
            </div>
          </IonContent>
        </IonModal>
      </div>
    </div>
  )
}

type PontonVisualisationProps = {
  reverse: boolean
  name: string,
  className?:string
  onChangeReverse: (reverseDirection: boolean) => void
}
const PontonVisualisation: FC<PontonVisualisationProps> = (
  {
    onChangeReverse, reverse, name = "", className=""
  }
) => {

  const { t } = useTranslation()

  return (
    <div className={classNames(
      // calc(100%-100px)
      className, 

      "pontoonside side h-[calc(100%-100px)] overflow-visible items-center rounded-3xl  bg-GREY w-5 absolute top-20 flex flex-col justify-between",
    )}>
      <button
        className={
          classNames(
            "flex flex-col justify-center items-center  rounded-full w-6",
            {
              "bg-SECONDARY h-12": reverse,
              "bg-PRIMARY h-16": !reverse,
            })
        }
        onClick={() => {
          onChangeReverse(false)
          // setReverse(false)
          // scrollToTop()
        }}>
        {
          !reverse && <IonIcon color="light" icon={person} />
        }
        <IonIcon color="light" icon={arrowDown} />
      </button>
      <span className="-rotate-90 origin-center text-white text-sm text-nowrap min-w-max">{t("shared.pontoon")}&nbsp;{name}</span>
      <button
        className={
          classNames(
            "flex flex-col justify-center items-center rounded-full w-6",
            {
              "bg-PRIMARY h-16": reverse,
              "bg-SECONDARY h-12 ": !reverse,

            })
        }
        onClick={() => {
          onChangeReverse(true)
          // setReverse(true)
          // scrollToTop()
        }}>
        <IonIcon color="light" icon={arrowUp} />
        {
          reverse && <IonIcon color="light" icon={person} />
        }

      </button>
    </div>
  )
}