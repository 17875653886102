import { IonButton, IonContent, IonIcon, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonSkeletonText, RefresherEventDetail, useIonLoading, useIonViewDidLeave } from "@ionic/react"
import BackHeader from "../components/layouts/BackHeader"
import { useTranslation } from "react-i18next"
import { TabsMenu } from "../components/UI/tabs/Tabs"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { useAppDispatch, useAppSelector } from "../core/store/hooks"
import { getTABookingDetail } from "../core/store/actions/TechnicalArea.actions"
import { removeDetail } from "../core/store/reducers/TechnicalArea.reducer"
import { TAStatusChip } from "../components/technicalArea/StatusChip.ta"
import { chevronBack } from "ionicons/icons"
import { AutomaticReport } from "../components/UI/AutomaticReport"
import { DetailLoader } from "../components/technicalArea/Detail.loader"
import { BookingTab } from "../components/technicalArea/Booking.tabs"

enum TABS {
  BOOKING,
  LOG,
}

export const TechnicalAreaDetailPage = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const booking = useAppSelector(state => state.technicalArea.detail)
  const port = useAppSelector(state => state.ports.current)
  const [tab, setTab] = useState(TABS.BOOKING);
  const [loading, setLoading] = useState(true)
  const [isUpdating] = useState(false) // would be nice with rtk or tanstask query
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (tab === TABS.BOOKING) {
      scrollToTop()
    }

  }, [tab])

  useEffect(() => {
    if (!id) return
    setLoading(true)
    dispatch(getTABookingDetail(id)).finally(() => {
      setLoading(false)
    })
  }, [id, dispatch])


  useIonViewDidLeave(() => {
    dispatch(removeDetail())
  })

  const loadData = (event?: CustomEvent<RefresherEventDetail>) => {
    dispatch(getTABookingDetail(id)).finally(() => {
      event && event.detail.complete()

    })
  }

  const displayNumber = (value: number | null) => {
    if (value === null || !!!value) {
      return "/"
    }
    return value
  }

  return (
    <IonPage>
      <BackHeader>
        <div className="w-full flex flex-col gap-3 items-center pr-12">
          {
            !loading && booking && <>
              <h1 className="text-white text-center text-xl font-bold">
                {booking.boat.name} - {booking.boat.registration_number}
              </h1>
              <div className="flex flex-col items-center">
                <span className="text-white text-base font-bold">
                  {displayNumber(booking.boat.width)}{booking.boat.width ? t(`shared.units.${port?.config.distance_unit}`) : ""} - {displayNumber(booking.boat.length) || "/"}{booking.boat.length ? t(`shared.units.${port?.config.distance_unit}`) : ""} - {displayNumber(booking.boat.draft) || "/"}{booking.boat.draft ? t(`shared.units.${port?.config.distance_unit}`) : ""}
                </span>
                <span className="text-xs text-white  opacity-70">{t("technicalArea.dimensions")}</span>
              </div>

              <TAStatusChip size="medium" status={booking.status} />
            </>
          }
          {
            loading && <>
              <IonSkeletonText className="w-9 h-9 secondary" animated={true} ></IonSkeletonText>
              <div className="flex flex-col items-center w-full">
                <IonSkeletonText className="w-1/2 h-5 secondary" animated={true} ></IonSkeletonText>
                <IonSkeletonText className="w-3/4 h-2 secondary" animated={true} ></IonSkeletonText>
                <IonSkeletonText className="w-1/3 h-5 secondary" animated={true} ></IonSkeletonText>
              </div>
            </>
          }

        </div>
      </BackHeader>
      <IonContent style={{ "--background": "#f4f4f4" }}>
        <IonRefresher slot="fixed" onIonRefresh={loadData}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonLoading showBackdrop={false} backdropDismiss={false} spinner="circles" isOpen={isUpdating} />
        <div ref={scrollRef} />
        <div className="px-6 pt-8 pb-20 flex flex-col gap-8 ">
          <TabsMenu
            selected={tab}
            selectKey="slug"
            onSelected={(e, tab) => {
              setTab(tab);
            }}
            tabs={[
              {
                label: t("technicalArea.detail.tabs.booking"),
                slug: TABS.BOOKING,
              },
              {
                label: t("technicalArea.detail.tabs.logs"),
                slug: TABS.LOG,
              },
            ]}
          />
          {
            tab === TABS.BOOKING && booking && !loading &&
            <BookingTab booking={booking} />
          }
          {
            tab === TABS.LOG && booking &&
            <div>
              <AutomaticReport logs={booking.formatted_logs} />
              <IonButton expand="block" fill="clear" onClick={() => setTab(TABS.BOOKING)}>
                <IonIcon slot="start" icon={chevronBack} />
                {t("technicalArea.detail.goBackToTabBooking")}
              </IonButton>
            </div>

          }

          {
            loading && <DetailLoader />
          }

        </div>
      </IonContent>
    </IonPage>
  )
}