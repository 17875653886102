import { useRef, useState } from "react";
import Btn from "./Btn";
import { IonModal, IonList } from "@ionic/react";


type ListItem = {
  value: string;
  text: string;
  selectable: boolean;
};

export function BtnSelect({
  value,
  list,
  setValue,
  ...props
}: {
  value: string;
  list: ListItem[];
  setValue: Function;
  [key: string]: any;
}) {
  const [open, setOpen] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);
  const label = list.find((i) => i.value === value);

  const SelectOption = ({ item }: { item: ListItem }) => {
    if (!item.selectable) return <></>;
    return (
      <div
        className="text-FONT_DARK w-40 py-2"
        onClick={() => {
          setOpen(false);
          setValue(item.value);
        }}
      >
        {item.text}
      </div>
    );
  };

  return (
    <div style={{ ...props?.style }} >
      <Btn color="secondary" size="large" label={label?.text} onClick={() => setOpen(!open)}>
        {open && (
          <img
            className="w-3 h-3 ml-2 mt-1"
            src={require("../../../assets/icons/chevron-up.png")}
          />
        )}
        {!open && (
          <img
            className="w-3 h-3 ml-2 mt-1"
            src={require("../../../assets/icons/chevron-down.png")}
          />
        )}
      </Btn>
      <IonModal className="select-modal" showBackdrop={false} ref={modal} trigger="open-modal" isOpen={open}>
        <div className="relative flex flex-1 justify-center items-center w-full h-full">
          <button
            className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
            onClick={() => {
              setOpen(!open);
            }}
          ></button>
          <div className="bg-CALCITE rounded-lg h-auto p-4 items-center">
            <div className="items-center">
              <IonList className={"flex-0 divide-y divide-solid divide-LIGHT_GREY text-center"}>
                {list.map(item =>
                  <SelectOption key={item.value} item={item} />
                )}
              </IonList>
              {/* <FlatList
                style={styles.flatlist}
                data={list}
                renderItem={SelectOption}
                keyExtractor={(item: ListItem) => item.value} 
              />*/}
            </div>
          </div>
        </div>
      </IonModal>
    </div>
  );
}
