import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TA_LIST } from "../reducers/TechnicalArea.reducer";
import { TABooking } from "../../models/technicalArea.models";
import { getUserID } from "./helpers";

export const getBookingList = createSelector(
  ({ technicalArea }: RootState) => technicalArea,
  getUserID,
  (ta, currentUser: string) => {
    let list = []
    switch (ta.selectedList) {
      case TA_LIST.TODAY:
        list = ta.today
        break;
      case TA_LIST.TOMORROW:
        list = ta.tomorrow
        break
      case TA_LIST.ON_AREA:
        list = ta.onArea
        break
      default:
        return []
    }
    const users = ta.filters.users
    if (users.length > 0) {
      list = list.filter((item: TABooking) =>
        (item?.agents)?.find((agent) => users.includes(agent.id))
      );
    }

    return list
  }
);