import { WritableDraft } from "immer/dist/internal"

export interface PaginatedPayload<T> {
  count: number
  page_number: number
  next_page_number: number | null
  next: string | null
  previous: string | null
  params?: { [key: string]: string }
  port: string
  results: T[]
}

export const handlePangination = (state: any[], payload: PaginatedPayload<any>) => {
  if (payload.page_number > 1) {
    return [...state, ...payload.results]
  } else {
    return payload.results
  }
}