import { IBoat } from "@wattsonelements/front-fdk/dist/models/Boats.models"
import { FC, useEffect, useState } from "react"
import BackHeader from "../../layouts/BackHeader"
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks"
import { useTranslation } from "react-i18next"
import { TBerthCategory } from "@wattsonelements/front-fdk/dist/models/Berth.models"
import { dockwalkApi, useGetBerthsCategoryStatsQuery, useGetBerthsForCategoriesQuery } from "../../../core/store/api/dockwalk.api"
import { skipToken } from "@reduxjs/toolkit/query"

import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from "swiper/react"
import { TabFilter } from "../../UI/tabs/TabFilter"
import { AvailableBerthItem } from "../../UI/berths/AvailableBerth.item"
import { IonButton, IonIcon, IonSpinner } from "@ionic/react"
import { checkmark } from "ionicons/icons"
import FDK from "@wattsonelements/front-fdk"
import MainAPI from "@wattsonelements/front-fdk/dist/api"
import { IGenericResponse } from "@wattsonelements/front-fdk/dist/models/Misc.models"
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models"
import { ActionStatus } from "../../UI/ActionStatus"

type PlaceBoatInBerthProps = {
  berthId: UUID, // current
  boat: Pick<IBoat, "id" | "name" | "length" | "width" | "hull_type" | "boat_type_icon">
  berthCategoryId?: number // id
  close?: () => any
}

export const PlaceBoatInBerth: FC<PlaceBoatInBerthProps> = ({ berthId, boat, berthCategoryId, close = () => { } }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { current: port } = useAppSelector(state => state.ports)
  const [berthCategory, setBerthCategory] = useState<TBerthCategory | null | undefined>(null)
  const [selected, setSelected] = useState<string>()
  const { data: categories = [] } = useGetBerthsCategoryStatsQuery(port?.id ?? skipToken)
  const [page, setPage] = useState(1)

  const {
    isLoading,
    data,
    isFetching
  } = useGetBerthsForCategoriesQuery((berthCategory && port?.id) ? { page, categoryId: berthCategory.id, portId: port?.id } : skipToken)

  const [list, setList] = useState((data && data.results) || [])
  const [requestStatus, setRequestStatus] = useState<number>(FDK.MainAPI.REQUEST_STATUS.IDLE)
  const [resquetMessages, setRequestMessages] = useState<IGenericResponse | null>(null)


  const onReplaceAction = async () => {
    setRequestStatus(FDK.MainAPI.REQUEST_STATUS.PENDING)
    MainAPI.API.request<IGenericResponse>({
      method: "post",
      responseType: "json",
      url: `/v2/marina/dockwalk/berths/${berthId}/action/move-boat`,
      data: { new_berth_id: selected }
    }).then(
      async res => {
        setRequestStatus(FDK.MainAPI.REQUEST_STATUS.SUCCESS)
        setRequestMessages(res.data)
        dispatch(dockwalkApi.util.invalidateTags(["BoatAction"]))
      },
      async err => {
        setRequestStatus(FDK.MainAPI.REQUEST_STATUS.ERROR)
        setRequestMessages(err?.data || null)
      }

    ).finally(() => {

    })
    //  FDK.Agent.DockwalkModule.replaceBoatAction(boat.id, selected)
  }

  const findBerthCategory = () => {
    if (berthCategoryId && categories) {
      return categories.find(c => c.id === berthCategoryId)
    } else if (boat.length && categories) {
      const length = boat.length
      let max = 1000
      let cat: TBerthCategory | null = null
      const tempCat = categories.find(b => {
        if (b.minimum && b.maximum) {
          return b.minimum <= length && b.maximum >= length
        }
        if (b.maximum < max && length < b.maximum) {
          max = b.maximum
          cat = b
          return false
        }
      })
      return cat
    } else if (categories && categories.length > 0) {
      return categories[0]
    }
    return null
  }

  useEffect(() => {
    if (!boat || !categories) return
    const cat = findBerthCategory()
    setBerthCategory(cat)


  }, [boat, berthCategoryId, categories])

  useEffect(() => {
    if (!data) return
    if (page > 1) {
      setList([...list, ...data.results])
    } else {
      setList([...data.results])
    }

  }, [data])
  return <div className="bg-LIGHT h-full">
    <BackHeader goBack={() => {
      close()
    }} title={t("dockwalk.placein.title")}>
      {/* contract brief summary */}
      {
        boat && <p className="text-white">
          {boat.name} &nbsp;
          {boat.length > 0 && boat.width > 0 &&
            <>
              |&nbsp;
              <span className="text-center text-base font-bold text-white">
                {boat.length}
                {t(`shared.units.${port?.config.distance_unit}`)}
                x
                {boat.width}{t(`shared.units.${port?.config.distance_unit}`)}
              </span>
            </>
          }
        </p>
      }
    </BackHeader>
    <div className="p-3 text-center">
      {
        requestStatus === FDK.MainAPI.REQUEST_STATUS.IDLE && berthCategory &&
        <>
          <div className="max-w-[200px] mx-auto pb-4">
            <Swiper
              slideToClickedSlide={true}
              centeredSlides={true}
              centerInsufficientSlides={true}
              initialSlide={1}
              spaceBetween={5}
              slidesPerView={3}
              onSlideChange={(s) => console.log('slide change', s.activeIndex)}
              onSwiper={(swiper) => {
                swiper.slideTo((categories.findIndex(c => c.id === berthCategory?.id) || 0))
              }}
            >
              {
                categories.map(cat => <SwiperSlide key={cat.id}>
                  <TabFilter onClick={() => {
                    // setCategory(cat.id)
                    setBerthCategory(cat)
                  }} selected={cat.id === berthCategory?.id} count={cat.available_berth_count} label={cat.label} />
                </SwiperSlide>)
              }
            </Swiper>

          </div>

          <div className="flex flex-col gap-2 items-center w-full">
            {
              isLoading && <IonSpinner />
            }
            {
              list.map(item => item.is_available && <div className="flex gap-1 items-center w-full">
                <AvailableBerthItem
                  key={"availableForPlaceIn" + item.id}
                  name={item.name!}
                  type={item.contract_type?.label}
                  arrivalDetectedAt={item.sensor_occupied_since}
                  availableUntil={item.available_until}
                  catway={item.catway}
                  category={item.category?.label}
                  hasReservation={!!item.reserved}
                  selected={selected === item.id}
                  onClick={() => {
                    setSelected(item.id);
                  }}
                />
                {
                  selected === item.id &&
                  <IonButton
                    onClick={() => {
                      onReplaceAction()
                    }}
                    color="secondary">
                    <IonIcon color="light" icon={checkmark} />
                  </IonButton>
                }
              </div>)
            }
            {
              data?.next_page_number &&
              <IonButton onClick={() => {
                setPage(data?.next_page_number)
                // refetch({ portId: port?.id, page:data?.next_page_number })
              }} color="secondary" shape="round" fill="outline">
                {
                  isFetching ? <IonSpinner color="secondary" /> : t('shared.loadmore')
                }
              </IonButton>
            }
          </div>
        </>
      }

      {
        requestStatus === FDK.MainAPI.REQUEST_STATUS.PENDING &&
        <IonSpinner className="block mx-auto my-5" />

      }

      {
        requestStatus === FDK.MainAPI.REQUEST_STATUS.ERROR && <>
          <ActionStatus
            className="mt-5"
            title={resquetMessages?.title || t("dockwalk.placein.titleError")}
            message={resquetMessages?.message || ""}
            sub_message={resquetMessages?.sub_message || ""}
            state={requestStatus} />
        </>
      }
      {
        requestStatus === FDK.MainAPI.REQUEST_STATUS.SUCCESS && <>
          <ActionStatus
            className="mt-5"
            title={resquetMessages?.title || t("dockwalk.placein.titleSuccess")}
            message={resquetMessages?.message || ""}
            sub_message={resquetMessages?.sub_message || ""}
            state={requestStatus} />
        </>
      }

    </div>
  </div>
}