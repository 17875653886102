import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import AddBtn from "../buttons/AddBtn";
import cameraIcon from "../../../assets/icons/ui/camera.png"
import FieldItem from "../form/FieldItem";
import icon from "../../../assets/icons/ui/attachment.png"
import { IonIcon, IonSpinner } from "@ionic/react";
import ImageModal from "./ImageModal";
import { close } from "ionicons/icons";
import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";

type Attachment = {
  file: File | Blob,
  preview: string,
}
type GenericImagePickerProps = {
  onChange?: (files: File[] | Blob[]) => any
}
export const GenericImagePicker: FC<GenericImagePickerProps> = ({ onChange = () => { } }) => {
  const { t } = useTranslation()
  const [attachments, setAttachments] = useState<Attachment[]>([]);


  const takePicture = async () => {
    const permission = await Camera.checkPermissions()
    console.log(permission);

    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      promptLabelHeader: t('shared.imagePicker.promptLabelHeader'),
      promptLabelCancel: t('shared.cancel'),
      promptLabelPhoto: t('shared.imagePicker.promptLabelPhoto'),
      promptLabelPicture: t('shared.imagePicker.promptLabelPicture')
    });

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    var imageUrl = image.webPath || '';
    if (!imageUrl) return // TODO ERROR
    const file = await fetch(imageUrl).then(r => r.blob());
    setAttachments([...attachments, { file, preview: imageUrl }])
    onChange([...attachments.map(a => a.file), file])
  };

  const remove = (fileToRemove: string) => {
    const tmp = attachments.filter(a => a.preview !== fileToRemove)
    setAttachments(tmp)
    onChange([...attachments.map(a => a.file)])
  };

  return (
    <FieldItem
      onClick={() => { }}
      filled={attachments.length > 0}
      field={{
        title: t("ticket.formFields.attachments"),
        icon,
      }}
    >
      {attachments.length > 0 && (
        <div
          className="flex w-full overflow-x-auto gap-2"
        >
          {attachments.map((file: Attachment, index: number) => (
            <AttchamentItem
              key={"file" + index}
              preview={file.preview}
              remove={remove} />
          ))}
        </div>
      )}

      <div className="flex w-full justify-center">

        <AddBtn
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            takePicture()
          }}
          icon={cameraIcon}
        />
      </div>
    </FieldItem>
  );
}

type AttchamentItemProps = {
  isUploading?: boolean,
  preview: string,
  remove?: (preview: string) => any
}
export const AttchamentItem: FC<AttchamentItemProps> = ({ preview, isUploading, remove }) => {
  const [modalImage, setModalImage] = useState("");
  const styles = useStyles()
  return (
    <div className="relative min-w-fit">
      {isUploading ? (
        <div className={styles.image}>
          <img
            // fadeDuration={0}
            className={styles.imageContainer}
            src={preview}
            alt='services images'
          />
          <div className={styles.loaderContainer}>
            <IonSpinner className={styles.loader} name="circular" color={"secondary"} />
          </div>
        </div>
      ) : (
        <>
          <ImageModal
            onClose={() => {
              setModalImage("");
            }}
            image={modalImage}
          />
          <button className="relative" onClick={() => setModalImage(preview || "")}>
            <img alt="preview" className={styles.image} src={preview} />
            {
              !preview && <IonSpinner className="absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]" />
            }

          </button>
          {
            !!remove && <button onClick={() => remove(preview)} className={`${styles.removeBtn} drop-shadow-lg`}>
              <IonIcon size="small" color="secondary" icon={close} />
            </button>
          }

        </>
      )}
    </div>
  );
}

const useStyles = createUseStyles({
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.FONT_DARK + "80",
    borderRadius: 4,
  },
  loader: {},
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: 4,
    position: "relative",
  },
  image: {
    width: "100px",
    height: "100px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: colors.LIGHT_GREY,
  },
  imagesContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.LIGHT_GREY,
  },
  removeBtn: {
    position: "absolute",
    right: "2px",
    top: "2px",
    display: "flex",
    backgroundColor: colors.LIGHT,
    width: '30px',
    height: '30px',
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    height: 20,
    width: 20,
    marginHorizontal: 5,
  },
});
