import { useEffect, useState } from "react";
import { useAppSelector } from "../../../core/store/hooks";
import { getCategoryCountByUser } from "../../../core/store/selectors/categories";
import { getCurrentTicketsByUser, isCurrentListLoading } from "../../../core/store/selectors/tickets";
import { TabFilter } from "../../UI/tabs/TabFilter";
import { ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";

export default function MenuItem(props: {
  category: Partial<ITicketCategory>;
  all: boolean;
  [key: string]: any;
}) {
  const { category, all } = props;
  const { selectedCategory } = useAppSelector((state) => state.categories);
  const filters = useAppSelector((state) => state.tickets.filters);
  const [isSelected, setSelected] = useState(false);
  const isListLoading = useAppSelector(isCurrentListLoading);
  const allTickets = useAppSelector(filters.users.length > 0 ? getCurrentTicketsByUser : ({ tickets }) => tickets.list).length;
  const fromCategoryTicket = useAppSelector((state) => {
    if ("id" in category) {
      return getCategoryCountByUser(state, (category as ITicketCategory)?.id);
    }
    return 0;
  });
  useEffect(() => {
    if ((all && !selectedCategory) || selectedCategory === category.id) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedCategory]);

  // quantity is ever for backlog or current user. Migth need cleaning / refactor so that the parent component do that king of calculation
  // because this component is not dumb enough at the moment.
  const qty = all ? allTickets : fromCategoryTicket;
  const newProps = { ...props } as any
  delete newProps.all

  return (
    <TabFilter className="flex-1" count={qty} label={category.label || ''} isLoading={isListLoading} selected={isSelected} {...newProps} />
  );
}
