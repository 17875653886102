import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useAppSelector } from "../../core/store/hooks";
import Avatar from "../UI/users/Avatar";
import { getLocalUserById } from "../../core/store/selectors/users";
import { useTranslation } from "react-i18next";
import ImageModal from "../UI/images/ImageModal";
import ImageLoader from "../UI/images/ImageLoader";
import { ITicketMessage } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { Typography } from "@mui/material";

export default function ActivityItem({
  message,
  loadedCallBack,
  previousMessageDate,
}: {
  message: ITicketMessage;
  loadedCallBack: Function;
  previousMessageDate: string | undefined;
}) {
  const { t } = useTranslation()
  const currentUser = useAppSelector((state) => state.users.current);
  const isCurrent = message.message_from === currentUser?.id;
  const itemUser = useAppSelector((state) =>
    getLocalUserById(state, message.message_from)
  );
  const uri = itemUser ? itemUser.picture : null;
  const prevDate = dayjs(previousMessageDate);
  const date = dayjs(message.date_created);
  let nbImagesLoaded = 0;

  const imagesLoadedCB = () => {
    nbImagesLoaded++
    if (message.files!.length === nbImagesLoaded)
      loadedCallBack();
  }

  const isFromToday =
    date.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");
  const isDateDisplayed = prevDate.format("HH:mm") !== date.format("HH:mm");
  const isHourOnly = isDateDisplayed
    ? date.format("YYYY-MM-DD") === prevDate.format("YYYY-MM-DD")
    : false;

  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    if (!message?.files)
      loadedCallBack()
  }, [])

  return (
    <div className="flex flex-col w-full">
      <ImageModal
        onClose={() => {
          setModalImage("");
        }}
        image={modalImage}
      />

      {isDateDisplayed && (
        <div className="w-full flex items-center justify-center">
          <span className="text-center text-GREY my-2">
            {(isFromToday && isHourOnly) || isHourOnly
              ? dayjs(message.date_created).format(t("dateFormat.hours"))
              : dayjs(message.date_created).format(
                t("dateFormat.longDate2")
              )}
          </span>
        </div>
      )}
      {message.system_message && (
        <span className="text-center text-[#4f5358]">{message.ticket_message}</span>
      )}
      {message.system_message && message?.files && (
        <div className="w-full flex justify-center items-center">
          {message?.files.map((file: string) => {
            return (
              <button className="p-4 bg-LIGHT_GREY rounded-lg" onClick={() => setModalImage(file)} key={file}>
                <ImageLoader loadedCB={imagesLoadedCB} className="w-20 h-18 object-contain rounded-md" src={file} />
              </button>
            );
          })}
        </div>
      )}
      {!message.system_message && (
        <>
          <div className={"w-full flex flex-col"}>
            {message.user &&
              <Typography
                className={isCurrent ? "text-right" : "text-left"}
                sx={{
                  fontSize: "10px",
                }}
                variant="subtitle2"
              >
                {`${message.user.first_name} ${message.user.last_name}`}
              </Typography>
            }
          </div>
          <div className={isCurrent ? "w-full flex flex-row-reverse items-center py-1 gap-2" : "gap-2 w-full items-center py-1 flex"}>
            <Avatar className="" src={message.user ? message.user.picture : uri} />
            {message.ticket_message &&
              <span className={isCurrent ? "bg-CALCITE py-3 px-2 rounded-md mr-2 whitespace-pre-wrap" : "bg-LIGHT_GREY p-2 rounded-md whitespace-pre-wrap"}>
                {message.ticket_message}
              </span>
            }
          </div>
          {message?.files && (
            <div className="w-full flex justify-center items-center">
              {message?.files.map((file: string) => {
                return (
                  <button className="p-4 bg-LIGHT_GREY rounded-lg" onClick={() => setModalImage(file)} key={file}>
                    <ImageLoader loadedCB={imagesLoadedCB} className="w-20 h-18 object-contain rounded-md" src={file} />
                  </button>
                );
              })}
            </div>
          )}
        </>
      )
      }
    </div >
  );
}
