import { createAsyncThunk, createReducer, createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/User";
// import { fakeApiCall } from "../../../utils/FakeApi";
// import { MOCK_users } from "../../../_faker/users.mock";
import { RootState } from "../store";
import jwtDecode from "jwt-decode";
import { JWTDecoded } from "../../models/JWTDecoded";
import { updateFilter } from "./Tickets";
import FDK from "@wattsonelements/front-fdk";

export interface UsersState {
  all: User[];
  current: User | null;
  loading: boolean;
  error: any;
}

export const getAgents = createAsyncThunk(
  "users/getAgents",
  async (_args, { dispatch, getState }) => {
    console.log("get agents");
    return FDK.Agent.UserModule.getAgentsList().then((res) => {
      const state = getState() as RootState;
      const data = res.data;
      // console.log("get agents received", res.data);

      if (!state.users.current && data && data.length > 0 && state.auth.token) {
        console.log(jwtDecode(state.auth.token));
        const jwt: JWTDecoded = jwtDecode(state.auth.token);
        // console.log(jwt, 'ici')
        const loggedUser = data.find((user: User) => user.id === jwt.user_id);
        if (loggedUser) {
          dispatch(users.actions.setCurrentUser(loggedUser));
        } else {
          dispatch(users.actions.setCurrentUser(data[0]));
        }
        dispatch(updateFilter({ users: [loggedUser.id] }))
      }
      return res.data;
    });
  }
);

const initialState = {
  all: [],
  current: null,
  loading: false,
  error: null,
} as UsersState;

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.current = payload;
    },
    resetUsers: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgents.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.all = payload;
      })
      .addCase(getAgents.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default users.reducer;

export const { setCurrentUser, resetUsers } = users.actions;
