import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { saveItem } from "../../../utils/asyncStorage";
import { refresh } from "./Auth";
import axios from "axios";
import { isProd } from "./../../../utils/env";
export interface DebugState {
  logRequest: boolean;
  useCustomProtocole: boolean;
  customProtocole: string;
}

const initialState = {
  logRequest: true,
  useCustomProtocole: false,
  customProtocole: "test",
} as DebugState;

const authUrl = "api-token-auth";

export const sendDebugCredentials = (
  email: string,
  password: string,
  API_URL: string
): Promise<any> => {
  if (isProd()) return new Promise(() => {});
  return axios({
    method: "post",
    url: `${API_URL}${authUrl}/`,
    data: {
      email,
      password,
    },
  });
};

export const debugAuth = createAsyncThunk(
  "debug/auth",
  async ({ email, API_URL }: any, { dispatch }) => {
    return sendDebugCredentials(email, "poipoi!!", API_URL).then((res) => {
      saveItem("token", res.data.access);
      saveItem("refresh", res.data.refresh);
      dispatch(refresh());
      return res.data;
    });
  }
);

export const slice = createSlice({
  name: "debug",
  initialState,
  reducers: {
    toggleRequest: (state) => {
      state.logRequest = !state.logRequest;
    },
  },
  extraReducers: (builder) => {},
});
export default slice.reducer;
