import { IonButton, IonContent, IonModal, IonSpinner } from "@ionic/react"
import { FC, useEffect, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { DateTimeField } from "../../UI/form/DateTime.field"
import { ServicesField } from "../amendment/Services.field"
import { CGUComponent } from "../amendment/CGU.component"
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks"
import { useTranslation } from "react-i18next"
import { TAAmendment, TABooking } from "../../../core/models/technicalArea.models"
import dayjs from "dayjs"
import { getDateObjectFromTime } from "../../../_helpers_/datatime.helpers"
import { getTAServiceslist, updateTABookingDetail } from "../../../core/store/actions/TechnicalArea.actions"
import { updateSelectedBooking } from "../../../core/store/reducers/TechnicalArea.reducer"


type AmendmentFormProps = {
  amendment: TAAmendment,
  booking: TABooking,
  onDone: () => void
}
export const AmendmentFormEdit: FC<AmendmentFormProps> = ({ booking, amendment, onDone }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const port = useAppSelector(state => state.ports.current)

  const form = useForm({
    defaultValues: {
      start_date: dayjs(booking.start_date),
      start_time: getDateObjectFromTime(booking.start_time),
      end_date: dayjs(amendment.end_date),
      end_time: getDateObjectFromTime(amendment.end_time),
      services: [...booking.services, ...amendment.services_to_add]
        .filter(s => !amendment.services_to_remove.map(sr => sr.id).includes(s.id)),
    }
  })
  const [isSaving, setIsSaving] = useState(false)


  const submit: SubmitHandler<any> = (data) => {
    setIsSaving(true)
    const dataToSend = {
      end_date: data.end_date.format("YYYY-MM-DD"),
      end_time: data.end_time.format("HH:mm"),
      services: data.services.map((s: TABooking["services"][0]) => s.id),
      update_request_validated: false,
    }

    updateTABookingDetail(booking.id, dataToSend).then((res) => {
      dispatch(updateSelectedBooking({
        ...res.data
      }))
      onDone()
    }).finally(() => {
      setIsSaving(false)
    })
  }

  useEffect(() => {
    if (!port) return
    dispatch(getTAServiceslist(port.id))
  }, [port, dispatch])

  return <div id="update-booking-amendment">
    <form className="mb-4 flex flex-col gap-4" onSubmit={form.handleSubmit(submit)}>
      <FormProvider {...form}>
        <div className="grid grid-cols-2 gap-x-1 ">
          <DateTimeField disabled={true} name="start_date" label={t("technicalArea.amendment.startDate")} type="date" />
          <DateTimeField disabled={true} name="start_time" label={t("technicalArea.amendment.startTime")} type="time" />
          <DateTimeField name="end_date" label={t("technicalArea.amendment.leavingDate")} type="date" />
          <DateTimeField name="end_time" label={t("technicalArea.amendment.leavingTime")} type="time" />
        </div>
        <ServicesField servicesStates={booking.booking_services} />
      </FormProvider>
      <CGUComponent />
      <IonButton disabled={isSaving || !form.formState.isValid} size="large" shape="round" color="secondary" expand="block" type="submit">
        {isSaving ? <IonSpinner></IonSpinner> : t("technicalArea.amendment.create")}
      </IonButton>
    </form>
  </div>
}