// import { ProtocoleStep } from "@wattsonelements/front-fdk/dist/models/Protocol.models";
import { goToNextStep } from "../../core/protocoles/protocole.service";
import { ACTION_STEP } from "../../core/store/reducers/Protocole";
import Btn from "../UI/buttons/Btn";
import { ProtocoleStep } from "../../core/models/Protocole";

export function ProtocoleQuestion({ step }: { step: ProtocoleStep }) {
  // fonction update step
  // fonction go to next step

  return (
    <div className="flex flex-col p-5 leading-10">
      <p style={styles.title}>{step.title}</p>
      {step.answers &&
        step.answers.map((answer, index) => {
          return (
            <Btn
              color={answer.color}
              key={index}
              label={answer.label}
              onClick={() => {
                if (answer?.action?.type === ACTION_STEP.CALL_FIREMEN) {
                  // TODO
                  // Linking.openURL(`tel:${answer?.action?.phone}`);
                }
                goToNextStep(step, answer.value);
              }}
            />
          );
        })}
    </div>
  );
}

const styles = {

  title: {
    fontSize: "30pt",
    fontWeight: "bold",
    // textAlign: "center",
    padding: "40px 20px"

  },
}
