import React, { useEffect, useState } from "react";
import AddBtn from "../../UI/buttons/AddBtn";
import FieldItem from "../../UI/form/FieldItem";
import ModalEdit from "../../UI/ModalEdit";
import SelectList from "../../UI/form/SelectList";
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks";
// import { useDebouncedCallback } from "use-debounce/lib";

import { getSpotDetail, resetSearch, searchSpots } from "../../../core/store/reducers/Spots";
import Input from "../../UI/form/Input";
import { useTranslation } from "react-i18next";
import { colors } from "../../../styles/colors";
import { createUseStyles } from "react-jss";
import { useDebouncedCallback } from "use-debounce";
import { IonSpinner } from "@ionic/react";
import { ISpot } from "@wattsonelements/front-fdk/dist/models/Spot.models";
import icon from "../../../assets/icons/ui/spot.png"

export default function TicketSpot({
  value,
  update,
}: {
  value: Partial<ISpot> | string;
  update: Function;
}) {
  const { t } = useTranslation()
  const [spot, setSpot] = useState<Partial<ISpot>>();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const spotsList = useAppSelector((state) => state.spots.searchResult);
  const error = useAppSelector((state) => state.spots.searchError);
  const loading = useAppSelector((state) => state.spots.searchLoading);
  const styles = useStyles()

  useEffect(() => {
    dispatch(resetSearch());
  }, [open]);

  useEffect(() => {
    console.log("set val spot", value);

    if (value && typeof value === "string") {
      dispatch(getSpotDetail(value)).then(
        (res) => setSpot(res.payload as Partial<ISpot>)
      )
    } else if (value && typeof value === "object")
      setSpot(value)
  }, [value]);

  const onSave = (isSave: boolean) => {
    console.log("onSave");

    if (!isSave) {
      // setSpot(value);
    } else {
      update(spot);
    }
  };

  const debounced = useDebouncedCallback(
    // function
    (text: string) => {
      if (text) {
        dispatch(searchSpots(text));
      }
    },
    1000 // delay in ms
  );

  const onChangespan = (value: string) => {
    debounced(value);
  };


  return (
    <FieldItem className="flex-1" onClick={() => {
      console.log("click ???")
      setOpen(true)
    }} field={{ title: t("ticket.formFields.spot"), icon }}>
      <>

        <ModalEdit
          save={onSave}
          title={t("ticket.formFields.spot")}
          open={open}
          setOpen={() => setOpen(false)}
        >
          {spot && spot.name && (
            <span>
              {t("ticket.currentSpot")} : {spot?.name}
            </span>
          )}
          <Input
            style={{ width: "100%" }}
            placeholder={t("ticket.spotSearch")}
            onChangeText={onChangespan}
          />
          {spotsList && spotsList.length === 0 && !error && loading && (
            <IonSpinner className="block m-auto my-2" name="circular" color={colors.SECONDARY} />
          )}
          {spotsList && spotsList.length === 0 && !error && !loading && (
            <span className={styles.hint}>{t("ticket.hintResult")}</span>
          )}
          {!loading && error !== "" && (
            <span className={styles.error}>{t(error)}</span>
          )}

          <SelectList
            isSelected={(item: Partial<ISpot>) => {
              return spot?.id === item.id;
            }}
            transformText={(item: Partial<ISpot>) => {
              return `${item.name}`;
            }}
            data={spotsList}
            setdata={(spot: Partial<ISpot>, add: Boolean) => {
              console.log("spot to add", spot, add);
              add ? setSpot(spot) : setSpot(undefined)
            }
            }
          />
        </ModalEdit>
        {spot ? <span className="block my-2">{spot?.name}</span> : (
          <AddBtn
            icon={icon}
          />
        )}
      </>
    </FieldItem>
  );
}

const useStyles = createUseStyles({
  error: {
    color: colors.DANGER,
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
  },
  hint: {
    color: colors.FONT_DARK,
    fontStyle: "italic",
    fontSize: 10,
    textAlign: "center",
  },
});
