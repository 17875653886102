import { useTranslation } from "react-i18next"
import { TabFilter } from "../UI/tabs/TabFilter"
import DashboardHeader from "../layouts/DashboardHeader"
import { useHistory, useLocation } from "react-router"
import { useAppDispatch, useAppSelector } from "../../core/store/hooks"
import { selectOnAreaList, selectTodayList, selectTomorrowList, updateFilter } from "../../core/store/reducers/TechnicalArea.reducer"
import { IonBadge, IonHeader } from "@ionic/react"
import Toggle from "../UI/form/Toggle"

export const TAHeaderPageList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const { onArea } = useAppSelector(state => state.technicalArea)

  const usersSelected = useAppSelector((state) => state.technicalArea.filters.users);
  const currentUserId = useAppSelector((state) => state.users.current ? state.users.current.id : "");


  const isSelected = (path: string) => {
    return !!location.pathname.match(path)
  }
  return <IonHeader className=" mb-3">
    <DashboardHeader>
      <div className="flex bg-LIGHT items-center justify-end pr-6">
        <div className="text-right text-xs font-medium">{t("technicalArea.filter.mineOnly")}</div>
        <Toggle
          value={!!usersSelected.find((usr: string) => usr === currentUserId)}
          setValue={(checked: any) => {
            checked ? dispatch(updateFilter({ users: [currentUserId] }))
              : dispatch(updateFilter({ users: [] }))
          }
          }
          size="medium"
          color="secondary"
        />
      </div>
    </DashboardHeader>

    <div className="flex bg-white px-5 py-3">
      <TabFilter
        className="flex-auto"
        selected={isSelected("/technical-area/dashboard/today")}
        isNav={true}
        isSe
        onClick={() => {
          dispatch(selectTodayList())
          history.push('/technical-area/dashboard/today')
        }}
        count={t('technicalArea.menu.today')} />
      <TabFilter
        className="flex-auto"
        selected={isSelected("/technical-area/dashboard/tomorrow")}
        onClick={() => {
          dispatch(selectTomorrowList())
          history.push('/technical-area/dashboard/tomorrow')
        }}
        count={t('technicalArea.menu.tomorrow')} />
      <TabFilter
        className="flex-auto"
        selected={isSelected("/technical-area/dashboard/parking")}
        onClick={() => {
          history.push('/technical-area/dashboard/parking')
          dispatch(selectOnAreaList())
        }}

        count={t('technicalArea.menu.parking')} >
        <IonBadge color="light">{onArea.length} </IonBadge>
      </TabFilter>
    </div>
  </IonHeader>
}