import { IonButton, IonContent, IonIcon, IonModal, IonSpinner } from "@ionic/react"
import { useAppDispatch, useAppSelector } from "../../core/store/hooks"
import { calendarOutline, wifi } from "ionicons/icons"
import { FC, useEffect, useState } from "react"
import { getSpotDetail } from "../../core/store/reducers/Spots"
import { TagsBadge } from "../UI/TagsBadge"
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './BoatsSwiper.scss';
import { UserContactInfo } from "../UI/user/ContactInfo"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { toCreate } from "../../core/store/reducers/Tickets"
import dayjs from "dayjs"
import { PlaceBoatInBerth } from "./actions/PlaceBoatInBerth"
import { IBoat } from "@wattsonelements/front-fdk/dist/models/Boats.models"

type BerthDetailModalProps = {
  berthId: string
  boatName?: string
  boat?: Pick<IBoat, "id" | "name" | "length" | "width" | "hull_type" | "boat_type_icon"> | null
}
export const BerthDetailModal: FC<BerthDetailModalProps> = ({ berthId, boatName, boat }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const {
    selected: selectedBerth,
    spotLoading: berthLoading,
    spotDetailError: berthDetailError
  } = useAppSelector(state => state.spots)

  const [activeContract, setActiveContract] = useState(0)
  const [showReplaceModal, setShowReplaceModal] = useState(false)


  const loadDetail = () => {
    if (berthId) {
      dispatch(getSpotDetail(berthId))
    }
  }

  useEffect(() => {
    loadDetail()
  }, [berthId])

  const createTicket = () => {
    console.log('create ticket');
    dispatch(toCreate({
      spot: berthId,
      boat: selectedBerth!.contracts[activeContract]?.boat?.id,
    }))
    history.push(`/ticket/create`)
  }
  return <div>
    {
      berthDetailError &&
      <div className="p-3">
        {/* <p>Une erreur est survenue</p> */}
        <div className="flex justify-center items-center gap-4 m-4 text-2xl text-red-800">
          <IonIcon className="text-6xl" color="default" icon={wifi} />
          <div>
            <p className="font-bold text-4xl">Erreur</p>
            <p className="text-lg">Une erreur est survenue</p>
          </div>
        </div>
        <IonButton onClick={() => { loadDetail() }} expand="full" shape="round" fill="solid" color="warning">
          {t("shared.reload")}
        </IonButton>
      </div>
    }

    {
      (berthLoading) &&
      <div className="w-full h-full text-center">
        <IonSpinner />
      </div>
    }
    {!berthLoading && selectedBerth && selectedBerth.id === berthId &&
      <div className="mx-3 flex flex-col justify-center items-center">
        <h1 className="text-4xl font-bold text-center">{selectedBerth.name}</h1>
        {
          !!selectedBerth.contracts[activeContract] && <div className="flex flex-wrap justify-center my-3">
            {
              selectedBerth.contracts[activeContract]?.tags?.map((tag: any) => <TagsBadge color="red" key={`${selectedBerth.contracts[activeContract].id}-${tag}`} label={tag} />)
            }
            {
              selectedBerth.contracts[activeContract]?.paid_status && <TagsBadge color={selectedBerth.contracts[activeContract]?.paid_status!.color}
                backgroundColor={selectedBerth.contracts[activeContract]?.paid_status!.background_color}
                label={selectedBerth.contracts[activeContract]?.paid_status!.label} />
            }
          </div>
        }
        <Swiper
          spaceBetween={20}
          className="w-full overflow-visible"
          pagination={true} modules={[Pagination]}
          onSlideChange={(e) => setActiveContract(e.activeIndex)}
        >
          {selectedBerth.contracts.map((contract) =>
            <SwiperSlide key={contract.id} className="text-left justify-normal">
              <div className="flex gap-3 w-full justify-normal text-left">
                <div className="max-w-[100px] min-w-[100px] flex justify-center items-end">
                  <img style={{ width: '100%', height: 'auto' }} alt="boat type" src={selectedBerth.contracts[activeContract]?.boat?.boat_type_icon?.dark} />
                </div>
                <div className="flex-auto">
                  <div className="my-2 leading-3 text-lg">
                    {contract.contract_type}
                    <div className="flex items-center gap-2 mt-2 ">
                      <IonIcon className="text-BLUE_GREY" icon={calendarOutline} />
                      <span className="text-BLUE_GREY text-sm">{t("dockwalk.nextDeparture")}{contract.next_departure && dayjs(selectedBerth.contracts[activeContract]?.next_departure).format(t("dateFormat.daymonth"))}</span>
                    </div>
                  </div>
                  <span className="leading-6 text-2xl font-bold block">
                    {contract.owner?.first_name} {contract.owner?.last_name}
                  </span>
                  <span className="leading-6 text-xl font-bold block">{contract.boat?.name}</span>
                  {/* 🚧 TODO 🚧 charge unit from config */}
                  <span className="leading-5 text-lg block">{contract.boat?.length}m x {contract.boat?.width}m</span>
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <div className="my-5 flex w-full">
          {selectedBerth.contracts[activeContract]?.owner &&
            <UserContactInfo
              email={selectedBerth.contracts[activeContract]?.owner?.email}
              phone={selectedBerth.contracts[activeContract]?.owner?.phone} />
          }
        </div>
        <IonButton
          onClick={() => {
            createTicket()
          }}
          className="w-full"
          size="large"
          shape="round"
          expand="full"
          color="secondary">
          {t("dockwalk.createTicket")}
        </IonButton>
        {
          boat && selectedBerth && <IonButton
            onClick={() => {
              setShowReplaceModal(true)
            }}
            className="w-full"
            size="large"
            shape="round"
            expand="full"
            color="secondary">
            {t("dockwalk.moveBoat")} {boatName}
          </IonButton>
        }

      </div>
    }
    {
      selectedBerth && boat && showReplaceModal &&
      <IonModal
        isOpen={showReplaceModal}
        onDidDismiss={() => {
          setShowReplaceModal(false)
        }}>
        <IonContent>
          <PlaceBoatInBerth
            close={() => setShowReplaceModal(false)}
            boat={boat!}
            berthId={selectedBerth.id}
          />
        </IonContent>
      </IonModal>
    }

  </div>
}