import { TBookingReportItem } from "@wattsonelements/front-fdk/dist/models/Booking.models"
import classNames from "classnames"
import { FC, useEffect, useRef } from "react"

// TODO : ADD images 
type AutomaticReportProps = {
  logs: TBookingReportItem[]
}
export const AutomaticReport: FC<AutomaticReportProps> = ({ logs }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom()
  }, [logs])

  return (
    <div className="h-full rounded  flex flex-col gap-2 justify-end">
      {
        logs && logs?.map((item) => {
          return <div key={item.text} className={classNames("leading-4 mb-2", { "text-fcodanger font-bold": item.type === "alert" })}>
            {item.text}
          </div>
        })
      }
      <div ref={messagesEndRef} />
    </div>
  )
}