import { useEffect, useState } from "react";
import FDK from "@wattsonelements/front-fdk";
import { useAppSelector, useAppDispatch } from "../../core/store/hooks";
import classNames from "classnames";
import {
  saveTicket,
  createTicket,
  setTicketStatus,
  createTicketMessage,
  resetToCreate,
  ticketApi,
} from "../../core/store/reducers/Tickets";
import { resetAttachment } from "../../core/store/reducers/Attachments";
import { colors } from "../../styles/colors";
import Btn from "../UI/buttons/Btn";
import TicketBoat from "./fields/Boat";
import TicketSpot from "./fields/Spot";
// import TicketAttachment from "./Attachment";
import TicketAssignee from "./fields/Assignee";
import TicketDescription from "./fields/Description";
import Toggle from "../UI/form/Toggle";
import BtnCheckbox from "../UI/buttons/BtnCheckbox";
import Creator from "../UI/users/Creator";
import LineSeperator from "../UI/LineSeparator";
import BtnList from "../UI/buttons/BtnList";
import { getMaintenance } from "../../core/store/selectors/categories";
import Spacer from "../UI/Spacer";
import { useTranslation } from "react-i18next";
import { IonButton, IonModal, IonSpinner, useIonToast, useIonViewDidLeave } from "@ionic/react";
import { useHistory } from "react-router";
import TicketAttachment from "./Attachment";
import MapBoxMarker from "./MapBox";
import { isProtocole } from "../../core/protocoles/protocole.service";
import FieldItem from "../UI/form/FieldItem";
import TicketTitle from "./fields/Title";
import { IBerth, ITicket, ITicketBoat, ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IAssignee } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IAttachment } from "@wattsonelements/front-fdk/dist/models/Attachment.models";
import { TicketPriority } from "@wattsonelements/front-fdk/dist/constants/ticket.constants";
import dayjs from "dayjs";
import { AreaSelect } from "./fields/AreaSelect";
import DatePicker from "../protocole/DatePicker";
import { Divider } from "@mui/material";
import { IPortArea } from "@wattsonelements/front-fdk/dist/models/Port.models";
import { Typefield } from "./fields/Type.field";
import { TICKET_FIELDS } from "../../core/models/Ticket";
import { getDateObjectFromTime } from "../../_helpers_/datatime.helpers";
import { refreshOutline, syncOutline } from "ionicons/icons";
import { useResultToast } from "../../_helpers_/useResultToast";
import { TimeSpent } from "./fields/TimeSpent";


export default function TicketForm({
  selectedTicket,
  mode,
  category
}: {
  selectedTicket?: ITicket;
  mode: string;
  category: ITicketCategory | undefined;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const config = useAppSelector(state => state.tickets.config)
  const [loading, setLoading] = useState(true)
  const [formError, setFormError] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [assignees, setAssignees] = useState<IAssignee[]>([]);
  const [hasProtocole, setHasProtocole] = useState(false)
  const requestStatus = useAppSelector((state) => state.tickets.status);
  const [ticket, setTicket] = useState<any>({});
  const currentUser = useAppSelector((state) => state.users.current);
  const { toCreate } = useAppSelector((state) => state.tickets);
  const categories = useAppSelector(getMaintenance);
  // const {data:ticketConfig} = ticketApi.endpoints.getConfig.useQuerySubscription()

  const isAttachmentsDone = useAppSelector(
    (state) => state.attachments.uploading.length === 0
  );

  const history = useHistory();
  const [present, dismiss] = useResultToast();

  useIonViewDidLeave(() => {
    //cleanup file upload data on leave
    dispatch(resetAttachment());
    dispatch(resetToCreate())
    dismiss()
  });

  useEffect(() => {
    if (!category) return
    setHasProtocole(isProtocole(category?.slug))
  }, [category])

  useEffect(() => {
    if (requestStatus === FDK.MainAPI.REQUEST_STATUS.SUCCESS) {
      console.log("navigation ticket edit");
      history.replace(`/ticket/edit/${selectedTicket?.id}`)
    }
  }, [requestStatus]);

  useEffect(() => {
    if (mode === "edit") {
      if (selectedTicket) {
        console.log("setAttachments", selectedTicket.attachments);

        setTicket(selectedTicket);
        setAttachments([...selectedTicket?.attachments] as IAttachment[])
        setAssignees([...selectedTicket.assignees] as IAssignee[])
      } else
        return
    } else {
      const newticket = {
        description: "",
        category: "",
        boat: toCreate ? toCreate.boat : null,
        spot: toCreate ? toCreate.spot : null,
        plug: null,
        localization: null,
        priority: TicketPriority.NORMAL,
        attachments: [],
        assignees: [],
        deadline: null,
        time_spent: null,
        area: null,
        event_date: null,
        kind: null,
        // creator: currentUser
      };
      setTicket(newticket);
    }
    setLoading(false)
  }, [mode, selectedTicket]);

  const updateTicket = (value: any) => {
    if (mode === "edit") {
      present({
        message: t("shared.saving"),
      }, "saving")
      dispatch(saveTicket({ id: ticket.id, field: { ...value } })).then(() => {
        dismiss()
      });
    } else {
      setTicket({ ...ticket, ...value });
    }
  };

  const onSaveTicket = async () => {
    if (!ticket.category || !ticket?.title) {
      const error = [];
      if (!ticket.category) {
        error.push(t("ticket.formFieldsRequired.category_required"));
      }
      if (!ticket.title) {
        error.push(t("ticket.formFieldsRequired.title_required"));
      }
      setFormError(error);
      return;
    }
    setFormError([]);
    try {
      let res = await dispatch(createTicket(ticket));
      if (ticket.attachments && ticket.attachments.length > 0) {
        let res2 = await dispatch(createTicketMessage(
          {
            id: (res as any).payload.id,
            message: {
              message_to: currentUser!.id,
              message_from: currentUser!.id,
              files: typeof ticket.attachments[0] === "string"
                ? ticket.attachments
                : ticket.attachments.map((file: IAttachment) => file.id!)
            }
          }))
      }
      dispatch(resetToCreate())
    } catch (e) {

    }
  };

  const updateAssignee = (value: IAssignee[]) => {
    setAssignees(value)
    updateTicket({ assignees: value.map((ass: IAssignee) => ass.id!) });
  };

  const updateFile = (value: any) => {
    setAttachments(value)
    updateTicket({ attachments: value.map((file: IAttachment) => file.id!) });
  };

  const updateGenericFields = (field: string | TICKET_FIELDS | "deadline" | "event_date", value: string | null) => {
    console.log("updateGenericFields", field, value);
    updateTicket({ [field]: value })
  }

  const updateTimeSpent = (value: string) => {
    // time_spent
    console.log("time_spent", value);

    if (value) {
      updateTicket({ time_spent: value })
    } else {
      updateTicket({ time_spent: "00:00:00" })
    }
  }

  // problématique du ticket original et de si on annule pour revenir au state précédent
  if (loading)
    return <div className="px-4 pb-4 pt-0 bg-LIGHT h-full w-full flex justify-center items-center">
      <IonSpinner name="circular" color={colors.PRIMARY} />
    </div>
  return (
    <div className="px-4 pb-4 pt-0 bg-LIGHT">
      <IonModal
        // ref={modal}
        isOpen={
          (requestStatus === FDK.MainAPI.REQUEST_STATUS.PENDING ||
            requestStatus === FDK.MainAPI.REQUEST_STATUS.SUCCESS ||
            requestStatus === FDK.MainAPI.REQUEST_STATUS.ERROR)
        }
        trigger="open-modal"
      >
        <div className="flex h-full w-full bg-GREY/[0.8] justify-center items-center px-6">
          <div className="bg-CALCITE justify-center items-center flex-col flex rounded-md p-3 w-full">

            {requestStatus === FDK.MainAPI.REQUEST_STATUS.PENDING && (
              <>
                <IonSpinner name="circular" color={colors.PRIMARY} />
                <span>{t("ticket.saving")}</span>
              </>
            )}
            {requestStatus === FDK.MainAPI.REQUEST_STATUS.SUCCESS && (
              <>
                <span style={{ color: colors.SUCCESS, fontSize: 20 }}>✓</span>
                <span>{t("ticket.saved")}</span>
              </>
            )}
            {requestStatus === FDK.MainAPI.REQUEST_STATUS.ERROR && (
              <>
                <span>❌</span>
                <span>{t("ticket.uploaderror")}</span>
                <div className="flex" style={{ flexDirection: "row", marginTop: 5 }}>
                  <Btn
                    onClick={() => {
                      dispatch(setTicketStatus(FDK.MainAPI.REQUEST_STATUS.ERROR));
                    }}
                    color="secondary"
                    size="small"
                    fill="outline"
                    label={t("ticket.cancel")}
                  />
                  <Spacer hSize={5} direction="horizontal" />
                  <Btn
                    onClick={() => {
                      onSaveTicket();
                    }}
                    size="small"
                    color="secondary"
                    label={t("ticket.retry")}
                  />

                </div>
              </>
            )}
          </div>
        </div>
      </IonModal>
      <div className="bg-LIGHT">
        {mode === "create" && (
          <>
            <BtnList>
              {categories?.map((cat) => (
                <BtnCheckbox
                  iconfile={{
                    selected: cat.icon_url,
                    default: cat.icon_url,
                  }}
                  key={cat.id}
                  onClick={() => {
                    updateTicket({ category: cat.id });
                  }}
                  selected={ticket.category === cat.id}
                  label={cat.label}
                />
              ))}
            </BtnList>
            <LineSeperator />
          </>
        )}
        <div className={classNames('flex w-full justify-between gap-2 items-center', { "mt-3 my-2": mode === "edit" })}>
          {selectedTicket?.creator && mode === "edit" && (
            <Creator defaultDate={selectedTicket.date_created} creator={selectedTicket?.creator as any} />
          )
          }
          {currentUser && mode === "create" && (
            <Creator creator={currentUser} />
          )}
          <Toggle
            value={ticket.priority}
            on={TicketPriority.INTERMEDIATE}
            setValue={(val: TicketPriority) => {
              updateTicket({ priority: val })
            }
            }
            off={TicketPriority.NORMAL}
            size="medium"
            color="secondary"
            label={t("ticket.formFields.priority")}
          />

        </div>
        {(mode === "create" || (mode === "edit" && selectedTicket)) && (
          <div className="flex flex-col gap-3 mb-10">
            {/* DESCRIPTION */}
            <div className="flex flex-row w-full justify-between">
              <TicketTitle
                update={updateTicket}
                value={ticket.title}
              />
            </div>
            {/* DESCRIPTION */}
            <div className="flex flex-row w-full justify-between">
              <TicketDescription
                update={updateTicket}
                value={ticket.description}
              />
              {
                hasProtocole &&
                <>
                  <Spacer direction="horizontal" hSize={20} />
                  <FieldItem filled={false}
                    onClick={() => { }}
                    field={{ title: 'Protocole' }}>
                    <IonButton routerLink={`/ticket/edit/${ticket.id}/protocole`} shape="round" color="secondary" size="small">Protocole</IonButton>
                  </FieldItem>
                </>
              }

            </div>

            {/* ADD AGENTS */}
            <TicketAssignee
              update={updateAssignee}
              value={assignees}
            />
            {/* ATTACHMENT */}
            <TicketAttachment
              value={attachments}
              update={updateFile as any}
            />

            <MapBoxMarker update={updateTicket} value={ticket.localization} />
            {
              config?.ticket_displayed_fields.includes("area") &&
              <AreaSelect value={typeof ticket?.area === "string" ? { id: ticket.area } : ticket.area} update={(value: IPortArea) => {
                updateTicket({ area: value.id })
              }} />
            }
            <div className="flex flex-row w-full justify-between my-2 gap-3">
              {
                config?.ticket_displayed_fields.includes("spot") &&
                <TicketSpot update={(spot: IBerth) => updateTicket({ spot: (spot && spot.id) ? spot.id : null })} value={ticket.spot} />
              }
              {
                config?.ticket_displayed_fields.includes("boat") &&
                <TicketBoat update={(boat: ITicketBoat) => updateTicket({ boat: (boat && boat.id) ? boat.id : null })} value={ticket.boat} />
              }
            </div>
            <Divider />
            <div className="grid grid-cols-1 gap-3">
              {/* {JSON.stringify(ticket.event_date)} */}
              {
                config?.ticket_displayed_fields.includes("event_date") &&
                <DatePicker
                  allowDelete
                  value={ticket.event_date && new Date(ticket.event_date)}
                  label={t('ticket.formFields.eventDate')}
                  mode="date"
                  update={(val: string) => { updateGenericFields('event_date', val ? dayjs(val).format('YYYY-MM-DD') : null) }} />
              }
              {/* {JSON.stringify(ticket.deadline)} */}
              {
                config?.ticket_displayed_fields.includes("deadline") &&
                <DatePicker
                  allowDelete
                  value={ticket.deadline}
                  label={t('ticket.formFields.deadline')}
                  mode="date-time"
                  update={(val: string) => { updateGenericFields('deadline', val) }} />
              }
              {/* {JSON.stringify(ticket.time_spent)} */}
              {
                config?.ticket_displayed_fields.includes("time_spent") &&
                <TimeSpent
                  value={ticket.time_spent}
                  update={(val: string) => { updateTimeSpent(val) }} />
              }
              {
                config?.ticket_displayed_fields.includes(TICKET_FIELDS.KIND) &&
                <Typefield
                  value={ticket.kind}
                  update={(val: string) => { updateGenericFields(TICKET_FIELDS.KIND, val) }} />
              }
            </div>
          </div>
        )}

        {mode === "create" && (
          <div className="flex items-center justify-center">
            {formError.map((error) => (
              <span key={error}>{error}</span>
            ))}

            <Btn
              disabled={
                !isAttachmentsDone || !ticket.category || !ticket.title
              }
              className={classNames("w-full")}
              onClick={() => onSaveTicket()}
              size="large"
              label={t("ticket.create")}
            />
          </div>
        )}
      </div>
    </div>
  );
}