export enum COMPONENT_TYPE {
  NOTE,
  ATTACHMENT,
  QUESTION,
  RADIO,
  CHECKLIST,
  USER_CONTACT,
  FIRE_CALL,
  DATE_SELECTOR,
  DATETIME_SELECTOR,
  HOURS_SELECTOR,
  DOCKWALK_PLACEIN
}
