import React from "react";
import { createUseStyles } from "react-jss";

import Avatar from "./Avatar";

export default function AvatarList({ list, id }: { list: string[], id:string }) {
  const styles = useStyle();
  return (
    <div className={styles.div}>
      {list.map((item: string, index) => {
        return (
          <Avatar
            key={id+index}
            size={30}
            className={index > 0 ? styles.squished : styles.notsquished}
            src={item}
          ></Avatar>
        );
      })}
    </div>
  );
}

const useStyle = createUseStyles({
  div: {
    display: "flex",
    flexDirection: "row",
  },
  notsquished: {
    paddingRight: 0,
  },
  squished: {
    // transform: [{ translateX: -20 }],
    marginLeft: -20,
    paddingRight: 0,
  },
});
