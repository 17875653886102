import classNames from "classnames"
import { FC, ReactElement, ReactNode } from "react"
import babord from "../../../assets/icons/babord.svg";
import tribord from "../../../assets/icons/tribord.svg";
import { IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding } from "@ionic/react";
import { TagsBadge } from "../TagsBadge";
import { useTranslation } from "react-i18next";
import { calendar, notifications } from "ionicons/icons";
import { durationDisplay } from "../../../_helpers_/datatime.helpers";
import dayjs from "dayjs";

type AvailableBerthItemProps = {
  hasReservation?: boolean
  selected?: boolean
  reverse?: boolean,
  name: string
  catway?: "babord" | "tribord"
  category?: string | null
  availableUntil?: string | null,
  arrivalDetectedAt?: string | null,
  type?: string | null
  [key: string]: any;
}
export const AvailableBerthItem: FC<AvailableBerthItemProps> = ({
  hasReservation = false,
  selected,
  reverse,
  catway,
  category, name = "", availableUntil, arrivalDetectedAt, type, children, ...props }) => {
  const { t } = useTranslation()
  const duration = availableUntil ? durationDisplay(dayjs(), dayjs(availableUntil)) : ""

  return <button {...props} className={classNames(
    "w-full text-left rounded-2xl  flex gap-3 overflow-hidden bg-white min-h-[60px]",
    {
      "flex-row-reverse rounded-tl-md": reverse,
      "rounded-tr-md ": !reverse,
      "shadow-md outline outline-1  outline-LIGHT_GREY": selected
    }
  )}>
    <div className={classNames('w-[10px] self-stretch ', {
      "bg-GREEN": !hasReservation,
      "bg-WARN": hasReservation
    })}>
      {/* Side color - orange : as a reservation, green : available */}
    </div>
    <div className="mx-2 my-1 text-GREY text-center flex flex-col">
      <span className="text-GREY font-bold uppercase">{name}</span>
      <span>
        {catway === "babord" && <IonIcon className="text-GREY" icon={babord} />}
        {catway === "tribord" && <IonIcon className="text-GREY" icon={tribord} />}
      </span>
      <span className="text-GREY text-center text-xs font-semibold">
        {type}
      </span>
    </div>
    <div className="self-stretch w-full flex flex-col gap-2 mb-2">
      {/* BADGES LINE */}
      <div className={
        classNames("flex gap-2 w-full", {
          "justify-end": !reverse,
        })
      }>
        {
          hasReservation && <TagsBadge uppercase={false} color="bg-WARN text-white" label={t("dockwalk.berth.reserved")} />
        }
        {
          category && <TagsBadge uppercase={false} color="grey" label={category} />
        }
      </div>
      <div className="mx-6">
        {
          duration && <span className="text-GREY text-sm flex items-center gap-2"><IonIcon className="text-GREY" icon={calendar} /> {t("dockwalk.berth.available")} {duration}</span>
        }
        {
          arrivalDetectedAt && <span className="text-WARN text-sm flex items-center gap-2">
            <IonIcon className="text-WARN" icon={notifications} /> {t("dockwalk.berth.arrivalDetected")}
            {
              dayjs(arrivalDetectedAt).isSame(dayjs(), "day") ? dayjs(arrivalDetectedAt).format(t("dateFormat.hours")) :
                dayjs(arrivalDetectedAt).format(t("dateFormat.dateHour"))
            }
          </span>
        }


      </div>
    </div>
  </button>
}

type AvailableBerthItemAndSlidingActionsProps = {
  children: ReactNode,
}
export const AvailableBerthItemAndSlidingActions: FC<AvailableBerthItemAndSlidingActionsProps> = ({ children }) => {
  const { t } = useTranslation()
  return <IonItemSliding>
    <IonItemOptions
      onIonSwipe={e => {
      }}
      side="start"
    >
      <IonItemOption
        onClick={e => {
        }}
        expandable
        className="rounded-l-2xl"
        color="secondary"
      >
        <div className="p-4 text-white font-bold">
          {t('dockwalk.berths')}
        </div>
      </IonItemOption>
    </IonItemOptions>
    <IonItem
      style={{ "--inner-padding-end": "0", "--padding-start": "0", "--padding-end": "0", "--background": "transparent" }}
      lines="none"
    >
      {children}
    </IonItem>
  </IonItemSliding>
}