import * as React from "react";

import { Route, Redirect } from "react-router";
import { useAppSelector } from "../core/store/hooks";
import { RootState } from "../core/store/store";
import { IPortAgent, MODULES } from "@wattsonelements/front-fdk/dist/models/Port.models";


interface IProps {
  exact?: boolean;
  path?: string;
  component: React.ComponentType<any>;
  defaultRedirectPath?: string
  module?: MODULES | undefined | string
}

export default function PrivateRoute({
  exact = false,
  path,
  component
}: IProps) {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (isLoggedIn === true) {
          return <Route strict {...props} component={component} />;
        } else if (isLoggedIn === false) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        } else {
          return null;
        }
      }}
    />
  );
}

export const isModuleActivated = (module: MODULES | string | undefined, port: IPortAgent | null | undefined) => {
  if (!port) return false
  if (!module) return false
  if (!port?.config?.modules) return false
  if (!(module in port?.config?.modules)) return false
  if (!port?.config?.modules[module].agent) {
    return false
  }
  return true
}

export function GuardedRoute({
  exact = false,
  path = '',
  component,
  defaultRedirectPath = "/dashboard",
  module = undefined

}: IProps) {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const port = useAppSelector((state) => state.ports.current);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (isLoggedIn === "pending") return null
        if (isLoggedIn === false) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }
        if (isLoggedIn === true && isModuleActivated(module, port)) {
          return <Route strict {...props} component={component} />;
        }
        return <Redirect
          to={{
            pathname: defaultRedirectPath,
            state: { from: props.location }
          }}
        />

      }}
    />
  );
}

