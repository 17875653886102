import { FC } from "react"
import { useTranslation } from "react-i18next"
import { TABooking } from "../../core/models/technicalArea.models"
import classNames from "classnames"

import { TAStatusChip } from "./StatusChip.ta"
import { addTimeToDate, durationDisplay } from "../../_helpers_/datatime.helpers"
import { useAppSelector } from "../../core/store/hooks"

type TAItemListProps = {
  item: TABooking
  onClick?: (e: any) => any
  selected?: boolean

}
export const TAItemList: FC<TAItemListProps> = ({ selected = false, item, onClick = () => { } }) => {
  const { t } = useTranslation()
  const port = useAppSelector(state => state.ports.current)

  const displayNumber = (value: number | null) => {
    if (value === null || !!!value) {
      return "/"
    }
    return value

  }

  return <button onClick={onClick}
    className={classNames("w-full text-left rounded-2xl shadow-lg flex gap-3 overflow-hidden bg-white", { "shadow-md outline outline-2  outline-SECONDARY": selected })}>
    <div className="self-stretch flex flex-col gap-1 justify-center items-center bg-BLUE_GREY p-2 min-w-[100px]">
      <div className="aspect-square max-w-[50px] min-w-[50px] flex justify-center items-center">
        <img style={{ width: '100%', height: 'auto' }} alt="boat type" src={item.boat?.boat_type_icon?.light} />
      </div>
      <div className="w-full max-w-[50px] border-b-2 border-white mx-4 mb-2  "></div>

      <div className="flex flex-col justify-center leading-3">
        {
          !item.is_leaving && <>
            <span className="leading-4 text-xl text-center text-white font-bold">
              {addTimeToDate(item.start_date, item.start_time).format(t("dateFormat.daymonth"))}
            </span>
            {
              item.start_time && <span className="leading-4 text-center text-white text-sm font-bold">
                {addTimeToDate(item.start_date, item.start_time).format(t("dateFormat.hours"))}

              </span>
            }

            <span className="leading-4 text-center text-white text-sm font-bold">
              {durationDisplay(
                addTimeToDate(item.start_date, item.start_time),
                addTimeToDate(item.end_date, item.end_time))
              }

            </span>
          </>
        }
        {
          item.is_leaving && <>
            <span className="leading-4 text-xl text-center text-white font-bold">
              {addTimeToDate(item.end_date, item.end_time).format(t("dateFormat.daymonth"))}
            </span>
            {
              item.end_time && <span className="leading-4 text-center text-white text-sm font-bold">
                {addTimeToDate(item.end_date, item.end_time).format(t("dateFormat.hours"))}

              </span>
            }
            <span className="leading-4 text-center text-white uppercase text-sm font-bold">
              {t("technicalArea.leaving")}
            </span>
          </>
        }



      </div>

    </div>
    <div className="self-stretch px-2 pb-2 gap-1 flex flex-col w-full">
      <div className="flex justify-end gap-2 mr-3 w-full">
        {/* tags */}
        <TAStatusChip status={item.status} />

      </div>
      <div className="flex flex-col gap-2 leading-3">
        <span className="font-bold text-xl">{item.boat.name}</span>
        {/* {
          item.boat?.registration_number && <span className="font-bold">{item.boat?.registration_number}</span>
        } */}
        <div className="flex flex-col">
          <span className="font-bold">
            {displayNumber(item.boat.width)}{item.boat.width ? t(`shared.units.${port?.config.distance_unit}`) : ""} - {displayNumber(item.boat.length) || "/"}{item.boat.length ? t(`shared.units.${port?.config.distance_unit}`) : ""} - {displayNumber(item.boat.draft) || "/"}{item.boat.draft ? t(`shared.units.${port?.config.distance_unit}`) : ""}
          </span>
          <span className="text-xs opacity-60">{t("technicalArea.dimensions")}</span>
        </div>
        <div className="leading-4 text-sm">
          {
            item.services.map(s => s.name).join(", ")
          }
        </div>
      </div>


      {/* {
      item?.agents && <div className="flex items-center gap-2 mt-2">
        <Avatar size={25} src={booking?.agent?.avatar} />
        <span className="text-BLUE_GREY text-sm">
          {booking?.agent?.first_name} {booking?.agent?.last_name}
        </span>
      </div>
    } */}

      {/* <span className="text-BLUE_GREY text-sm">{t("dockwalk.nextDeparture")}{dayjs(contract.next_departure).format(t("dateFormat.daymonth"))}</span> */}
    </div>

  </button>
}