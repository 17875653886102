import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/User";
import FDK from "@wattsonelements/front-fdk";
import { saveItem } from "../../../utils/asyncStorage";
import { getAgents } from "./Users";
import { getCategories } from "./Categories";
import { getUserPort } from "./Ports";

export interface AuthState {
  sendPhoneStatus: "error" | "success" | "pending" | undefined;
  token: string | undefined;
  tokenRefresh: string | undefined;
  user: User | undefined;
  isLoggedIn: boolean | "pending";
  error: string;
}

const initialState = {
  sendPhoneStatus: undefined,
  token: undefined,
  tokenRefresh: undefined,
  user: undefined,
  isLoggedIn: "pending",
  error: "",
} as AuthState;

export const sendCode = createAsyncThunk(
  "auth/token",
  async ({ phone }: { phone: string }, { dispatch, getState }) => {
    ("send Code");
    // (API_URL)

    return FDK.Auth.getCodeForPhone(phone).then(
      (res) => {
        return res.data.message;
      },
      (error) => {
        //to test
        return Promise.reject(error);
      }
    );
  }
);

export const sendLogin = createAsyncThunk(
  "auth/login",
  async ({ phone, token }: { phone: string; token: string }, { dispatch }) => {
    return FDK.Auth.sendPhoneCredentials(phone, token).then((res) => {
      localStorage.setItem("access", res.data.access);
      localStorage.setItem("refresh", res.data.refresh);
      dispatch(getUserPort());
      return res.data;
    });
  }
);

export const Auth = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      return { ...initialState, isLoggedIn: false };
    },
    refresh: (state) => {
      state.isLoggedIn = true;
    },
    login: (state) => {
      state.isLoggedIn = true;
      state.token = localStorage.getItem("token") as string;
      state.tokenRefresh = localStorage.getItem("refresh") as string;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendCode.pending, (state, { payload }) => {
        state.sendPhoneStatus = "pending";
      })
      .addCase(sendCode.fulfilled, (state, { payload }) => {
        state.sendPhoneStatus = "success";
      })
      .addCase(sendCode.rejected, (state, { payload }) => {
        state.sendPhoneStatus = "error";
      })
      .addCase(sendLogin.pending, (state, { payload }) => {})
      .addCase(sendLogin.fulfilled, (state, { payload }) => {
        state.isLoggedIn = true;
        state.token = payload.access;
        state.tokenRefresh = payload.refresh;
      })
      .addCase(sendLogin.rejected, (state, { payload }) => {
        state.isLoggedIn = false;
      });
  },
});

export default Auth.reducer;
export const { logout, refresh, login } = Auth.actions;
