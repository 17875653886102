import { resetAttachment } from "../core/store/reducers/Attachments";
import { logout } from "../core/store/reducers/Auth";
import { resetBoats } from "../core/store/reducers/Boats";
import { resetCategories } from "../core/store/reducers/Categories";
import { resetPorts } from "../core/store/reducers/Ports";
import { resetProtocole } from "../core/store/reducers/Protocole";
import { resetSpots } from "../core/store/reducers/Spots";
import { resetTickets } from "../core/store/reducers/Tickets";
import { resetUsers } from "../core/store/reducers/Users";

/**
 * reset the whole store | useful in debug
 * @param dispatch dispatch function from store
 */
export const resetAll = (dispatch: Function) => {
  dispatch(resetAttachment());
  dispatch(resetBoats());
  dispatch(resetCategories());
  dispatch(resetPorts());
  dispatch(resetProtocole());
  dispatch(resetSpots());
  dispatch(resetTickets());
  dispatch(resetUsers());
  dispatch(logout());
};
