import i18n from "../../utils/langs";
import { ITicket, ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IPortAgent } from "@wattsonelements/front-fdk/dist/models/Port.models";
import FDK from "@wattsonelements/front-fdk";
import { COMPONENT_TYPE } from "./constants";
import { ACTION_STEP } from "@wattsonelements/front-fdk/dist/constants/protocol.constants";

enum STEPS {
  HAS_FIRE,
  NO_FIRE_REPORT,
  YES_FIRE_DAMAGES,
  YES_FIRE_PICTURE,
  NO_FIRE_PICTURE,
  SEND,
}

// will need harbor access for phone
export function generateSmokeStepDefinition(
  ticket: ITicket,
  category: ITicketCategory,
  port: IPortAgent
) {
  return {
    [STEPS.HAS_FIRE]: {
      isNode: true,
      title: i18n.t("protocole.smoke.confirmFire"),
      stepNumber: 1,
      name: "STEPS.HAS_FIRE",
      answers: [
        {
          label: i18n.t("protocole.smoke.yes"),
          value: "yes",
          color: "secondary",
          action: {
            type: ACTION_STEP.CALL_FIREMEN,
            phone: port.config?.fire_departement_phone,
          },
          nextStep: STEPS.YES_FIRE_DAMAGES,
        },
        {
          label: i18n.t("protocole.smoke.no"),
          value: "no",
          color: "secondary",
          nextStep: STEPS.NO_FIRE_REPORT,
        },
      ],
    },
    [STEPS.YES_FIRE_DAMAGES]: {
      isNode: false,
      title: i18n.t("protocole.smoke.damages"),
      name: "YES_FIRE_DAMAGES",
      stepNumber: 2,
      nextStep: STEPS.YES_FIRE_PICTURE,
      previousStep: STEPS.HAS_FIRE,
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.RADIO,
          multiple: true,
          required: true,
          data: [
            { value: false, name: "low", label: i18n.t("protocole.smoke.low") },
            {
              value: false,
              name: "medium",
              label: i18n.t("protocole.smoke.medium"),
            },
            {
              value: false,
              name: "high",
              label: i18n.t("protocole.smoke.high"),
            },
          ],
        },
      ],
    },
    [STEPS.YES_FIRE_PICTURE]: {
      isNode: false,
      title: i18n.t("protocole.smoke.imageControl"),
      name: "YES_FIRE_PICTURE",
      stepNumber: 3,
      nextStep: null,
      previousStep: STEPS.YES_FIRE_DAMAGES,
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.ATTACHMENT,
          multiple: true,
          required: false,
        },
      ],
    },
    [STEPS.NO_FIRE_REPORT]: {
      isNode: false,
      title: i18n.t("protocole.smoke.control"),
      stepNumber: 2,
      name: "NO_FIRE_REPORT",
      nextStep: STEPS.NO_FIRE_PICTURE,
      previousStep: STEPS.HAS_FIRE,
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.CHECKLIST,
          multiple: true,
          required: true,
          data: [
            {
              value: false,
              name: "Pas de départ de feu constaté",
              label: i18n.t("protocole.smoke.noFire"),
            },
            {
              value: false,
              name: "Pas de fumée apparente",
              label: i18n.t("protocole.smoke.noSmoke"),
            },
            {
              value: false,
              name: "Pas d'odeur",
              label: i18n.t("protocole.smoke.noSmell"),
            },
          ],
        },
      ],
    },
    [STEPS.NO_FIRE_PICTURE]: {
      isNode: false,
      title: i18n.t("protocole.smoke.boatState"),
      stepNumber: 2,
      name: "NO_FIRE_PICTURE",
      nextStep: null,
      previousStep: STEPS.NO_FIRE_PICTURE,
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.ATTACHMENT,
          multiple: true,
          required: false,
        },
        {
          order: 2,
          component: COMPONENT_TYPE.NOTE,
          name: i18n.t("protocole.smoke.note"),
          required: true,
        },
      ],
    },
  };
}
