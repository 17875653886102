import { IonIcon, IonNav, IonNavLink, IonSpinner } from "@ionic/react";
import classNames from "classnames";
import { FC, forwardRef, ReactElement, ReactNode } from "react";

type TabFilterProps = {
  isLoading?: boolean;
  count?: number | string | null | undefined;
  [key: string]: any;
  label?: string;
  selected?: boolean;
  isNav?: boolean;
  iconRight?: string | null,
  iconLeft?: string | null
  children?: ReactNode | ReactElement | ReactNode[] | ReactElement[]
};

export const TabFilter: FC<TabFilterProps> = forwardRef(
  ({ className, iconRight, iconLeft, isNav = false, label, children, selected = false, count, isLoading = false, ...props }, ref: any) => {
    return (
      <Wrapper
        isNav={isNav}
        ref={ref}
        className={classNames(className, "min-w-[65px] flex justify-center items-center gap-1 p-1", { "bg-PRIMARY rounded-lg": selected })}
        {...props}
      >
        {isLoading && <IonSpinner name="crescent" color="secondary" />}
        <div className="flex flex-col justify-center items-center w-full">
          {!isLoading && (count != null || count !== undefined) && (
            <p
              className={classNames("leading-4 min-h-[25px] text-md font-bold flex justify-center items-center", {
                "text-CALCITE": selected,
                "text-FONT_DARK": !selected,
              })}
            >
              {count}
            </p>
          )}
          {label && (
            <p
              className={classNames("relative flex flex-1 text-[10px] leading-3 font-bold overflow-ellipsis text-center", {
                "text-CALCITE": selected,
                "text-FONT_DARK": !selected,
              })}
            >
              {
                iconLeft && selected && <IonIcon className="absolute right-full" color="white" icon={iconLeft} />
              }
              {label}
              {
                iconRight && selected && <IonIcon className="absolute left-full" color="white" icon={iconRight} />
              }

            </p>
          )}
        </div>
        {children}
      </Wrapper>
    );
  }
);

type WrapperProps = {
  isNav: boolean;
  [key: string]: any;
  children?: ReactNode;
};
const Wrapper: FC<WrapperProps> = forwardRef(({ isNav, children, ...props }, ref: any) => {
  if (isNav) {
    return (
      <IonNavLink ref={ref} {...props}>
        {children}
      </IonNavLink>
    );
  }

  return (
    <button ref={ref} {...props}>
      {children}
    </button>
  );
});
