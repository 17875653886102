import packageJson from "../../package.json";

export default function useVersion() {
  
  const checkVersion = async () => {
    try {
      // let res = await fetch("/build.json?" + new Date().getTime())
      // let meta = await res.json();
      // const latestVersion = meta.version;
      const currentVersion = packageJson.version;
      return currentVersion;
      // TODO check if there is a better way to check
    //   const shouldForceRefresh = latestVersion !== currentVersion
    //   if (shouldForceRefresh) {
    //     refreshCacheAndReload();
    //     return null;
    //   } else {
    //     return latestVersion;
    //   }
    } catch (e) {
    }
    return "";
  }


  // const refreshCacheAndReload = () => {
  //   if (caches) {
  //     // Service worker cache should be cleared with caches.delete()
  //     caches.keys().then((names) => {
  //       for (const name of names) {
  //         caches.delete(name);
  //       }
  //     });
  //   }
  //   // delete browser cache and hard reload
  //   window.location.reload();
  // };

  return { checkVersion }
}