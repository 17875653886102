import { IonButton, IonContent, IonModal, IonSearchbar, IonSpinner } from "@ionic/react";
import FDK from "@wattsonelements/front-fdk";
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api";
import { TContractDockWalk } from "@wattsonelements/front-fdk/dist/models/Boats.models";
import { IGenericResponse } from "@wattsonelements/front-fdk/dist/models/Misc.models";
import { ISpot } from "@wattsonelements/front-fdk/dist/models/Spot.models";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../core/store/hooks";
import { getArrivingContract } from "../../core/store/reducers/Dockwalk";
import { ActionStatus } from "../UI/ActionStatus";
import { ContractItem } from "../UI/boats/ContractItem";
import { TabsMenu } from "../UI/tabs/Tabs";
import { TagsBadge } from "../UI/TagsBadge";
import { DraftPlaceIn, TemporarySheet } from "./TemporarySheet";
import { useTranslation } from "react-i18next";
import { IAgentBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { IDockwalkBerth } from "@wattsonelements/front-fdk/dist/models/Dockwalk.models";

enum TAB {
  TEMPORARY_SHEET = "temporary",
  RESERVATION = "reservation",
}

type PlaceInBoatProps = {
  berth: Partial<IAgentBerth | IDockwalkBerth>;
  hasCreateBtn: boolean;
  title?: string;
  displayHeader?: boolean;
  onPlaceInAction: (e: any, contract: Partial<TContractDockWalk>) => any;
  onDraftPlaceInAction?: (draf: DraftPlaceIn) => any;
  onDraftPlaceInActionChange?: (draf: DraftPlaceIn) => any;
  status: REQUEST_STATUS;
  actionMessages?: IGenericResponse | null;
  [key: string]: any;
};
/**
 * Select a boat to associate to a berth
 * @param props
 * @returns Modal
 */
export const PlaceInBoatComponent: FC<PlaceInBoatProps> = ({
  berth,
  title,
  hasCreateBtn,
  displayHeader = true,
  actionMessages,
  onPlaceInAction,
  onDraftPlaceInAction = () => { },
  onDraftPlaceInActionChange = () => { },
  status = REQUEST_STATUS.IDLE,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const { arriving } = useAppSelector(state => state.dockWalk);
  // const [status, setstatus] = useState<number>(FDK.MainAPI.REQUEST_STATUS.IDLE)
  const [searchLoading, setSearchLoading] = useState(true);
  const [nextPage, setNextPage] = useState<number>(1);
  const [searchText, setSearchText] = useState("");
  const [tab, setTab] = useState<TAB | null>(null);
  const [selectedContract, setSelectedContract] = useState<Partial<TContractDockWalk> | null>(null);
  const [associatedReservation, setAssociatedReservation] = useState<TContractDockWalk[]>([]);
  const [associatedReservationLoading, setAssociatedReservationloading] = useState(true);

  useEffect(() => {
    setSearchLoading(true);

    FDK._helpers.withParams(FDK.Agent.DockwalkModule.getArriving, { page: 1, berth: berth?.id || "" }).then(
      res => {
        setAssociatedReservation(res.data.results)
        if (res.data.results.length > 0) {
          setTab(TAB.RESERVATION)
        } else {
          setTab(TAB.TEMPORARY_SHEET)
        }
      },
      err => { }
    ).finally(() => {
      setAssociatedReservationloading(false)
    })

    dispatch(getArrivingContract({ params: { page: 1 } }))
      .then(res => {
        setNextPage(res.payload?.next_page_number);
      })
      .finally(() => {
        setSearchLoading(false);
      });
    return () => {
      setSearchText("");
      setNextPage(1);
      setSearchLoading(true);
      // status = FDK.MainAPI.REQUEST_STATUS.IDLE
    };
  }, [berth]);

  const placeInAction = (e: any, contract: Partial<TContractDockWalk>) => {
    onPlaceInAction(e, contract);
  };

  const search = (event: any) => {
    setSearchLoading(true);
    setSearchText(event.detail.value);
    dispatch(getArrivingContract({ params: { search: event.detail.value } })).finally(() => {
      setSearchLoading(false);
    });
  };

  const getNextPage = () => {
    dispatch(getArrivingContract({ params: { search: searchText, page: nextPage, berth: berth?.id || "" } }));
  };

  return (
    <div>
      <div className="p-3">
        {status === FDK.MainAPI.REQUEST_STATUS.IDLE && (
          <>
            {displayHeader && (
              <header className="pb-4">
                {title && <h2 className="font-bold text-center text-4xl">{title}</h2>}
                {berth && (
                  <p className="flex flex-gap justify-center items-center gap-2">
                    <span className="text-base font-bold">
                      {t("shared.berth")} {berth.name}
                    </span>
                    {berth?.category?.label && <TagsBadge color="grey" size="medium" uppercase={false} label={berth?.category?.label} />}
                  </p>
                )}
              </header>
            )}
            {
              tab && <TabsMenu
                className="mb-6"
                selected={tab}
                selectKey="slug"
                onSelected={(e, tab) => {
                  setTab(tab);
                }}
                tabs={[
                  {
                    label: t("dockwalk.placein.tab.temporary"),
                    slug: TAB.TEMPORARY_SHEET,
                  },
                  {
                    label: t("dockwalk.placein.tab.reservation"),
                    slug: TAB.RESERVATION,
                  },
                ]}
              />
            }

            <div className="overflow-auto max-h-[75vh] pb-40">
              <div className="flex flex-col gap-5">
                {(!tab && (searchLoading || associatedReservationLoading)) && <IonSpinner className="mx-auto my-2" />}
                {tab === TAB.RESERVATION && (
                  <>
                    {
                      arriving && arriving.length > 0 && <IonSearchbar
                        onIonChange={search}
                        style={{ '--icon-color': 'var(--ion-color-primary', '--box-shadow': "0", '--border-radius': "40px", padding: "2px 8px" }}
                        className="rounded-full border-2 border-SECONDARY"
                        placeholder={t("dockwalk.placein.search")} />
                    }

                    {(searchLoading || associatedReservationLoading) && <IonSpinner className="mx-auto my-2" />}

                    <div className="flex flex-col gap-3">
                      {!associatedReservationLoading && associatedReservation && associatedReservation.length > 0 && (
                        <div className="border-b border-LIGHT_GREY mb-2 pb-4 flex flex-col gap-3">
                          <div className="text-center font-bold">
                            {t("dockwalk.placein.bookingForBerth")}
                          </div>
                          {associatedReservation.map(contract => (
                            <ContractItem
                              selected={selectedContract?.id === contract?.id}
                              onClick={(e: any) => {
                                placeInAction(e, contract);
                                setSelectedContract(contract);
                              }}
                              key={contract.id}
                              contract={contract}
                            />
                          ))}
                        </div>
                      )}
                      {
                        !associatedReservationLoading && (!associatedReservation || associatedReservation.length === 0) &&
                        <div className="border rounded border-LIGHT_GREY mb-2 p-2 flex items-center justify-center ">
                          {t("dockwalk.placein.noBookingForBerth")}
                        </div>
                      }
                      {
                        !searchLoading &&
                        arriving && arriving.length > 0 &&
                        <div className="pb-2 font-bold">
                          {t("dockwalk.placein.otherBookings")}
                        </div>
                      }
                      {!searchLoading &&
                        arriving &&
                        arriving.map(contract => (
                          <ContractItem
                            selected={selectedContract?.id === contract?.id}
                            onClick={(e: any) => {
                              placeInAction(e, contract);
                              setSelectedContract(contract);
                            }}
                            key={contract.id}
                            contract={contract}
                          />
                        ))}
                      {!searchLoading && nextPage && nextPage > 1 && (
                        <IonButton
                          onClick={() => {
                            getNextPage();
                          }}
                          color="secondary"
                          shape="round"
                          fill="outline"
                        >
                          {t("shared.loadmore")}
                        </IonButton>
                      )}
                      {!searchLoading &&
                        (!associatedReservation || (associatedReservation && associatedReservation.length === 0)) &&
                        (!arriving || (arriving && arriving.length === 0)) && (
                          <div className="text-center italic">Aucune réservation trouvée</div>
                        )}
                    </div>
                  </>
                )}
                {tab === TAB.TEMPORARY_SHEET && (
                  <>
                    <TemporarySheet createButton={hasCreateBtn} onSend={onDraftPlaceInAction} onChange={onDraftPlaceInActionChange} />
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {status === FDK.MainAPI.REQUEST_STATUS.ERROR && (
          <>
            <ActionStatus
              title={actionMessages?.title || t("dockwalk.placein.titleError")}
              message={actionMessages?.message || t("dockwalk.placein.error")}
              sub_message={actionMessages?.sub_message || t("dockwalk.placein.errorHint")}
              state={status}
            />
          </>
        )}
        {status === FDK.MainAPI.REQUEST_STATUS.SUCCESS && (
          <>
            <ActionStatus
              title={actionMessages?.title || t("dockwalk.placein.titleSuccess")}
              message={actionMessages?.message || ""}
              sub_message={actionMessages?.sub_message || ""}
              state={status}
            />
          </>
        )}
        {status === FDK.MainAPI.REQUEST_STATUS.PENDING && <ActionStatus title={t("dockwalk.placein.titleLoading")} state={status} />}
      </div>
    </div>
  );
};
