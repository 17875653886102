import { IonButton, IonContent, IonIcon, IonModal, IonTextarea } from "@ionic/react"
import { FC, useEffect, useState } from "react"
import { getTABookingDetail, setBookingToDone } from "../../core/store/actions/TechnicalArea.actions"
import { ServicesDonesData, TAAmendment } from "../../core/models/technicalArea.models"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../core/store/hooks"
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api"
import { IGenericResponse } from "@wattsonelements/front-fdk/dist/models/Misc.models"
import { ActionStatus } from "../UI/ActionStatus"
import AddBtn from "../UI/buttons/AddBtn"
import FieldItem from "../UI/form/FieldItem"
import { GenericImagePicker } from "../UI/images/GenericImagepicker"
import iconComment from "../../assets/icons/ui/description.png"
import { warning } from "ionicons/icons"

enum STEP_VALIDATION_SERVICES {
  REPORT,
  END
}

type TADoneActionProps = {
  isUpdating: boolean,
  amendment?: TAAmendment | null
}
export const TADoneAction: FC<TADoneActionProps> = ({ isUpdating, amendment }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const booking = useAppSelector(state => state.technicalArea.detail)

  const [comment, setComment] = useState<string>("")
  const [showComment, setShowComment] = useState(!!booking?.closing_comment)
  const [files, setFiles] = useState<File[] | Blob[]>([])

  const [isOpen, setIsOpen] = useState(false)
  const [saveStatus, setSaveStatus] = useState<number>(REQUEST_STATUS.IDLE)
  const [response, setResponse] = useState<IGenericResponse>()

  const [step, setStep] = useState(STEP_VALIDATION_SERVICES.REPORT)


  const submit = async () => {
    if (!booking) return
    setSaveStatus(REQUEST_STATUS.PENDING)
    const data: Partial<Pick<ServicesDonesData, "closing_comment" | "attachments">> = {
      attachments: files,
      closing_comment: comment
    }
    const getFormData = (object: any) => Object.keys(object).reduce((formData, key: string) => {
      if (Array.isArray(object[key])) {
        for (var i = 0; i < object[key].length; i++) {
          if ((object[key][i] instanceof File) ||
            (object[key][i] instanceof Blob)) {
            formData.append(`${key}`, object[key][i]);
          } else if (typeof object[key][i] === "object") {
            formData.append(`${key}`, JSON.stringify(object[key][i]));
          }
        }
        return formData;
      }
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    const formData = getFormData(data)
    setBookingToDone(booking.id, formData).then(async res => {
      setResponse(res.data)
      setSaveStatus(REQUEST_STATUS.SUCCESS)
      await dispatch(getTABookingDetail(booking.id)).finally(() => { })
    }, err => {
      setSaveStatus(REQUEST_STATUS.ERROR)
    })
  }

  useEffect(() => {
    if (!booking) return
    if (comment) return
    setComment(booking.closing_comment)
  }, [booking])

  return <>
    <IonButton disabled={isUpdating} onClick={() => {
      setIsOpen(true)
    }}
      size="large"
      color="secondary"
      shape="round"
      expand="full">{t("technicalArea.detail.actions.boatLeft")}</IonButton>

    <IonModal
      isOpen={isOpen}
      handle
      onDidDismiss={() => {
        setIsOpen(false)
        setStep(STEP_VALIDATION_SERVICES.REPORT)
      }}
      className="bottom-modal"
    // initialBreakpoint={0.75}
    // breakpoints={[0, .5, .75, 1]}
    >
      <IonContent>
        <div className="p-6">
          {
            step === STEP_VALIDATION_SERVICES.REPORT &&
            <div>
              {amendment && <div className="border-2 flex gap-4 items-center border-WARN rounded-md px-5 py-2 mb-4  bg-WARN_LIGHT ">
                <IonIcon color="warning" className="text-[60px]" icon={warning}></IonIcon>
                <p className="leading-4 text-sm ">{t("technicalArea.detail.actions.done.stepReport.hasPendingAmendment")}</p>
              </div>}

              <h2 className="text-2xl font-bold mb-2">
                {t("technicalArea.detail.actions.done.stepReport.title")}
              </h2>
              <p className="text-sm italic">
                {t("technicalArea.detail.actions.done.all.info")}
              </p>

              <div className="flex flex-col gap-8 mt-8">
                <GenericImagePicker onChange={(attachments) => {
                  setFiles(attachments)
                }} />
                {
                  booking && <FieldItem
                    filled={showComment ? true : false}
                    field={{ title: t("technicalArea.detail.actions.done.stepReport.comment") }}
                  >
                    {showComment &&
                      <IonTextarea
                        rows={4}
                        placeholder={t('technicalArea.detail.commentPlaceholder')}
                        autofocus
                        value={comment}
                        onIonChange={(e) => setComment(e.target.value || "")}
                      ></IonTextarea>
                    }

                    {(!comment && !showComment) && (
                      <AddBtn
                        onClick={() => {
                          setShowComment(true)
                        }}
                        icon={iconComment}
                      />
                    )}
                  </FieldItem>
                }

                <IonButton
                  expand="full"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    submit()
                    setStep(STEP_VALIDATION_SERVICES.END)

                  }}
                  shape="round">{t("technicalArea.detail.actions.done.all.save")}</IonButton>

              </div>

            </div>
          }

          {
            step === STEP_VALIDATION_SERVICES.END &&
            <div>

              <ActionStatus
                className="mt-5"
                title={response?.title}
                message={response?.message || ""}
                sub_message={response?.sub_message || ""}
                state={saveStatus}
              />


            </div>
          }

        </div>

      </IonContent>
    </IonModal>
  </>
}