import { IonContent, IonHeader, IonLoading, IonModal, IonPage, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonViewWillEnter } from "@ionic/react"
import DashboardHeader from "../components/layouts/DashboardHeader"
import { TabFilter } from "../components/UI/tabs/TabFilter"
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router"
import { useAppDispatch, useAppSelector } from "../core/store/hooks"
import { BookingItem } from "../components/crane/BookingItem"
import { useEffect, useState } from "react"
import { getCraneBooking } from "../core/store/reducers/Crane"
import { BOOKING_STATUS, IBooking } from "@wattsonelements/front-fdk/dist/models/Booking.models"
import { CraneModal } from "../components/crane/CraneModal"
import dayjs from "dayjs"
import { ChangeDateSelector } from "../components/crane/ChangeDate.component";

export const CranePage = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { land, water } = useAppSelector(state => state.crane)
  const [openActionModal, setOpenActionModal] = useState(false)
  const [selectedBooking, setSelectedBooking] = useState<IBooking | null>(null)

  const [listLoading, setListLoading] = useState(true)
  const [date, setDate] = useState(dayjs())


  const isSelected = (path: string) => {
    return !!location.pathname.match(path)
  }

  const loadData = (event?: CustomEvent<RefresherEventDetail>) => {
    console.log(event);

    if ((!!!event)) {
      setListLoading(true)
    }
    dispatch(getCraneBooking({
      status: BOOKING_STATUS.BOOKED,
      date: date.format('YYYY-MM-DD'),
      // date__gte: date.format('YYYY-MM-DD'),
      // date__lte: date.add(1, 'day').format('YYYY-MM-DD'),
      ordering: "date"
    })).finally(() => {
      event && event.detail.complete()
      setListLoading(false)
    });
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  useIonViewWillEnter(() => {
    loadData()
    history.replace("/crane/land")
  }, [])

  return <IonPage>
    <IonHeader className="bg-LIGHT">
      <DashboardHeader size="small">
        <ChangeDateSelector onChange={(date) => { setDate(date) }} />
      </DashboardHeader>
      <div className="flex bg-white px-5 py-3">
        <TabFilter className="flex-auto" selected={isSelected("/crane/land")} isNav={true} onClick={() => { history.push('/crane/land') }} count={t('crane.menu.land')} />
        <TabFilter className="flex-auto" selected={isSelected("/crane/water")} onClick={() => { history.push('/crane/water') }} count={t('crane.menu.water')} />
      </div>
    </IonHeader>
    <IonContent>
      <IonRefresher slot="fixed" onIonRefresh={loadData}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonLoading showBackdrop={false} backdropDismiss={false} spinner="circles" isOpen={listLoading} />

      <div className="p-5 bg-LIGHT h-full flex flex-col gap-3">
        {
          isSelected("/crane/land") && land.map((booking, i) => {

            return <>
              {
                i === 0 && dayjs().isSame(booking.date, 'day') &&
                <span className="text-center text-sm text-slate-400">{t('shared.today')}</span>
              }
              {
                ((i === 0 && dayjs().isBefore(booking.date, 'day'))
                  || (i > 0 && dayjs(land[i - 1].date).isBefore(booking.date, 'day'))) &&
                <span className="text-center text-sm text-slate-400">{t('shared.tomorrow')}</span>
              }
              <BookingItem onClick={() => {
                setOpenActionModal(true)
                setSelectedBooking(booking)
              }}
                booking={booking} /></>
          })
        }
        {
          isSelected("/crane/water") && water.map((booking, i) => <>
            {
              i === 0 && dayjs().isSame(booking.date, 'day') &&
              <span className="text-center text-sm text-slate-400">{t('shared.today')}</span>
            }
            {
              ((i === 0 && dayjs().isBefore(booking.date, 'day'))
                || (i > 0 && dayjs(water[i - 1].date).isBefore(booking.date, 'day'))) &&
              <span className="text-center text-sm text-slate-400">{t('shared.tomorrow')}</span>
            }
            <BookingItem
              onClick={() => {
                setOpenActionModal(true)
                setSelectedBooking(booking)
              }}
              booking={booking} />
          </>)
        }
      </div>
      <IonModal
        isOpen={openActionModal}
        className="swipe-modal"
        initialBreakpoint={0.75}
        breakpoints={[0, .75, .9]}
        onDidDismiss={() => {
          // setActionContract(null)
          // setActionStatus(FDK.MainAPI.REQUEST_STATUS.IDLE)
          // setActionType(null)
          // setActionMessage(null)
          setSelectedBooking(null)
          setOpenActionModal(false)
          loadData()
        }}>
        <IonContent>
          <div className="p-3 text-center">
            <CraneModal booking={selectedBooking!} />
          </div>
        </IonContent>
      </IonModal>
    </IonContent>
  </IonPage >
}