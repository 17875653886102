import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import FDK from "@wattsonelements/front-fdk"
import { TBerthCategory } from "@wattsonelements/front-fdk/dist/models/Berth.models"
import { IDockwalkBerth, IPontoonBerthList, IPontoonStats } from "@wattsonelements/front-fdk/dist/models/Dockwalk.models"
import { IPaginatedRequest, TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models"

export const dockwalkApi = createApi({
  reducerPath: 'dockwalkApi',
  baseQuery: fetchBaseQuery({
    baseUrl: "v2/dockwalk",
  }),
  tagTypes: ['BoatAction'],
  endpoints: (builder) => {
    return {
      getBerths: builder.query<IPaginatedRequest<IDockwalkBerth> | null, { portId: string, page?: number, search?: string }>({
        queryFn: async ({ portId, page = 1, search = "" }, { getState }, extraOptions, baseQuery) => {
          if (portId) {
            const res = await FDK.Agent.DockwalkModule.getAllBerth({ port_id: portId, page, search })
            return { data: res.data }
          }
          return { data: null }
        },
        providesTags: ["BoatAction"]
      }),
      getBerthsForPontoon: builder.query<IPontoonBerthList | null, { portId: string, pontoonId: string }>({
        queryFn: async ({ portId, pontoonId }, { getState }, extraOptions, baseQuery) => {
          if (portId && pontoonId) {
            const res = await FDK.Agent.DockwalkModule.getPontoonBerthsList(pontoonId, { port_id: portId })
            return { data: res.data }
          }
          return { data: null }
        },
        providesTags: ["BoatAction"]
      }),
      getPontoons: builder.query<IPontoonStats[], string>({
        queryFn: async (portId, { getState }, extraOptions, baseQuery) => {
          if (portId) {
            const res = await FDK.Agent.DockwalkModule.getPontoonStats({ port_id: portId })
            return { data: res.data }
          }
          return { data: [] }
        },
      },
      ),
      getBerthsForCategories: builder.query<IPaginatedRequest<IDockwalkBerth> | null, { portId: string, categoryId: number, search?: string, page?: number }>({
        queryFn: async ({ portId, categoryId, search = "", page = 1 }, { getState }, extraOptions, baseQuery) => {
          if (portId) {
            const res = await FDK.Agent.DockwalkModule.getBerthsAvailableForCategory(categoryId, { port_id: portId, search, page })
            return { data: res.data }
          }
          return { data: null }
        },
      }),
      getAllBerthsAvailable: builder.query<IPaginatedRequest<IDockwalkBerth> | null, { portId: string, search?: string }>({
        queryFn: async ({ portId, search = "" }) => {
          if (portId) {
            const res = await FDK.Agent.DockwalkModule.getAllBerthsAvailable({ port_id: portId, search })
            return { data: res.data }
          }
          return { data: null }
        }
      }),
      getBerthsCategoryStats: builder.query<TBerthCategory[], string>({
        queryFn: async (portId, { getState }, extraOptions, baseQuery) => {
          if (portId) {
            const res = await FDK.Agent.DockwalkModule.getBerthsCategoryStats({ port_id: portId })
            return { data: res.data }
          }
          return { data: [] }
        },
      }),
    }
  },
}
)

export const { getBerths, getPontoons, getBerthsForPontoon, getBerthsCategoryStats } = dockwalkApi.endpoints
export const { useGetBerthsQuery,
  useGetBerthsForPontoonQuery,
  useGetPontoonsQuery,
  useGetBerthsForCategoriesQuery,
  useGetBerthsCategoryStatsQuery,
  useGetAllBerthsAvailableQuery
} = dockwalkApi
