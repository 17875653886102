import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../../core/store/hooks"
import { useGetAllBerthsAvailableQuery } from "../../../core/store/api/dockwalk.api"
import { skipToken } from "@reduxjs/toolkit/query"
import { useEffect, useState } from "react"
import { IonButton, IonSpinner } from "@ionic/react"
import { AvailableBerthItem } from "../../UI/berths/AvailableBerth.item"
import { PlaceInBoatModal } from "../PlaceInBoat.modal"
import { IDockwalkBerth } from "@wattsonelements/front-fdk/dist/models/Dockwalk.models"

export const AllAvailableBerthsList = () => {
  const port = useAppSelector(state => state.ports.current)
  const { search, searchScope } = useAppSelector(state => state.dockWalk)
  const { t } = useTranslation()
  const {
    isLoading,
    data,
    isFetching
  } = useGetAllBerthsAvailableQuery(port?.id ? { portId: port.id, search: search && searchScope === "availableBerths" ? search : "" } : skipToken,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  )

  const [list, setList] = useState((data && data.results) || [])
  const [page, setPage] = useState(1)

  const [openModal, setOpenModal] = useState(false)
  const [selected, setSelected] = useState<IDockwalkBerth | null>(null)


  useEffect(() => {
    if (!data) return
    if (page > 1) {
      setList([...list, ...data.results])
    } else {
      setList([...data.results])
    }

  }, [data])

  if (isLoading) return <IonSpinner className="mx-auto  block" />

  return <div className="flex flex-col gap-2  pb-10">
    {
      isFetching && <IonSpinner className="mx-auto  block" />
    }
    {
      list.map(item => <AvailableBerthItem
        key={"available" + item.id}
        name={item.name!}
        type={item.contract_type?.label}
        arrivalDetectedAt={item.sensor_occupied_since}
        availableUntil={item.available_until}
        catway={item.catway}
        category={item.category?.label}
        hasReservation={!!item.reserved}
        onClick={() => {
          setSelected(item);
          setOpenModal(true);
        }}
      />)
    }
    {
      data?.next_page_number &&
      <IonButton onClick={() => {
        setPage(data?.next_page_number)
        // refetch({ portId: port?.id, page:data?.next_page_number })
      }} color="secondary" shape="round" fill="outline">
        {
          isFetching ? <IonSpinner color="secondary" /> : t('shared.loadmore')
        }
      </IonButton>
    }
    {
      selected && <PlaceInBoatModal
        berth={selected} isOpen={openModal}
        close={() => {
          setOpenModal(false)
          setSelected(null)
        }}
      />
    }
  </div>
}