import { IonCheckbox, IonIcon, IonItem, IonLabel } from "@ionic/react"
import { FC, useState } from "react"
import { TABooking } from "../../core/models/technicalArea.models"
import { checkmarkCircle, closeCircle } from "ionicons/icons"
import classNames from "classnames"
import { getDateObjectFromTime } from "../../_helpers_/datatime.helpers"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

type ServicesListProps = {
  services: TABooking["booking_services"],
  startDate: string,
  startTime?: string,
  endDate: string,
  endTime?: string,
  canEdit: boolean,
  hintText?: string,
  showCheckbox?: boolean,
  showStatus?: boolean
  onChange?: (service: TABooking["booking_services"][0], checked: boolean) => any
}
export const ServicesList: FC<ServicesListProps> = (
  { services, canEdit, showCheckbox = false, showStatus = false, hintText,
    startDate, endDate, startTime, endTime,
    onChange = () => { } }
) => {
  const { t } = useTranslation()
  return (<div className={
    classNames(" rounded-xl overflow-hidden p-4", {
      "bg-white shadow-lg": canEdit
    })
  }>

    <div className="pb-2 flex items-center gap-3">
      <div className="rounded-full w-[10px] min-h-[10px] max-h-[10px] bg-black "></div>
      {t('technicalArea.arrival')}<span className="font-bold">
        {dayjs(startDate).format(t("dateFormat.shortDate"))}
        &nbsp;{t("dateFormat.at")}&nbsp;
        {startTime && getDateObjectFromTime(startTime).format(t("dateFormat.hours"))}
      </span>
    </div>
    <div className="flex items-center gap-3">
      <div className="relative pl-5
        before:block before:bg-black before:w-[2px] before:absolute 
        before:-top-4  before:-bottom-4  before:left-[4px] 
      ">
        {
          hintText && canEdit && <p className="text-xs px-5">{hintText}</p>
        }
        {
          services.map(service => {
            return <ServiceItem key={service.id} service={service} {... { showStatus, showCheckbox, hintText, canEdit, onChange }} />
          })
        }
      </div>
    </div>
    <div className="pt-2 flex items-center gap-3">
      <div className="rounded-full w-[10px] min-h-[10px] max-h-[10px] bg-black "></div>

      {t('technicalArea.leaving')}<span className="font-bold">
        {dayjs(endDate).format(t("dateFormat.shortDate"))}
        &nbsp;{t("dateFormat.at")}&nbsp;
        {endTime && getDateObjectFromTime(endTime).format(t("dateFormat.hours"))}

      </span>
    </div>
  </div>
  )

}

type ServiceItemProps = {
  service: TABooking["booking_services"][0],
  canEdit: boolean,
  hintText?: string,
  showCheckbox?: boolean,
  showStatus?: boolean,
  onChange?: (service: TABooking["booking_services"][0], checked: boolean) => any

}

const ServiceItem: FC<ServiceItemProps> = ({ service, canEdit, showCheckbox = false, showStatus = false, hintText, onChange = () => { } }) => {
  const [checked, setChecked] = useState(service.is_done)
  return <IonItem
    style={{ "--min-height": "20px" }}
    color={canEdit ? "white" : "transparent"}
    key={service.id} lines="none">
    {(canEdit || showCheckbox) &&
      <IonCheckbox
        disabled={!canEdit}
        onClick={(e: any) => {
          if (checked !== e.target.checked && service.is_done !== e.target.checked) {
            onChange(service, e.target.checked)
            setChecked(e.target.checked)
          }
        }}
        checked={service.is_done}
        slot="start" style={{
          "--border-color": "var(--ion-color-secondary)"
        }} color="secondary"></IonCheckbox>
    }
    <IonLabel style={{ opacity: 1 }} color="dark">
      <span className="flex items-center gap-2">
        {service.name}
        {
          !canEdit && service.is_done && <IonIcon className="text-xl" color="success" icon={checkmarkCircle} />
        }
        {
          !canEdit && showStatus && !service.is_done && <IonIcon className="text-xl" color="danger" icon={closeCircle} />
        }
        {/* {
                [TA_BOOKING_STATUS.ONGOING, TA_BOOKING_STATUS.ON_HOLD, TA_BOOKING_STATUS.BOAT_IN_AREA, TA_BOOKING_STATUS.DONE].includes(booking.status) &&
                service.is_done &&
                <IonIcon size="large" color="success" icon={checkmarkCircle} />
              }
              {
                [TA_BOOKING_STATUS.BOAT_IN_AREA, TA_BOOKING_STATUS.DONE].includes(booking.status) && !service.is_done &&
                <IonIcon size="large" color="danger" icon={closeCircle} />
              } */}
      </span>
    </IonLabel>

  </IonItem>
}