import { useAppSelector, useAppDispatch } from "../../../core/store/hooks";
import { useTranslation } from "react-i18next";
import Toggle from "../../UI/form/Toggle";
import { updateFilter } from "../../../core/store/reducers/Tickets";

export default function TicketFilterMenu() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const usersSelected = useAppSelector((state) => state.tickets.filters.users);
  const currentUserId = useAppSelector((state) => state.users.current ? state.users.current.id : "");

  return (
    <div className="flex bg-LIGHT items-center justify-end pr-6">
      <div className="text-xs font-medium">{t("ticket.mineOnly")}</div>
      <Toggle
        value={!!usersSelected.find((usr: string) => usr === currentUserId)}
        setValue={(checked: any) =>
          checked ? dispatch(updateFilter({ users: [currentUserId] }))
            : dispatch(updateFilter({ users: [] }))
        }
        size="medium"
        color="secondary"
      />
    </div>
  );
}
