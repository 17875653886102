import { IonButton, IonSpinner, } from "@ionic/react"
import { FC, useEffect, useState } from "react"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"
import { TABooking, TABookingEdit } from "../../../core/models/technicalArea.models"
import dayjs from "dayjs"
import { ServicesField } from "./Services.field"
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks"
import { getTAServiceslist, updateTABookingDetail } from "../../../core/store/actions/TechnicalArea.actions"
import { useTranslation } from "react-i18next"
import { getDateObjectFromTime } from "../../../_helpers_/datatime.helpers"
import { CGUComponent } from "./CGU.component"
import { DateTimeField } from "../../UI/form/DateTime.field"
import { updateSelectedBooking } from "../../../core/store/reducers/TechnicalArea.reducer"


type AmendmentFormProps = {
  booking: TABooking,
  onDone: () => void
}
export const AmendmentFormCreation: FC<AmendmentFormProps> = ({ booking, onDone }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const port = useAppSelector(state => state.ports.current)
  const form = useForm({
    defaultValues: {
      services: [...booking.services],
      start_date: dayjs(booking.start_date),
      start_time: getDateObjectFromTime(booking.start_time),
      end_date: dayjs(booking.end_date),
      end_time: getDateObjectFromTime(booking.end_time),
    } satisfies TABookingEdit
  })

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (!port) return
    dispatch(getTAServiceslist(port.id))
  }, [port, dispatch])
  const { handleSubmit, formState: { isValid } } = form
  const submit: SubmitHandler<any> = (data) => {
    setIsSaving(true)
    const toCreate = {
      services: data.services.map((s: TABooking["services"][0]) => s.id),
      end_date: data.end_date.format("YYYY-MM-DD"),
      end_time: data.end_time.format("HH:mm"),
      update_request_validated: false,
    }
    console.log("SUBMIT CREATION AMEND", toCreate);
    updateTABookingDetail(booking.id, toCreate).then((res) => {
      dispatch(updateSelectedBooking({
        ...res.data
      }))
      onDone()
    }).finally(() => {
      setIsSaving(false)
    })
  }

  return <div id="create-booking-amendment">
    <form className="mb-4 flex flex-col gap-4" onSubmit={handleSubmit(submit)}>
      <FormProvider {...form}>
        <div className="grid grid-cols-2 gap-x-1 ">
          <DateTimeField disabled={true} name="start_date" label={t("technicalArea.amendment.startDate")} type="date" />
          <DateTimeField disabled={true} name="start_time" label={t("technicalArea.amendment.startTime")} type="time" />
          <DateTimeField name="end_date" label={t("technicalArea.amendment.leavingDate")} type="date" />
          <DateTimeField name="end_time" label={t("technicalArea.amendment.leavingTime")} type="time" />
        </div>
        <ServicesField servicesStates={booking.booking_services} />
      </FormProvider>
      <CGUComponent />
      <IonButton disabled={isSaving || !isValid} size="large" shape="round" color="secondary" expand="block" type="submit">
        {isSaving ? <IonSpinner></IonSpinner> : t("technicalArea.amendment.create")}
      </IonButton>
    </form>
  </div>
}