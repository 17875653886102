
import { colors } from "../../styles/colors";
import { useAppSelector, useAppDispatch } from "../../core/store/hooks";
import { getCategoryById } from "../../core/store/selectors/categories";
import { ticketsIcons } from "../../assets/icons/tickets/loadTicketsIcon";
import {
  saveTicket,
  setSelectedTicket,
} from "../../core/store/reducers/Tickets";
import dayjs from "dayjs";
import chatbubble from "../../assets/icons/chat.png";
import AvatarList from "../UI/users/AvatarList";
import { RootState } from "../../core/store/store";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import Avatar from "../UI/users/Avatar";
import { IAssignee, ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import FDK from "@wattsonelements/front-fdk";
import { ITicketBoat } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IBerth } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import classNames from "classnames";
import { calendar, calendarOutline, time } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

export default function TicketItem(props: {
  priority: string;
  ticket: ITicket;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const { priority = "normal", ticket } = props;
  // const user = useAppSelector((state) => state.users.current);
  // const agents = useAppSelector((state) => state.users.all);
  // const assigned = (ticket.assignees as IAssignee[]).map((u) => {
  //   return agents.find((a) => a.id === u.id)?.picture || "";
  // }) || [];
  const assigned = (ticket?.assignees as IAssignee[])?.map((u) =>
    u.picture || "") || [];
  const countMessages = ticket.nb_messages || ticket.messages?.length;

  // const assigneTicketToMe = () => {‹
  //   if (!user) return;
  //   const newTicket = { ...ticket };
  //   const ticketIntervention = { ...ticket?.ticket_intervention[0] };
  //   ticketIntervention.intervention_assignee = [{ agent: user.id }];
  //   newTicket.ticket_intervention = [ticketIntervention];
  //   dispatch(saveTicket(newTicket));
  // };

  return (
    <>
      <BareItem
        disabled={false}
        priority={priority}
        ticket={ticket}
        users={assigned}
        countMessages={countMessages}
      />
    </>
  );
}

function SwipeAction() {
  const { t } = useTranslation()
  const styles = useStyles();
  return (
    <div className={styles.swipeContainer}>
      <p className={styles.swipep}>{t("ticket.assignToMe")}</p>
    </div>
  );
}

function BareItem({
  priority,
  ticket,
  disabled,
  users,
  countMessages = -1,
}: {
  priority: string;
  ticket: ITicket;
  disabled: boolean;
  users: string[];
  countMessages?: number;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const history = useHistory();
  const category: ITicketCategory | undefined = useAppSelector((state: RootState) =>
    getCategoryById(state, ticket.category)
  );
  const port = useAppSelector(state => state.ports.current)

  const iconfile = category?.icon_url;
  const date = dayjs(ticket.date_created);
  const styles = useStyles();
  let title,
    subtitle = "";
  if (ticket.title && ticket.title !== "") {
    title = ticket.title;
  } else if ("boat" in ticket && ticket.boat && (ticket.boat as ITicketBoat).name) {
    title = (ticket.boat as ITicketBoat).name;
  } else {
    // TODO : CASE BOAT EXPECTED NOT HANDLED
    title = "";
  }

  if ("spot" in ticket && ticket.spot && (ticket.spot as IBerth).name) {
    subtitle = `Place ${(ticket.spot as IBerth).name}, Ponton ${(ticket.spot as IBerth).pontoon_name} `;
  }

  const handleClick = () => {
    if (disabled) return;
    dispatch(setSelectedTicket(ticket));
    const isClosed = ticket.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED;
    if (!isClosed) {
      history.push(`/ticket/edit/${ticket.id}`)
    }
  };
  return (
    <button
      onClick={handleClick}
      className="mb-2 space-y-2 w-full flex items-center h-28 rounded-2xl bg-white shadow-md drop-shadow-md overflow-visible"
    >
      <div style={createPriorityStyle(ticket.priority)} className={styles.col1}>
        <img
          alt={category?.label}
          className={styles.categoryIcon}
          src={iconfile}
        />
        {!iconfile && <p className={styles.status}>{category?.label} </p>}
        <span className={styles.status}>{ticket.reference}</span>
        <div className={styles.line} />
        <p className={styles.date}>{date.format("DD MMM")}</p>
        <p className={styles.date}>{date.format(t("dateFormat.hours"))}</p>
      </div>

      <div className={styles.col2 + " overflow-hidden"}>
        <p className="text-md font-bold text-FONT_DARK text-left truncate text-ellipsis">
          {title}

        </p>
        <p className={styles.subtitle}>{subtitle}</p>
        <div className="mt-2 flex items-center justify-between">
          {/* ADD ASSIGNEE AVATAR */}
          <div className="flex items-center">
            <Avatar size={30} src={ticket.creator?.picture} />
            <div className="h-8 mx-2 w-[2px] rounded bg-gray-200"></div>
            <AvatarList id={ticket.id} list={users} />
          </div>
          {/* Add counte message */}
          {countMessages >= 0 && (
            <div className="flex items-center justify-between">
              <p>{countMessages}</p>
              <img className={styles.icon} src={chatbubble} />
            </div>
          )}
        </div>
      </div>
      {/* <div className={styles.col3}><p>Carte</p></div> */}
      <div className="flex flex-col gap-1">

        {
          port?.config.ticket_displayed_fields.includes('deadline') && ticket.deadline &&
          <>
            <div
              className={classNames(
                "-mr-2 py-1 px-2 text-xs rounded-md text- font-bold flex gap-1 justify-center items-center",
                {
                  "bg-LIGHT_GREY text-FONT_DARK": !dayjs(ticket.deadline).isSame(dayjs(), 'day'),
                  "bg-RED text-white": dayjs(ticket.deadline).isSame(dayjs(), 'day'),
                }
              )}>
              <IonIcon
                color={dayjs(ticket.deadline).isSame(dayjs(), 'day') ? "light" : "dark"}
                icon={calendar} ></IonIcon>
              {dayjs(ticket.deadline).format("DD MMM")} <br />

            </div>
            {dayjs(ticket.deadline).isSame(dayjs(), 'day') &&
              <div
                className={classNames(
                  "-mr-2 py-1 px-2 text-xs rounded-md text- font-bold flex gap-1 justify-center items-center",
                  {
                    "bg-LIGHT_GREY text-FONT_DARK": !dayjs(ticket.deadline).isSame(dayjs(), 'day'),
                    "bg-RED text-white": dayjs(ticket.deadline).isSame(dayjs(), 'day'),
                  }
                )}>
                <IonIcon
                  color={dayjs(ticket.deadline).isSame(dayjs(), 'day') ? "light" : "dark"}
                  icon={time} ></IonIcon>
                {dayjs(ticket.deadline).format(t("dateFormat.hours"))}

              </div>
            }

          </>
        }
        <div
          className="-mr-2 py-1 px-2 text-xs rounded-md text- bg-SECONDARY font-bold text-white">
          {t("ticket.status." + ticket.status)}
        </div>
      </div>
    </button>
  );
}

const createPriorityStyle = (priority: string) => {
  let color: string = colors.SECONDARY;
  let style = { backgroundColor: color };
  switch (priority) {
    case "normal":
      style.backgroundColor = colors.GREY;
      break;
    case "intermediate":
      style.backgroundColor = colors.SECONDARY;
      break;
    case "urgent":
      style.backgroundColor = colors.DANGER;
      break;
    default:
      break;
  }
  return style;
};

const useStyles = createUseStyles({
  col1: {
    flex: 1,
    backgroundColor: colors.SECONDARY,
    alignSelf: "stretch",
    padding: 5,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 40,
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px 0 0 15px",
    overflow: "hidden"
  },
  col2: {
    flex: 3,
    padding: "5px 10px"
  },
  col3: {
    flex: 0.5,
    padding: 2
  },
  categoryIcon: {
    width: 20,
    height: 20,
  },
  subtitle: {
    fontSize: 12,
    textAlign: "left",
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0,
    color: colors.FONT_DARK,
    lineHeight: "0.9em",
  },
  status: {
    fontSize: 10,
    fontWeight: "normal",
    fontStyle: "normal",
    pTransform: "uppercase",
    letterSpacing: 0,
    pAlign: "center",
    color: colors.CALCITE,
    marginTop: 2,
  },
  date: {
    // fontFamily: "Roboto",

    fontSize: 12,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    pAlign: "center",
    color: colors.CALCITE,
  },
  line: {
    width: "70%",
    height: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colors.CALCITE,
    margin: "3px 0",
  },
  swipeContainer: {
    backgroundColor: colors.SECONDARY,
    marginVertical: 10,
    padding: 10,
    paddingRight: 25,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "flex-end",
    width: "100%",
  },
  swipep: {
    color: colors.CALCITE,
  },
  icon: {
    width: 20,
    height: 20,
  },
});
