import React from "react";
import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";

export default function SelectItem({ data, setdatas, textKey = "", image, isSelected = false, ...props }: any) {
  const styles = useStyles()
  return (
    <button
      className={
        isSelected ? styles.selected : styles.default
      }

      onClick={() => {
        setdatas(data, !isSelected);
      }}
    >
      {image && <img alt="item-avatar" className={styles.img} src={image} />}

      <span className="text-left">{textKey}</span>
    </button>
  );
}

const useStyles = createUseStyles({
  selected: {
    color: colors.FONT_DARK,
    display: "flex",
    backgroundColor: colors.LIGHT,
    width: "100%",
    padding: "5px 10px",
    margin: "5px 0",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "10px",
    borderLeftWidth: "12px",
    borderColor: colors.SECONDARY,
    borderStyle: "solid"
  },
  default: {
    color: colors.FONT_DARK,
    display: "flex",
    backgroundColor: colors.CALCITE,
    width: "100%",
    padding: "5px 10px",
    margin: "5px 0",
    flexDirection: "row",
    alignItems: "center",
    borderLeftWidth: "12px",
    borderLeftColor: colors.CALCITE,
  },
  img: {
    width: 40,
    height: 40,
    backgroundColor: colors.LIGHT_GREY,
    marginRight: 10,
    borderRadius: 50,
  },
});
