import { IonIcon, IonSpinner } from "@ionic/react"
import FDK from "@wattsonelements/front-fdk"
import classNames from "classnames"
import { checkmark, close } from "ionicons/icons"
import { FC, useEffect, useState } from "react"

export type ActionStatusProps = {
  state: number //FDK.MainAPI.REQUEST_STATUS
  title?: string
  message?: string
  sub_message?: string
  size?: number,
  className?: string
}
const COLOR_AND_ICON_BY_STATUS = {
  [FDK.MainAPI.REQUEST_STATUS.ERROR]: {
    icon: close,
    color: "danger"
  },
  [FDK.MainAPI.REQUEST_STATUS.SUCCESS]: {
    icon: checkmark,
    color: "success"
  }
}
export const ActionStatus: FC<ActionStatusProps> = ({ state, title, message, sub_message, className="", size = 160 }) => {
  const [color, setColor] = useState<null | string>(null)
  const [icon, setIcon] = useState<string | null>(null)
  useEffect(
    () => {
      if (state in COLOR_AND_ICON_BY_STATUS) {
        const tmp = (COLOR_AND_ICON_BY_STATUS as any)[state]
        setIcon(tmp.icon)
        setColor(tmp.color)
      } else {
        setIcon(null)
        setColor(null)
      }
    },
    [state]
  )
  return <div className={classNames(className, "flex flex-col justify-center items-center")}>
    {
      title && <h2 className="font-bold text-center text-4xl pb-6">{title}</h2>
    }
    {
      [FDK.MainAPI.REQUEST_STATUS.ERROR, FDK.MainAPI.REQUEST_STATUS.SUCCESS].includes(state) && color && icon &&
      <div className="bg-MEDIUM_LIGHT_GREY rounded-full flex justify-center items-center p-3 mb-6">
        <IonIcon color={color} style={{ height: `${size}px`, width: `${size}px` }} icon={icon} />
      </div>
    }
    {
      state === FDK.MainAPI.REQUEST_STATUS.PENDING &&
      <IonSpinner name="circular" />
    }
    {
      message &&
      <p className="mt-2 text-lg font-bold text-center">{message}</p>
    }
    {
      sub_message &&
      <p className="text-center mt-3">{sub_message}</p>
    }

  </div>
}