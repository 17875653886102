import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as SentryReact from "@sentry/react";
// import * as Sentry from "@sentry/capacitor";
import { BrowserTracing } from "@sentry/tracing";
import { isLocal, isProd } from './utils/env';

if(!isLocal()) {
  // Sentry.init(
  //   {
  //     dsn: "https://examplePublicKey@o0.ingest.sentry.io/0",
  
  //     // Set your release version, such as "getsentry@1.0.0"
  //     release: "app-agent@25.1",
  //     // Set your dist version, such as "1"
  //     dist: "1",
  //     environment: isProd() ? 'production' : 'staging',
  //     debug: isProd() ? false : true,
      
  //   }
  // );
  SentryReact.init({
    dsn: "https://ca0b88bbfa5e469d977a09b76ce5447e@o69095.ingest.sentry.io/5987753",
    
    // integrations: [new BrowserTracing()],
    integrations: [new SentryReact.Integrations.Breadcrumbs({ console: false })],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
// Call the element loader after the app has been rendered the first time
defineCustomElements(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
