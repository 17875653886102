import { IonRefresher, IonRefresherContent, RefresherEventDetail } from "@ionic/react"
import { useEffect, useMemo, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks"
import { TabFilterScrollList } from "../../UI/tabs/TabFilterList"
import { useGetBerthsForPontoonQuery, useGetBerthsQuery, useGetPontoonsQuery } from "../../../core/store/api/dockwalk.api"
import { skipToken } from "@reduxjs/toolkit/query"
import { AllBerthsList } from "../list/AllBerths.list"
import { PontoonBerthsList } from "../list/PontoonBerths.list"
import { caretBack, caretForward } from "ionicons/icons"
import { IPontoonBerthList, IPontoonStats } from "@wattsonelements/front-fdk/dist/models/Dockwalk.models"
import { setSearchActive } from "../../../core/store/reducers/Dockwalk"

const pontoonDefaultSide = (p: IPontoonBerthList) => {
  return (p.left.spots.length > 0) ? "left" : "right"
}
const pontoonHasOneSide = (p: IPontoonBerthList) => {
  return p.left.spots.length === 0 || p.right.spots.length === 0
}

export const DockwalkTab = () => {
  const port = useAppSelector(state => state.ports.current)
  const dispatch = useAppDispatch()
  const [pontoon, setPontoon] = useState<IPontoonStats | "all">("all")
  const [pontoonSide, setPontoonSide] = useState<"left" | "right" | null>("left")

  const { isLoading: isLoadingAll, data: allBerthsList, refetch: refetchBerths } = useGetBerthsQuery((pontoon === "all" && port?.id) ? { portId: port?.id } : skipToken)
  const {
    isLoading: isLoadingPontoons,
    data: pontoonsList = [],
    refetch: refetchPontoon
  } = useGetPontoonsQuery(port?.id ?? skipToken)
  const { data: currentpontoon, isLoading: isLoadingBerthsPontoons, refetch: refetchBerthsPontoon } = useGetBerthsForPontoonQuery(port && pontoon !== "all" ? { portId: port?.id || "", pontoonId: pontoon.pontoon.id || "" } : skipToken)

  const oneSidedPontoon = useMemo(() => {
    if (!currentpontoon) return true
    return pontoonHasOneSide(currentpontoon)
  }, [currentpontoon])

  const loadData = async (event?: CustomEvent<RefresherEventDetail>) => {
    if (isLoadingBerthsPontoons || isLoadingPontoons || isLoadingAll) return
    if (pontoon === 'all') {
      await refetchBerths()
    } else {
      await refetchBerthsPontoon()
    }
    await refetchPontoon()
    event && event.detail.complete();
  }


  const setSideOnClick = (p: IPontoonStats | "all") => {
    if (p !== "all" && currentpontoon?.id === p?.pontoon.id) {
      dispatch(setSearchActive(false))
      if (pontoonHasOneSide(currentpontoon)) {
        setPontoonSide(pontoonDefaultSide(currentpontoon))
      } else {
        setPontoonSide(prev => prev === "left" ? "right" : "left")
      }
    }
  }
  useEffect(() => {
    if (pontoon === "all") return
    if (currentpontoon && !pontoonSide) {
      setPontoonSide(pontoonDefaultSide(currentpontoon))
    }
  }, [currentpontoon, pontoon, pontoonSide])

  return <div className=" flex flex-col h-full gap-3 relative mx-2 mt-2">
    <IonRefresher slot="fixed" onIonRefresh={loadData}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
    <TabFilterScrollList
      onSelected={(e, tab: string | 'all') => {
        const p = pontoonsList.find(pt => pt.pontoon.id === tab)
        if (tab === "all") {
          setPontoon("all")
          dispatch(setSearchActive(true))
          setPontoonSide(null)
        } else if (p) {
          if (currentpontoon?.id === tab) {
            setSideOnClick(p)
          } else {
            setPontoonSide(null)
          }
          setPontoon(p)
        }
      }}
      count={allBerthsList?.count}
      countKey="mooring-idle"
      labelKey="pontoon.name"
      selectKey="pontoon.id"
      tabs={pontoonsList}
      iconLeft={!oneSidedPontoon && pontoonSide === "left" ? caretBack : null}
      iconRight={!oneSidedPontoon && pontoonSide === "right" ? caretForward : null}
    />
    <div className="overflow-auto ">
      {
        pontoon === "all" ? <AllBerthsList /> : <PontoonBerthsList side={pontoonSide} pontoonId={pontoon.pontoon.id} />
      }
    </div>
  </div>
} 