import { createSelector } from "@reduxjs/toolkit";
import {
  getCategories,
  getFlatCategories,
  ticketsListHasCategories,
  getCategoriesFromParentId,
  getCategory,
  getUserID,
  getTicketsForUser,
  getCatgoryFromSlug,
  getTicketsList,
  getSelectedTicket,
} from "./helpers";
import { RootState } from "../store";
import { ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";

export const getFirstLvlOnly = createSelector(
  getCategories,
  (categories: Array<ITicketCategory[]>) => {
    if (categories && categories.length > 0) {
      return categories[0];
    }
    return [];
  }
);

export const getMaintenance = createSelector(
  getFlatCategories,
  (categories: ITicketCategory[]) => {
    const categoryParent = getCatgoryFromSlug(categories, "maintenance");

    if (categoryParent) {
      return categories.filter(
        (cat) => cat.parent_category === categoryParent.id
      );
    }
    return undefined;
  }
);

export const getSelectedTicketCategory = createSelector(
  getFlatCategories,
  getSelectedTicket,
  (categories: ITicketCategory[], ticket: ITicket | null) => {
    if (ticket) {
      return getCategory(categories, ticket.category);
    }
    return undefined;
  }
);

export const getCategoryById = createSelector(
  getFlatCategories,
  (_: any, categoryId: string) => categoryId,
  (categories: ITicketCategory[], categoryId) => {
    return getCategory(categories, categoryId);
  }
);

export const getCategoryCount = createSelector(
  getFlatCategories,
  getTicketsList,
  (_: any, categoryParentId: string) => categoryParentId,
  (categories, tickets, catParentId) => {
    const listCategoriesConcerned = getCategoriesFromParentId(
      categories,
      catParentId
    );
    return ticketsListHasCategories(tickets, [
      ...listCategoriesConcerned,
      catParentId,
    ]).length;
  }
);

/**
 * @deprecated
 */
export const getCategoryCountByUser = createSelector(
  getFlatCategories,
  getTicketsList,
  (state: RootState) => state.tickets.filters,
  (_: any, categoryParentId: string) => categoryParentId,
  (categories, tickets, filters, catParentId) => {
    const listCategoriesConcerned = getCategoriesFromParentId(
      categories,
      catParentId
    );
    const list = ticketsListHasCategories(tickets, [
      ...listCategoriesConcerned,
      catParentId,
    ]);
    return (filters.users.length > 0 ? getTicketsForUser(list, filters.users[0]).length : list.length)
  }
);

/**
 * @deprecated
 */
export const getBacklogCategoryCount = createSelector(
  getFlatCategories,
  getTicketsList,
  (_: any, categoryParentId: string) => categoryParentId,
  (categories, tickets, catParentId) => {
    const listCategoriesConcerned = getCategoriesFromParentId(
      categories,
      catParentId
    );
    const list = ticketsListHasCategories(tickets, [
      ...listCategoriesConcerned,
      catParentId,
    ]);

    return list.length;
  }
);
