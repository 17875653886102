import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import FDK from "@wattsonelements/front-fdk"
import { IAgentBerth, TBerthCategory } from "@wattsonelements/front-fdk/dist/models/Berth.models"
import { TContractDockWalk } from "@wattsonelements/front-fdk/dist/models/Boats.models"
import { TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models"
import { handlePangination } from "../../../_helpers_/pagination.helpers"
import { RootState } from "../store"

interface DockwalkState {
  leaving: TContractDockWalk[],
  arriving: TContractDockWalk[]
  moored: TContractDockWalk[]
  berths: Partial<IAgentBerth>[]
  berthCategories: TBerthCategory[]
  pontoons: any[]
  isSearchActive: boolean,
  search: string | null,
  searchScope: "dockwalk" | "availableBerths" | "booking" | null
  stats: {
    leaving: number | string | null | undefined
    arriving: number | string | null | undefined
    berth: number | string | null | undefined
    moored: number | string | null | undefined
  },
  loading: boolean
}

const initialState = {
  leaving: [],
  arriving: [],
  moored: [],
  berths: [],
  pontoons: [],
  berthCategories: [],
  loading: true,
  isSearchActive: true,
  search: null,
  searchScope: null,
  stats: {
    moored: null,
    leaving: null,
    arriving: null,
    berth: null
  },
} as DockwalkState

export const getLeavingContract = createAsyncThunk(
  "leaving/get",
  async ({ params }: { params: TParams }, { getState }) => {
    return FDK._helpers.withParams(FDK.Agent.DockwalkModule.getLeaving, params).then((res) => {
      console.log(res.data);
      return res.data;
    });
  }
);
export const getArrivingContract = createAsyncThunk(
  "arriving/get",
  async ({ params }: { params: TParams }, { getState }) => {
    return FDK._helpers.withParams(FDK.Agent.DockwalkModule.getArriving, params).then((res) => {
      console.log(res.data);
      return res.data;
    });
  }
);
// export const getMooredContract = createAsyncThunk(
//   "moored/get",
//   async ({ params }: { params: TParams }, { getState }) => {
//     return FDK._helpers.withParams(FDK.Agent.DockwalkModule.getMoored, params).then((res) => {
//       return res.data;
//     });
//   }
// );

export const getBerthAvailableForCategory = createAsyncThunk(
  "berths/get",
  async ({ categoryId, params }: { categoryId: number, params?: TParams }, { getState }) => {
    const port = (getState() as RootState).ports.current?.id || ""
    return FDK._helpers.withParams(() => FDK.Agent.DockwalkModule.getBerthsAvailableForCategory(categoryId), { port_id: port, ...params }).then((res) => {
      console.log(res.data);
      return res.data;
    });
  }
);
export const getBerthsCatStatsList = createAsyncThunk(
  "berths/stats",
  async (_args, { getState }) => {
    const port = (getState() as RootState).ports.current?.id || ""
    return FDK.Agent.DockwalkModule.getBerthsCategoryStats({ port_id: port }).then((res) => {
      console.log(res.data);
      return res.data;
    });
  }
);

export const getPontoonStatsList = createAsyncThunk(
  "pontoon/stats",
  async (_args, { getState }) => {
    return FDK.Agent.DockwalkModule.getPontoonStats().then((res) => {
      console.log(res.data);
      return res.data;
    });
  }
);

const slice = createSlice({
  name: "dockwalk",
  initialState,
  reducers: {
    setSearchActive: (state, { payload }) => {
      state.isSearchActive = payload
    },
    setSearch: (state, { payload }) => {
      state.search = payload.text
      state.searchScope = payload.scope
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLeavingContract.fulfilled, (state, { payload }) => {
      state.leaving = handlePangination(state.leaving, payload);
      state.stats.arriving = payload.arriving
      state.stats.leaving = payload.leaving
    }).addCase(getLeavingContract.pending, (state, { payload }) => {
      // state.leaving = payload.results;
    }).addCase(getLeavingContract.rejected, (state, { payload }) => {
      // state.leaving = payload.results;
    }).addCase(getArrivingContract.pending, (state, { payload }) => {
      // state.leaving = payload.results;
    }).addCase(getArrivingContract.fulfilled, (state, { payload }) => {
      state.arriving = handlePangination(state.arriving, payload);
      state.stats.arriving = payload.arriving
      state.stats.leaving = payload.leaving
    }).addCase(getArrivingContract.rejected, (state, { payload }) => {
      // state.leaving = payload.results;
    })
      // .addCase(getMooredContract.pending, (state, { payload }) => {
      //   // state.leaving = payload.results;
      // }).addCase(getMooredContract.fulfilled, (state, { payload }) => {
      //   state.moored = handlePangination(state.moored, payload);
      //   if (!payload.hasParam) {
      //     state.stats.moored = payload.count
      //   }
      // }).addCase(getMooredContract.rejected, (state, { payload }) => {
      //   // state.leaving = payload.results;
      // })
      .addCase(getBerthAvailableForCategory.pending, (state, { payload }) => {
        // state.leaving = payload.results;
      }).addCase(getBerthAvailableForCategory.fulfilled, (state, { payload }) => {
        // 🚧 TODO 🚧 THIS IS WRONG - need 2 routes and 2 handlers
        state.berths = handlePangination(state.berths, payload);
        if (!payload.hasParam) {
          state.stats.berth = payload.count
        }
      }).addCase(getBerthAvailableForCategory.rejected, (state, { payload }) => {
        // state.leaving = payload.results;
      })
      .addCase(getBerthsCatStatsList.pending, (state, { payload }) => {
        // state.leaving = payload.results;
      }).addCase(getBerthsCatStatsList.fulfilled, (state, { payload }) => {
        state.berthCategories = payload;
      }).addCase(getBerthsCatStatsList.rejected, (state, { payload }) => {
        // state.leaving = payload.results;
      }).addCase(getPontoonStatsList.fulfilled, (state, { payload }) => {
        state.pontoons = payload;
      })
  }
})

export default slice.reducer;
export const { setSearchActive, setSearch } = slice.actions;