import { createSlice } from "@reduxjs/toolkit"
import { TABooking, TAService } from "../../models/technicalArea.models";
import { getTABookingDetail, getTABookingListOnArea, getTABookingListToday, getTABookingListTomorrow, getTAServiceslist } from "../actions/TechnicalArea.actions";

export enum TA_LIST {
  TODAY,
  TOMORROW,
  ON_AREA
}
interface TAState {
  today: TABooking[],
  tomorrow: TABooking[],
  onArea: TABooking[],
  detail: TABooking | null,
  selectedList: TA_LIST,
  services: TAService[],
  filters: {
    text?: string;
    users: string[];
  };
}

const initialState = {
  today: [],
  tomorrow: [],
  onArea: [],
  selectedList: TA_LIST.TODAY,
  detail: null,
  services: [],
  filters: {
    text: "",
    users: []
  }
} as TAState

const slice = createSlice({
  name: "technicalArea",
  initialState,
  reducers: {
    removeDetail: (state) => {
      state.detail = null
    },
    selectTodayList: (state) => {
      state.selectedList = TA_LIST.TODAY
    },
    selectTomorrowList: (state) => {
      state.selectedList = TA_LIST.TOMORROW
    },
    selectOnAreaList: (state) => {
      state.selectedList = TA_LIST.ON_AREA
    },
    changeDetailStatus: (state, { payload }) => {
      if (!state.detail) return state
      state.detail.status = payload
    },
    updateFilter: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    updateServiceDone: (state, { payload }) => {
      if (!state.detail) return state
      const services = state.detail?.booking_services.map(s => {
        if (s.id === payload.id) {
          return {
            ...s,
            is_done: payload.is_done
          }
        }
        return s
      })
      state.detail.booking_services = services
    },
    updateSelectedBooking: (state, { payload }) => {
      if (state.detail && state.detail?.id === payload.id) {
        state.detail = {
          ...state.detail,
          ...payload
        }
      }
    },
    // updateItemInList: (state, { payload }) => {
    //   if (!state.bookingList) return state
    //   const newlist = [...state.bookingList].map(b => {
    //     if (payload.id === b.id) {
    //       return {
    //         ...b,
    //         services: payload?.services || b.services,
    //         agents: payload?.agent || b.agents,
    //         status: payload?.status || b.status,
    //         start_date: payload?.start_date || b.start_date,
    //         end_date: payload?.end_date || b.end_date,
    //         start_time: payload?.start_time || b.start_time,
    //         end_time: payload?.end_time || b.end_time
    //       }
    //     }
    //     return b
    //   })
    //   state.bookingList = newlist
    // },

  },
  extraReducers: (builder) => {
    builder.addCase(getTABookingListToday.fulfilled, (state, { payload }) => {
      state.today = payload.results
    })
      .addCase(getTABookingListTomorrow.fulfilled, (state, { payload }) => {
        state.tomorrow = payload.results
      })
      .addCase(getTABookingListOnArea.fulfilled, (state, { payload }) => {
        state.onArea = payload.results
      })
      .addCase(getTABookingDetail.fulfilled, (state, { payload }) => {
        state.detail = payload
      }).addCase(getTAServiceslist.fulfilled, (state, { payload }) => {
        state.services = [...payload]
      })
  }

})

export default slice.reducer;
export const {
  changeDetailStatus,
  updateServiceDone,
  removeDetail,
  selectTodayList,
  selectTomorrowList,
  selectOnAreaList,
  updateFilter,
  updateSelectedBooking
} = slice.actions
