import FDK from "@wattsonelements/front-fdk";
import { ITicket, ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import i18n from "../../utils/langs";
import { COMPONENT_TYPE } from "./constants";

enum STEPS {
  HAS_BOAT,
  YES_HAS_PERSON,
  NO_PERSON_REPORT,
  NO_PERSON_REPORT_PICTURE,
  YES_PERSON_IDENTITY,
  NO_BOAT_REPORT,
  NO_BOAT_REPORT_PICTURE,
}

export function generateIntrusionStepDefinition(
  ticket: ITicket,
  category: ITicketCategory
) {
  return {
    [STEPS.HAS_BOAT]: {
      isNode: true,
      title: i18n.t("protocole.intrusion.question1"),
      subtitle: "",
      text: "",
      stepNumber: 1,
      name: "HAS_BOAT",
      answers: [
        {
          label: i18n.t("protocole.intrusion.yes"),
          value: "yes",
          color: "secondary",
          nextStep: STEPS.YES_HAS_PERSON,
        },
        {
          label: i18n.t("protocole.intrusion.no"),
          value: "no",
          color: "primary",
          nextStep: STEPS.NO_BOAT_REPORT,
        },
      ],
    },
    [STEPS.YES_HAS_PERSON]: {
      isNode: true,
      title: i18n.t("protocole.intrusion.question2"),
      stepNumber: 2,
      name: "YES_HAS_PERSON",
      answers: [
        {
          label: i18n.t("protocole.intrusion.yes"),
          value: "yes",
          color: "secondary",
          nextStep: STEPS.YES_PERSON_IDENTITY,
        },
        {
          label: i18n.t("protocole.intrusion.no"),
          value: "no",
          color: "primary",
          nextStep: STEPS.NO_PERSON_REPORT,
        },
      ],
    },
    [STEPS.YES_PERSON_IDENTITY]: {
      isNode: false,
      title: i18n.t("protocole.intrusion.owner"),
      name: "YES_PERSON_IDENTITY",
      stepNumber: 3,
      nextStep: null,
      previousStep: STEPS.YES_HAS_PERSON,
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.USER_CONTACT,
          multiple: true,
          required: false,
        },
        {
          order: 2,
          component: COMPONENT_TYPE.RADIO,
          data: [
            { value: false, label: i18n.t("protocole.intrusion.justify") },
          ],
          required: false,
        },
      ],
    },
    [STEPS.NO_BOAT_REPORT]: {
      isNode: false,
      title: i18n.t("protocole.intrusion.control"),
      name: "NO_BOAT_REPORT",
      stepNumber: 2,
      nextStep: STEPS.NO_BOAT_REPORT_PICTURE,
      previousStep: STEPS.HAS_BOAT,
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.CHECKLIST,
          data: [
            {
              name: "trace",
              value: false,
              label: i18n.t("protocole.intrusion.effraction"),
            },
            {
              name: "volelec",
              value: false,
              label: i18n.t("protocole.intrusion.volelec"),
            },
            {
              name: "desordre",
              value: false,
              label: i18n.t("protocole.intrusion.mess"),
            },
            {
              name: "volacastillage",
              value: false,
              label: i18n.t("protocole.intrusion.vol"),
            },
          ],
          required: false,
        },
      ],
    },
    [STEPS.NO_BOAT_REPORT_PICTURE]: {
      isNode: false,
      title: i18n.t("protocole.intrusion.imageControl"),
      name: "NO_BOAT_REPORT_PICTURE",
      stepNumber: 3,
      nextStep: null,
      previousStep: STEPS.NO_BOAT_REPORT,
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.ATTACHMENT,
          name: i18n.t("protocole.intrusion.images"),
          required: true,
        },
        {
          order: 2,
          component: COMPONENT_TYPE.NOTE,
          name: i18n.t("protocole.intrusion.note"),
          required: true,
        },
      ],
    },
    [STEPS.NO_PERSON_REPORT]: {
      isNode: false,
      title: i18n.t("protocole.intrusion.control"),
      name: "NO_PERSON_REPORT]",
      stepNumber: 3,
      nextStep: STEPS.NO_PERSON_REPORT_PICTURE,
      previousStep: STEPS.YES_HAS_PERSON,
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.CHECKLIST,
          data: [
            {
              name: "trace",
              value: false,
              label: i18n.t("protocole.intrusion.effraction"),
            },
            {
              name: "volelec",
              value: false,
              label: i18n.t("protocole.intrusion.volelec"),
            },
            {
              name: "volacastillage",
              value: false,
              label: i18n.t("protocole.intrusion.vol"),
            },
          ],
          required: false,
        },
      ],
    },
    [STEPS.NO_PERSON_REPORT_PICTURE]: {
      isNode: false,
      title: i18n.t("protocole.intrusion.imageControl"),
      name: "NO_PERSON_REPORT_PICTURE",
      stepNumber: 3,
      nextStep: null,
      previousStep: STEPS.NO_PERSON_REPORT,
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.ATTACHMENT,
          name: i18n.t("protocole.intrusion.images"),
          required: true,
        },
        {
          order: 2,
          component: COMPONENT_TYPE.NOTE,
          name: i18n.t("protocole.intrusion.note"),
          required: true,
        },
      ],
    },
  };
}
