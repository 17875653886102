import { IonIcon, IonSpinner } from "@ionic/react";
import { IAttachment } from "@wattsonelements/front-fdk/dist/models/Attachment.models";
import { close } from "ionicons/icons";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
import ImageModal from "../UI/images/ImageModal";

interface IAttachmentBis extends Partial<IAttachment> {
  preview?: string
}

export default function AttchamentItem({
  file,
  remove,
}: {
  file: Partial<IAttachmentBis>;
  remove: Function;
}) {
  const isUploading = file?.isUploading;
  const url = isUploading ? file.preview : file?.url || file?.file;
  const [modalImage, setModalImage] = useState("");
  const styles = useStyles()
  return (
    <div className="relative min-w-fit">
      {isUploading ? (
        <div className={styles.image}>
          <img
            // fadeDuration={0}
            className={styles.imageContainer}
            src={url}
          />
          <div className={styles.loaderContainer}>
            <IonSpinner className={styles.loader} name="circular" color={colors.SECONDARY} />
          </div>
        </div>
      ) : (
        <>
          <ImageModal
            onClose={() => {
              setModalImage("");
            }}
            image={modalImage}
          />
          <button className="relative" onClick={() => setModalImage(url ? url : "")}>
            <img className={styles.image} src={url} />
            {
              !url && <IonSpinner className="absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]" />
            }

          </button>

          <button onClick={() => remove(file)} className={`${styles.removeBtn} drop-shadow-lg`}>
            <IonIcon size="small" color="secondary" icon={close} />
          </button>
        </>
      )}
    </div>
  );
}

const useStyles = createUseStyles({
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.FONT_DARK + "80",
    borderRadius: 4,
  },
  loader: {},
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: 4,
    position: "relative",
  },
  image: {
    width: "100px",
    height: "100px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: colors.LIGHT_GREY,
  },
  imagesContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.LIGHT_GREY,
  },
  removeBtn: {
    position: "absolute",
    right: "2px",
    top: "2px",
    display: "flex",
    backgroundColor: colors.LIGHT,
    width: '30px',
    height: '30px',
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    height: 20,
    width: 20,
    marginHorizontal: 5,
  },
});
