import dayjs from "dayjs";
import { useAppContext } from "../context/app.context";

export const usePeriodic = () => {
  const { periodics, setPeriodics } = useAppContext();
  /**
   * Initiate a periodic pull
   * @param name unique name of the periodic
   * @param delay in seconds
   * @param request the request - must be an observable that END.
   * @param isActive is ok to send or not
   * @param requireLogin is the request only when logged or not
   */
  const startPeriodic = (
    name: string,
    delay: number,
    request: () => Promise<any>,
    isActive: boolean = true,
    requireLogin: boolean = true
  ) => {
    if (name in periodics && periodics[name].timeoutId && periodics[name].isActive) {
      // clearTimeout(periodics[name].timeoutId!);
      console.log("periodics START Already exist", name, periodics);
      return
    }

    periodics[name] = {
      delay: delay * 1000, // ms
      isActive,
      request,
      timeoutId: null,
      requireLogin,
      retry: 0,
    };
    setPeriodics!(periodics);
    console.log("periodics START", name, periodics);

    sendPeriodicPull(name);
  };

  const updatePeriodicDelay = (name: string, delay: number) => {
    if (name in periodics) {
      const temp = periodics[name];
      periodics[name] = {
        ...temp,
        delay: delay * 1000, // ms
      };
      setPeriodics!(periodics);
    }
  };

  const restartPeriodic = (name: string) => {
    console.log("periodics restartPeriodic", name, name in periodics, periodics);

    if (name in periodics && !periodics[name].isActive) {
      periodics[name].isActive = true;
      sendPeriodicPull(name);
      setPeriodics!(periodics);
    }
  };

  const clearAll = () => {
    console.log("periodics CLEAR ALL", periodics);
    Object.keys(periodics).map((name) => {
      periodics[name].isActive = false;
      if (periodics[name].timeoutId) {
        clearTimeout(periodics[name].timeoutId!);
      }
    });
    setPeriodics!({});
  };

  const clearLogged = () => {
    Object.keys(periodics).map((name) => {
      const item = periodics[name];
      if (item.requireLogin) {
        item.isActive = false;
        clearTimeout(item.timeoutId!);
        delete periodics[name];
      }
    });
    setPeriodics!(periodics);
  };

  const stopPeriodic = (name: string) => {
    console.log("periodics STOP", name, periodics);
    if (name in periodics) {
      periodics[name].isActive = false;
      if (periodics[name].timeoutId) {
        clearTimeout(periodics[name].timeoutId!);
      }
    }
  };

  const sendPeriodicPull = (name: string) => {
    const date = dayjs()
    // console.log("PERIODIC", name, "startime", dayjs().format("hh:mm:ss"));
    if (!periodics[name].isActive) {
      // console.log("PERIODIC DISABLED - NOT SENDING", name, "startime", dayjs().format("hh:mm:ss"));
      return;
    }
    periodics[name].request().then(
      (res) => {
        // console.log("PERIODIC", name, "received", dayjs().format("hh:mm:ss"), "delta:", dayjs().diff(date, 's'), res);
        periodics[name].timeoutId = setTimeout(() => {
          sendPeriodicPull(name);
        }, periodics[name].delay);
        setPeriodics!(periodics);
        return res;
      },
      (err) => {
        // console.log("PERIODIC", name, "ERROR", dayjs().format("hh:mm:ss"), "delta:", dayjs().diff(date, 's'), err);
        if (err.status === 404) {
          return;
        }
        periodics[name].retry = periodics[name].retry + 1;
        periodics[name].timeoutId = setTimeout(() => {
          sendPeriodicPull(name);
        }, periodics[name].delay * periodics[name].retry);
        setPeriodics!(periodics);
      }
    );
  };

  return {
    startPeriodic,
    restartPeriodic,
    stopPeriodic,
    clearAll,
    clearLogged,
    updatePeriodicDelay,
  };
};
