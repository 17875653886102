import { IonBadge, IonButton, IonDatetime, IonIcon, IonModal } from "@ionic/react"
import dayjs from "dayjs"
import { add, calendarOutline } from "ionicons/icons"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"

type ChangeDateSelectorProps = {
  onChange?: (date: dayjs.Dayjs) => void
}
export const ChangeDateSelector: FC<ChangeDateSelectorProps> = ({ onChange = () => { } }) => {
  const { t } = useTranslation()

  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [date, setDate] = useState(dayjs())

  return <>
    <IonButton className="w-full" expand="full" color="light" onClick={() => setDateModalOpen(true)}>
      {date.format(t("dateFormat.date")).toUpperCase()} &nbsp;{" "}
      <IonIcon icon={calendarOutline} />
    </IonButton>
    <IonModal
      onDidDismiss={() => {
        setDateModalOpen(false)
      }}
      className="ion-datetime-button-overlay"

      isOpen={dateModalOpen} >
      <IonDatetime
        onIonChange={(e) => {
          setDateModalOpen(false)
          if (!e.detail.value) return
          setDate(dayjs(e.detail.value as any))
          onChange(dayjs(e.detail.value as any))
        }}
        value={date.format()}
        firstDayOfWeek={1}
        presentation="date">
      </IonDatetime>
      <div className="px-3 grid grid-cols-2 mb-2">
        <IonButton color="secondary" fill="outline" onClick={() => {
          setDateModalOpen(false)
          setDate(dayjs())
          onChange(dayjs())
        }}>{t("shared.today")}</IonButton>
        <IonButton color="secondary" fill="outline" onClick={() => {
          setDateModalOpen(false)
          setDate(dayjs().add(1, "day"))
          onChange(dayjs().add(1, "day"))
        }}>{t("shared.tomorrow")}</IonButton>
      </div>
    </IonModal>
  </>
}