import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { IPortAgent } from "@wattsonelements/front-fdk/dist/models/Port.models";

interface PortState {
  current: IPortAgent | null;
}
const initialState = {
  current: null,
} as PortState;

export const getUserPort = createAsyncThunk("port/current", async () => {
  return FDK.Agent.PortAPI.getUserPort().then((res) => {
    if (res && res.data && res.data.length > 0) {
      return res.data[0]
    }
    return null
  });
});
const slice = createSlice({
  name: "ports",
  initialState,
  reducers: {
    resetPorts: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPort.fulfilled, (state, { payload }) => {
        state.current = payload!;
      })
      .addCase(getUserPort.rejected, (state, { payload }) => {})
      .addCase(getUserPort.pending, (state, { payload }) => {});
  },
});

export default slice.reducer;
export const { resetPorts } = slice.actions;
