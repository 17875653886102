import { IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding } from "@ionic/react";
import { TContractDockWalk } from "@wattsonelements/front-fdk/dist/models/Boats.models";
import classNames from "classnames";
import dayjs from "dayjs";
import { calendarOutline } from "ionicons/icons";
import { FC } from "react";
import { useAppSelector } from "../../../core/store/hooks";
import { useTranslation } from "react-i18next";
import { TagsBadge } from "../TagsBadge";

type ContractItemProps = {
  contract: TContractDockWalk;
  selected?: boolean;
  [key: string]: any;
};

export const ContractItem: FC<ContractItemProps> = ({ contract, selected = false, ...props }) => {
  const { t } = useTranslation()
  const { current } = useAppSelector(state => state.ports);

  return (
    <button
      {...props}
      className={classNames("w-full text-left rounded-2xl flex gap-3 overflow-hidden bg-white min-h-[110px]", {
        "shadow-md outline outline-2  outline-SECONDARY": selected,
      })}
    >
      <div className="self-stretch flex flex-col gap-1 justify-center items-center bg-GREY p-2 min-w-[100px]">
        {contract.berth && <span className="text-white text-lg font-bold">{contract.berth?.name}</span>}
        <img className="max-w-[60px] min-h-[60px]" src={contract?.boat?.boat_type_icon.light} />
        {!contract.berth && current && (
          <div className="flex flex-col justify-center leading-3">

            {contract.boat.length && (
              <span className="leading-4 text-center text-white text-base font-bold">
                {contract.boat.length} {t(`shared.units.${current?.config.distance_unit}`)}
              </span>
            )}
            {contract.boat.width && (
              <span className="leading-4 text-center text-white text-base font-bold">
                {contract.boat.width} {t(`shared.units.${current?.config.distance_unit}`)}
              </span>

            )}
          </div>
        )}
      </div>
      <div className="self-stretch px-2 pb-2 gap-1 flex flex-col w-full">
        <div className="flex justify-end gap-2 mr-3 w-full">
          {contract.tags.map((tag: any) => (
            <TagsBadge color="grey" key={`${contract.id}-tag`} label={tag} />
          ))}
          {contract.paid_status && (
            <TagsBadge
              color={contract.paid_status.color}
              backgroundColor={contract.paid_status.background_color}
              label={contract.paid_status.label}
            />
          )}
        </div>
        <div className="flex flex-col leading-3">
          <span className="font-bold text-xl">{contract.boat.name}</span>
          <span>
            {contract.owner?.first_name} {contract.owner?.last_name}
          </span>
        </div>
        {contract.next_departure && (
          <div className="flex items-center gap-2 mt-2">
            <IonIcon className="text-BLUE_GREY" icon={calendarOutline} />
            <span className="text-BLUE_GREY text-sm">
              {t("dockwalk.nextDeparture")}
              {contract.next_departure && dayjs(contract.next_departure).format(t("dateFormat.daymonth"))}
            </span>
          </div>
        )}
      </div>
    </button>
  );
};

type contractItemSlidingProps = {
  contract: TContractDockWalk;
  left?: {
    label: string;
    onSlided: (e: any, contract: TContractDockWalk) => any;
  };
  right?: {
    label: string;
    onSlided: (e: any, contract: TContractDockWalk) => any;
  };
  [key: string]: any;
};
export const ContractItemSliding: FC<contractItemSlidingProps> = ({ contract, left, right, ...props }) => {
  return (
    <IonItemSliding>
      {left && (
        <IonItemOptions
          onIonSwipe={e => {
            left.onSlided(e, contract);
          }}
          side="start"
        >
          <IonItemOption
            onClick={e => {
              left.onSlided(e, contract);
            }}
            expandable
            className="rounded-l-2xl"
            color="secondary"
          >
            <div className="p-4 text-white font-bold">{left.label}</div>
          </IonItemOption>
        </IonItemOptions>
      )}

      <IonItem
        style={{ "--inner-padding-end": "0", "--padding-start": "0", "--padding-end": "0", "--background": "transparent" }}
        lines="none"
      >
        <ContractItem {...props} contract={contract} />
      </IonItem>
      {right && (
        <IonItemOptions
          onIonSwipe={e => {
            right.onSlided(e, contract);
          }}
        >
          <IonItemOption
            onClick={e => {
              right.onSlided(e, contract);
            }}
            expandable
            className="rounded-r-2xl"
            color="secondary"
          >
            <div className="p-4 text-white font-bold">{right.label}</div>
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
};
