import React from "react";
import { colors } from "../../../styles/colors";
import { IonInput } from "@ionic/react";
import { createUseStyles } from "react-jss";


type InputProps = {
  placeholder?: string;
  value?: string | number;
  topPlaceholder?: string;
  onChangeText: any;
  className?: string
  error?: boolean
  [key: string]: any;
} & Partial<typeof IonInput>

export default function Input(props: InputProps) {
  const { placeholder, topPlaceholder, onChangeText = () => { } } = props;
  const styles = useStyles();

  return (
    <div className={props.className}>
      {topPlaceholder && <p>{topPlaceholder}</p>}
      <IonInput
        {...props}
        onIonChange={(e) => {
          onChangeText(e.detail.value);
        }}
        className={`${styles.input} ${props.error ? styles.inputError : ''}`}
      />
    </div>
  );
}

const useStyles = createUseStyles({
  input: {
    height: "100%",
    width: "100%",
    marginVertical: 5,
    borderWidth: 1,
    borderRadius: 7.5,
    backgroundColor: colors.CALCITE,
    borderColor: colors.LIGHT_GREY,
    borderStyle: "solid",
    shadowColor: "rgba(0, 0, 0, 0.2)",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowRadius: 19,
    elevation: 5,
  },
  inputError: {
    borderColor: colors.DANGER,
  }
});
