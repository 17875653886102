import MenuItem from "./MenuItem";
import { useAppSelector, useAppDispatch } from "../../../core/store/hooks";
import { getFirstLvlOnly } from "../../../core/store/selectors/categories";
import { setSelectedCategory } from "../../../core/store/reducers/Categories";
import { useTranslation } from "react-i18next";
import { ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";

export default function InterventionMenu() {
  const { t } = useTranslation()
  const categories = useAppSelector(getFirstLvlOnly);
  const dispatch = useAppDispatch();
  return (
    <div className="flex flex-wrap p-2 bg-LIGHT">
      <MenuItem
        all={true}
        onClick={() => dispatch(setSelectedCategory(null))}
        category={{ label: t("ticket.all") }}
      />
      {categories.map((cat: ITicketCategory) => {
        return (
          <MenuItem
            all={false}
            onClick={() => {
              dispatch(setSelectedCategory(cat.id));
            }}
            key={cat.slug}
            category={cat}
          />
        );
      })}
    </div>
  );
}
