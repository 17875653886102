import React, { useState } from "react";
import Toggle from "../UI/form/Toggle";

interface CheckList {
  label: string;
  name?: string;
  value?: boolean;
}
export function RadioList({
  list,
  update,
}: {
  list: CheckList[];
  update: Function;
}) {
  const [values, setValue] = useState(list);

  const setList = (item: CheckList, ind: number) => (toggleValue: any) => {
    console.log("set list", item, ind, toggleValue);
    const newValues = values.map((val, index) => {
      if (index === ind)
        return { ...val, value: toggleValue } as CheckList;
      else
        return { ...val, value: false } as CheckList;
    });
    update(newValues);
    setValue(newValues);
  };
  return (
    <div className="drop-shadow-md rounded-sm overflow-hidden">
      {values.map((item, index) => {
        return (
          <div className="flex-col flex bg-LIGHT_GREY justify-center items-center py-3 px-0 border-CALCITE border-2 border-t-0 border-l-0 border-r-0" key={index}>
            <p className="text-xl mb-1 font-bold px-3">{item.label}</p>
            <Toggle
              name={item?.name}
              on={true}
              off={false}
              value={item.value}
              setValue={setList(item, index)}
            />
          </div>
        );
      })}
    </div>
  );
}