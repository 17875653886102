import classNames from "classnames";

import TicketItem from "./ticketItem";
import { createUseStyles } from "react-jss";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";

export default function TicketsList(props: any) {
  // const renderItem = ({ item }) => <TicketItem priority={item.priority} />;
  const { tickets, style } = props;
  const styles = useStyles();
  return (
    <div className={classNames(styles.container, style, "mt-4")}>
      {tickets &&
        tickets.map((item: ITicket) => (
          <TicketItem
            key={item.id}
            priority={item.priority}
            ticket={item}
          />
        ))}
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    width: "100%",
    paddingBottom: 20,
  },
});
