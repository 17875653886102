import { IonButton } from "@ionic/react";
import React from "react";
import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";

export default function Btn({
  label,
  color = "secondary",
  disabled = false,
  children,
  className,
  ...props
}: {
  label?: string;
  size?: "default" | "large" | "small" | undefined;
  expand?:  "block" | "full" | undefined;
  color?: "danger" | "dark" | "light" | "medium" | "primary" | "secondary" | "success" | "tertiary" | "warning" | string & Record<never, never> | undefined;
  disabled?: boolean;
  children?: any;
  className?: string
  [key: string]: any;
}) {
  
  return (
    <IonButton
      disabled={disabled}
      className={`my-2 normal-case ${className}`}
      style={{"--padding-start": "1em", "--padding-end": "1em"}}
      shape="round"
      color={color}
      {...props}
      
    >
      {label && (
        label
      )}
      {children}
    </IonButton>
  );
}