import { useState, useRef } from "react";
import { IonModal, IonDatetime, IonButton, IonIcon, IonButtons } from "@ionic/react"
import { timeOutline, calendarOutline, createOutline } from 'ionicons/icons';

import FieldItem from "../UI/form/FieldItem";
import Btn from "../UI/buttons/Btn";
import AddBtn from "../UI/buttons/AddBtn";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const icons = {
  "date-time": calendarOutline,
  "date": calendarOutline,
  "time": timeOutline,
}

export default function DatePicker({
  mode,
  update,
  label = '',
  icon = icons[mode],
  value,
  allowDelete = false
}: {
  mode: "date" | "time" | "date-time";
  update: Function;
  label?: string;
  icon?: string,
  value?: null | undefined | dayjs.Dayjs,
  allowDelete?: boolean
}) {
  const { t, i18n } = useTranslation()
  const modal = useRef<HTMLIonModalElement>(null);
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState<dayjs.Dayjs>(value ? dayjs(value) : dayjs());
  const styles = useStyles();

  const datetime = useRef<null | HTMLIonDatetimeElement>(null);

  const cancel = () => {
    datetime.current?.cancel();
    modal.current?.dismiss();
  }
  const confirm = () => {
    datetime.current?.confirm();
    modal.current?.dismiss();
  }

  const onChangeValue = (event: any) => {
    const updatedDate = dayjs(event.target.value)
    setDate(updatedDate);
    update(updatedDate?.toISOString());
  };

  enum fieldName {
    "date-time" = "shared.datetime",
    "date" = "shared.date",
    "time" = "shared.hour",
  }

  enum dateFormat {
    "date-time" = "dateFormat.longDate",
    "date" = "dateFormat.date",
    "time" = "dateFormat.hours",
  }

  return (
    <div>
      <FieldItem onClick={() => setOpen(true)} field={{ title: label || t(fieldName[mode]) }}>
        <IonModal onWillDismiss={() => setOpen(false)} keepContentsMounted={false} className={styles.modal} ref={modal} isOpen={open}>
          <IonDatetime
            defaultValue={date.format()}
            value={date.format()}
            ref={datetime}
            onIonChange={(e: any) => onChangeValue(e)}
            onIonBlur={() => {
              console.log("date blur");

            }}
            doneText={t("shared.done")}
            cancelText={t("shared.cancel")}
            showDefaultButtons={true}
            showDefaultTitle={false}
            locale={i18n.language}
            firstDayOfWeek={parseInt(t("shared.firstDayOfWeek"))}
            presentation={mode}>
            <span slot="time-label">{t("shared.hour")}</span>
            <IonButtons slot="buttons">
              <IonButton shape="round" color="secondary" onClick={cancel}>{t("shared.cancel")}</IonButton>


              <Btn fill="solid" color="secondary" onClick={confirm}>{t("shared.ok")}</Btn>
            </IonButtons>
          </IonDatetime>
        </IonModal>
        {
          !value &&
          <AddBtn color="secondary" onClick={() => setOpen(true)}>
            <IonIcon className={styles.icon} size="large" color="light" icon={icon} />
            {mode === "date-time" &&
              <IonIcon className={styles.icon} size="large" color="light" icon={timeOutline} />
            }
          </AddBtn>
        }
        {
          value && <>
            <IonButton fill="clear">
              {dayjs(value).format(t(dateFormat[mode]))}
              <IonIcon className={styles.icon} size="small" color="secondary" icon={createOutline} />
            </IonButton>
            {/* {
              allowDelete && value &&  <IonButton size="small" shape="round" fill="outline" color="danger" onClick={() => {
                update(null)
              }}>x</IonButton>
            } */}
          </>
        }
      </FieldItem>
    </div>
  );
}

const useStyles = createUseStyles({
  modal: {
    "--width": "fit-content",
    "--min-width": " 250px",
    "--height": "fit-content",
    "--border-radius": " 6px",
    "--box-shadow": "0 28px 48px rgba(0, 0, 0, 0.4)",
    "--backdrop-opacity": "var(--ion-backdrop-opacity, 0.32)",
    "color": "var(--ion-color-primary)",
  },
  icon: {
    height: 22,
    width: 22,
    marginLeft: "5px",
    color: "white"
  }
}
)