import { IonSkeletonText } from "@ionic/react"

export const DetailLoader = () => {

  return <div className=" flex flex-col gap-8 ">
    <div className="grid grid-cols-3 gap-x-6 w-full" >
      <IonSkeletonText className="w-full" animated={true} ></IonSkeletonText>
      <IonSkeletonText animated={true} ></IonSkeletonText>
      <IonSkeletonText animated={true} ></IonSkeletonText>
      <IonSkeletonText animated={true} ></IonSkeletonText>
      <IonSkeletonText animated={true} ></IonSkeletonText>
      <IonSkeletonText animated={true} ></IonSkeletonText>
    </div>
    <div className="flex flex-col gap-4">
      <IonSkeletonText className="w-1/2 h-5" animated={true} ></IonSkeletonText>
      <IonSkeletonText className="w-full h-[150px]" animated={true} ></IonSkeletonText>
    </div>
    <div className="flex flex-col gap-2">
      <IonSkeletonText className="w-1/2 h-5" animated={true} ></IonSkeletonText>
      <IonSkeletonText className="w-full" animated={true} ></IonSkeletonText>
      <IonSkeletonText className="w-3/4" animated={true} ></IonSkeletonText>
      <IonSkeletonText className="w-1/3" animated={true} ></IonSkeletonText>
    </div>
    <div className="flex flex-col gap-2">
      <IonSkeletonText className="w-1/2 h-5" animated={true} ></IonSkeletonText>
      <IonSkeletonText className="w-full" animated={true} ></IonSkeletonText>
      <IonSkeletonText className="w-3/4" animated={true} ></IonSkeletonText>
      <IonSkeletonText className="w-1/3" animated={true} ></IonSkeletonText>
    </div>
  </div>
}