import React, { useEffect, useState } from "react";
import { IonModal, IonSpinner } from "@ionic/react"
import { useAppDispatch, useAppSelector } from "../../core/store/hooks";
import { colors } from "../../styles/colors";
import Btn from "../UI/buttons/Btn";
import {
  PROTOCOLE_STATUS,
  setProtocoleStatus,
} from "../../core/store/reducers/Protocole";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { removeTicket } from "../../core/store/reducers/Tickets";
import { ActionStatus } from "../UI/ActionStatus";
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api";
import { checkmark, close } from "ionicons/icons"
import { IGenericResponse } from "@wattsonelements/front-fdk/dist/models/Misc.models";


export default function ResultModal({
  type = "success",
  retry,
  state,
  requestMessage
}: {
  type: "error" | "success" | "loading";
  state: REQUEST_STATUS,
  retry: Function;
  requestMessage?: IGenericResponse | null
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const history = useHistory()
  const ticket = useAppSelector((state) => state.tickets.selected);
  const typeDef = {
    [REQUEST_STATUS.ERROR]: {
      icon: close,
      text: t("protocole.resultModal.error"),
    },
    [REQUEST_STATUS.SUCCESS]: {
      icon: checkmark,
      text: t("protocole.resultModal.success"),
    },
    [REQUEST_STATUS.PENDING]: {
      icon: null,
      text: t("protocole.resultModal.loading"),
    },
  } as any;

  const [timeout, setTimeoutValue] = useState<any>();

  useEffect(() => {
    // avoid function being called multiple time and crashing goback function
    if (type === "success" && !timeout) {
      setTimeoutValue(
        setTimeout(() => {
          // goBack();
          dispatch(removeTicket(ticket));
          history.push("/dashboard/opens")
          dispatch(setProtocoleStatus(PROTOCOLE_STATUS.END));
        }, 10000)
      );
    }
  }, [type]);

  return (
    <div
    >
      <div className="flex flex-col" style={styles.container}>
        <ActionStatus
          state={state}
          title={requestMessage?.title || typeDef[state].text}
          message={requestMessage?.message || ""}
          sub_message={requestMessage?.sub_message || ""}
        />

        {type === "error" && (
          <div className="flex flex-col" style={styles.btnGroup}>
            <Btn label={t("ticket.retry")} onClick={() => retry()} />
            <Btn
              color="secondary"
              onClick={() => history.goBack()}
              label={t("ticket.goBackToTickets")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
  text: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 50,
  },
  imageContainer: {
    backgroundColor: colors.LIGHT_GREY,
    borderRadius: 80,
    padding: 10,
    width: 160,
    height: 160,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 70,
    height: 70,
  },
  btnGroup: {
    marginTop: 30,
  },
}
