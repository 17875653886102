import { IonContent, IonModal } from "@ionic/react"
import FDK from "@wattsonelements/front-fdk"
import { TContractDockWalk } from "@wattsonelements/front-fdk/dist/models/Boats.models"
import { IDockwalkBerth, IPlaceInDraft } from "@wattsonelements/front-fdk/dist/models/Dockwalk.models"
import { IGenericResponse } from "@wattsonelements/front-fdk/dist/models/Misc.models"
import { ISpot } from "@wattsonelements/front-fdk/dist/models/Spot.models"
import { FC, useState } from "react"
import { PlaceInBoatComponent } from "./PlaceInBoat.component"
import { DraftPlaceIn } from "./TemporarySheet"
import BackHeader from "../layouts/BackHeader"
import { TagsBadge } from "../UI/TagsBadge"
import { useTranslation } from "react-i18next";
import { IAgentBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models"

type PlaceInBerthProps = {
  berth: Partial<IAgentBerth | IDockwalkBerth>,
  close?: () => any
  [key: string]: any
}
/**
 * Select a boat to associate to a berth
 * @param props 
 * @returns Modal
 */
export const PlaceInBoatModal: FC<PlaceInBerthProps> = ({ berth, close = () => { }, ...props }) => {
  // const dispatch = useAppDispatch()
  const [requestStatus, setRequestStatus] = useState<number>(FDK.MainAPI.REQUEST_STATUS.IDLE)
  const [resquetMessages, setRequestMessages] = useState<IGenericResponse | null>(null)
  const { t } = useTranslation()

  const placeInAction = (e: any, contract: Partial<TContractDockWalk>) => {
    setRequestStatus(FDK.MainAPI.REQUEST_STATUS.PENDING)
    FDK.Agent.DockwalkModule.placeIn(contract.id!, berth.id!).then(res => {
      setRequestMessages(res.data)
      setRequestStatus(FDK.MainAPI.REQUEST_STATUS.SUCCESS)
    }, err => {
      setRequestMessages(err?.data || null)
      console.log("error", err);

      setRequestStatus(FDK.MainAPI.REQUEST_STATUS.ERROR)
    }).catch(function (error) {
      console.log("error catch ??", error, error.message);
    });
  }

  const placeInDraftAction = (data: Partial<DraftPlaceIn>) => {
    delete data.valid
    FDK.Agent.DockwalkModule.placeInDraft(data as IPlaceInDraft, berth.id!).then(res => {
      setRequestMessages(res.data)
      setRequestStatus(FDK.MainAPI.REQUEST_STATUS.SUCCESS)
    }, err => {
      setRequestMessages(err?.data || null)
      setRequestStatus(FDK.MainAPI.REQUEST_STATUS.ERROR)
    })
  }

  return <IonModal onDidDismiss={() => {
    // setSearchText("")
    // setNextPage(1)
    setRequestMessages(null)
    setRequestStatus(FDK.MainAPI.REQUEST_STATUS.IDLE)
  }} {...props}
  // className="swipe-modal" 
  // initialBreakpoint={0.75}
  //  breakpoints={[0, .75, .9]}
  >
    <BackHeader
      goBack={() => {
        close()
      }} title={t("dockwalk.placein.title")}>
      {
        berth &&
        <p className="flex items-center flex-gap gap-2">
          <span className="text-white text-base font-bold">
            {t('shared.berth')} {berth.name}
          </span>
          {
            berth?.category?.label && <TagsBadge color="grey" size="small" uppercase={false} label={berth?.category?.label} />
          }
        </p>
      }
    </BackHeader>

    <IonContent scrollY={false}>
      <PlaceInBoatComponent
        displayHeader={false}
        actionMessages={resquetMessages}
        title={t("dockwalk.placein.title")}
        hasCreateBtn={true}
        status={requestStatus}
        onPlaceInAction={placeInAction}
        onDraftPlaceInAction={placeInDraftAction}
        berth={berth} />
    </IonContent>
  </IonModal>
}