// import { I18n } from "i18n-js";
import i18n from 'i18next';
import { initReactI18next, } from "react-i18next";

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as fr from '../assets/langs/fr.json'
import * as en from '../assets/langs/en.json'

// const i18n = new I18n({
//     "en": en,
//     "fr": fr,
// })

// // TODO
// i18n.defaultLocale = 'en-EN';
// i18n.locale = window.navigator.language
// // I18n.locale = 'en-EN';
// // When a value is missing from a language it'll fallback to another language with the key present.
// i18n.enableFallback = true;
// i18n.onChange((test) => {
//     console.log("I18n has changed!", test);
// });


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        // lng: 'fr', // lng from language
        detection: { order: ["navigator"] },
        ns: ['common'],
        defaultNS: 'common',
        // the translations
        supportedLngs: ['fr', 'fr-FR', 'en', 'en-US'],
        fallbackLng: "fr",
        resources: {
            en: {
                common: en
            },
            fr: {
                common: fr
            },
        },
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });
i18n.changeLanguage()
export default i18n;
