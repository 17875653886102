import { IonContent, IonIcon, IonModal } from "@ionic/react";
import { closeCircleOutline } from 'ionicons/icons';
import React, { useState } from "react";

export default function ImageModal({
  image,
  onClose,
}: {
  image: string;
  onClose: any;
}) {

  return (
    <IonModal
      className="select-modal"
      isOpen={image ? true : false}
    >
      <IonContent

        style={{
          "--background": 'transparent'
        }} >
        <div className="h-full flex justify-center items-center">
          <button
            onClick={() => onClose()}
            className=" h-fit relative w-full"
          >
            <IonIcon className="absolute -top-5 right-5" color="light" size="large" icon={closeCircleOutline} />
            <div className="p-4">
              <img
                className="object-contain rounded-xl"
                src={image}
                alt=""
              />
            </div>
          </button>
        </div>
      </IonContent>
    </IonModal>
  );
}
