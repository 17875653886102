import { createContext, FC, useContext, useState } from "react";
interface ListPeriodic {
  [key: string]: {
    delay: number;
    isActive: boolean;
    request: () => Promise<any>;
    timeoutId: NodeJS.Timer | null;
    requireLogin: boolean;
    retry: number;
  };
}

interface IAppContext {
  periodics: ListPeriodic;
  setPeriodics?: (periodic: ListPeriodic) => void;
}

const defaultState = {
  periodics: {},
};
export const AppContext = createContext<IAppContext>(defaultState);
export const useAppContext = () => useContext(AppContext);

export const AppProvider: FC = ({ children }) => {
  const [periodics, setPeriodics] = useState(defaultState.periodics);

  return (
    <AppContext.Provider
      value={{
        periodics,
        setPeriodics,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
