import React from "react";
import Avatar from "./Avatar";
import dayjs from "dayjs";
import { IUser } from "@wattsonelements/front-fdk/dist/models/User.models";
import { useTranslation } from "react-i18next";

export default function Creator({ creator, defaultDate }: { creator: IUser, defaultDate?: string }) {
  const { t } = useTranslation()
  const date = defaultDate ? dayjs(defaultDate) : dayjs();

  return (
    <div className="flex flex-1 justify-start items-center pb-4">
      <div className="flex items-center justify-center "><Avatar src={creator.picture} /></div>
      <div className="flex flex-col pl-2 flex-1">
        <span className="text-FONT_DARK text-sm">
          {" "}
          {t("ticket.creator.createdBy")}{" "}
          <span className="font-bold">
            {creator.first_name} {creator.last_name}
          </span>{" "}
        </span>
        <span className="text-xs text-LIGHT_GREY">
          {" "}
          {t("ticket.creator.on")}{" "}
          {date.format(t("dateFormat.longDate"))}{" "}
        </span>
      </div>
    </div>
  );
}
