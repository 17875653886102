import { useAppDispatch } from "../../core/store/hooks";
import { resetProtocole } from "../../core/store/reducers/Protocole";
import { arrowBackOutline } from 'ionicons/icons';
import { useHistory } from "react-router";
import { IonHeader, IonIcon } from "@ionic/react";

export default function BackHeaderFlux({
  title,
  subtitle,
  children,
}: {
  title?: string;
  subtitle?: string;
  children?: any;
}) {
  const history = useHistory()
  const dispatch = useAppDispatch();
  const goBack = () => {
    history.goBack();
    
    dispatch(resetProtocole());
  };
  return (
    <IonHeader mode="md" className="z-0">
    <div className="w-full px-4 flex justify-start items-center gap-2 pt-10 pb-9 bg-PRIMARY">
      <div className="flex justify-center align-center text-3xl">
        <button onClick={() => goBack()} className="text-3xl">
          <IonIcon color="secondary" style={{ fontSize: "1.4em" }} icon={arrowBackOutline} />
        </button>
      </div>
      <div className="flex-4">
        <p className="font-extrabold text-CALCITE text-xl">{title}</p>
        <p className="text-CALCITE text-lg">{subtitle}</p>
      </div>
    </div>
    </IonHeader>
  );
}
