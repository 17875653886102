
export const saveItem = async (key: string, value: string) => {
    try {
        await localStorage.setItem(key, value);
    } catch (error) {
        console.log("error async storage", error);
    }
}

export const getDataFromStorage = async (key: string) => {
    try {
        const value = await localStorage.getItem(key)
        // console.log("val", key, value);
        if (value !== null) {
            // value previously stored
            return value
        }
    } catch (e) {
        // error reading value
    }
}
