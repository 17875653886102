
import { IonSpinner } from '@ionic/react';
import { useState } from 'react';

type imgProps = {
  src: string,
  className?: string,
  loadedCB?: Function
}

export default function ImageLoader({ src,
  className = "",
  loadedCB }: imgProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const onLoadCB = () => {
    setLoading(false);
    if (loadedCB)
      loadedCB()
  }
  const onErrorCB = () => {
    setError(true)
    setLoading(false);
    if (loadedCB)
      loadedCB()
  }
  return (
    <>
      {loading && <div className="absolute w-full h-full flex items-center"><IonSpinner name="crescent" /></div>}
      {!error ?
        <img className={className}
          src={src} onError={onErrorCB} onLoad={onLoadCB} />
        :
        <div className={className}>Image Error</div>
      }
    </>
  )

}