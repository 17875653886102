import { IonContent, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonSpinner, RefresherEventDetail, useIonViewWillEnter } from "@ionic/react"
import { TAHeaderPageList } from "../components/technicalArea/HeaderList"
import { useAppDispatch, useAppSelector } from "../core/store/hooks"
import { useHistory, useLocation } from "react-router"
import { useState } from "react"
import { getTABookingListOnArea, getTABookingListToday, getTABookingListTomorrow } from "../core/store/actions/TechnicalArea.actions"
import { getBookingList } from "../core/store/selectors/technicalArea.selector"
import { TA_LIST, selectTodayList } from "../core/store/reducers/TechnicalArea.reducer"
import { TAItemList } from "../components/technicalArea/TAItem.list"
import { useTranslation } from "react-i18next"

export const TechnicalAreaPage = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const listBooking = useAppSelector(getBookingList)
  const listType = useAppSelector(state => state.technicalArea.selectedList)
  const history = useHistory()
  const location = useLocation()

  const [listLoading, setListLoading] = useState(true)

  const loadData = (event?: CustomEvent<RefresherEventDetail>) => {
    console.log(event);

    if ((!!!event)) {
      setListLoading(true)
    }
    // TODO get current list
    dispatch(getTABookingListToday({
    })).finally(() => {
      if (listType === TA_LIST.TODAY) {
        event && event.detail.complete()
        setListLoading(false)
      }
    });
    dispatch(getTABookingListTomorrow({
    })).finally(() => {
      if (listType === TA_LIST.TOMORROW) {
        event && event.detail.complete()
        setListLoading(false)
      }
    });
    dispatch(getTABookingListOnArea({
    })).finally(() => {
      if (listType === TA_LIST.ON_AREA) {
        event && event.detail.complete()
        setListLoading(false)
      }

    });

  }
  useIonViewWillEnter(() => {
    loadData()
    if (location.pathname.split('/').length === 3) {
      history.replace("/technical-area/dashboard/today")
      dispatch(selectTodayList())
    }
  }, [])


  return (
    <IonPage>
      <IonHeader>
        <TAHeaderPageList />
      </IonHeader>
      <IonContent style={{ "--background": "#f4f4f4" }}>
        <IonRefresher slot="fixed" onIonRefresh={loadData}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {/* <IonLoading showBackdrop={false} backdropDismiss={false} spinner="circles" isOpen={listLoading} /> */}

        {
          listLoading && <IonSpinner color="secondary" className="m-auto block pt-5" />
        }
        {
          !listLoading && listBooking.length === 0 && listType !== TA_LIST.ON_AREA &&
          <div className="p-5 italic text-gray-500 text-center w-full">
            {t("technicalArea.list.emtpy")}
          </div>
        }
        {
          !listLoading && listBooking.length === 0 && listType === TA_LIST.ON_AREA &&
          <div className="p-5 italic text-gray-500 text-center w-full">
            {t("technicalArea.list.emtpyArea")}
          </div>
        }

        <div className="bg-LIGHT flex flex-col gap-3 px-5 pb-14">
          {
            listBooking.map(b => <TAItemList
              key={b.id}
              item={b}
              onClick={() => history.push(`/technical-area/detail/${b.id}`)}
            />)
          }
        </div>

      </IonContent>
    </IonPage>
  )
}