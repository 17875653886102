import imageCompression from "browser-image-compression";
import { useDropzone } from 'react-dropzone'
// import { UseFormReturn } from 'react-hook-form';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { loadAsBase64 } from '../../../_helpers_/image.helpers';
import ImageModal from "./ImageModal";

type ImageUploaderProps = {
  name: string;
  simpleButton?: boolean;
  multiple?: boolean;
  label?: string | null;
  displayLabel?: boolean;
  children?: JSX.Element;
  onChange?: (files: File[], previews: string[]) => void;
}
export const ImageUploader = forwardRef((props: ImageUploaderProps, ref: any) => {
  const {
    name,
    multiple = false,
    onChange = null,
    children = <></>
  } = props;
  const [loading, setLoading] = useState(false);
  const [modalImage, setModalImage] = useState('')
  const [compressedFiles, setCompressedFiles] = useState<File[]>([])

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    const options = {
      maxSizeMB: 5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
      initialQuality: 0.8
    };
    let compFiles: File[] = [];
    let base64Files: string[] = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      try {
        let compFile = await imageCompression(acceptedFiles[i], options);
        compFiles.push(new File([compFile], compFile.name))
        base64Files.push(await loadAsBase64(compFile));
      } catch (error) {
        console.log(error);
      }
    }
    setCompressedFiles(compFiles)
    if (typeof onChange === "function")
      onChange(compFiles, base64Files);
    setLoading(false);
  }, [])

  useImperativeHandle(ref, () => ({
    reset() {
      // handleDelete(null);
    },
  }))
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpg, image/jpeg, image/png', multiple })
  return (
    <>
      <ImageModal
        onClose={() => {
          setModalImage("");
        }}
        image={modalImage}
      />
      <div
        {...getRootProps({
          className: (isDragActive && " border-fcosecondaryhover") || ""
        })}>
        <div className="flex items-center justif-center w-full gap-x-2">
          {children}

        </div>
        <input
          multiple={multiple}
          className="hidden"
          accept="image/*"
          id={name} type="file" />

        <input
          {...getInputProps()} />
      </div>
    </>
  )
})
