import { IonIcon, IonNavLink } from "@ionic/react"
import { ticket } from "ionicons/icons"
import { FC } from "react"

type TicketErrorApiProps = {
  error: string,
  label: string
}

export const TicketErrorApi:FC<TicketErrorApiProps> = ({error, label}) => {

  return (
    <div className="flex justify-center items-center gap-4 m-4 text-2xl text-red-800">
      <IonIcon className="text-6xl" color="default" icon={ticket} />
      <div>
        <p className="font-bold text-4xl">{error}</p>
        <p className="">{label}</p>
      </div>
    </div>
  )
}