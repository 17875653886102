import { useEffect, useState } from "react";
import AddBtn from "../UI/buttons/AddBtn";
import FieldItem from "../UI/form/FieldItem";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import { useAppDispatch, useAppSelector } from "../../core/store/hooks";
import { useTranslation } from "react-i18next";
import {
  postDocument,
  removeFromUploaded,
} from "../../core/store/reducers/Attachments";
import AttchamentItem from "./AttachmentItem";
import { ImageUploader } from "../UI/images/ImageUploader.element";
import { IAttachment } from "@wattsonelements/front-fdk/dist/models/Attachment.models";
import icon from "../../assets/icons/ui/attachment.png"
import cameraIcon from "../../assets/icons/ui/camera.png"

export default function TicketAttachment({
  value = [],
  raw = false,
  update,
}: {
  value?: Partial<IAttachment>[];
  raw?: boolean;
  upload?: boolean;
  update: (value: any) => any;
}) {
  const { t } = useTranslation()
  const [attachments, setAttachments] = useState<any[]>(value);
  const { uploaded, uploading } = useAppSelector((state) => state.attachments);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setAttachments(value)
  }, [value])

  const pickImage = (files: File[], previews: string[]) => {
    console.log("pickImage", files);
    files.map((file, i) => {
      dispatch(postDocument({ file, name: new Date().toISOString() + '_' + file.name, preview: previews[i] }));
    })
  };

  const takePicture = async () => {
    const permission = await Camera.checkPermissions()
    console.log(permission);

    // const permission = await Camera.requestPermissions({permissions:["camera"]})
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      promptLabelHeader: t('shared.imagePicker.promptLabelHeader'),
      promptLabelCancel: t('shared.cancel'),
      promptLabelPhoto: t('shared.imagePicker.promptLabelPhoto'),
      promptLabelPicture: t('shared.imagePicker.promptLabelPicture')
    });

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    var imageUrl = image.webPath || '';
    const file = await fetch(imageUrl).then(r => r.blob());
    if (!imageUrl) return // TODO ERROR
    dispatch(postDocument({ file, name: new Date().toISOString() + '_photo', preview: imageUrl }));
  };

  useEffect(() => {
    if (raw) {
      setAttachments([...value, ...uploading, ...uploaded]);
    } else {
      setAttachments([...value, ...uploading]);
    }
    [...uploaded].map((file: IAttachment) => {
      const fileUploadedToTicket = value.find((u: IAttachment) => {
        return u?.linked_file === file?.id
      });

      if (fileUploadedToTicket) {
        dispatch(removeFromUploaded(fileUploadedToTicket.linked_file));
      }
      return file;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaded.length, uploading.length, value.length])

  // // remove image from list
  const remove = (fileToRemove: IAttachment) => {
    const newAttachments = attachments.filter(
      (file: IAttachment) => {
        return file.id !== fileToRemove.id && !file.isUploading
      }
    );
    console.log("remove", newAttachments);
    if (fileToRemove.isLocal) {
      dispatch(removeFromUploaded(fileToRemove.id));
    } else {
      update(newAttachments);
    }
  };

  useEffect(() => {
    console.log("image uploaded useEffect in attachment.tsx", uploaded);
    // update file info after upload
    // prevent empty uploaded array to send odd ticket
    if (uploaded.length === 0) return;
    if (raw) {
      update(uploaded);
    } else {
      update([...value, ...uploaded]);
    }
  }, [uploaded]);

  return (
    <FieldItem
      onClick={() => { }}
      filled={attachments.length > 0}
      field={{
        title: t("ticket.formFields.attachments"),
        icon,
      }}
    >
      {attachments.length > 0 && (
        <div
          className="flex w-full overflow-x-auto gap-2"
        >
          {attachments.map((file: IAttachment, index: number) => (
            <AttchamentItem key={"file" + index} file={file} remove={remove} />
          ))}
          {/* <IonSlides options={{
            spaceBetween: 3,
            slidesPerView: 2.75
          }}>
          {attachments.map((file: Attachment, index: number) => (
            <IonSlide key={index}>
              <AttchamentItem key={"file" + index} file={file} remove={remove} />
            </IonSlide>
          ))}
            </IonSlides> */}
        </div>
      )}
      {/* {attachments.length === 0 && ( */}
      <div className="flex w-full justify-center">
        <ImageUploader
          multiple={true}
          onChange={pickImage}
          label={" "}

          name="picture"
        >
          <AddBtn
            onClick={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
              console.log("attachments", attachments);
              console.log("uploaded", uploaded);
              console.log("uploading", uploading);
              takePicture()
            }}
            icon={cameraIcon}
          />
        </ImageUploader>

      </div>
      {/* )} */}
    </FieldItem>
  );
}

