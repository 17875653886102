import { IonButton } from "@ionic/react";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router";
import { useAppSelector } from "../../core/store/hooks";
import { RootState } from "../../core/store/store";
// import JSONTree from "react-native-json-tree";

export function DebugState() {
  const page = useLocation().pathname;
  const [useLog, setUseLog] = useState(true);
  const [showLog, setShowLog] = useState(false);
  const state = useAppSelector((state: RootState) => state);
  const styles = useStyles();
  const pageState = useAppSelector((state: RootState) => state);
  const [displayedStates, setDisplayedStates] = useState({
    tickets: false,
    users: false,
  });
  return (
    <div>
      <p>Log state page {page}</p>
      {/* <BtnCheckbox label={useLog ?"Use console Log" : "Show Log"} selected={useLog} onClick={() => {
                setUseLog(!useLog)
            }}></BtnCheckbox> */}
      {/* <BtnCheckbox label='tickets' selected={useLog} onClick={() => {
               
            }}></BtnCheckbox> 
            <BtnCheckbox label='users' selected={useLog} onClick={() => {
               
            }}></BtnCheckbox>
            <BtnCheckbox label='boats' selected={useLog} onClick={() => {
               
            }}></BtnCheckbox>
            <BtnCheckbox label='Protocole' selected={useLog} onClick={() => {
               
            }}></BtnCheckbox> */}
      <IonButton
        size="small"
        color="secondary"
        onClick={() => {
          if (useLog) {
            console.log(state);
          } else {
            setShowLog(!showLog);
          }
        }}
      >
        Log State
      </IonButton>
      {/* {showLog && !useLog && (
        <JSONTree data={JSON.stringify(pageState)}></JSONTree>
      )} */}
    </div>
  );
}

const useStyles = createUseStyles({
  container: {},
});
