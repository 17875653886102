import { IonButton, IonInput, IonTextarea } from "@ionic/react"
import { FC, useEffect, useState } from "react"
import { TabFilterScrollList } from "../UI/tabs/TabFilterList"
import { useTranslation } from "react-i18next";

export type DraftPlaceIn = {
  passportEscale: string,
  informations: string,
  stayDuration: number,
  valid: boolean
}
type TemporarySheetProps = {
  onSend?: (data: DraftPlaceIn) => any,
  onChange?: (data: DraftPlaceIn) => any,
  createButton?: boolean
}
export const TemporarySheet: FC<TemporarySheetProps> = ({ createButton = true, onSend = () => { }, onChange = () => { } }) => {
  const { t } = useTranslation()
  const [passportEscale, setPassportEscale] = useState("")
  const [informations, setInformations] = useState("")
  const [stayDuration, setStayDuration] = useState(1)
  const [forceRefresh, setForceRefresh] = useState("")

  const isValid = () => {
    if ((!passportEscale || !informations) && !stayDuration) return false
    if (!stayDuration) return false
    if (!informations) return false
    if (passportEscale.length >= 1 && passportEscale.length < 6) return false
    return true
  }
  useEffect(() => {
    onChange({
      passportEscale,
      informations,
      stayDuration,
      valid: isValid()
    })
  }, [passportEscale, informations, stayDuration])

  return (
    <>
      <IonInput
        style={{
          '--icon-color': 'var(--ion-color-primary',
          '--box-shadow': "0",
          '--border-radius': "40px",
          "--padding-start": "25px",
          "--padding-end": "25px",
          "--padding-top": "12px",
          "--padding-bottom": "12px",
        }}
        type="number"
        min="1" max="5"
        value={passportEscale}
        className="rounded-full border-2 border-SECONDARY"
        placeholder={t("dockwalk.placein.temporary.passportNumberPlaceholder")}
        maxlength={6}

        onIonChange={(e) => {
          const value = e.detail.value;
          setForceRefresh(e.detail.value || "")
          if (e.detail.value === passportEscale) return
          if (!e.detail.value) return setPassportEscale("")
          if (e.detail.value && e.detail.value.length > 6) {
            e.preventDefault();
            setPassportEscale((' ' + passportEscale).slice(1))
            return
          }
          setPassportEscale(e.detail.value!)
        }}

      />
      <div className="border border-LIGHT_GREY mx-6"></div>
      <IonTextarea
        style={{
          '--icon-color': 'var(--ion-color-primary',
          '--box-shadow': "0",
          '--border-radius': "40px",
          "--padding-start": "25px",
          "--padding-end": "25px",
          "--padding-top": "12px",
          "--padding-bottom": "12px",
        }}
        autoGrow={true}
        rows={3}
        className="rounded-3xl border-2 border-SECONDARY"
        placeholder={t("dockwalk.placein.temporary.informationsPlaceholder")}
        onIonChange={(e) => {
          setInformations(e.detail.value || "")
        }}
      ></IonTextarea>
      <TabFilterScrollList
        selectKey="count"
        hasAllTab={false}
        onSelected={(e, duration) => { setStayDuration(duration) }}
        tabs={[
          {
            label: "Nuit",
            count: 1,
          },
          {
            label: "Nuits",
            count: 2
          },
          {
            label: "Nuits",
            count: 3
          },
          {
            label: "Nuits",
            count: 4
          },
          {
            count: "Non défini"
          },

        ]} />
      {
        createButton &&
        <IonButton
          disabled={!isValid()}
          color="secondary"
          size="large"
          fill="solid"
          expand="full"
          shape="round"
          onClick={() => {
            onSend({
              passportEscale,
              informations,
              stayDuration,
              valid: isValid()
            })
          }}
        >{t('shared.create')}</IonButton>
      }

    </>
  )
}