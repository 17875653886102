import i18n from "./../../utils/langs";
// import "../../utils/langs"; // called here because this file is sometimes loaded before config i18n
import markerIconSVG from "./../../assets/icons/marker.svg";
import markerIconSelectedSVG from "./../../assets/icons/marker-selected.svg";


export const markerIcon = {
  icon: markerIconSVG,
  selectedIcon: markerIconSelectedSVG,
  width: 20,
  height: 20
}
