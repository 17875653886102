import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const getStep = ({ protocole }: RootState) => protocole.currentStep;
const getConfig = ({ protocole }: RootState) => protocole.config;

export const getFullstep = createSelector(
  getStep,
  getConfig,
  (step: number, config: any) => {
    if (config && step in config) {
      return config[step];
    }
    return undefined;
  }
);
