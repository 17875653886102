import { arrowBackOutline } from 'ionicons/icons';
// import Toggle from "../UI/form/Toggle";
import dayjs from "dayjs";
import { useAppDispatch } from "../../core/store/hooks";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { IonHeader, IonIcon } from "@ionic/react";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";

export default function BackHeaderTicket({
  title,
  creator,
  reference,
  icon,
  selectedTicket
}: {
  title?: string;
  icon?: any;
  creator?: string;
  reference?: string;
  children?: any;
  selectedTicket: ITicket
}) {
  const history = useHistory()
  const { t } = useTranslation()

  // const selectedTicket = useAppSelector(
  //   (state) => state.tickets.selected
  // ) as ITicket;

  const date = dayjs(selectedTicket.date_created);
  const dispatch = useAppDispatch();
  // const [priority, setPriority] = useState(selectedTicket.priority);

  // const updatePriority = (value: TicketPriority) => {
  //   setPriority(value);
  //   dispatch(saveTicket({ id: selectedTicket.id, field: { priority: value } }));
  // };



  return (
    <IonHeader mode="md" className="z-0">
      <div className="w-full px-4 flex justify-start items-center gap-2 pt-10 pb-14 bg-PRIMARY">
        <div className="flex flex-1 justify-start align-center text-3xl">
          <IonIcon onClick={() => history.goBack()} color="secondary" style={{ fontSize: "1.4em" }} icon={arrowBackOutline} />
        </div>
        <div className="text-LIGHT flex flex-3 flex-col text-center items-center justify-center">
          <img alt="category" className="w-8 h-8" src={icon} />
          <span className="text-CALCITE text-xl font-bold text-center">{title}</span>
          <span className="text-CALCITE text-md font-medium text-center">{reference}</span>
        </div>
        <div className="flex flex-1 justify-center">
          {/* {priority !== TicketPriority.URGENT && (
            <Toggle
              value={TicketPriority.NORMAL}
              setValue={() => {}}
              on={TicketPriority.INTERMEDIATE}
              off={TicketPriority.NORMAL}
              size="medium"
              color="secondary"
              labelColor={colors.CALCITE}
              label={t("ticket.formFields.priority")}
            />
          )} */}
        </div>
      </div>
    </IonHeader>
  );
}