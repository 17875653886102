import { createRef, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabFilter } from "./TabFilter";

const getDataFromKey = (key: string, data: any) => {
  if (!data) return
  const keys = key.split(".")
  const k = keys.reduce((acc: any, key, index) => {
    return acc[key]
  }, data)
  return k
}

type TabFilterScrollListProps = {
  onSelected: (e: any, tab: any) => any;
  labelKey?: string;
  selectKey?: string;
  countKey?: string;
  count?: number | null | undefined | string;
  hasAllTab?: boolean;
  tabs: {
    [key: string]: any;
    label?: string;
    count?: string | number;
  }[];
  iconRight?: string | null,
  iconLeft?: string | null
  // selected?: any
};

export const TabFilterScrollList: FC<TabFilterScrollListProps> = ({
  count,
  tabs,
  countKey = "count",
  labelKey = "label",
  selectKey = "id",
  hasAllTab = true,
  iconRight,
  iconLeft,
  onSelected,
  ...props
}) => {
  const { t } = useTranslation()
  const onClick = (e: any, tab: TabFilterScrollListProps["tabs"][0]) => {
    onSelected(e, getDataFromKey(selectKey, tab));
  };
  const [selected, setSelected] = useState<string>(hasAllTab ? "all" : tabs && tabs.length > 0 ? getDataFromKey(selectKey, tabs[0])! : "");


  return (
    <div className="overflow-y-auto min-h-[65px]">
      <div className="flex">
        {hasAllTab && (
          <TabFilter

            className="flex-1"
            selected={selected === "all"}
            onClick={(e: any) => {
              setSelected("all");
              onSelected(e, "all");
            }}
            count={count}
            label={t("common.all")}
          />
        )}

        <div className="flex  flex-grow ">
          {tabs.map((tab, i) => {
            const ref = createRef<any>();
            const handleClick = () => {
              if (!ref.current) return;
              ref.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
            };
            const select = getDataFromKey(selectKey, tab)
            const label = getDataFromKey(labelKey, tab)
            const count = getDataFromKey(countKey, tab)

            return (
              <TabFilter
                key={select}
                className="flex-1"
                selected={selected === select}
                ref={ref}
                onClick={(e: any) => {
                  setSelected(select);
                  onClick(e, tab);
                  handleClick();
                }}
                iconRight={iconRight}
                iconLeft={iconLeft}
                count={count || 0}
                label={label}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
