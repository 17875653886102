import FDK from "@wattsonelements/front-fdk";
import { IBerth, ITicket, ITicketBoat, ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import i18n from "../../utils/langs";
import { COMPONENT_TYPE } from "./constants";

enum STEPS {
  BOAT_QUESTION,
  REGISTER_BOAT,
  REGISTER_DEPARTURE,
}
export function generateMooringToConfirmStepDefinition(
  ticket: ITicket,
  category: ITicketCategory,
  isDockwalkActive: boolean = false
) {
  console.log("generateMooringToConfirmStepDefinition", isDockwalkActive);
  
  const boatName = (ticket.boat as ITicketBoat)?.name;
  const steps = isDockwalkActive ? dockwalkSteps() : baseSteps()
  return {
    [STEPS.BOAT_QUESTION]: {
      isNode: true,
      title: i18n.t("protocole.mooringToConfirm.question1", {
        boatname: boatName || "",
      }),
      stepNumber: 1,
      name: "STEPS.BOAT_QUESTION",
      answers: [
        {
          label: i18n.t("protocole.mooringToConfirm.yes"),
          value: "yes",
          color: "secondary",
          nextStep: STEPS.REGISTER_DEPARTURE,
        },
        {
          label: i18n.t("protocole.mooringToConfirm.no"),
          value: "no",
          color: "secondary",
          nextStep: STEPS.REGISTER_BOAT,
        },
        {
          label: i18n.t("protocole.mooringToConfirm.noBoat"),
          value: "closed",
          color: "primary",
          nextStep: null,
        },
      ],
    },
    [STEPS.REGISTER_DEPARTURE]: {
      isNode: false,
      title: i18n.t("protocole.mooringToConfirm.departure"),
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.DATETIME_SELECTOR,
          name: i18n.t("protocole.mooringToConfirm.departurFieldName"),
          required: true,
        },
      ],
      nextStep: null,
    },
    ...steps
  };
}

const baseSteps:any= () => {
  return {
    [STEPS.REGISTER_BOAT]: {
      isNode: false,
      name: "DOCKWALK PLACE IN",
      title: i18n.t("protocole.mooringToConfirm.registerBoat"),
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.ATTACHMENT,
          multiple: true,
          required: false,
        },
        {
          order: 2,
          component: COMPONENT_TYPE.NOTE,
          name: i18n.t("protocole.mooringToConfirm.note"),
          required: true,
        },
      ],
      nextStep: null,
    },
  }
}

const dockwalkSteps:any = () => {

  return {
    [STEPS.REGISTER_BOAT]: {
      isNode: false,
      title: i18n.t("protocole.mooringToConfirm.registerBoat"),
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.DOCKWALK_PLACEIN,
          required: true,
        }
      ],
      nextStep: null,
    },
  }
}