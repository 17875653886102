import { IonButton, IonIcon, IonTextarea, useIonLoading, useIonModal } from "@ionic/react";
import { TAAmendment, TABooking, TA_AMENTDMENT_STATUS, TA_BOOKING_STATUS, UPDATABLE_FIELD } from "../../core/models/technicalArea.models";
import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { addTimeToDate } from "../../_helpers_/datatime.helpers";
import { useResultToast } from "../../_helpers_/useResultToast";
import { getAmendments, getTABookingDetail, setBookingToDone, startTABooking, unblockBooking, updateBookingService, updateTABookingDetail } from "../../core/store/actions/TechnicalArea.actions";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../core/store/hooks";
import { AmendmentFormCreation } from "./amendment/AmendmentCreation.form";
import { ServicesList } from "./ServicesList";
import { changeDetailStatus, updateServiceDone } from "../../core/store/reducers/TechnicalArea.reducer";
import { checkmark } from "ionicons/icons";
import { UserContactInfo } from "../UI/user/ContactInfo";
import { TAOngoingAction } from "./TAOngoingAction";
import { TAUpdateModal } from "./form/TAUpdate.modal";
import { UpdateForm } from "./form/Update.form";
import { AmendmentSummary } from "./amendment/AmendmentSummary";
import FieldItem from "../UI/form/FieldItem";
import { AttchamentItem } from "../UI/images/GenericImagepicker";
import { TADoneAction } from "./TADoneAction";

type BookingTabProps = {
  booking: TABooking
}
export const BookingTab: FC<BookingTabProps> = ({ booking }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [isUpdating, setIsUpdating] = useState(false)
  const [comment, setComment] = useState(booking.agent_comment)
  const [present] = useResultToast();
  const [presentLoader, dismissLoader] = useIonLoading();

  const [amendment, setAmendment] = useState<TAAmendment | null>()

  const start = async () => {
    if (!booking) return
    setIsUpdating(true)
    try {
      await startTABooking(booking.id)
      await dispatch(getTABookingDetail(booking.id))
    } catch (error) {
      present({
        message: t("technicalArea.detail.savefail"),
      }, "error");
    }
    setIsUpdating(false)
  }

  useEffect(() => {
    if (booking.update_requests?.length > 0) {
      const ongoingUpdateRequest = booking.update_requests.find(ur => ur.status === TA_AMENTDMENT_STATUS.TO_VALIDATE_BY_BOATER)
      if (!ongoingUpdateRequest) return setAmendment(null)
      getAmendments(booking.id, ongoingUpdateRequest.id).then(res => {
        setAmendment(res.data)
      })
    } else {
      setAmendment(null)
    }
  }, [booking, booking.update_requests])

  return <>
    {
      amendment &&
      <AmendmentSummary booking={booking} amendment={amendment} />

    }
    <div className="grid grid-cols-3 gap-x-5 max-w-[300px]" >
      <span className="font-bold">{t('technicalArea.detail.start')}</span>
      <span>{dayjs(booking.start_date).format(t("dateFormat.shortDate"))}</span>
      <span>{addTimeToDate(booking.start_date, booking.end_time).format(t("dateFormat.hours"))}</span>
      <span className="font-bold">{t('technicalArea.detail.end')}</span>
      <span>{dayjs(booking.end_date).format(t("dateFormat.shortDate"))}</span>
      <span>{addTimeToDate(booking.end_date, booking.end_time).format(t("dateFormat.hours"))}</span>
    </div>
    <div>
      <div className="flex justify-between flex-wrap">
        <h3 className="font-bold py-2 text-base">{t('technicalArea.detail.services.title')}</h3>
        {
          !amendment && ![TA_BOOKING_STATUS.DONE, TA_BOOKING_STATUS.CANCELLED, TA_BOOKING_STATUS.REJECTED].includes(booking.status) &&
          <TAUpdateModal
            render={(dismiss) => (<>
              {/* EDIT BOOKING */}
              {
                booking.status === TA_BOOKING_STATUS.BOOKED && <UpdateForm onDone={() => {
                  if (!!dismiss) {
                    dismiss()
                  }

                }} booking={booking} />
              }
              {/* 
            Create Amendments
             */}
              {
                booking.status !== TA_BOOKING_STATUS.BOOKED &&
                [TA_BOOKING_STATUS.ONGOING, TA_BOOKING_STATUS.BOAT_IN_AREA, TA_BOOKING_STATUS.ON_HOLD].includes(booking.status) && <AmendmentFormCreation onDone={() => { dismiss() }} booking={booking} />
              }
              {/* 
            Update Amendments
             */}
            </>)}
          >


          </TAUpdateModal>
        }

      </div>
      <ServicesList
        startDate={booking.start_date}
        startTime={booking.start_time}
        endDate={booking.end_date}
        endTime={booking.end_time}
        showCheckbox={[TA_BOOKING_STATUS.ONGOING].includes(booking.status)}
        showStatus={[TA_BOOKING_STATUS.DONE, TA_BOOKING_STATUS.BOAT_IN_AREA].includes(booking.status)}
        hintText={t("technicalArea.detail.services.hint")}
        canEdit={booking.updatable_fields.includes(UPDATABLE_FIELD.BOOKING_SERVICES)}
        services={booking.booking_services}
        onChange={(service, checked) => {
          try {
            console.log({ service, checked });
            setIsUpdating(true)
            updateBookingService(booking.id, service.id, checked).then(
              res => {
                dispatch(updateServiceDone(res.data))
              }
            ).finally(() => {
              setIsUpdating(false)
            })
          } catch (error) {
            console.log(error);
            present({
              message: t("technicalArea.detail.savefail"),
            }, "error");
          }

        }}
      />

    </div>
    {
      booking?.closing_comment &&
      <div className="whitespace-break-spaces">
        <h3 className="font-bold py-2 text-base whitespace-break-spaces">{t('technicalArea.detail.closingComment')}</h3>
        {booking?.closing_comment}
      </div>
    }
    {
      booking.marina_comment &&
      <div className="whitespace-break-spaces">
        <h3 className="font-bold py-2 text-base ">{t('technicalArea.detail.marinaComment')}</h3>
        {booking.marina_comment || t("technicalArea.detail.nocomment")}
      </div>
    }

    <div className="whitespace-break-spaces">
      <h3 className="font-bold py-2 text-base whitespace-break-spaces">{t('technicalArea.detail.comment')}</h3>
      {
        booking.updatable_fields.includes(UPDATABLE_FIELD.AGENT_COMMENT) ?
          <div className="flex flex-col gap-1 items-end">
            <div className="bg-white shadow-lg px-2 rounded-lg w-full">
              <IonTextarea
                rows={4}
                value={comment}
                placeholder={t('technicalArea.detail.commentPlaceholder')}
                onIonChange={(e) => setComment(e.target.value || "")}
              ></IonTextarea>

            </div>
            <IonButton
              disabled={!comment}
              shape="round"
              color="secondary"
              onClick={() => {
                try {
                  setIsUpdating(true)
                  updateTABookingDetail(booking.id, {
                    agent_comment: comment
                  }).then(
                    res => {
                      dispatch(updateServiceDone(res.data))
                    }
                  ).finally(() => {
                    setIsUpdating(false)
                  })
                } catch (error) {
                  console.log(error);
                  present({
                    message: t("technicalArea.detail.savefail"),
                  }, "error");
                }
              }}
            >
              <IonIcon color="light" icon={checkmark} />
            </IonButton>

          </div> : booking.agent_comment || t("technicalArea.detail.nocomment")

      }

    </div>

    {booking.attachments.length > 0 &&
      <FieldItem
        onClick={() => { }}
        filled
        field={{
          title: t("ticket.formFields.attachments"),
        }}
      >
        <div
          className="flex w-full overflow-x-auto gap-2"
        >
          {booking.attachments.filter(f => f.kind === "picture").map((file, index: number) => (
            <AttchamentItem key={file.id} preview={file.url} />
          ))}
        </div>
      </FieldItem>
    }

    <div>
      <h3 className="font-bold py-2 text-base">{t('technicalArea.detail.boater')}</h3>
      {booking.boater.first_name} {booking.boater.last_name}
      <UserContactInfo
        email={booking.boater.email}
        phone={booking.boater.phone}
      />
    </div>
    {
      booking.status === TA_BOOKING_STATUS.BOOKED && <div>
        <IonButton onClick={start} size="large" color="secondary" shape="round" expand="full">{t("technicalArea.detail.actions.start")}</IonButton>
      </div>
    }

    {
      booking.status === TA_BOOKING_STATUS.ONGOING && <TAOngoingAction amendment={amendment} isUpdating={isUpdating} />
    }
    {
      booking.status === TA_BOOKING_STATUS.ON_HOLD && <IonButton
        expand="full"
        color="danger"
        size="large"
        onClick={() => {
          presentLoader()
          unblockBooking(booking.id).then((async res => {
            dispatch(changeDetailStatus(TA_BOOKING_STATUS.ONGOING))
            present({
              message: t("technicalArea.detail.actions.unblockSuccess"),
            }, "success")
            await dispatch(getTABookingDetail(booking.id))
          }), err => {
            present({
              message: err.data.message || t("technicalArea.detail.actions.unblockErreur")
            }, "error")
          }
          )
            .finally(() => {
              dismissLoader()
            })
        }}
        shape="round">{t("technicalArea.detail.actions.unblock")}</IonButton>
    }

    {
      booking.status === TA_BOOKING_STATUS.BOAT_IN_AREA && <TADoneAction amendment={amendment} isUpdating={isUpdating} />
    }

  </>
}