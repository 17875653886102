import { IonButton, IonModal, IonSpinner } from "@ionic/react"
import { Swiper, SwiperSlide } from 'swiper/react';
import { FC, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../core/store/hooks"
import { getBerthAvailableForCategory } from "../../core/store/reducers/Dockwalk"
import { ActionStatus } from "../UI/ActionStatus"
import { BerthItem } from "../UI/berths/BerthItem"
import { TabFilter } from "../UI/tabs/TabFilter"
import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import FDK from "@wattsonelements/front-fdk";
import { TContractDockWalk } from "@wattsonelements/front-fdk/dist/models/Boats.models";
import { IGenericResponse, TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models";
import { TagsBadge } from "../UI/TagsBadge";
import { IAgentBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import BackHeader from "../layouts/BackHeader";
import { useTranslation } from "react-i18next";

type PlaceInBerthProps = {
  contract: TContractDockWalk
  close?: () => any
  [key: string]: any
}

export const PlaceInBerth: FC<PlaceInBerthProps> = ({ contract, close = () => { }, ...props }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { berths, berthCategories } = useAppSelector(state => state.dockWalk)
  const [requestStatus, setRequestStatus] = useState<number>(FDK.MainAPI.REQUEST_STATUS.IDLE)
  let initialLoading = useRef(true)
  const [listLoading, setListLoading] = useState(true)
  const [preselectedBerth, setPreselectedBerth] = useState<Partial<IAgentBerth> | null | undefined>(null)
  const [slider, setSlider] = useState<any>(null)
  const [resquetMessages, setRequestMessages] = useState<IGenericResponse | null>(null)
  const [slide, setSlide] = useState<number | null>()

  const [nextPage, setNextPage] = useState<number>(1)
  const [category, setCategory] = useState<null | number>(null)
  const { current } = useAppSelector(state => state.ports)


  const loadData = () => {

    if (!category) return
    const params: TParams = {
      page: !nextPage ? 1 : nextPage,
    }
    setListLoading(true)
    dispatch(getBerthAvailableForCategory({ categoryId: category, params })).then((res: any) => {
      setNextPage(res.payload.next_page_number)
    }).finally(() => {
      setListLoading(false)
    })
  }

  useEffect(() => {
    if (!initialLoading.current || !contract || !berthCategories || berthCategories.length === 0) return
    // 🚧 TODO 🚧
    let cat = null
    // berthCategories.find(b => b.id === contract?.berth?.category)
    if (contract.boat.length && !cat) {
      const length = parseFloat(contract.boat.length)
      let max = 1000
      const tempCat = berthCategories.find(b => {
        if (b.minimum && b.maximum) {
          return b.minimum <= length && b.maximum >= length
        }
        if (b.maximum < max && length < b.maximum) {
          max = b.maximum
          cat = b
          return false
        }
      })
      if (tempCat) cat = tempCat
    }
    if (contract?.berth?.category) {
      setCategory(contract?.berth?.category)
    } else {
      setCategory(berthCategories[0].id)
    }
    initialLoading.current = false
  }, [berthCategories, contract, dispatch])

  useEffect(() => {
    loadData()
    // const index = berthCategories.findIndex(b => b.id === category)
    // console.log(index, category);
  }, [category])

  useEffect(() => {
    if (contract && !!contract.berth && "id" in contract.berth && berths && berths.length > 0) {
      const tempBerth = berths.find((b: Partial<IAgentBerth>) => b?.id === contract?.berth?.id)
      if (tempBerth) {
        setPreselectedBerth(tempBerth)
      }

    }
  }, [contract, berths])

  const placeInAction = (e: any, berth: Partial<IAgentBerth>) => {
    setRequestStatus(FDK.MainAPI.REQUEST_STATUS.PENDING)
    FDK.Agent.DockwalkModule.placeIn(contract.id, berth.id!).then((res) => {
      setRequestStatus(FDK.MainAPI.REQUEST_STATUS.SUCCESS)
      setRequestMessages(res.data)
    }, err => {
      setRequestMessages(err?.data || null)
      setRequestStatus(FDK.MainAPI.REQUEST_STATUS.ERROR)
    })
  }

  return <IonModal
    onWillDismiss={() => {
      setNextPage(1)
      setSlide(null)
      setPreselectedBerth(null)
      setRequestMessages(null)
      setRequestStatus(FDK.MainAPI.REQUEST_STATUS.IDLE)
      initialLoading.current = true
    }}
    {...props}
  // className="swipe-modal" 
  // initialBreakpoint={0.9}
  // breakpoints={[0, .75, .9]}
  >
    <BackHeader goBack={() => { close() }} title={t("dockwalk.placein.title")}>
      {/* contract brief summary */}
      {
        contract && current && <p className="text-white">
          {contract.boat.name} &nbsp;
          {contract.boat.length && contract.boat.width &&
            <>
              |&nbsp;
              <span className="text-center text-base font-bold text-white">
                {contract.boat.length}{t(`shared.units.${current?.config.distance_unit}`)}
                x
                {contract.boat.width}{t(`shared.units.${current?.config.distance_unit}`)}
              </span>
            </>
          }
        </p>
      }
    </BackHeader>

    <div className="p-3 text-center">
      {
        requestStatus === FDK.MainAPI.REQUEST_STATUS.IDLE && <>
          <div className="max-w-[200px] mx-auto pb-4">
            <Swiper
              slideToClickedSlide={true}
              centeredSlides={true}
              initialSlide={1}
              spaceBetween={5}
              slidesPerView={3}
              onSlideChange={(s) => console.log('slide change', s.activeIndex)}
              onSwiper={(swiper) => {
                setSlider(swiper)
              }}
            >
              {
                berthCategories.map(cat => <SwiperSlide key={cat.id}>
                  <TabFilter onClick={() => {
                    setCategory(cat.id)
                  }} selected={cat.id === category} count={cat.available_berth_count} label={cat.label} />
                </SwiperSlide>)
              }
            </Swiper>

          </div>
          {
            preselectedBerth &&
            <>
              <div >
                {/* className="bg-LIGHT rounded-md mb-6 py-2" */}
                <TagsBadge className="w-auto" uppercase={false} label={t('dockwalk.placein.preselectedBerth')} />
                <BerthItem className="bg-LIGHT rounded-md mb-4 cursor-pointer" lines="none" berth={preselectedBerth} onClick={(e: any) => placeInAction(e, preselectedBerth)} />
              </div>
              <TagsBadge className="w-auto" uppercase={false} label={t('dockwalk.placein.selectAnotherBerth')} />
            </>
          }
          {
            !preselectedBerth && contract && contract.berth &&
            <BerthItem hideBerthSize={true} className="bg-LIGHT rounded-md mb-4 cursor-pointer opacity-50" lines="none" berth={contract.berth as any} >
              <span className="">{t("dockwalk.berth.berthNotAvailable")}</span>
            </BerthItem>

          }
          <div className="max-h-[70vh] overflow-auto pb-80">
            {
              !listLoading && berths.map(berth => <BerthItem
                className="cursor-pointer"
                key={berth.id}
                berth={berth}
                onClick={(e: any) => placeInAction(e, berth)}
              />)
            }
            {
              listLoading && <IonSpinner />
            }
            {
              !listLoading && nextPage && nextPage > 1 &&
              <IonButton onClick={() => { loadData() }} color="secondary" shape="round" fill="outline">{t('shared.loadmore')}</IonButton>
            }
          </div>
        </>
      }
      {
        requestStatus === FDK.MainAPI.REQUEST_STATUS.ERROR && <>
          <ActionStatus
            className="mt-5"
            title={resquetMessages?.title || t("dockwalk.placein.titleError")}
            message={resquetMessages?.message || ""}
            sub_message={resquetMessages?.sub_message || ""}
            state={requestStatus} />
        </>
      }
      {
        requestStatus === FDK.MainAPI.REQUEST_STATUS.SUCCESS && <>
          <ActionStatus
            className="mt-5"
            title={resquetMessages?.title || t("dockwalk.placein.titleSuccess")}
            message={resquetMessages?.message || ""}
            sub_message={resquetMessages?.sub_message || ""}
            state={requestStatus} />
        </>
      }

    </div>
  </IonModal>
}