import { IonContent, IonHeader, IonPage, useIonViewWillEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { DockwalkBooking, NAMED_TABS } from "../components/dockwalk/tabs/DockwalkBooking.tab";
import DashboardHeader from "../components/layouts/DashboardHeader";
import { TabFilter } from "../components/UI/tabs/TabFilter";
import { useAppDispatch, useAppSelector } from "../core/store/hooks";
import {
  getArrivingContract,
  getBerthsCatStatsList,
  getLeavingContract,
  setSearch,
  setSearchActive,
} from "../core/store/reducers/Dockwalk";
import { useTranslation } from "react-i18next";
import { DockwalkTab } from "../components/dockwalk/tabs/Dockwalk.tab";
import { AvailableBerthsTabs } from "../components/dockwalk/tabs/AvailableBerths.tab";

export const DockwalkPage = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isSearchActive, search: searchText } = useAppSelector(state => state.dockWalk)
  const { t } = useTranslation()
  const [page, setPage] = useState("dockwalk");

  const isSelected = (path: string) => {
    return !!location.pathname.match(path);
  };
  useIonViewWillEnter(() => {
    if (location.pathname === "/dockwalk") {
      history.replace("/dockwalk/dockwalk");
    }
    dispatch(getBerthsCatStatsList());
    console.log("DOCKWALK USE EFFECT PAGE LEVEL");
  }, []);

  useEffect(() => {
    console.log("DOCKWALK USE EFFECT PAGE LEVEL", location.pathname);
    switch (location.pathname) {
      case "/dockwalk/dockwalk":
        setPage("dockwalk");
        dispatch(setSearch({ text: "", scope: "dockwalk" }))
        break;
      case "/dockwalk/berths":
        setPage("berths");
        dispatch(setSearchActive(true))
        dispatch(setSearch({ text: "", scope: "availableBerths" }))
        break;
      case "/dockwalk/bookings":
        setPage("bookings");
        dispatch(setSearchActive(true))
        dispatch(setSearch({ text: "", scope: "booking" }))
        break;
      default:
        break;
    }
  }, [location]);

  const search = (e: any) => {
    const text = e.detail.value;
    console.log("SEARCH DOCKWALK", location.pathname);
    switch (page) {
      case "dockwalk":
        console.log("search /dockwalk/dockwalk");
        dispatch(setSearch({ text, scope: "dockwalk" }))
        break;
      case "berths":
        dispatch(setSearch({ text, scope: "availableBerths" }))
        break;
      case "bookings":
        let params = new URLSearchParams(location.search);
        const tab = params.get("tab");
        if (tab === NAMED_TABS.ARRIVING) {
          dispatch(getArrivingContract({ params: { search: text } }));
          break;
        }
        if (tab === NAMED_TABS.LEAVING) {
          dispatch(getLeavingContract({ params: { search: text } }));
          break;
        }
        console.log("search /dockwalk/bookings", location);
        break;
      default:
        break;
    }
  };
  return (
    <IonPage>
      <IonHeader className="bg-LIGHT">
        <DashboardHeader
          disableSearch={!isSearchActive}
          searchText={searchText}
          searchPlaceholder={t(`dockwalk.search.${page}`)}
          search={search} />
        <div className="flex bg-white px-2 py-2 mt-1">
          <TabFilter
            className="flex-1 p-3"
            selected={isSelected("/dockwalk/dockwalk")}
            isNav={true}
            onClick={() => {
              history.push("/dockwalk/dockwalk");
            }}
            count={t("dockwalk.menu.directory")}
          />
          <TabFilter
            className="flex-1 p-3"
            selected={isSelected("/dockwalk/berths")}
            onClick={() => {
              history.push("/dockwalk/berths");
            }}
            count={t("dockwalk.menu.berth")}
          />
          <TabFilter
            className="flex-1 p-3"
            selected={isSelected("/dockwalk/bookings")}
            onClick={() => {
              history.push("/dockwalk/bookings");
            }}
            count={t("dockwalk.menu.dockwalk")}
          />
        </div>
      </IonHeader>
      <IonContent style={
        { "--background": "#f4f4f4" }
      } scrollY={false}>
        <div className="flex flex-col h-full ">
          {isSelected("/dockwalk/dockwalk") && <DockwalkTab />}
          {isSelected("/dockwalk/berths") && <AvailableBerthsTabs />}
          {isSelected("/dockwalk/bookings") && <DockwalkBooking />}
        </div>
      </IonContent>
    </IonPage>
  );
};
