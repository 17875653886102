import React from "react";
import { createUseStyles } from "react-jss";
import SelectItem from "./SelectItem";

export type SelectListProps = {
  data: any[]
  multiple?: boolean, // not implemented
  setdata: (item: any, add: boolean) => any,
  transformText: (item: any) => string,
  getIcon: (item: any) => string,
  isSelected: (item: any) => boolean,
}
export default function SelectList(props: any) {
  const {
    data,
    setdata,
    transformText,
    getIcon,
    isSelected,
  } = props;
  const styles = useStyles()
  return (
    <div className={styles.container}>
      {data &&
        data.map((item: any, index: number) => {
          return (
            <SelectItem
              image={getIcon && getIcon(item)}
              textKey={transformText(item)}
              key={index}
              data={item}
              isSelected={isSelected(item)}
              setdatas={setdata}
            />
          );
        })}
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    flex: 1,
    width: "100%",
  },
});
