/**
 * Returns promise with loaded image
 *
 * @param {string} url path of the image to load
 * @param {number[]} sizes image sizes w, h
 * @return {Promise} promise with loaded image or error
 */
export const loadImage = (url: string, sizes: number[]) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image(sizes[0], sizes[1]);
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", (err) => reject(err));
    img.src = url;
  });
export const loadAsBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.addEventListener("load", (e) => resolve(e.target!.result as string));
    reader.addEventListener("error", (err) => reject(err));
    reader.readAsDataURL(file);
  });
