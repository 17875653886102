import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../core/store/hooks";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

// UI
import DatePicker from "./DatePicker";
import Spacer from "../UI/Spacer";
import Btn from "../UI/buttons/Btn";
// CONTANTS
import { colors } from "../../styles/colors";
import { COMPONENT_TYPE } from "../../core/protocoles/constants";
// PROTOCOLE
import TicketAttachment from "../tickets/Attachment";
import { CheckList } from "./Checklist";
import { UserContact } from "./UserContact";
import { RadioList } from "./RadioList";
import ProtocoleNote from "./Note";
import { goToNextStep } from "../../core/protocoles/protocole.service";
// MODEL
import {
    ProtocoleComponent,
    ProtocoleStep,
} from "../../core/models/Protocole";

import { PlaceInBoatProtocol } from "./PlaceInBoat.protocol";
import { IBerth } from "@wattsonelements/front-fdk/dist/models/Ticket.models";


export function StepProtocole({ step }: { step: ProtocoleStep }) {
    const { t } = useTranslation()
    const ticket = useAppSelector((state) => state.tickets.selected);
    const styles = useStyles()
    const [errors, setErrors] = useState(false);
    const isAttachmentsDone = useAppSelector(
        (state) => state.attachments.uploading.length === 0
    );

    let [values, setValues] = useState<any>([]);

    const updateValue =
        (index: number, component: ProtocoleComponent) => (value: any) => {
            const tempValues = [...values];


            tempValues[index] = { value, type: component.component };
            setValues(tempValues);
            if (errors) {
                setErrors(hasErrors());
            }
            console.log("value", tempValues, value);
        };

    const updateValueRaw =
        (index: number, component: ProtocoleComponent, tempValues: any) =>
            (value: any) => {
                tempValues[index] = { value, type: component.component };
                return tempValues;
            };


    useEffect(() => {
        /**
         * create default value for the step just selected
         */
        let tempValues: any = [];
        if (!step.components) return;
        step.components.map((comp: ProtocoleComponent, index: number) => {
            switch (comp.component) {
                case COMPONENT_TYPE.NOTE:
                    tempValues = updateValueRaw(index, comp, tempValues)("");
                    break;
                case COMPONENT_TYPE.ATTACHMENT:
                    tempValues = updateValueRaw(index, comp, tempValues)([]);
                    break;
                case (COMPONENT_TYPE.CHECKLIST, COMPONENT_TYPE.RADIO):
                    tempValues = updateValueRaw(index, comp, tempValues)(comp.data);
                    break;
                default:
                    tempValues = updateValueRaw(index, comp, tempValues)("");
                    break;
            }
            console.log("tempValues", tempValues);

        });
        setValues(tempValues);
        return () => {
            setValues([]);
        };
    }, [step]);

    useEffect(() => {
        // setErrors(hasErrors());
    }, [values])

    const hasErrors = (): boolean => {
        let error = false;
        if (!step.components) return false;
        step.components.map((comp: any, index: number) => {
            if (
                comp.required &&
                (!values[index] || (values[index] && !values[index].value))
            ) {
                console.log("error", comp, values[index]);

                error = true;
            }
            console.log("error test", values, comp, values[index]);
        });

        return error;
    };
    const onGoToNextStep = () => {
        setErrors(false);
        let error = hasErrors();
        setErrors(error);
        if (!error) {
            goToNextStep(step, values);
        }
    };

    return (
        <div className={styles.container}>
            <p className={styles.title}>{step.title}</p>
            {step?.subtitle && <p className={styles.subtitle}>{step?.subtitle}</p>}
            {step?.text && <p className={styles.text}>{step?.text}</p>}
            {
                step.components && step.components.length > 0 && step.components[0].component !== COMPONENT_TYPE.DOCKWALK_PLACEIN &&
                <Spacer vSize={20} direction="vertical" />
            }

            {
                step.components && step.components.map(
                    (comp: any, index: number) => {
                        switch (comp.component) {
                            case COMPONENT_TYPE.NOTE:
                                return <ProtocoleNote key={'' + index + comp.component} title={comp.name} value={values[index]?.value || ''} update={(val: any) => updateValue(index, comp)(val)} />
                            case COMPONENT_TYPE.ATTACHMENT:
                                return <TicketAttachment key={'' + index + comp.component} raw update={(updateValue(index, comp) as any)} />
                            case COMPONENT_TYPE.CHECKLIST:
                                return <CheckList key={'' + index + comp.component} list={comp.data} update={updateValue(index, comp)} />
                            case COMPONENT_TYPE.DATETIME_SELECTOR:
                                return <DatePicker update={(val: any) => updateValue(index, comp)(val)} mode="date-time" key={'' + index + comp.component} />
                            case COMPONENT_TYPE.DATE_SELECTOR:
                                return <DatePicker update={updateValue(index, comp)} mode="date" key={'' + index + comp.component} />
                            case COMPONENT_TYPE.HOURS_SELECTOR:
                                return <DatePicker update={updateValue(index, comp)} mode="time" key={'' + index + comp.component} />
                            case COMPONENT_TYPE.RADIO:
                                return <RadioList update={updateValue(index, comp)} list={comp.data} key={'' + index + comp.component} />
                            case COMPONENT_TYPE.USER_CONTACT:
                                if (ticket && ticket.boater) {
                                    return <UserContact user={ticket.boater} key={'' + index + comp.component} />
                                }
                                break;
                            case COMPONENT_TYPE.DOCKWALK_PLACEIN:
                                if (ticket && ticket.spot) {
                                    return <PlaceInBoatProtocol update={updateValue(index, comp)} berth={ticket.spot as IBerth} key={'' + index + comp.component} />
                                }
                                break;
                            default:
                                break;
                        }

                    }
                )
            }
            {errors && (
                <p className={styles.errors}>{t("protocole.requiredError")}</p>
            )}
            <div>
                <Btn
                    disabled={errors || !isAttachmentsDone}
                    onClick={() => onGoToNextStep()}
                    label={
                        step.nextStep ? t("protocole.next") : t("protocole.send")
                    }
                    expand="full"
                    size="large"
                    color="secondary"
                />
            </div>
        </div>
    );
}

const useStyles = createUseStyles({
    title: {
        fontSize: 24,
        textAlign: "center",
        fontWeight: "bold",
        paddingHorizontal: 20,
        marginVertical: 10,
    },
    subtitle: {
        fontSize: 17,
        textAlign: "center",
        fontWeight: "bold",
        // marginVertical: 5,
        paddingHorizontal: 20,
    },
    text: {
        fontSize: 13,
        textAlign: "center",
        marginVertical: 5,
        paddingHorizontal: 20,
    },
    container: {
        padding: "20px",

    },
    errors: {
        color: colors.WARN,
        textAlign: "center",
        marginTop: 20,
        fontWeight: "bold",
    },
})
