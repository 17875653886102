import { FC, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks"
import { useGetBerthsQuery } from "../../../core/store/api/dockwalk.api"
import { skipToken } from "@reduxjs/toolkit/query"
import { AdaptaterBerthWithBoatItem, BerthWithBoatItemAndSlidingActions } from "../../UI/berths/BerthWithBoat.item"
import { AvailableBerthItem } from "../../UI/berths/AvailableBerth.item"
import { IonButton, IonContent, IonModal, IonRefresher, IonRefresherContent, IonSpinner } from "@ionic/react"
import { BerthDetailModal } from "../BerthDetail.modal"
import { IDockwalkBerth } from "@wattsonelements/front-fdk/dist/models/Dockwalk.models"
import { useTranslation } from "react-i18next"
import { resetSelected } from "../../../core/store/reducers/Spots"
import { PlaceBoatInBerth } from "../actions/PlaceBoatInBerth"

type AllBerthsListProps = {

}
export const AllBerthsList: FC<AllBerthsListProps> = () => {
  const { t } = useTranslation()
  const port = useAppSelector(state => state.ports.current)
  const { search, searchScope } = useAppSelector(state => state.dockWalk)
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const { isLoading, data, isFetching, refetch } = useGetBerthsQuery(
    port?.id ? { portId: port?.id, page, search: search && searchScope === "dockwalk" ? search : "" } : skipToken,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  )
  const [list, setList] = useState((data && data.results) || [])
  const [openModal, setOpenModal] = useState(false)
  const [openReplaceModal, setOpenReplaceModal] = useState(false)
  const [selected, setSelected] = useState<IDockwalkBerth | null>(null)

  useEffect(() => {
    if (!data) return
    if (page > 1) {
      setList([...list, ...data.results])
    } else {
      setList([...data.results])
    }

  }, [data])

  if (isLoading) return <IonSpinner className="mx-auto my-2 block  " />
  return <div className="ion-content-scroll-host p-2 grid gap-2 overflow-auto pb-10">
    {
      list.map(item => item.is_available ? <AvailableBerthItem
        key={"available" + item.id}
        name={item?.name || ""}
        type={item.contract_type?.label}
        arrivalDetectedAt={item.sensor_occupied_since}
        availableUntil={item.available_until}
        catway={item.catway}
        category={item.category?.label}
        hasReservation={!!item.reserved}
        onClick={() => {
          setSelected(item);
          setOpenModal(true);
        }}
      /> : <BerthWithBoatItemAndSlidingActions
        key={"notavailable" + item.id}
        onReplaceAction={(itemSliding) => {
          setSelected(item);
          itemSliding?.close()
          setOpenReplaceModal(true)
        }}
      >
        <AdaptaterBerthWithBoatItem
          berth={item}
          onClick={() => {
            setSelected(item);
            setOpenModal(true);
          }} /> </BerthWithBoatItemAndSlidingActions>)
    }
    {
      data?.next_page_number &&
      <IonButton onClick={() => {
        setPage(data?.next_page_number)
        // refetch({ portId: port?.id, page:data?.next_page_number })
      }} color="secondary" shape="round" fill="outline">
        {
          isFetching ? <IonSpinner color="secondary" /> : t('shared.loadmore')
        }
      </IonButton>
    }
    <IonModal
      isOpen={openModal}
      onDidDismiss={() => {
        setOpenModal(false)
        dispatch(resetSelected())
        setSelected(null)
        // setSelectedContract(null)
      }}
      className="swipe-modal"
      initialBreakpoint={0.75}
      breakpoints={[0, .5, .75, .9]}
    >
      <IonContent>
        {
          selected && <BerthDetailModal boat={selected.boat} boatName={selected.boat?.name} berthId={selected.id} />
        }
      </IonContent>
    </IonModal>
    <IonModal
      isOpen={openReplaceModal}
      onDidDismiss={() => {
        setOpenReplaceModal(false)
      }}>
      <IonContent>
        {
          selected && !selected.is_available && <PlaceBoatInBerth
            close={() => setOpenReplaceModal(false)}
            boat={selected.boat!}
            berthId={selected.id}
            berthCategoryId={selected.category?.id as any}
          />
        }
      </IonContent>
    </IonModal>

  </div>
}