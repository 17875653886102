import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IProtocol } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import FDK from "@wattsonelements/front-fdk";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { IGenericResponse } from "@wattsonelements/front-fdk/dist/models/Misc.models";
import { PROTOCOLE_TYPE } from "@wattsonelements/front-fdk/dist/constants/protocol.constants";

export enum PROTOCOLE_STATUS {
  COMPLETING,
  COMPLETED,
  SENDING,
  SUCCESS,
  ERROR,
  END,
}

export interface ProtocoleState {
  config: any;
  status: PROTOCOLE_STATUS;
  requestMessages: IGenericResponse | null,
  currentStep: number;
  history: number[];
  values: any[];
  type: PROTOCOLE_TYPE | null
}

// TODO bound to desapear
export enum TYPES_STEP {
  NODE = "node",
  LIST = "list",
  UNIQUE_ANSWER = "uniqueanswer",
  PICTURE_AND_MESSAGE = "picturenotes",
  PICTURE = "picture",
  MESSAGE = "message",
}

export enum ACTION_STEP {
  CALL_FIREMEN,
}

const initialState = {
  config: undefined,
  status: PROTOCOLE_STATUS.COMPLETING,
  requestMessages: null,
  currentStep: 1,
  history: [],
  values: [],
  type: null
} as ProtocoleState;

export const sendProtocole = createAsyncThunk(
  "protocole/send",
  async (
    { id, field }: { id: UUID; field: IProtocol },
    { dispatch }
  ) => {
    console.log("before update", field);
    return FDK.Agent.TicketsModule.sendProtocol(id, field).then((res) => {
      return res.data;
    });
  }
);

export const slice = createSlice({
  name: "protocole",
  initialState,
  reducers: {
    initProtocole: (state, { payload }) => {
      console.log("init protocole", payload);

      // state = initialState
      state.config = payload.config;
      state.currentStep = payload.step;
      state.history = [payload.step];
      state.status = PROTOCOLE_STATUS.COMPLETING;
      state.requestMessages = null
      state.type = payload.type
    },
    setNextStep: (state, { payload }) => {
      state.currentStep = payload;
      state.history.push(payload);
    },
    setStepValue: (state, { payload }) => {
      state.values.push(payload);
    },
    setProtocoleStatus: (state, { payload }) => {
      state.status = payload;
    },
    resetProtocole: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendProtocole.pending, (state, { payload }) => {
        state.status = PROTOCOLE_STATUS.SENDING;
      })
      .addCase(sendProtocole.fulfilled, (state, { payload }) => {
        state.status = PROTOCOLE_STATUS.SUCCESS;
        state.requestMessages = payload 
      })
      .addCase(sendProtocole.rejected, (state, { payload }: {payload:any}) => {
        state.status = PROTOCOLE_STATUS.ERROR;
        if(payload){
          state.requestMessages = payload.data as IGenericResponse
        } else {
          state.requestMessages = null
        }
       
      });
  },
});

export default slice.reducer;

export const {
  initProtocole,
  setNextStep,
  setStepValue,
  setProtocoleStatus,
  resetProtocole,
} = slice.actions;
