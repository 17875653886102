import { FC, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks"
import { useGetAllBerthsAvailableQuery, useGetBerthsCategoryStatsQuery, useGetBerthsForCategoriesQuery } from "../../../core/store/api/dockwalk.api"
import { skipToken } from "@reduxjs/toolkit/query"
import { IonRefresher, IonRefresherContent, RefresherEventDetail, useIonViewWillEnter } from "@ionic/react"
import { TabFilterScrollList } from "../../UI/tabs/TabFilterList"
import { TBerthCategory } from "@wattsonelements/front-fdk/dist/models/Berth.models"
import { PairMooringList } from "../list/PairMooring.list"
import { AvailableBerthForCategoryList } from "../list/AvailableBerthsForCategory.list"
import { AllAvailableBerthsList } from "../list/AllAvailableBerths.list"
import { setSearch, setSearchActive } from "../../../core/store/reducers/Dockwalk"

export const AvailableBerthsTabs: FC = () => {
  const dispatch = useAppDispatch()
  const port = useAppSelector(state => state.ports.current)
  const { search, searchScope } = useAppSelector(state => state.dockWalk)

  const { isLoading, data: categories = [], refetch: refetchCategories } = useGetBerthsCategoryStatsQuery(port?.id ?? skipToken)
  const [category, setCategory] = useState<"all" | TBerthCategory>("all")
  const {
    isLoading: isLoadingAll,
    data: allBerthsList,
    refetch: refetchBerths }
    = useGetAllBerthsAvailableQuery((category === "all" && port?.id) ? { portId: port.id, search: search && searchScope === "availableBerths" ? search : "" } : skipToken,)
  const {
    isLoading: isLoadingbyCat,
    refetch: refetchBerthsForCategory,
  } = useGetBerthsForCategoriesQuery((category !== "all" && port?.id) ? { categoryId: category.id, portId: port?.id, search: search && searchScope === "availableBerths" ? search : "" } : skipToken)


  const loadData = async (event?: CustomEvent<RefresherEventDetail>) => {
    if (isLoading || isLoadingbyCat || isLoadingAll) return
    refetchCategories()
    if (category === 'all') {
      await refetchBerths()
    } else {
      await refetchBerthsForCategory()
    }
    event && event.detail.complete();
  }
  return <div className="flex px-2 h-full flex-col gap-3">
    <IonRefresher slot="fixed" onIonRefresh={loadData}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
    <TabFilterScrollList
      onSelected={(e, tab: number | 'all') => {
        if (tab === "all") {
          // refetchBerths()
          setCategory("all")
        } else {
          const cat = categories.find(c => c.id === tab)
          setCategory(cat || 'all')
          // refetchCatBerths()
        }
      }}
      count={allBerthsList?.count}
      countKey="available_berth_count"
      labelKey="label"
      selectKey="id"
      tabs={categories}
    />
    <div className="overflow-auto ion-content-scroll-host">

      {
        category !== "all" && category?.is_pair_mooring && <PairMooringList category={category} />
      }
      {
        category !== "all" && !category?.is_pair_mooring && <AvailableBerthForCategoryList category={category} />
      }
      {
        category === "all" && <AllAvailableBerthsList />
      }
    </div>
  </div>
}