import React, { useState } from "react";
import Toggle from "../UI/form/Toggle";

interface CheckListItem {
  label: string;
  name?: string;
  value?: boolean;
}
export function CheckList({
  list,
  update,
}: {
  list: CheckListItem[];
  update: Function;
}) {
  const [values, setValue] = useState(list);
  const setList = (item: CheckListItem, index: number) => (toggleValue: any) => {
    item = { ...item, value: !item.value };
    const newValue = [...values];
    newValue[index] = item;
    setValue(newValue);
    update(newValue);
  };
  return (
    <div className="drop-shadow-md rounded-sm overflow-hidden">
      {values.map((item, index) => {
        return (
          <div className="flex-col flex bg-LIGHT_GREY justify-center items-center py-3 px-0 border-CALCITE border-2 border-t-0 border-l-0 border-r-0" key={index}>
            <p className="text-xl mb-1 font-bold px-3">{item.label}</p>
            <Toggle
              name={item?.name}
              on={true}
              off={false}
              value={item.value}
              setValue={(val: any) => setList(item, index)(val)}
            />
          </div>
        );
      })}
    </div>
  );
}
