import { IonIcon } from "@ionic/react";
import React from "react";

export default function AddBtn({ icon, ionIcon, ...props }: any) {
  return (
    <button {...props} className="my-2 normal-case bg-SECONDARY rounded-3xl px-4 py-1 text-white text-sm" >
      <div className="flex justify-center items-center gap-2">
        <img
          alt="add"
          className="w-5 h-5"
          src={require("../../../assets/icons/ui/plus.png")}
        />
        {
          icon && <img alt="add" className="w-5 h-5" src={icon} />
        }
        {
          ionIcon && <IonIcon className="text-lg" color="light" icon={ionIcon}></IonIcon>
        }
        {props.children}

      </div>
    </button>
  );
}
