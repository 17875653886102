import React, { useEffect, useState } from "react";
import { IonContent, IonHeader, IonIcon, IonPage, useIonViewWillEnter } from "@ionic/react";
// import BackHeaderTicket from "../components/layouts/BackHeaderTicket";
import BackHeader from "../components/layouts/BackHeader";
import { useAppSelector, useAppDispatch } from "../core/store/hooks";
import { getCategoryById } from "../core/store/selectors/categories";
import {
  getTicketById,
  saveTicket,
  setTicketStatus,
  ticketApi,
} from "../core/store/reducers/Tickets";
import { BtnSelect } from "../components/UI/buttons/BtnSelect";
import { resetAttachment } from "../core/store/reducers/Attachments";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import BackHeaderTicket from "../components/layouts/BackHeaderTicket";
import TicketForm from "../components/tickets/Form";
import TicketActivity from "../components/tickets/Activity";
import { TabsTicket } from "../components/UI/tabs/Tabs";
import { arrowBackOutline } from "ionicons/icons";
import { TicketErrorApi } from "../components/tickets/TicketErrorApi";
import FDK from "@wattsonelements/front-fdk";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { TicketStatus } from "@wattsonelements/front-fdk/dist/constants/ticket.constants";
import { usePeriodic } from "../core/services/Periodic.service";
import { skipToken } from "@reduxjs/toolkit/query";

enum TICKET_TABS {
  FORM,
  ACTIVITY,
}

// https://reactnavigation.org/docs/typescript/
export default function TicketPage({
  route,
}: {
  route?: any;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const history = useHistory();
  const { mode, id } = useParams<{ mode: string, id: string }>()
  const { stopPeriodic } = usePeriodic()

  const selectedTicket = useAppSelector(
    (state) => state.tickets.selected
  ) as ITicket;

  if (!id && mode === "edit") {
    history.push("/");
  }
  // const loading = useAppSelector((state) => state.tickets.loading);
  ticketApi.useLazyGetConfigQuery()
  let category = useAppSelector((state) =>
    selectedTicket ? getCategoryById(state, selectedTicket.category) : undefined
  );
  const port = useAppSelector(state => state.ports.current)
  const requestStatus = useAppSelector((state) => state.tickets.status);
  const requestError = useAppSelector((state) => state.tickets.error);

  const [status, setStatus] = useState(selectedTicket?.status);

  const [selectedTab, setSelectedTab] = useState(TICKET_TABS.FORM);
  ticketApi.useGetConfigQuery(port?.id ?? skipToken)

  useIonViewWillEnter(() => {
    stopPeriodic("get-ticket-list")
    dispatch(setTicketStatus(FDK.MainAPI.REQUEST_STATUS.UNAVAILABLE))
  }, [])

  useEffect(() => {
    if (!id) return

    dispatch(getTicketById(id))
    return () => {
      dispatch(setTicketStatus(FDK.MainAPI.REQUEST_STATUS.UNAVAILABLE))
    }
  }, [id, dispatch])

  useEffect(() => {
    setStatus(selectedTicket?.status);
  }, [selectedTicket]);


  useEffect(() => {
    if (requestStatus === FDK.MainAPI.REQUEST_STATUS.SUCCESS) {
      setTimeout(() => {
        dispatch(setTicketStatus(FDK.MainAPI.REQUEST_STATUS.UNAVAILABLE));
      }, 2000);
      // history.push(``);
    }
  }, [requestStatus, dispatch]);

  const updateStatus = (value: TicketStatus) => {
    setStatus(value);
    dispatch(saveTicket({ id: selectedTicket.id, field: { status: value } }));
    if (value === "closed") {
      history.goBack();
    }
  };

  // cleaning mesure
  useEffect(() => {
    dispatch(resetAttachment());
    return () => {
      dispatch(resetAttachment());
    };
  }, [dispatch]);

  return (
    <IonPage>
      <IonContent>
        {
          requestStatus === FDK.MainAPI.REQUEST_STATUS.ERROR &&

          <div className="bg-LIGHT flex flex-col h-full">
            <IonHeader mode="md" className="z-0">
              <div className="w-full px-4 flex justify-start items-center gap-2 pt-10 pb-14 bg-PRIMARY">
                <div className="flex flex-1 text-3xl">
                  <IonIcon onClick={() => history.goBack()} color="secondary" style={{ fontSize: "1.4em" }} icon={arrowBackOutline} />
                </div>
              </div>
            </IonHeader>
            <div className="flex h-full justify-center items-center">
              {
                requestError === 404 &&
                <TicketErrorApi error="404" label={t("ticket.errors.404")} />
              }
              {
                requestError > 500 && (!selectedTicket || !(selectedTicket.id === id)) &&
                <TicketErrorApi error="500" label={t("ticket.errors.default")} />
              }
            </div>


          </div>
        }
        {requestStatus !== FDK.MainAPI.REQUEST_STATUS.ERROR &&
          <div className="bg-LIGHT flex flex-col h-full">
            {/* EDIT / VIEW */}
            <div className="flex flex-col flex-[0_1_auto]">
              {mode === "edit" && selectedTicket && (
                <>
                  <BackHeaderTicket
                    selectedTicket={selectedTicket}
                    icon={category?.icon_url}
                    title={category?.label}
                    reference={selectedTicket?.reference}
                    creator={selectedTicket?.creator?.name}
                  />
                </>
              )}
              {/* CREATE TICKET */}
              {mode === "create" && (
                <BackHeader
                  title={t("ticket.createHeader")}
                />
              )}
            </div>
            <div className="bg-LIGHT rounded-2xl -mt-3 flex flex-col flex-[0_1_auto] items-center relative p-6 pb-2">
              {mode === "edit" && selectedTicket && status && (
                <>
                  <BtnSelect
                    style={{
                      marginTop: "-65px",
                      zIndex: 9,
                    }}
                    setValue={updateStatus}
                    value={status}
                    list={[
                      {
                        value: TicketStatus.ONGOING,
                        text: t("ticket.status.ongoing"),
                        selectable: true,
                      },
                      {
                        value: TicketStatus.CLOSED,
                        text: t("ticket.status.closed"),
                        selectable: true,
                      },
                      {
                        value: TicketStatus.OPEN,
                        text: t("ticket.status.open"),
                        selectable: false,
                      },
                      {
                        value: TicketStatus.BLOCKED,
                        text: t("ticket.status.blocked"),
                        selectable: true,
                      },
                      {
                        value: TicketStatus.CANCELLED,
                        text: t("ticket.status.cancelled"),
                        selectable: false,
                      },
                    ]}
                  />
                  <TabsTicket selected={selectedTab} setSelected={setSelectedTab} />
                </>
              )}
            </div>
            {(selectedTab === TICKET_TABS.FORM || mode === " create") && (
              <TicketForm
                category={category}
                selectedTicket={selectedTicket || undefined}
                mode={mode}
              />
            )}
            {selectedTab === TICKET_TABS.ACTIVITY && selectedTicket && (
              <TicketActivity />
            )}
          </div>

        }

      </IonContent>
    </IonPage>
  );
}
