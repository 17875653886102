import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { IBoat } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import FDK from "@wattsonelements/front-fdk";
import { ApiError } from "../store";

export interface BoatsState {
  all: IBoat<string, string>[];
  searchResult: IBoat<string, string>[];
  searchError: string;
  searchLoading: boolean;
}

const initialState = {
  all: [],
  searchResult: [],
  searchError: "",
  searchLoading: false,
} as BoatsState;

export const getBoats = createAsyncThunk(
  "boats/get",
  async (_args, { getState }) => {
    return FDK.Agent.BoatModule.getBoatsList().then((res) => {
      console.log(res.data);
      return res.data;
    });
  }
);

export const getBoat = createAsyncThunk(
  "boat/get",
  async (id: string, { getState }) => {
    return FDK.Agent.BoatModule.getBoat(id).then((res) => {
      return res.data;
    });
  }
);

export const searchBoats = createAsyncThunk(
  "boats/search",
  async (name: string, { dispatch, rejectWithValue }) => {
    return FDK.Agent.BoatModule.searchByBoatName(name).then(
      (res) => {
        return res.data;
      },
      (error: ApiError) => {
        console.log("error rejectWithValue", error);
        return rejectWithValue(error);
      }
    );
  }
);

export const slice = createSlice({
  name: "boats",
  initialState,
  reducers: {
    resetError(state) {
      state.searchError = "";
    },
    resetSearch(state) {
      console.log("reset boat state");
      state.searchError = initialState.searchError;
      state.searchLoading = initialState.searchLoading;
      state.searchResult = initialState.searchResult;
    },
    resetBoats() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoats.fulfilled, (state, { payload }) => {
        state.all = payload;
      })
      .addCase(getBoats.rejected, (state, { payload }) => {})
      .addCase(searchBoats.pending, (state, { payload }) => {
        state.searchLoading = true;
        state.searchError = "";
      })
      .addCase(searchBoats.fulfilled, (state, { payload }) => {
        if (payload.length === 0) {
          state.searchError = "ticket.boatListError.404";
        }
        state.searchResult = payload;
        state.searchLoading = false;
      })
      .addCase(searchBoats.rejected, (state, { payload }) => {
        console.log("error rejected", payload);
        if (!payload) {
          state.searchError = "shared.unknownError";
        } else {
          switch ((payload as ApiError).status) {
            case 404:
              state.searchError = "ticket.boatListError.404";
              break;
            case 500:
              state.searchError = "ticket.boatListError.500";
              break;
            default:
              state.searchError = "shared.unknownError";
              break;
          }
        }
        state.searchResult = [];
        state.searchLoading = false;
      });
  },
});

export default slice.reducer;
export const { resetError, resetSearch, resetBoats } = slice.actions;
