import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { FC, useEffect, useRef, useState } from "react";
import { Geolocation } from '@capacitor/geolocation';
import { useAppSelector } from "../../core/store/hooks";
import FieldItem from "../UI/form/FieldItem";
import { markerIcon } from "../UI/constants";
import AddBtn from "../UI/buttons/AddBtn";
import ModalEdit from "../UI/ModalEdit";
import { loadImage } from "../../_helpers_/image.helpers";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { close, scanOutline } from "ionicons/icons";
import { markerGeojson } from "../../_helpers_/map.helper";
import { useTranslation } from "react-i18next";
import icon from "../../assets/icons/ui/localization.png"

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_TOKEN!;

export interface MarkerDatas {
  type: string;
  coordinates: number[];
}

interface MapProps {
  value: MarkerDatas;
  mapId?: string;
  update: Function;
}

export default function MapBoxMarker({ value, mapId = "marina_map", update }: MapProps) {
  const { t } = useTranslation()
  const port = useAppSelector((state) => state.ports.current);
  const mapContainer = useRef<any>(null);
  // const mapModalContainer = useRef<any>(null);
  const [mapModalContainer, setMapModalContainer] = useState<HTMLElement | null>(null);
  const mapModalRef = useRef<any>(null);
  const mapRef = useRef<any>(null);
  const MIN_ZOOM = 17,
    MAX_ZOOM = 20;
  const [zoom, setZoom] = useState(17);
  // const [mapMarker, setMapMarker] = useState<mapboxgl.Marker | null | string>(null)
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [mapModalLoaded, setMapModalLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [markerImg, setMarkerImg] = useState<any>(null);
  const currentTicket = useAppSelector((state) => state.tickets.selected);
  const [coordinates, setCoordinates] = useState<MarkerDatas>()
  const [open, setOpen] = useState(false);

  const getPhonePosition = async () => {
    const coordinates = await Geolocation.getCurrentPosition();
    return [coordinates.coords.longitude, coordinates.coords.latitude]
  };

  const tryGetPhonePosition = async () => {
    let position;
    setLoading(true)
    try {
      position = await getPhonePosition();
      mapModalRef.current.setCenter([...position])
    } catch (e: any) {
      try {
        await Geolocation.requestPermissions();
        position = await getPhonePosition();
        mapModalRef.current.setCenter([...position])
      } catch (e: any) {
        console.log("error mapbox")
        console.log(e)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (value) {
      setCoordinates(value);
    }
  }, [value]);

  const onSave = (isSave: boolean) => {
    if (mapModalRef.current !== null) {
      mapModalRef.current.remove();
      mapModalRef.current = null;
    }
    if (mapRef.current !== null) {
      mapRef.current.remove();
      mapRef.current = null;
    }
    setMapLoaded(false)
    setMapModalLoaded(false)
    if (!isSave) {
      setCoordinates(value);
    } else {
      update({ localization: { ...coordinates } })
    }
  };

  const getMarkerImg = async () => {
    let tmp = await loadImage(markerIcon.icon, [markerIcon.width, markerIcon.height]);
    setMarkerImg(tmp);
  };

  useEffect(() => {
    if (markerImg === null) {
      getMarkerImg();
    }
  }, [markerImg]);

  useEffect(() => {
    // LITTLE MAP STUFF
    if (mapRef.current || open) return; // initialize mapRef only once
    if (mapContainer.current && value && port && port.center_latitude && port.center_latitude) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/falco-admin/ck553uje100561cplwwm7pe0k",
        center: [port!.center_longitude, port!.center_latitude],
        zoom: zoom,
        attributionControl: false,
        antialias: true,
      });
      // to prevent size of the mapRef when dom loading
      mapRef.current.on('idle', function () {
        mapRef.current.resize()
      })
      mapRef.current.on("load", () => {
        setMapLoaded(true);
        mapRef.current.on("zoom", () => {
          const currentZoom = mapRef.current.getZoom();
          if (zoom !== currentZoom) {
            setZoom(Math.round(currentZoom));
          }
        });
      });
    }
  }, [mapContainer.current, port, value, open]);
  useEffect(() => {
    // MODAL MAP STUFF
    if (mapModalRef.current || !open) return; // initialize mapModalRef only once
    if (mapModalContainer !== null && port && port.center_latitude && port.center_latitude) {
      mapModalRef.current = new mapboxgl.Map({
        container: mapModalContainer,
        style: "mapbox://styles/falco-admin/ck553uje100561cplwwm7pe0k",
        center: [port!.center_longitude, port!.center_latitude],
        zoom: zoom,
        attributionControl: false,
        antialias: true,
      });
      // to prevent size of the mapModalRef when dom loading
      mapModalRef.current.on('idle', function () {
        mapModalRef.current.resize()
      })
      mapModalRef.current.on('move', function () {
        setCoordinates({ type: "Point", coordinates: [parseFloat(mapModalRef.current.getCenter().lng.toFixed(6)), parseFloat(mapModalRef.current.getCenter().lat.toFixed(6))] })
      })
      mapModalRef.current.on("load", () => {
        setCoordinates({ type: "Point", coordinates: [parseFloat(mapModalRef.current.getCenter().lng.toFixed(6)), parseFloat(mapModalRef.current.getCenter().lat.toFixed(6))] })
        setMapModalLoaded(true);
        tryGetPhonePosition()
        mapModalRef.current.on("zoom", () => {
          const currentZoom = mapModalRef.current.getZoom();
          if (zoom !== currentZoom) {
            setZoom(Math.round(currentZoom));
          }
        });
      });
    }
  }, [port, open, mapModalContainer]);

  useEffect(() => {
    if (!mapLoaded || !mapRef.current || value === null || markerImg === null) return;
    if (!mapRef.current.hasImage("marker-icon"))
      mapRef.current.addImage("marker-icon", markerImg);
    if (!mapRef.current.getSource("points"))
      mapRef.current.addSource('points', markerGeojson(false, "marker-icon", value.coordinates, 20));
    else
      mapRef.current.getSource("points").setData(markerGeojson(true, "marker-icon", value.coordinates, 20))
    // Add a symbol layer
    if (!mapRef.current.getLayer("points"))
      mapRef.current.addLayer({
        'id': 'points',
        'type': 'symbol',
        'source': 'points',
        'layout': {
          "icon-image": { type: "identity", property: "icon" }
        }
      });
    mapRef.current.flyTo({
      center: value.coordinates
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, mapLoaded, markerImg]);

  return (
    <FieldItem
      onClick={() => { }}
      filled={false}
      field={{ icon, title: t("ticket.formFields.location") }}
    >
      <ModalEdit
        onRequestClose={() => { }}
        title={t("ticket.formFields.location")}
        open={open}
        save={onSave}
        setOpen={setOpen}
      >
        <div className="h-5/6 relative w-full flex justify-center items-center">
          <div className="map-modal-container overflow-hiddem absolute top-0 left-0 w-full h-full">
            <div id="map-modal-container" ref={setMapModalContainer} className="map-modal-container overflow-hiddem w-full h-full" />
          </div>
          <div className="tracking-wider w-1/2 absolute text-xs text-white font-bold top-1 right-1 bg-SECONDARY bg-opacity-90 rounded-md p-1 flex text-center items-center justify-center">
            {`${coordinates?.coordinates[0]}, ${coordinates?.coordinates[1]}`}
          </div>
          {(loading || !mapModalLoaded) &&
            <div className="z-10 absolute top-0 left-0 flex items-center justify-center w-full h-full bg-CALCITE/[.8]">
              <IonSpinner name="crescent" />
            </div>
          }
          <div className="absolute bottom-1 flex items-center justify-center w-3/4"><IonButton color="secondary" onClick={tryGetPhonePosition}>{t("ticket.map.myPosition")}</IonButton></div>
          <IonIcon size="large" color="danger" icon={scanOutline} />
        </div>
      </ModalEdit>
      {value && !open ?
        <div className="relative h-44 w-full">
          <div id="small-map-container" ref={mapContainer} className="map-container overflow-hiddem absolute top-0 left-0 w-full h-full" />
          {/* <div className="absolute top-0 left-0 w-full h-full"></div> */}
          {currentTicket === null &&
            <button className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 rounded-full bg-CALCITE drop-shadow-lg"
              onClick={() => update({ localization: null })}>
              <IonIcon size="small" color="secondary" icon={close} />
            </button>
          }
        </div>
        :
        <AddBtn
          onClick={() => setOpen(!open)}
          icon={icon}
        />
      }
    </FieldItem>
  );
};
