import React, { useState } from "react";
import classNames from "classnames";

import defaultAvatar from "../../../assets/icons/user.png";


export default function Avatar({
  src,
  size = 50,
  selected = false,
  onError = (err: any, src: string) => {
    console.log("err", err, src);
  },
  ...props
}: {
  src: any;
  selected?: boolean;
  onError?: any;
  size?: number;
  [key: string]: any;
}) {
  // TODO not the correct way
  const avatarStyle = {
    width: size,
    height: size
  };
  const [error, setError] = useState(false)
  return (
    <div
      className={classNames(props?.className, { "border-solid border-2 border-SECONDARY": selected })}
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size
      }}
    >
      <img
        alt="avatar"
        onError={(error) => {
          onError(error, src)
          setError(true)
        }}
        style={avatarStyle}
        className="object-cover bg-LIGHT_GREY rounded-full"
        // resizeMode="contain"
        src={src && !error ? src : defaultAvatar}
      />
    </div>
  );
}