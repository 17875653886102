import { IonLabel, IonToggle } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { colors } from "../../../styles/colors";

export default function Toggle(props: {
  value?: any;
  on?: any;
  off?: any;
  setValue: Function;
  color?: string;
  size?: string;
  labelColor?: string;
  label?: string;
  name?: string;
  labels?: { on: string; off: string };
}): JSX.Element {

  const { value, on = true, off = false, setValue, labelColor = colors.FONT_DARK, name = "", label } = props;
  const [localValue, setLocalValue] = useState(value === on)

  const onChangeCB = (event: any) => {
    setValue(value === off ? on : off);
  }
  useEffect(() => {
    setLocalValue(value === on)
  }, [value])

  return (
    <div className="flex flex-col w-auto justify-center items-start">
      <IonToggle color="secondary" checked={localValue} onIonChange={(e: any) => setLocalValue(e.detail.checked)} onClick={onChangeCB} />
      {label && (
        <IonLabel style={{ color: labelColor }} className="text-xs font-bold text-center">
          {label}
        </IonLabel>
      )}
    </div>
  );
}


