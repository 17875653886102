import { IonButton, IonDatetime, IonModal } from "@ionic/react"
import dayjs from "dayjs"
import { FC, useRef } from "react"
import { useTranslation } from "react-i18next"
import { createUseStyles } from "react-jss"
import { useFormContext, Controller } from "react-hook-form";
import { TABookingEdit } from "../../../core/models/technicalArea.models"
import i18n from "../../../utils/langs"

type DateTimeFieldProps = {
  name: string,
  label: string,
  type: "date" | "time"
  disabled?: boolean
}
/**
 * Made to work solely with useForm.
 * @param DateTimeFieldProps 
 * @returns 
 */
export const DateTimeField: FC<DateTimeFieldProps> = ({ name, label, type, disabled = false }) => {
  const { t } = useTranslation()
  const { modal, button } = useStyles();
  const modalDateRef = useRef<HTMLIonModalElement>(null);
  const { control, watch } = useFormContext()
  const datetime = watch(name)

  return <div className="flex flex-col ">
    <span className="mx-2 px-2 bg-white w-fit z-10 h-fit translate-y-1/2 text-xs text-[#6F6F6F]  ">
      {label}
    </span>
    <IonButton
      disabled={disabled}
      className={(button)}
      id={`modal-update-date-${name}`}
      fill="outline">

      {datetime && type === "date" && dayjs(datetime).format(t("dateFormat.shortDate"))}
      {datetime && type === "time" && dayjs(datetime).format(t("dateFormat.hours"))}
    </IonButton>
    <IonModal
      ref={modalDateRef}
      className={modal}
      showBackdrop
      trigger={`modal-update-date-${name}`} keepContentsMounted={true}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <IonDatetime
            onIonChange={(e) => {
              console.log("ON CHANGE end date");
              field.onChange(e.detail.value ? dayjs(e.detail.value as string) : "")
              field.onBlur()
            }}
            showDefaultButtons
            doneText={t("shared.done")}
            cancelText={t("shared.cancel")}
            value={field?.value?.format()}
            ref={field.ref}
            presentation={type === "date" ? "date" : "time"}
            locale={i18n.language}
            min={dayjs().format()}
            firstDayOfWeek={parseInt(t("shared.firstDayOfWeek"))}
          ></IonDatetime>
        )}
      />

    </IonModal>
  </div>
}

const useStyles = createUseStyles({
  modal: {
    "--width": "fit-content",
    "--min-width": " 250px",
    "--height": "fit-content",
    "--border-radius": " 6px",
    "--box-shadow": "0 28px 48px rgba(0, 0, 0, 0.4)",
    "--backdrop-opacity": "0.5 !important",
    "color": "var(--ion-color-primary)",
  },
  button: {
    "minHeight": "56px",
    "--border-color": "#CACACA",
    "--border-width": "1px",
    // "--padding-start": "4px",
    // "--padding-end": "4px",
    "margin": 0,
    "color": "#1F1F1F"

  },
}
)