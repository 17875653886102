import React from "react";
import { colors } from "../../styles/colors";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { IUserAgent } from "@wattsonelements/front-fdk/dist/models/User.models";

export function UserContact({
  showTitle = false,
  user,
}: {
  showTitle?: boolean;
  user: Partial<IUserAgent>;
}) {
  const { t } = useTranslation()

  const styles = useStyles()
  return (
    <div className={styles.container}>
      <p className={styles.title}>{t("protocole.owner")}</p>
      <div className={styles.containerNames}>
        <p className={`${styles.text} ${styles.borderR} `}>
          {user?.first_name}
        </p>
        <p className={styles.text}>{user?.last_name}</p>
      </div>
      <p className={styles.text}>{user.phone}</p>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    marginBottom: 20,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.21)",
    borderRadius: "10px",
    overflow: "hidden",
    background: colors.CALCITE,
  },
  title: {
    padding: "6px",
    textAlign: "center",
    fontSize: "25px",
  },
  borderR: {
    borderRightColor: colors.CALCITE,
    borderRightWidth: 2,
  },
  containerNames: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: colors.CALCITE,
  },
  text: {
    textAlign: "center",
    flex: 1,
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px 0",
    backgroundColor: colors.LIGHT_GREY,
  },
})
