import React from "react";
import { colors } from "../../../styles/colors";
import { useAppSelector } from "../../../core/store/hooks";
import { createUseStyles } from "react-jss";

export default function BtnList(props: any) {
  // useAppSelector()
  const styles = useStyles()
  return <div className={styles.container}>{props.children}</div>;
}

const useStyles = createUseStyles({
  container: {
    display:"flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
