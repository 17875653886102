import classNames from "classnames";
import { FC } from "react"

export interface TagsBadgeProps {
  label: string,
  color?: "green" | "red" | "grey" | string,
  backgroundColor?: string,
  uppercase?: boolean,
  className?: string,
  size?: "small" | "medium" | "large"
}

export const TagsBadge: FC<TagsBadgeProps> = ({ label, className, size = 'small', uppercase = true, color = "grey", backgroundColor }) => {
  return <span
    style={backgroundColor && color ? { color, backgroundColor } : {}}
    className={classNames(
      'rounded flex items-center text-center font-bold px-2 py-1 justify-center',
      {
        "uppercase": uppercase,
        "text-RED bg-LIGHT_RED": color === "red",
        "text-GREEN bg-LIGHT_GREEN": color === "green",
        "bg-MEDIUM_LIGHT_GREY  text-FONT_DARK": color === "grey",
        [color]: !["red", "green", "grey"].includes(color),
        "text-xs leading-3": size === "small",
        "text-sm leading-4": size === "medium",
        "text-base leading-4": size === "large",
      },
      className)}>
    {label}
  </span>
}