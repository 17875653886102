import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import FDK from "@wattsonelements/front-fdk";
import { BOOKING_SERVICE, IBooking } from "@wattsonelements/front-fdk/dist/models/Booking.models";
import { TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models";

interface CraneState {
  land: IBooking[],
  water: IBooking[]
}

const initialState = {
  land: [],
  water: []
} as CraneState

export const getCraneBooking = createAsyncThunk(
  "booking/get",
  async (params: TParams = {} , { getState }) => {
    return FDK.Agent.CraneModule.getBookings(params).then((res) => {
      console.log(res.data);
      return res.data;
    });
  }
)

const slice = createSlice({
  name: "crane",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCraneBooking.fulfilled, (state, { payload }) => {
      state.land = payload.results.filter(p => p.service === BOOKING_SERVICE.CRANE_TO_LAND)
      state.water = payload.results.filter(p => p.service === BOOKING_SERVICE.CRANE_TO_WATER)
    })
  }
})

export default slice.reducer;
// export const {} = slice.actions;