import React, { useEffect, useState } from "react";
import AddBtn from "../../UI/buttons/AddBtn";
import FieldItem from "../../UI/form/FieldItem";
import ModalEdit from "../../UI/ModalEdit";
import SelectList from "../../UI/form/SelectList";
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks";
import Input from "../../UI/form/Input";
import { useDebouncedCallback } from "use-debounce";
import { getBoat, resetSearch, searchBoats } from "../../../core/store/reducers/Boats";
import { colors } from "../../../styles/colors";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { IonSpinner } from "@ionic/react";
import { IBoat } from "@wattsonelements/front-fdk/dist/models/Boats.models";
import icon from "../../../assets/icons/ui/boat.png"

export default function TicketBoat({
  value,
  update,
}: {
  value: Partial<IBoat> | string;
  update: Function;
}) {
  const { t } = useTranslation()
  const [boat, setBoat] = useState<Partial<IBoat>>();
  const [open, setOpen] = useState(false);
  const [text, setspan] = useState("");
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const boatList = useAppSelector((state) => state.boats.searchResult);
  const error = useAppSelector((state) => state.boats.searchError);
  const loading = useAppSelector((state) => state.boats.searchLoading);
  useEffect(() => {
    dispatch(resetSearch());
  }, [open]);

  useEffect(() => {
    if (!value) return
    if (typeof value === "string") {
      dispatch(getBoat(value)).then(
        (res) => setBoat(res.payload as Partial<IBoat>)
      )
    } else if (typeof value === "object")
      setBoat(value)
  }, [value]);

  const onSave = (isSave: boolean) => {
    if (!isSave) {
      // setBoat(value);
    } else {
      update(boat);
    }
  };

  const debounced = useDebouncedCallback(
    // function
    (value: string) => {
      if (value) {
        dispatch(searchBoats(value));
      }
    },
    1000 // delay in ms
  );

  const onChangespan = (value: string) => {
    setspan(value);
    debounced(value);
  };
  return (
    <FieldItem className="flex-1"
      onClick={() => setOpen(!open)}
      field={{
        title: t("ticket.formFields.boat"),
        icon
      }}>
      <>
        <ModalEdit
          save={onSave}
          title={t("ticket.formFields.boat")}
          open={open}
          setOpen={setOpen}
        >
          {boat && (
            <span >
              {t("ticket.currentBoat")} : {boat.name}{" "}
              {boat?.registration_number}
            </span>
          )}
          {/* keyboardType="phone-pad"
              textContentType="telephoneNumber"
              placeholder={t("login.phone")}
              onChangeText={setPhone} */}
          <Input
            style={{ width: "100%" }}
            placeholder={t("ticket.boatSearch")}
            // onChangespan={onChangespan}
            onChangeText={onChangespan}
          />
          {boatList && boatList.length === 0 && !error && loading && (
            <IonSpinner className="m-auto mt-2" name="circular" color={colors.SECONDARY} />
          )}
          {boatList && boatList.length === 0 && !error && !loading && (
            <span className={styles.hint}>{t("ticket.hintResult")}</span>
          )}
          {!loading && error !== "" && (
            <span className={styles.error}>{t(error)}</span>
          )}
          <div className="countries-list overflow-auto">
            <SelectList
              isSelected={(item: Partial<IBoat>) => {
                return boat?.id === item.id;
              }}
              transformText={(item: Partial<IBoat>) => {
                return `${item.name} ${item.registration_number || ""}`;
              }}
              // getIcon={(boat:Partial<IBoat>) => {return {
              //     uri: boat.picture
              // }}}
              data={boatList}
              setdata={(boat: Partial<IBoat>, add: Boolean) =>
                add ? setBoat(boat) : setBoat(undefined)
              }
            />
          </div>
        </ModalEdit>
        {boat && <span className="block my-2">{boat.name}</span>}
        {!boat && (
          <AddBtn
            onClick={() => setOpen(!open)}
            icon={icon}
          />
        )}
      </>
    </FieldItem>
  );
}

const useStyles = createUseStyles({
  error: {
    color: colors.DANGER,
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
  },
  hint: {
    color: colors.FONT_DARK,
    fontStyle: "italic",
    fontSize: 10,
    textAlign: "center",
  },
});
