import FDK from "@wattsonelements/front-fdk";
import { ITicket, ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import i18n from "../../utils/langs";
import { COMPONENT_TYPE } from "./constants";

enum STEPS {
  DEFAULT,
}

export function generateDefaultStepsDefinition(
  ticket: ITicket,
  category: ITicketCategory
) {
  return {
    [STEPS.DEFAULT]: {
      isNode: false,
      stepNumber: 1,
      name: "STEPS.DEFAULT",
      // type: TYPES_STEP.PICTURE_AND_MESSAGE,
      title: category.label,
      subtitle: i18n.t("protocole.default.subtitle"),
      text: i18n.t("protocole.default.text"),
      components: [
        {
          order: 1,
          component: COMPONENT_TYPE.ATTACHMENT,
          multiple: true,
          required: false,
        },
        {
          order: 2,
          component: COMPONENT_TYPE.NOTE,
          name: i18n.t("protocole.default.note"),
          required: true,
        },
      ],
      nextStep: null,
    },
  };
}
