import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../core/store/hooks";
import ActivityItem from "./ActivityItem";
import {
  getMessageTicketById,
  createTicketMessage,
} from "../../core/store/reducers/Tickets";
import { useTranslation } from "react-i18next";
import { usePeriodic } from "../../core/services/Periodic.service";
import { IonButton, IonIcon } from "@ionic/react";
import { sendOutline } from 'ionicons/icons';
import { CircularProgress, TextField } from "@mui/material";
import { ITicketMessage } from "@wattsonelements/front-fdk/dist/models/Ticket.models";

export default function TicketActivity() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const selectedTicket = useAppSelector((state) => state.tickets.selected);
  const isLoading = useAppSelector((state) => state.tickets.loading)
  const currentUser = useAppSelector((state) => state.users.current);
  const [activities, setActivities] = useState(selectedTicket?.messages);
  const { startPeriodic, stopPeriodic } = usePeriodic();
  const scrollToBottomContainer = useRef<any>()
  const periodicName = "get-ticket-activity";
  let nbItemsLoaded = 0;


  const scrollToBottom = () => {
    nbItemsLoaded++
    if (activities && activities.length === nbItemsLoaded) {
      scrollToBottomContainer.current!.scrollTo(0, scrollToBottomContainer.current!.firstChild.offsetHeight);
    }
  }

  useEffect(() => {
    setActivities(selectedTicket?.messages);

    return () => {
      setActivities([]);
      stopPeriodic(periodicName);
    };
  }, [selectedTicket]);

  // to uncomment
  useEffect(() => {
    if (selectedTicket && selectedTicket?.id) {
      startPeriodic(periodicName, 5, () =>
        dispatch(getMessageTicketById(selectedTicket!.id!))
      );
    }
    return () => {
      stopPeriodic(periodicName);
    };
  }, [selectedTicket]);

  useEffect(() => {
    if (!isLoading)
      setTimeout(() => scrollToBottomContainer.current!.scrollTo(0, scrollToBottomContainer.current!.firstChild.offsetHeight), 300);
  }, [isLoading])

  const [message, setMessage] = useState("");

  const sendMessage = () => {
    if (message !== "") {
      const mess = {
        message_from: currentUser?.id,
        message_to: currentUser?.id,
        ticket_message: message
      }
      setMessage("");
      dispatch(createTicketMessage({ id: selectedTicket!.id, message: mess }));
    }
  };

  // const renderItem = ({
  //   item,
  //   index,
  // }: {
  //   index: number;
  //   item: TicketMessage;
  // }) => (
  //   <ActivityItem
  //     previousMessageDate={
  //       index > 0 && activities ? activities[index - 1].date_created : undefined
  //     }
  //     message={item}
  //   />
  // );
  return (
    <div className="flex flex-col flex-[1_1_auto] overflow-hidden relative pt-2 pb-24 px-4">
      <div ref={scrollToBottomContainer} className="flex h-full overflow-auto relative w-full">
        <div className="absolute left-0 top-0 w-full flex flex-col justify-center items-center">
          {activities && activities.map((item: ITicketMessage, index: number) =>
            <ActivityItem
              key={"activity-" + index}
              loadedCallBack={scrollToBottom}
              previousMessageDate={
                index > 0 && activities ? activities[index - 1].date_created : undefined
              }
              message={item}
            />)}
        </div>
      </div>
      <div className="flex items-center justify-center absolute bottom-0 left-0 right-0 px-4 pb-4">
        {/* <Input
          value={message}
          className="flex flex-1"
          placeholder={t("ticket.message")}
          onChangeText={setMessage}
        /> */}
        <TextField
          size="small"
          multiline
          maxRows={3}
          placeholder={t("ticket.message")}
          value={message}
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setMessage(e.target.value);
          }}
          className="flex flex-1"
        />
        <IonButton color="secondary" className="rounded-full" onClick={sendMessage}>
          {isLoading ?
            <CircularProgress size={20} />
            :
            <IonIcon color="light" slot="icon-only" icon={sendOutline}></IonIcon>}
        </IonButton>
        {/* <Btn label=">" onClick={sendMessage} /> */}
      </div>
    </div>
  );
}