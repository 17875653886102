import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  IonApp,
  IonBackdrop,
  IonBadge,
  IonButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ticket, boatOutline, apps } from "ionicons/icons";
import { isPlatform } from "@ionic/react";
import FDK from "@wattsonelements/front-fdk";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { AppProvider } from "./core/context/app.context";
import { Provider } from "react-redux";
import dayjs from "dayjs";
import store from "./core/store/store";
import LoginPage from "./pages/Login";
import "./index.css";
import "./index.scss";
import DashboardPage from "./pages/Dashboard";
import ProtocolePage from "./pages/Protocole";
import { Suspense, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./core/store/hooks";
import PrivateRoute, { GuardedRoute, isModuleActivated } from "./_helpers_/privateRoute.helper";
import TicketPage from "./pages/Ticket";
import { getTicketsByUser } from "./core/store/selectors/tickets";

import { useAuth } from "./core/services/Auth.service";
import { QAProvider, useQAContext } from "./core/context/QAContext";
import SystemMode from "./components/layouts/SystemMode";
import AppVersion from "./components/UI/AppVersion";
import PublicRoute from "./_helpers_/publicRoute.helper";
import { Playground } from "./pages/Playground";
import DockWalkSVG from "./assets/icons/dockwalk.svg";
import TechAreaSVG from "./assets/icons/ZoneTechnique.svg";
import { DockwalkPage } from "./pages/DockWalk";
import { CranePage } from "./pages/Crane";
import { ManutentionPLPage } from "./pages/ManutentionPL";
import classNames from "classnames";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useTranslation } from "react-i18next";
import { TechnicalAreaPage } from "./pages/TechnicalArea";
import { TechnicalAreaDetailPage } from "./pages/TechnicalAreaDetail";
import i18n from "./utils/langs";
import { IPortAgent, MODULES } from "@wattsonelements/front-fdk/dist/models/Port.models";
import { getUserPort } from "./core/store/reducers/Ports";

require("dayjs/locale/fr");
require("dayjs/locale/en");
dayjs.locale(i18n.language);
i18n.on('languageChanged', () => {
  dayjs.locale(i18n.language);
})
dayjs.extend(isSameOrAfter)

setupIonicReact();

export const HANDLINGSERVICES_PL = "handling-services-pl";

const App: React.FC = () => (
  <QAProvider>
    <AppProvider>
      <Provider store={store}>
        <IonApp>
          <IonReactRouter>
            <AppInit>
              <SystemMode />
              <AppVersion />
              <Routing />
            </AppInit>
          </IonReactRouter>
        </IonApp>
      </Provider>
    </AppProvider>
  </QAProvider>
);

const AppInit = (props: any) => {
  const dispatch = useAppDispatch()
  const { checkLogin, logout, init } = useAuth();
  const { APP_VERSION, API_URL } = useQAContext();
  const [initDone, setInitDone] = useState(false);
  const { isLoggedIn } = useAppSelector(state => state.auth);

  useEffect(() => {
    if (initDone) checkLogin();
  }, [initDone]);

  useEffect(() => {
    if (!initDone) {

      FDK.Agent.init(
        API_URL,
        APP_VERSION,
        () => {
          return {
            access: localStorage.getItem("access") || "",
            refresh: localStorage.getItem("refresh") || "",
          };
        },
        () => {
          logout();
        }, // logout
        ({ access, refresh }) => {
          localStorage.setItem("access", access);
          localStorage.setItem("refresh", refresh);
          // dispatch(refreshAuth())
        }
      );
      dispatch(getUserPort()).finally(() => {
        setInitDone(true);
      })

    } else init();
  }, [initDone, isLoggedIn]);

  if (!initDone)
    return (
      <div className="absolute w-full h-full flex items-center justify-center">
        <IonSpinner />
      </div>
    );
  return <Suspense fallback={<IonSpinner />}>{props.children}</Suspense>
};

type TabsMenuItem = {
  url: string,
  icon: string
  badge: null | number,
  label: string
  showDisabled: boolean,
  isActive: (port?: IPortAgent) => boolean,
}
const Routing: React.FC = () => {
  const [displayTabButtons, setDisplayTabButtons] = useState(false);
  const userTickets = useAppSelector(getTicketsByUser);
  const location = useLocation();
  const history = useHistory()
  const port = useAppSelector(state => state.ports.current);
  const { t } = useTranslation()

  const [menu, setMenu] = useState<TabsMenuItem[][]>([[], [], []])
  const [showSubMenu, setShowSubMenu] = useState(false)

  useEffect(() => {
    if (!location) return;

    setDisplayTabButtons(
      !location.pathname.match("/login") && !location.pathname.includes("/ticket") && !location.pathname.includes("/protocole")
    );
  }, [location]);

  useEffect(() => {
    howToSplit()
  }, [port])

  const menuItem: TabsMenuItem[] = [
    {
      url: "/dashboard/opens",
      icon: ticket,
      badge: userTickets && userTickets.length,
      label: "menu.dashboard",
      isActive: () => true,
      showDisabled: true,
    },
    {
      url: "/dockwalk",
      icon: DockWalkSVG,
      badge: null,
      label: "menu.dockwalk",
      showDisabled: true,
      isActive: (port?: IPortAgent) => isModuleActivated(MODULES.DOCKWALK, port)
    }, {
      url: "/crane",
      icon: boatOutline,
      badge: null,
      label: "menu.crane",
      showDisabled: false,
      isActive: (port?: IPortAgent) => isModuleActivated(MODULES.CRANE, port)
    },
    {
      url: "/hspl",
      icon: boatOutline,
      badge: null,
      label: "menu.crane-PL",
      showDisabled: false,
      isActive: (port?: IPortAgent) => isModuleActivated(HANDLINGSERVICES_PL, port)

    },
    {
      url: "/technical-area/dashboard",
      icon: TechAreaSVG,
      badge: null,
      label: "menu.technicalArea",
      showDisabled: false,
      isActive: (port?: IPortAgent) => isModuleActivated("technical-areas", port)
    },

  ]

  const isTabsSelectedByLocation = (url: string) => {
    return location.pathname.includes(url)
  }

  const howToSplit = () => {
    if (!port) return
    const actualMenuContent = menuItem.filter(item => item.isActive(port) || item.showDisabled)
    const nbItemsToShow = actualMenuContent.length
    if (nbItemsToShow > 4) {
      // setSplitAt(2)
      const side1 = actualMenuContent.slice(0, 2)
      const side2 = [...actualMenuContent.slice(2, 3)]
      const side3 = actualMenuContent.slice(3)
      setMenu([
        side1,
        side2,
        side3
      ])
      console.log("MENU 4+", side1, side2, side3);
      return
      //need to add sub menu tabs
    }
    if (nbItemsToShow % 2 === 0 && nbItemsToShow <= 4) {
      // setSplitAt(nbItemsToShow / 2)
      const side1 = actualMenuContent.slice(0, nbItemsToShow / 2)
      const side2 = actualMenuContent.slice(nbItemsToShow / 2)
      setMenu([
        side1,
        side2,
        []
      ])
      console.log("MENU pair", nbItemsToShow / 2, side1, side2);
      return
    } else {
      // setSplitAt(Math.floor(nbItemsToShow / 2))
      const side1 = actualMenuContent.slice(0, Math.floor(nbItemsToShow / 2))
      const side2 = actualMenuContent.slice(Math.floor(nbItemsToShow / 2))
      console.log("MENU impair", Math.floor(nbItemsToShow / 2), side1, side2);
      setMenu([
        side1,
        side2,
        []
      ])
    }
  }
  // console.log("MENU", menuItem);

  return (
    <>
      <div style={{
        display: displayTabButtons ? "flex" : "none",
        marginLeft: window.innerWidth / 2 - 25 + "px"
      }}
        className={`z-10 fixed bottom-7`}>
        <button onClick={() => history.push("/ticket/create")}
          className="w-12 h-12 bg-SECONDARY flex relative justify-center items-center rounded-full ring-4 ring-white/[.65]">
          <span className="text-CALCITE text-2xl -mt-1 font-bold">+</span>
        </button>
      </div>
      {
        showSubMenu && <IonBackdrop
          className="bottom-16"
          tappable={true}
          onIonBackdropTap={() => { setShowSubMenu(false) }}
          visible={showSubMenu}></IonBackdrop>
      }


      {port && showSubMenu && menu[2].length > 0 &&
        <div
          // style={{ "--max-height": 50 * menu[2].length + 'px', "--max-width": "160px" }}
          //   backdropDismiss
          //  isOpen={showSubMenu} 
          className={classNames(
            "bg-white z-10 fixed flex flex-col max-w-40 gap-1 right-0 bottom-16",
          )}>
          {/* <IonContent> */}
          {
            menu[2].map(item => <IonButton
              onClick={() => {
                setShowSubMenu(false)
                history.push(item.url)
              }}
              className={classNames(
                "z-10",
                {
                  "opacity-80": !isTabsSelectedByLocation(item.url) && item.isActive(port),
                  "opacity-40": item.showDisabled && !item.isActive(port),
                })} fill="clear" expand="block" color="dark">
              <IonIcon slot="start" color={"grey"} icon={item.icon} />
              {t(item.label)}

            </IonButton>)
          }

          {/* </IonContent> */}
        </div>}

      <IonTabs>
        <IonRouterOutlet animated={true}>
          {/* <Route exact path="/login" component={LoginPage} /> */}
          <PublicRoute exact path="/login" component={LoginPage} />
          {/* MODULES */}

          <GuardedRoute module={MODULES.DOCKWALK} path="/dockwalk" component={DockwalkPage} />
          <GuardedRoute module={MODULES.CRANE} path="/crane" component={CranePage} />
          <GuardedRoute module={HANDLINGSERVICES_PL} path="/hspl" component={ManutentionPLPage} />
          <GuardedRoute module="technical-areas" path="/technical-area/dashboard" component={TechnicalAreaPage} />
          <GuardedRoute module="technical-areas" exact={true} path="/technical-area/detail/:id" component={TechnicalAreaDetailPage} />
          {/* TICKETING */}
          <Redirect exact={true} path="/" from="/" to="dashboard/opens" />
          <Redirect exact={true} path="/dashboard" from="/dashboard" to="dashboard/opens" />
          <PrivateRoute exact={true} path="/dashboard/opens" component={DashboardPage} />
          <PrivateRoute exact={true} path="/ticket/" component={TicketPage} />
          <PrivateRoute exact={true} path="/ticket/:mode/:id?/protocole" component={ProtocolePage} />
          <PrivateRoute exact={true} path="/ticket/:mode" component={TicketPage} />
          <PrivateRoute exact={true} path="/ticket/:mode/:id?" component={TicketPage} />
          {/* <PrivateRoute exact={true} path="/" component={DashboardPage} /> */}
          {/* DEV */}
          {/* <PrivateRoute exact={true} path="/playground" component={Playground} /> */}
          {/* <PrivateRoute component={NotFoundPage} /> */}
        </IonRouterOutlet>
        <IonTabBar className="m-0" style={{ display: displayTabButtons ? "flex" : "none" }} slot="bottom">

          {
            port && menu[0].map(item =>
              <IonTabButton
                key={item.url}
                onClick={() => {
                  setShowSubMenu(false)
                }}
                className={classNames(
                  "tab-btn",
                  {
                    "bg-PRIMARY": isTabsSelectedByLocation(item.url),
                    "opacity-80": !isTabsSelectedByLocation(item.url) && item.isActive(port),
                    "opacity-40": item.showDisabled && !item.isActive(port),
                    "min-w-[40vw]": menu[1].length > menu[0].length && menu[2].length === 0
                  }
                )}
                href={item.url}
                tab={item.url}
                disabled={!item.isActive(port)}
              >
                <IonIcon color={isTabsSelectedByLocation(item.url) ? "light" : "grey"} icon={item.icon} />
                <IonLabel color={isTabsSelectedByLocation(item.url) ? "light" : "grey"}>
                  {t(item.label)}
                </IonLabel>
                {
                  item.badge && item.badge > 0 ? <IonBadge className="bg-DANGER text-white">{item.badge}</IonBadge> : <></>
                }

              </IonTabButton>)
          }
          <IonTabButton
            className={(location.pathname.match("create") ? "bg-PRIMARY" : "opacity-80") + " tab-btn"}
            href="/ticket/create"
            tab="/ticket/create"
            onClick={() => {
              setShowSubMenu(false)
            }}
          >
            {/* <div className="w-12 h-12 bg-SECONDARY flex relative justify-center items-center rounded-full ring-4 ring-white/[.65]">
              <IonIcon className="text-white text-2xl font-bold" color="light" icon={add} />
            </div> */}
          </IonTabButton>

          {
            port && menu[1].map(item =>
              <IonTabButton
                key={item.url}
                onClick={() => {
                  setShowSubMenu(false)
                }}
                className={classNames(
                  "tab-btn",
                  {
                    "bg-PRIMARY": isTabsSelectedByLocation(item.url),
                    "opacity-80": !isTabsSelectedByLocation(item.url) && item.isActive(port),
                    "opacity-40": item.showDisabled && !item.isActive(port),

                  }
                )}
                href={item.url}
                tab={item.url}
                disabled={!item.isActive(port)}
              >
                <IonIcon color={isTabsSelectedByLocation(item.url) ? "light" : "grey"} icon={item.icon} />
                <IonLabel color={isTabsSelectedByLocation(item.url) ? "light" : "grey"}>{t(item.label)}</IonLabel>
                {
                  item.badge && item.badge > 0 ? <IonBadge className="bg-DANGER text-white">{item.badge}</IonBadge> : <></>
                }

              </IonTabButton>)

          }
          {
            menu[2].length > 0 &&
            <IonTabButton
              className={classNames("opacity-80 tab-btn",
                {
                  "bg-PRIMARY": menu[2].some(i => isTabsSelectedByLocation(i.url)),
                  "opacity-80": !menu[2].some(i => isTabsSelectedByLocation(i.url)),

                })}
              tab="more"
              onClick={() => {
                setShowSubMenu(!showSubMenu)
              }}
            >
              <IonIcon color={menu[2].some(i => isTabsSelectedByLocation(i.url)) ? "light" : "grey"} icon={apps} />
              <IonLabel color={menu[2].some(i => isTabsSelectedByLocation(i.url)) ? "light" : "grey"}>{t("menu.other")}</IonLabel>

            </IonTabButton>
          }
        </IonTabBar>
      </IonTabs>
    </>
  );
};

export default App;
