import { IonTextarea } from "@ionic/react"
import { IBooking } from "@wattsonelements/front-fdk/dist/models/Booking.models"

import { FC, useEffect, useState } from "react"
import Avatar from "../UI/users/Avatar"
import Btn from "../UI/buttons/Btn"
import { UserContactInfo } from "../UI/user/ContactInfo"
import { CheckList } from "../protocole/Checklist"
import { ActionStatus, ActionStatusProps } from "../UI/ActionStatus"
import { getDateObjectFromTime } from "../../_helpers_/datatime.helpers"
import FDK from "@wattsonelements/front-fdk"
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api"
import { useTranslation } from "react-i18next";

type CraneModalProps = {
  booking: IBooking
}

enum STEPS {
  INFO,
  FORM,
  RESULT
}

export const CraneModal: FC<CraneModalProps> = ({ booking }) => {
  const { t } = useTranslation()
  const [comment, setComment] = useState(booking.comment)

  const [checklist, setChecklist] = useState<any>([])
  const [result, setResult] = useState<ActionStatusProps>({
    state: REQUEST_STATUS.IDLE,
  })
  const [step, setStep] = useState(STEPS.INFO)

  useEffect(() => {
    FDK.Agent.CraneModule.getManutentionChecklist().then(res => {
      console.log(res);
      if (res && booking && (booking?.service! in res.data)) {
        setChecklist(res.data[booking?.service!])
      }
    })
  }, [])

  const send = () => {
    setResult({
      state: REQUEST_STATUS.PENDING,
      // title: t(`crane.service.${booking.service}`) || ""
    })
    setStep(STEPS.RESULT)
    // TODO ADD VALIDATION 
    FDK.Agent.CraneModule.sendBookingAction(booking.id, "done", {
      comment,
      report: checklist
    }).then(({ data }) => {
      setResult({
        state: REQUEST_STATUS.SUCCESS,
        ...data
      })
    }, err => {
      setResult({
        state: REQUEST_STATUS.ERROR,
        ...err
      })
    })
  }

  const missed = () => {
    setResult({
      state: REQUEST_STATUS.PENDING,
      title: booking.service || ""
    })
    setStep(STEPS.RESULT)
    FDK.Agent.CraneModule.sendBookingAction(booking.id, "miss").then(res => {
      setResult({
        state: REQUEST_STATUS.SUCCESS,
        ...res.data
      })


    }, err => {
      setResult({
        state: REQUEST_STATUS.ERROR,
        ...err
      })
    })
  }

  return (
    <div className="flex flex-col leading-3">
      <h1 className="font-bold text-3xl mb-4">{t(`crane.service.${booking.service}`)}</h1>
      {
        step === STEPS.INFO &&
        <>
          {
            booking?.agent && <div className="flex justify-center items-center gap-2 mb-3">
              <Avatar size={25} src={booking?.agent?.avatar} />
              <span className="text-BLUE_GREY text-sm">
                {booking?.agent?.first_name} {booking?.agent?.last_name}
              </span>
            </div>
          }
          {
            booking.contract?.spot?.name && <span className="font-bold mb-2">{booking.contract?.spot?.name}</span>
          }
          <span className="text-2xl text-center font-bold">
            {getDateObjectFromTime(booking.related_planning.start_time, booking.date).format(t("dateFormat.hours"))}
          </span>
          <span className="font-bold text-lg">{booking.boat.name}</span>
          {
            booking.boat?.registration_number && <span className="font-bold mb-2">{booking.boat?.registration_number}</span>
          }

          <span>{booking.boater?.first_name} {booking.boater?.last_name}</span>


          <UserContactInfo
            email={booking.boater?.email}
            phone={booking.boater?.phone} />
          <div className="text-left p-4 mb-3 whitespace-break-spaces leading-5">
            <h3 className="text-lg font-bold mb-2">{t("crane.modal.comment")}</h3>
            {
              !booking.boater_comment && !comment && t("crane.modal.nocomment")
            }
            <div className="flex flex-col gap-2">
              {booking.boater_comment && <p>{booking.boater_comment}</p>}
              {comment && <p>{comment}</p>}
            </div>
          </div>

          <Btn onClick={() => {
            setStep(STEPS.FORM)
          }} expand="block" size="large" color="secondary">{t("crane.modal.done")}</Btn>
          <Btn onClick={missed} fill="outline" size="small" color="secondary" >{t("crane.modal.missed")}</Btn>
        </>
      }
      {
        step === STEPS.FORM &&
        <>
          <IonTextarea
            style={{
              '--icon-color': 'var(--ion-color-primary',
              '--box-shadow': "0",
              '--border-radius': "40px",
              "--padding-start": "25px",
              "--padding-end": "25px",
              "--padding-top": "12px",
              "--padding-bottom": "12px",
            }}
            autoGrow={true}
            rows={4}
            value={comment}
            className="rounded-3xl border-2 border-SECONDARY mb-3 text-left leading-5"
            placeholder={t("crane.modal.comment")}
            onIonChange={(e) => {
              setComment(e.detail.value || "")
            }}
          ></IonTextarea>
          {
            checklist.length > 0 &&
            <div>
              <CheckList update={setChecklist} list={checklist} />
            </div>
          }

          <Btn onClick={send} expand="block" size="large" color="secondary">{t("crane.modal.done")}</Btn>
          <Btn
            onClick={() => {
              setStep(STEPS.INFO)
            }}
            fill="outline" size="small" color="secondary" >{t("crane.modal.goback")}</Btn>
        </>
      }
      {
        step === STEPS.RESULT &&
        <ActionStatus {...result} />
      }





    </div>)
}