import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonBadge,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonToggle,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
  useIonViewDidEnter,
  IonDatetime,
} from "@ionic/react";
import FDK from "@wattsonelements/front-fdk";
import DashboardHeader from "../components/layouts/DashboardHeader";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import classNames from "classnames";
import { ActionStatus } from "../components/UI/ActionStatus";
import { IGenericResponse } from "@wattsonelements/front-fdk/dist/models/Misc.models";
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api";
import { calendarOutline, close } from "ionicons/icons";
import { BOOKING_STATUS } from "@wattsonelements/front-fdk/dist/models/Booking.models";
import { useTranslation } from "react-i18next";

type ManutentionPLItem = {
  id: UUID;
  description: string;
  date_start_time: string;
  status: BOOKING_STATUS;
  service: { name: string; cssstyle: string };
};

type Header = {
  todayDate: dayjs.Dayjs;
  countBooked: number;
  countAll: number;
};

export const ManutentionPLPage = () => {
  const { t } = useTranslation()
  const [header, setHeader] = useState<Header>({ todayDate: dayjs(), countBooked: 0, countAll: 0 });
  const [list, setList] = useState<ManutentionPLItem[]>([]);
  const [listLoading, setListLoading] = useState(true);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [showBooked, setShowBooked] = useState(true);
  const [actionMessage, setActionMessage] = useState<IGenericResponse | null>(null);
  const [actionStatus, setActionStatus] = useState<number>(FDK.MainAPI.REQUEST_STATUS.IDLE);
  const [actionItem, setActionItem] = useState<null | ManutentionPLItem>(null);
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [date, setDate] = useState(dayjs().toISOString())
  const listRef = useRef<any>();

  useIonViewDidEnter(() => {
    loadData();
  }, []);



  const loadData = (event?: CustomEvent<RefresherEventDetail>) => {
    if (!event) {
      setListLoading(true);
    }

    FDK.MainAPI.API.request<ManutentionPLItem[]>({
      method: "get",
      url: `/v2/agent/handling-tasks-pl?date_start_time__date=${dayjs(date).format("YYYY-MM-DD")}`, ///pljoinville-handling-tasks
      // headers: {"Content-Type": "text/plain"},
    })
      .then(
        res => {
          setList(res.data);
          const countBooked = res.data.filter(item => item.status === BOOKING_STATUS.BOOKED).length;
          const countAll = res.data.length;

          // bg-gray-100

          setHeader({
            todayDate: dayjs(date),
            countBooked,
            countAll,
          });
        },
        err => { }
      )
      .finally(() => {
        event && event.detail.complete();
        setListLoading(false);
        listRef?.current?.closeSlidingItems();
      });
  };

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const onSlided = (e: any, item: ManutentionPLItem) => {
    if (item.status === BOOKING_STATUS.DONE) return;
    setActionItem(item);
    setActionStatus(REQUEST_STATUS.PENDING);
    setOpenActionModal(true);
    FDK.MainAPI.API.request<IGenericResponse>({
      method: "put",
      url: `/v2/agent/handling-tasks-pl/${item.id}/action/done`,
      data: { status: BOOKING_STATUS.DONE },
    }).then(
      res => {
        setActionStatus(REQUEST_STATUS.SUCCESS);
        setActionMessage({ ...res.data });
      },
      (err: any) => {
        setActionStatus(REQUEST_STATUS.ERROR);
        setActionMessage({ message: t("shared.unknownError"), ...err });
      }
    );
  };

  return (
    <IonPage>
      {false && <div className="bg-sky-400 bg-amber-500"></div>}
      <IonHeader className="bg-LIGHT">
        <DashboardHeader size="small" />
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={loadData}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonLoading showBackdrop={false} backdropDismiss={false} spinner="circles" isOpen={listLoading} />

        <div className="w-full px-3 bg-LIGHT text-lg flex gap-1 justify-between items-center flex-wrap">
          <IonButton color="light" onClick={() => setDateModalOpen(true)}>
            <IonBadge slot="start" color="secondary">RDV: {showBooked ? header.countBooked : header.countAll}</IonBadge>&nbsp;{" "}
            {header.todayDate.format("ddd D MMMM").toUpperCase()} &nbsp;{" "}
            <IonIcon icon={calendarOutline} />
          </IonButton>

          {/* <span className="font-bold"></span> */}
          <div className="flex gap-1 text-xs font-medium items-center w-24 text-center">
            A&nbsp;faire
            <IonToggle color="secondary" checked={showBooked} onIonChange={() => setShowBooked(!showBooked)}></IonToggle>
          </div>
        </div>
        <IonModal onDidDismiss={() => { setDateModalOpen(false) }} className="ion-datetime-button-overlay" onClick={() => setDateModalOpen(false)} isOpen={dateModalOpen} >
          <IonDatetime onIonChange={(e) => {
            setDate(e.target.value as string)
            const newDate = dayjs(e.target.value as string)
            if (newDate.isSameOrAfter(dayjs(), "date")) {
              setShowBooked(true)
            } else {
              setShowBooked(false)
            }
            setHeader({
              todayDate: dayjs(e.target.value as string),
              countBooked: 0,
              countAll: 0,
            });
          }}
            value={date}
            firstDayOfWeek={1}
            presentation="date"></IonDatetime>
        </IonModal>
        <IonList ref={listRef}>
          {list
            .filter(item => {
              if (!showBooked) return true;
              if (item.status === BOOKING_STATUS.BOOKED) return true;
              return false;
            })
            .map(item => (
              <IonItemSliding disabled={item.status === BOOKING_STATUS.DONE} key={item.id}>
                <IonItem>
                  <div className={classNames("flex items-center space-x-4 py-2", { "opacity-40": item.status === BOOKING_STATUS.DONE })}>
                    <div
                      className={classNames("relative inline-flex items-center justify-center w-14 h-14 overflow-hidden rounded-full", {
                        "bg-gray-100": item.status === BOOKING_STATUS.DONE || item.service.cssstyle === null,
                        [item.service.cssstyle]: !!item.service.cssstyle,
                      })}
                    >
                      <span className="font-bold text-gray-900">{dayjs(item.date_start_time).format(t("dateFormat.hours"))}</span>
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className={classNames("text-base text-gray-900", { "line-through": item.status === BOOKING_STATUS.DONE })}>
                        {item.service.name}
                      </p>
                      <p className={classNames("text-sm font-bold text-gray-900", { "line-through": item.status === BOOKING_STATUS.DONE })}>
                        {item.description.replace(item.service.name, "")}
                      </p>
                    </div>
                  </div>
                </IonItem>
                {item.status === BOOKING_STATUS.BOOKED && (
                  <IonItemOptions
                    side="end"
                    onIonSwipe={e => {
                      onSlided(e, item);
                    }}
                  >
                    <IonItemOption
                      onClick={e => {
                        onSlided(e, item);
                      }}
                      expandable
                      className="rounded-l-2xl"
                      color="secondary"
                    >
                      <div className="p-4 text-white font-bold">C'est fait</div>
                    </IonItemOption>
                  </IonItemOptions>
                )}
              </IonItemSliding>
            ))}
        </IonList>
        <IonModal
          style={{ "--max-height": "400px", "--max-width": "90vw" }}
          isOpen={openActionModal}
          onDidDismiss={() => {
            setActionItem(null);
            setActionStatus(FDK.MainAPI.REQUEST_STATUS.IDLE);
            setActionMessage(null);
            loadData();
          }}
        >
          <IonContent>
            <div className="flex flex-col gap-3 justify-center items-center h-full p-4">
              <ActionStatus
                title={actionMessage?.title || ""}
                message={actionMessage?.message || ""}
                sub_message={actionMessage?.sub_message || ""}
                size={50}
                state={actionStatus}
              />
              {FDK.MainAPI.REQUEST_STATUS.ERROR === actionStatus && actionItem && (
                <IonButton
                  shape="round"
                  color="danger"
                  onClick={(e: any) => {
                    actionItem && onSlided(e, actionItem);
                  }}
                >
                  {t("shared.retry")}
                </IonButton>
              )}
              {[FDK.MainAPI.REQUEST_STATUS.ERROR, FDK.MainAPI.REQUEST_STATUS.SUCCESS].includes(actionStatus) && (
                <IonButton
                  style={{
                    "--padding-start": "0",
                    "--padding-end": "0",
                    "--border-radius": "50%",
                    width: "40px",
                    height: "40px",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                  color="secondary"
                  fill="outline"
                  onClick={() => {
                    setOpenActionModal(false);
                  }}
                >
                  <IonIcon color="secondary" className="min-w-4 min-h-4 font-bold" icon={close} />
                </IonButton>
              )}
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
