export enum colors {
  FONT_DARK = "#252631",
  GREY = "#92929d",
  LIGHT_GREY = "#ccccdb",
  LIGHT = "#f4f4f4",
  PRIMARY = "#49107e",
  SECONDARY = "#0eb6cd",
  CALCITE = "#ffffff",
  ACCENT = "#0cb6fb",
  DANGER = "#ff0000",
  WARN = "#ff0000",
  SUCCESS = "#0ab767",
}
