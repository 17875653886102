import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IonContent, IonSpinner } from "@ionic/react"

import { useAppDispatch, useAppSelector } from "../core/store/hooks";

import BackHeaderFlux from "../components/layouts/BackHeaderFlux";
import { getSelectedTicketCategory } from "../core/store/selectors/categories";
import {
  getProtocole,
  formatAndSendProtocole,
} from "../core/protocoles/protocole.service";
import {
  initProtocole,
  resetProtocole,
} from "../core/store/reducers/Protocole";
import { getFullstep } from "../core/store/selectors/protocole";
import { ProtocoleQuestion } from "../components/protocole/Question";
import { StepProtocole } from "../components/protocole/Step";
import ResultModal from "../components/protocole/Result";
import { getTicketById } from "../core/store/reducers/Tickets";
import FDK from "@wattsonelements/front-fdk";
import { ProtocoleStep } from "@wattsonelements/front-fdk/dist/models/Protocol.models";
import { IBerth, ITicketBoat } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api";

export default function ProtocolePage() {
  const dispatch = useAppDispatch();
  const ticket = useAppSelector((state) => state.tickets.selected);
  const category = useAppSelector(getSelectedTicketCategory);
  const step = useAppSelector(getFullstep) as ProtocoleStep;
  const { status, requestMessages } = useAppSelector((state) => state.protocole);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  // const [type, setType] = useState(""); // more of a test datas
  const { id } = useParams<{ mode: string, id: string }>()

  useEffect(() => {
    if (ticket) {
      dispatch(resetProtocole());
      setTitle(`${(ticket?.boat as ITicketBoat)?.name || category?.label} `);
      if (ticket?.spot) {
        setSubtitle(`${(ticket?.boat as ITicketBoat)?.name || " - "}, ${(ticket?.spot as IBerth)?.pontoon_name}`);
      } else if ((ticket?.boat as ITicketBoat)?.name) {
        setSubtitle(`${category?.label}`);
      }
    } else {
      dispatch(getTicketById(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  useEffect(() => {
    if (category && ticket) {
      const getData = async () => {
        const { protocole, type } = await getProtocole(ticket, category);
        // setType(type);
        dispatch(initProtocole({ config: protocole, step: 0, type }));
      }
      getData()
    }
  }, [category, ticket, dispatch]);

  const send = () => {
    console.log("sent retry");
    if (status === FDK.Constants.ProtocolConstants.PROTOCOLE_STATUS.ERROR && ticket) {
      formatAndSendProtocole();
    }
  };

  return (
    <>
      <IonContent color="light">

        {status !== FDK.Constants.ProtocolConstants.PROTOCOLE_STATUS.COMPLETING &&
          <div className="h-full flex justify-center items-center">
            {status === FDK.Constants.ProtocolConstants.PROTOCOLE_STATUS.SUCCESS && (
              <ResultModal
                retry={send}
                requestMessage={requestMessages}
                state={REQUEST_STATUS.SUCCESS}
                type={"success"}
              />
            )}
            {status === FDK.Constants.ProtocolConstants.PROTOCOLE_STATUS.ERROR && (
              <ResultModal
                state={REQUEST_STATUS.ERROR}
                retry={send}
                requestMessage={requestMessages}
                type={"error"}
              />
            )}
            {status === FDK.Constants.ProtocolConstants.PROTOCOLE_STATUS.SENDING && (
              <ResultModal
                retry={send}
                type={"loading"}
                state={REQUEST_STATUS.PENDING}
              />
            )}
          </div>}
        {status === FDK.Constants.ProtocolConstants.PROTOCOLE_STATUS.COMPLETING && (
          <>
            <BackHeaderFlux
              title={title}
              subtitle={subtitle}
            />
            <div className="bg-LIGHT relative rounded-2xl pt-5 -mt-5 mb-20 max-h-full">
              {
                !step &&
                <IonSpinner className="mx-auto my-10 block" name="circular" />
              }
              {step && step.isNode && <ProtocoleQuestion step={step} />}
              {step && !step.isNode && <StepProtocole step={step} />}
            </div>
          </>
        )}
      </IonContent>
    </>
  );
}
