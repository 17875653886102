import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { IonButton, IonContent, IonHeader, IonIcon, IonModal, IonPage } from "@ionic/react";
import Input from "../components/UI/form/Input";
import { colors } from "../styles/colors";
import { sendCode, sendLogin } from "../core/store/reducers/Auth";
import { useAppDispatch, useAppSelector } from "../core/store/hooks";
import { useTranslation } from "react-i18next";

// import SystemMode from "../components/layouts/SystemMode";
import logo from "../assets/app/round-logo.svg";
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import flags from 'react-phone-number-input/flags'
import { closeOutline } from "ionicons/icons";
import en from 'react-phone-number-input/locale/en.json'
import fr from 'react-phone-number-input/locale/fr.json'
import "./Login.scss";
import trueCountryFlags from "../_helpers_/trueCountryFlags";
import { useQAContext } from "../core/context/QAContext";
import i18n from "../utils/langs";

const SelectCountries = (props: any) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredCountry, setFilteredCountry] = useState(props.options.filter((c: any) => c.label.toLowerCase() !== "international"
  ));
  const { t } = useTranslation()
  const dismiss = () => {
    modal.current?.dismiss();
    setOpen(false);
  };
  return (
    <>
      <button onClick={() => { console.log("click"); setOpen(true) }} className="invisible-input-btn">
        <props.iconComponent
          label={props.options.find((opt: any) => opt.value === props.value).label}
          country={trueCountryFlags(props.value)} />
      </button>
      <IonModal
        onDidDismiss={() => setOpen(false)}
        showBackdrop={true}
        ref={modal}
        className="input-modal"
        isOpen={open}
      >
        <IonHeader className="flex items-center p-4 justify-end">
          {/* <h2>TEST</h2> */}
          <IonIcon
            className="cursor-pointer"
            onClick={dismiss}
            color="secondary"
            size="large"
            icon={closeOutline}
          />
        </IonHeader>
        <div className="py-2 px-4">
          <Input
            className="my-3"
            placeholder={t("login.searchCountry")}
            value={search}
            onChangeText={(e: any) => {
              // console.log(e)
              // const val = e.target.value.toLowerCase();
              const val = e
              setSearch(val);
              setFilteredCountry(
                props.options.filter((c: any) =>
                  c.label.toLowerCase().includes(val) && c.label.toLowerCase() !== "international"
                )
              );
            }}
            // icon="search"
            // label="Search country"
            type="text"
          />
          <div className="countries-list overflow-auto">
            {filteredCountry?.map((country: any) => (
              <button
                className="invisible-input-btn flex items-center py-2"
                key={country.value}
                value={country.label.toLowerCase()}
                onClick={(e) => {
                  // setValue("");
                  // props.onBlur({ ...e, value });
                  setSearch(country.label);
                  props?.onChange &&
                    props?.onChange(country.value);
                  dismiss();
                }}
              >
                <props.iconComponent
                  label=""
                  // label={props.options.find((opt: any) => opt.value === country[0]).label}
                  country={trueCountryFlags(country.value)} />
                <div className="flex space-x-1 ml-2"><span className="text-xs items-center flex">(+{getCountryCallingCode(country.value || "FR")})</span>
                  <span>{country.label}</span></div>
              </button>
            ))}
          </div>
        </div>
      </IonModal>
    </>
  )
}

export default function LoginPage(props: any) {
  const dispatch = useAppDispatch();
  const { sendPhoneStatus, isLoggedIn } = useAppSelector(({ auth }) => auth)
  const [phone, setPhone] = useState<any>();
  const [token, setToken] = useState("");
  const [tokenStep, setTokenStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const styles = useStyles();
  const { APP_VERSION } = useQAContext();
  const { t } = useTranslation()
  const [lang, setLang] = useState(i18n.language.includes('fr') ? fr : en)
  i18n.on('languageChanged', (e) => {
    const newLang = e.includes('fr') ? fr : en
    if (newLang.phone === lang.phone) return
    setLang(newLang)
  })

  useEffect(() => {
    if (isLoggedIn === true) {
      props.history.push('/dashboard/opens');
    } else {
      setPhone('')
      setToken('')
      setTokenStep(false)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (sendPhoneStatus === "success") {
      setTokenStep(true)
    }
  }, [sendPhoneStatus])

  return (
    // keyboardShouldPersistTaps="handled"
    <IonPage >
      <IonContent className={styles.container + ' ion-padding'} fullscreen>
        <header className="mb-7">
          <img className="mb-4 w-24 h-24" alt="logo" src={logo} />
          <p className="text-md font-bold uppercase text-PRIMARY tracking-tight">{t("login.appName")}</p>
          <p className="text-FONT_DARK font-bold tracking-tight text-3xl">{t("login.port")}</p>
        </header>
        <div className="flex flex-col gap-3">
          <p className="font-bold text-PRIMARY text-center">{t("login.phoneNumber")}</p>
          <PhoneInput
            placeholder={t("login.phone")}
            defaultCountry="FR"
            labels={lang}
            value={phone}
            flags={flags}
            className={`${styles.phoneInput} ${props.error ? styles.phoneInputError : ''}`}
            countrySelectComponent={SelectCountries}
            onChange={setPhone} />
          {!tokenStep && (
            <IonButton
              disabled={loading}
              className="my-4"
              color="secondary"
              size="default"
              expand="full"
              onClick={() => {
                // TODO add phone verification here
                setLoading(true)
                dispatch(sendCode({ phone })).then(() => setLoading(false)).catch(() => setLoading(false))
              }}
              shape="round"
            >
              {" "}
              {t("login.next")}{" "}
            </IonButton>
          )}
          {tokenStep && (
            <>
              <Input
                value={token}
                onChangeText={setToken}
                placeholder={t("login.code")}
                type="number"
                returnKeyLabel={t("shared.send")}
                returnKeyType="done"
              />
              <IonButton
                disabled={loading}
                className="my-4"
                color="secondary"
                size="default"
                expand="full"
                shape="round"
                onClick={() => {
                  setLoading(true)
                  dispatch(sendLogin({ phone, token })).then(() => setLoading(false)).catch(() => setLoading(false))
                }}
              >
                {t("login.next")}
              </IonButton>
            </>
          )}
        </div>
        <p className="font-bold text-xs text-center text-GREY">{APP_VERSION}</p>
      </IonContent>
    </IonPage>
  );
}

const useStyles = createUseStyles({
  phoneInput: {
    height: 40,
    marginVertical: 5,
    borderWidth: 1,
    borderRadius: 7.5,
    backgroundColor: colors.CALCITE,
    borderColor: colors.LIGHT_GREY,
    borderStyle: "solid",
    padding: '0 10px ',
    shadowColor: "rgba(0, 0, 0, 0.2)",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowRadius: 19,
    elevation: 5,
  },
  phoneInputError: {
    borderColor: colors.DANGER,
  },
  container: {
    // width: 300,
    height: "100%",
    alignSelf: "stretch",
    "--padding-start": "30px",
    "--padding-end": "30px",
    "--padding-top": "50px",
    backgroundColor: "#ececf3",
    "--ion-background-color": "#ececf3"
  },
});
