import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api"
import { IAgentBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models"
import { TContractDockWalk } from "@wattsonelements/front-fdk/dist/models/Boats.models"
import { IBerth } from "@wattsonelements/front-fdk/dist/models/Ticket.models"
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models"
import { FC } from "react"
import { PlaceInBoatComponent } from "../dockwalk/PlaceInBoat.component"
import { DraftPlaceIn } from "../dockwalk/TemporarySheet"

type PlaceInBoatProtocolProps = {
  berth: IBerth
  update: (value: PlaceInValue | null) => any
}

export type PlaceInValue = {
  berth:UUID,
  contract?:UUID
  boat_name?: string
  passportEscale?: string,
  informations?: string,
  stayDuration?: number,
  type: "contract" | "draftContract"
}
export const PlaceInBoatProtocol: FC<PlaceInBoatProtocolProps> = ({ berth, update }) => {
  const onPlaceInAction = (e: any, contract: Partial<TContractDockWalk>) => {
    update({ berth:berth.id, contract: contract.id, boat_name: contract.boat?.name, type: "contract" })
  }

  const onDraftPlaceInAction = (data: DraftPlaceIn) => {
    if(data.valid) {
      update({ berth:berth.id, type:"draftContract", ...data })
    } else {
      update(null)
    }
  }

  return <PlaceInBoatComponent
    hasCreateBtn={false}
    onPlaceInAction={onPlaceInAction}
    onDraftPlaceInActionChange={onDraftPlaceInAction}
    status={REQUEST_STATUS.IDLE} berth={berth as IAgentBerth} />
}