import { IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding } from "@ionic/react";
import classNames from "classnames";
import { FC, ReactElement, ReactNode, forwardRef, useRef } from "react";
import { useAppSelector } from "../../../core/store/hooks";
import { TagsBadge } from "../TagsBadge";
import { useTranslation } from "react-i18next";
import { calendar } from "ionicons/icons";
import dayjs from "dayjs";
import { durationDisplay, getIsYesterdayOrTomorrowOrToday } from "../../../_helpers_/datatime.helpers";
import { IDockwalkBerth } from "@wattsonelements/front-fdk/dist/models/Dockwalk.models";

type BerthWithBoatItemProps = {
  selected?: boolean;
  reverse?: boolean,
  icon: string
  name?: string | null
  type?: string | null
  boat?: string | null,
  owner?: string | null
  category?: string | null
  departurePlannedAt?: string | null,
  departureDetectedAt?: string | null
  paidStatus?: { color: string, background_color: string, label: string } | null
  boatMarkedAsGone?: boolean
  [key: string]: any;
}
export const BerthWithBoatItem: FC<BerthWithBoatItemProps> = ({ type, selected, name, reverse, icon, category, paidStatus, boat, boatMarkedAsGone, owner, departureDetectedAt, departurePlannedAt, ...props }) => {
  // const port = useAppSelector(state => state.ports.current);
  const { t } = useTranslation()
  const durationBeforeDeparture = departurePlannedAt ? durationDisplay(dayjs(), dayjs(departurePlannedAt)) : ""
  const departureDetectedFormatter = () => {
    const date = dayjs(departureDetectedAt)
    const day = getIsYesterdayOrTomorrowOrToday(date)
    if (date.hour() === 0 && date.minute() === 0) return date.format(t("dateFormat.daymonth"))
    if (day) return t(day) + date.format(t("dateFormat.hours"))
    return date.format(t("dateFormat.dateHour"))
  }
  return <button {...props}
    className={classNames(
      "w-full text-left rounded-2xl flex gap-3 overflow-hidden bg-white min-h-[110px]",
      {
        "flex-row-reverse rounded-tl-md": reverse,
        "rounded-tr-md ": !reverse,
        "shadow-md outline outline-2  outline-LIGHT_GREY": selected
      })}>
    <div className="self-stretch flex flex-col gap-1 justify-center items-center bg-GREY p-2 min-w-[100px]">
      <span className="text-white text-lg font-bold">{name}</span>
      <img className="max-w-[60px] min-h-[60px]" src={icon} alt="berth icon" />
      <span className="text-white text-center text-xs font-semibold">
        {type}
      </span>
    </div>
    <div className="self-stretch w-full flex flex-col gap-2">
      {/* BADGES LINE */}
      <div className={
        classNames("flex gap-2 w-full ", {
          "justify-end": !reverse,
        })
      }>

        {!!paidStatus ? <TagsBadge
          color={paidStatus.color}
          backgroundColor={paidStatus.background_color}
          label={paidStatus.label}
        /> : <TagsBadge
          color=""
          backgroundColor=""
          label="?"
          className="invisible"
        />
        }
        {
          category && <TagsBadge uppercase={false} color="grey" label={category} />
        }

      </div>
      <div className={classNames("flex flex-col leading-3", {
        "mr-2": !reverse,
        "ml-4": reverse
      })}>
        <span className="font-bold text-xl">{boat}</span>
        <span className="mb-2 leading-4">
          {owner}
        </span>


        <span className="text-GREY text-sm flex items-center gap-2 leading-4">
          <IonIcon className="text-GREY" icon={calendar} /> {t("dockwalk.berth.departurePlanned")} {durationBeforeDeparture ? durationBeforeDeparture : t("dockwalk.berth.unknown")}
        </span>

        {
          departureDetectedAt && <span className="text-WARN text-sm flex items-center gap-2 leading-4">
            <IonIcon className="text-WARN" icon={calendar} /> {t("dockwalk.berth.departureDetected")} {

              departureDetectedFormatter()
            }
          </span>
        }
      </div>
      {
        boatMarkedAsGone && <span className={classNames("rounded-full  px-3 text-GREY text-xs border border-GREY", {
          "self-end mx-2": !reverse,
          "self-start mx-6": reverse
        })}>{t("dockwalk.berth.gone")}</span>
      }
    </div>

  </button>
}

type AdaptaterBerthWithBoatItemProps = {
  berth: IDockwalkBerth
  [key: string]: any;
}
export const AdaptaterBerthWithBoatItem: FC<AdaptaterBerthWithBoatItemProps> = forwardRef(({ berth, ...props }, ref) => {
  return <BerthWithBoatItem
    ref={ref}
    {...props}
    name={berth.name}
    icon={berth.boat?.boat_type_icon?.light || ""}
    type={berth.contract_type?.label}
    paidStatus={berth.paid_status}
    boat={berth.boat?.name}
    owner={berth.boater ? (berth.boater.first_name + ' ' + berth.boater.last_name) : ""}
    departurePlannedAt={berth.next_disponibility}
    departureDetectedAt={berth.sensor_empty_since}
    boatMarkedAsGone={(berth as any).is_marked_boat_missing}
  />
})

type BerthWithBoatItemAndSlidingActionsProps = {
  children?: ReactNode,
  onLeaveAction?: (item: HTMLIonItemSlidingElement) => any,
  onReplaceAction?: (item: HTMLIonItemSlidingElement) => any,
}
export const BerthWithBoatItemAndSlidingActions: FC<BerthWithBoatItemAndSlidingActionsProps> = ({ children, onLeaveAction, onReplaceAction }) => {
  const { t } = useTranslation()
  const slidingItem = useRef<HTMLIonItemSlidingElement>(null)
  return <IonItemSliding ref={slidingItem}>
    {
      !!onReplaceAction && <IonItemOptions
        onIonSwipe={e => {
          if (!slidingItem.current) return
          onReplaceAction(slidingItem.current)
        }}
        side="start"
      >
        <IonItemOption
          onClick={e => {
            if (!slidingItem.current) return
            onReplaceAction(slidingItem.current)
          }}
          expandable
          className="rounded-l-2xl"
          color="secondary"
        >
          <div className="p-4 text-white font-bold">
            {t('dockwalk.dockwalk.actions.replaceBoat')}
          </div>
        </IonItemOption>
      </IonItemOptions>
    }


    <IonItem
      style={{ "--inner-padding-end": "0", "--padding-start": "0", "--padding-end": "0", "--background": "transparent" }}
      lines="none"
    >
      {children}
    </IonItem>
    {
      !!onLeaveAction &&
      <IonItemOptions
        onIonSwipe={e => {
          if (!slidingItem.current) return
          onLeaveAction(slidingItem.current)
        }}
        side="end"
      >
        <IonItemOption
          onClick={e => {
            if (!slidingItem.current) return
            onLeaveAction(slidingItem.current)
          }}
          expandable
          className="rounded-r-2xl"
          color="secondary"
        >
          <div className="p-4 text-white font-bold">
            {t('dockwalk.dockwalk.actions.boatLeft')}
          </div>
        </IonItemOption>
      </IonItemOptions>
    }

  </IonItemSliding>
}