import { useTranslation } from "react-i18next"
import FieldItem from "../../UI/form/FieldItem"
import { FC, useEffect, useState } from "react"
import AddBtn from "../../UI/buttons/AddBtn"
import { timerOutline } from "ionicons/icons"
import ModalEdit from "../../UI/ModalEdit"
import { IonButton, IonIcon } from "@ionic/react"
import Input from "../../UI/form/Input"
import { TagsBadge } from "../../UI/TagsBadge"

type TimeSpentProps = {
  update: (value: string) => any
  value: string // format 00:00:00
}
export const TimeSpent: FC<TimeSpentProps> = ({ value, update }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false);
  const [hour, setHour] = useState<number>()
  const [minutes, setMinutes] = useState<number>()
  const [time, setTime] = useState('')
  const [duration, setDuration] = useState('')

  useEffect(() => {
    if (!time) {
      reset(value)
    }
  }, [value])

  const reset = (val: string | null | undefined) => {
    console.log("reset TIME SPENT value", val);

    if (val) {
      if (val.includes(' ')) {
        let [days, time] = val.split(' ')
        let [h, m, s] = time.split(':')
        setHour(parseInt(h) + parseInt(days) * 24)
        setMinutes(parseInt(m))
        setTime(`${days}${t('shared.dateFormat.abbrday')} ${h}:${m} `)
        setDuration(`${parseInt(h) + parseInt(days) * 24}h${m}`)
      } else {
        let [ht, m, s] = val.split(':')
        let h = ht
        if (parseInt(h) > 24) {
          const days = Math.floor(parseInt(h) / 24)
          console.log(days, parseInt(h), parseInt(h) - days * 24);

          h = (parseInt(h) - days * 24) + ''
          setTime(`${days}${t('shared.dateFormat.abbrday')} ${h.padStart(2, '0')}:${m}`)
          setDuration(`${ht}h${m}`)
        } else {
          setTime(`${h.padStart(2, '0')}:${m}`)
          setDuration('')
        }
        setHour(parseInt(ht))
        setMinutes(parseInt(m))
      }
    }
  }
  const save = () => {
    console.log("save", hour && hour < 10 ? hour?.toString().padStart(2, '0') : hour, minutes?.toString().padStart(2, '0'));
    const duration = (hour && hour >= 0 ? (hour < 10 ? hour?.toString().padStart(2, '0') : '' + hour) : '00')
      + ':' + (minutes && minutes >= 0 ? minutes.toString().padStart(2, '0') : '00')
      + ":00"
    console.log(duration);
    reset(duration)
    update(duration)
  }

  return (
    <FieldItem className="flex-1" onClick={() => {
      console.log("click ???")
      setOpen(true)
    }}
      field={{ title: t('ticket.formFields.timeSpent') }}>
      <ModalEdit
        save={(isSave: boolean) => {
          if (!isSave) {
            reset(value)
            return
          }
          save()
        }}
        title={t('ticket.formFields.timeSpent')}
        open={open}
        setOpen={setOpen}
        showBackdrop={true}
        style={{
          "--box-shadow": "0 28px 48px rgba(0, 0, 0, 0.4)",
          "--backdrop-opacity": "var(--ion-backdrop-opacity, 0.32)",
          "--height": "300px",
          "--border-radius": "20px",
          "--width": "95%",

        }}
      >
        {value &&
          <span className="mb-4 flex gap-2 items-center justify-center">
            <span className="font-bold">{t("ticket.formFields.timeAlreadySpent")} : </span>
            <TagsBadge size="large" label={`${time} ${duration && `(${duration})`}`}></TagsBadge>
          </span>}
        <div className="flex gap-3 items-center mx-5">
          <Input
            className="h-[75px] min-w-[75px] text-center flex-1"
            style={{
              "--padding-start": 0,
              "--padding-end": 0,
              fontSize: "2em"
            }}
            value={hour}
            placeholder={t("shared.hours")}
            size="large"
            onChangeText={(val: string) => { setHour(parseInt(val)) }}
            step={1}
            min={0}
            type="number" />
          <span className="text-2xl font-bold">h</span>
          <Input
            className="h-[75px] min-w-[75px] text-center flex-1"
            style={{
              "--padding-start": 0,
              "--padding-end": 0,
              fontSize: "2em"
            }}
            value={minutes}
            placeholder={t("shared.minutes")}
            size="large"
            step={1}
            min={0}
            max={59}
            onChangeText={(val: string) => { setMinutes(parseInt(val)) }}
            type="number" />
        </div>
        <div className="flex gap-3 mt-5 mx-5 justify-end">
          <IonButton
            fill="clear"
            color="medium"
            className="psx-1"
            onClick={() => {
              setOpen(false);
              reset(value)
            }}
          >
            <span className="text-center py-1 flex items-center justify-center">
              {t("shared.cancel")}
            </span>
          </IonButton>
          <IonButton
            shape="round"
            color="secondary"
            className="psx-1"
            onClick={() => {
              setOpen(false);
              save();
            }}
          >
            <span className=" text-white w-16 text-center py-1 flex items-center justify-center">{t("shared.ok")}</span>
          </IonButton>
        </div>
      </ModalEdit>
      {
        !value ? <AddBtn>
          <IonIcon style={{
            height: 22,
            width: 22,
            marginLeft: "5px"
          }} size="large" color="light" icon={timerOutline} />

        </AddBtn> : <span className="block my-2"> {time} </span>
      }


    </FieldItem >
  )
}