import classNames from "classnames";
import { useTranslation } from "react-i18next";

export function TabsTicket({
  setSelected,
  selected,
}: {
  setSelected: Function;
  selected: number;
}) {
  const { t } = useTranslation()
  return (
    <div className="flex bg-CALCITE rounded-md w-full border-SECONDARY border border-solid border-1">
      <button
        onClick={() => {
          setSelected(0);
        }}
        className={selected === 0 ? "p-2 bg-PRIMARY rounded-md flex-1" : "p-2 flex-1"}
      >
        <span className={selected === 0 ? "text-center text-CALCITE" : "text-center text-FONT_DARK"}>
          {t("ticket.tabs.description")}
        </span>
      </button>
      <button
        onClick={() => setSelected(1)}
        className={selected === 1 ? "bg-PRIMARY rounded-md flex-1" : "flex-1"}
      >
        <span className={selected === 1 ? "text-center text-CALCITE" : "text-center text-FONT_DARK"}>
          {t("ticket.tabs.activity")}
        </span>
      </button>
    </div>
  );
}

export function TabsMenu({
  onSelected,
  selected,
  selectKey = "id",
  tabs,
  className
}: {
  onSelected: (e: any, tab: any) => any
  selected: number | string;
  selectKey?: string
  className?: string
  tabs: {
    [key: string]: any
    label?: string
  }[]
}) {
  return (
    <div className={classNames("flex bg-CALCITE rounded-md w-full border-SECONDARY border border-solid border-1", className)}>
      {
        tabs.map((tab, i) => {
          return (<button
            key={tab[selectKey]}
            onClick={(e) => {
              onSelected(e, tab[selectKey]);
            }}
            className={selected === tab[selectKey] ? "p-2 bg-PRIMARY rounded-md flex-1" : "p-2 flex-1"}
          >
            <span className={selected === tab[selectKey] ? "text-center text-CALCITE" : "text-center text-FONT_DARK"}>
              {tab.label}
            </span>
          </button>)
        })
      }

    </div>
  );
}