import { IonButton, IonContent, IonHeader, IonIcon, IonModal } from "@ionic/react"
import BackHeader from "../../layouts/BackHeader"
import { FC, ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { closeOutline } from "ionicons/icons";

type TAUpdateModalProps = {
  children?: ReactNode,
  render?: (dimiss: HTMLIonModalElement["dismiss"]) => ReactNode
  label?: string
  iconUpdate?: ReactNode
  iconOnly?: boolean
  id?: string
}
export const TAUpdateModal: FC<TAUpdateModalProps> = ({ id = "open-update-modal", iconUpdate, iconOnly, children, label, render }) => {
  const { t } = useTranslation()
  const modalRef = useRef<HTMLIonModalElement>(null);

  return <>
    <IonButton color="secondary" fill="clear" id={id}>
      {!iconOnly && (label || t("technicalArea.detail.actions.update"))}
      {iconUpdate}
    </IonButton>
    <IonModal className="bottom-modal" trigger={id} ref={modalRef} >
      {/* <BackHeader goBack={() => { modalRef.current?.dismiss() }} title={t("technicalArea.amendment.title")}>
      </BackHeader> */}
      <IonHeader mode="md" className="z-0">
        <div className="w-full flex justify-between items-center gap-2 pt-5 pb-4 px-9">

          <div className="flex justify-center flex-col w-full">
            <p className="font-extrabold text-xl">{t("technicalArea.amendment.title")}</p>
          </div>
          <div className="flex justify-center items-center align-center text-3xl">
            <IonIcon onClick={() => modalRef.current?.dismiss()} color="secondary" style={{ fontSize: "1.4em" }} icon={closeOutline} />
          </div>
        </div>
      </IonHeader>
      <IonContent className="ion-padding">
        {children}
        {
          render && modalRef.current && !!modalRef.current.dismiss && render(() => modalRef!.current!.dismiss())
        }
      </IonContent>
    </IonModal>

  </>
}