import { IonIcon } from "@ionic/react"
import { IBooking } from "@wattsonelements/front-fdk/dist/models/Booking.models"
import classNames from "classnames"
import { boat, calendarOutline, person } from "ionicons/icons"
import { FC } from "react"
import dayjs from "dayjs"
import Avatar from "../UI/users/Avatar";
import { getDateObjectFromTime } from "../../_helpers_/datatime.helpers"
import { useTranslation } from "react-i18next";
import { TagsBadge } from "../UI/TagsBadge"


type BookingItemProps = {
  onClick?: (e: any) => any
  booking: IBooking
  selected?: boolean
}

export const BookingItem: FC<BookingItemProps> = ({ booking, selected = false, onClick = () => { } }) => {
  const { t } = useTranslation()

  return (
    <div>

      <button onClick={onClick} className={classNames("w-full text-left rounded-2xl flex gap-3 overflow-hidden bg-white", { "shadow-md outline outline-2  outline-SECONDARY": selected })}>

        <div className="self-stretch flex flex-col gap-1 justify-center items-center bg-BLUE_GREY p-2 min-w-[100px]">

          <IonIcon size="large" className="text-white my-2" icon={boat} />
          {/* <img className="max-w-[60px] min-h-[60px]" src={contract?.boat?.boat_type_icon.light} /> */}
          <div className="flex flex-col justify-center leading-3">
            <span className="leading-4 text-xl text-center text-white font-bold">
              {getDateObjectFromTime(booking.related_planning.start_time, booking.date).format(t("dateFormat.hours"))}

            </span>
            <span className="leading-4 text-center text-white text-sm font-bold">
              {getDateObjectFromTime(booking.related_planning.end_time, booking.date).format(t("dateFormat.hours"))}

            </span>

          </div>

        </div>
        <div className="self-stretch px-2 pb-2 gap-1 flex flex-col w-full">
          <div className="flex justify-end gap-2 mr-3 w-full">
            {/* tags */}
            <TagsBadge color="" className="text-BLUE_GREY" label={dayjs(booking.date).format("dddd DD")} />
            {
              booking.contract?.spot?.name && <TagsBadge color="grey" className="" label={booking.contract?.spot?.name} />
            }
          </div>
          <div className="flex flex-col leading-3">
            <span className="font-bold text-xl">{booking.boat.name}</span>
            {
              booking.boat?.registration_number && <span className="font-bold mb-2">{booking.boat?.registration_number}</span>
            }
            <span>{booking.boater?.first_name} {booking.boater?.last_name}</span>
          </div>

          {
            booking?.agent && <div className="flex items-center gap-2 mt-2">
              <Avatar size={25} src={booking?.agent?.avatar} />
              <span className="text-BLUE_GREY text-sm">
                {booking?.agent?.first_name} {booking?.agent?.last_name}
              </span>
            </div>
          }

          {/* <span className="text-BLUE_GREY text-sm">{t("dockwalk.nextDeparture")}{dayjs(contract.next_departure).format(t("dateFormat.daymonth"))}</span> */}
        </div>

      </button>
    </div>
  )
}