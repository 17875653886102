import React from "react";

interface SpacerStyle {
  marginLeft: number | string;
  marginBottom: number | string;
}

export default function Spacer({
  direction,
  vSize = 5,
  hSize = 5,
}: {
  direction: "horizontal" | "vertical";
  vSize?: string | number;
  hSize?: string | number;
}) {
  let style = {
    marginLeft: 0,
    marginBottom: 0,
  } as SpacerStyle;

  if (direction === "horizontal") {
    style.marginLeft = `${hSize}px`;
  }
  if (direction === "vertical") {
    style.marginBottom =  `${vSize}px`;
  }
  return <div style={style}></div>;
}
