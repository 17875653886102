import { useTranslation } from "react-i18next"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../../core/store/hooks"
import { FC, useEffect, useState } from "react"
import { TABooking, TABookingEdit, TABookingUpdateData, TAService, UPDATABLE_FIELD } from "../../../core/models/technicalArea.models"
import dayjs from "dayjs"
import { getDateObjectFromTime } from "../../../_helpers_/datatime.helpers"
import { getTABookingDetail, getTAServiceslist, updateTABookingDetail } from "../../../core/store/actions/TechnicalArea.actions"
import { EndDateTimeField } from "../amendment/EndDateTime.field"
import { ServicesField } from "../amendment/Services.field"
import { DateTimeField } from "../../UI/form/DateTime.field"
import { IonButton, IonSpinner } from "@ionic/react"
import { CGUComponent } from "../amendment/CGU.component"
import { updateSelectedBooking } from "../../../core/store/reducers/TechnicalArea.reducer"

type UpdateFormProps = {
  booking: TABooking,
  onDone: () => void
}
export const UpdateForm: FC<UpdateFormProps> = ({ booking, onDone }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const port = useAppSelector(state => state.ports.current)
  const form = useForm({
    defaultValues: {
      start_date: dayjs(booking.start_date),
      start_time: getDateObjectFromTime(booking.start_time),
      end_date: dayjs(booking.end_date),
      end_time: getDateObjectFromTime(booking.end_time),
      services: booking.services || [],
    } satisfies TABookingEdit
  })

  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    if (!port) return
    dispatch(getTAServiceslist(port.id))
  }, [port, dispatch])

  const { handleSubmit } = form
  const submit: SubmitHandler<TABookingEdit> = (data) => {
    console.log("SUBMIT UPDATE BOOKING", data);
    setIsSending(true)
    const services = data.services ? data.services.map((s: TAService) => s.id) : []
    const updatedBooking: TABookingUpdateData = {
      start_date: dayjs(data.start_date).format("YYYY-MM-DD"),
      start_time: dayjs(data.start_time).format("HH:mm"),
      end_date: dayjs(data.end_date).format("YYYY-MM-DD"),
      end_time: dayjs(data.end_time).format("HH:mm"),
      services
    }
    updateTABookingDetail(booking.id, updatedBooking).then(async (res) => {
      // dispatch(updateItemInList(res.data))
      dispatch(updateSelectedBooking({
        ...res.data
      }))
      // await dispatch(getTABookingDetail(booking.id))
      onDone()
    }).finally(() => {
      setIsSending(false)
    })
  }
  return <div id="update-booking">
    <form className="mb-4 flex flex-col gap-4" onSubmit={handleSubmit(submit)}>
      <FormProvider {...form}>
        <div className="grid grid-cols-2 gap-x-1 ">
          <DateTimeField
            disabled={!booking.updatable_fields.includes(UPDATABLE_FIELD.START_DATE)}
            name="start_date" label={t("technicalArea.amendment.startDate")} type="date" />
          <DateTimeField
            disabled={!booking.updatable_fields.includes(UPDATABLE_FIELD.START_TIME)}
            name="start_time" label={t("technicalArea.amendment.startTime")} type="time" />
          <DateTimeField
            disabled={!booking.updatable_fields.includes(UPDATABLE_FIELD.END_DATE)}
            name="end_date" label={t("technicalArea.amendment.leavingDate")} type="date" />
          <DateTimeField
            type='time'
            disabled={!booking.updatable_fields.includes(UPDATABLE_FIELD.END_TIME)}
            name="end_time" label={t("technicalArea.amendment.leavingTime")} />
        </div>
        <ServicesField servicesStates={booking.booking_services} />
      </FormProvider>
      <CGUComponent />
      <IonButton disabled={isSending}
        size="large"
        shape="round"
        color="secondary"
        expand="block"
        type="submit">
        {isSending ? <IonSpinner /> : t("technicalArea.amendment.create")}
      </IonButton>
    </form>
  </div>
}