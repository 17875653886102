import { createSelector } from "@reduxjs/toolkit";
import {
  getTicketsList,
  getUserID,
  getCurrentCategory,
  getFlatCategories,
  ticketsListHasCategories,
  getCategoriesFromParentId,
  getTicketsForUser,
  getBacklog,
  getTickets,
  getOpensTicketsList,
} from "./helpers";
import { RootState } from "../store";
import dayjs from "dayjs";
import i18n from "../../../utils/langs";
import { compareString } from "../../../_helpers_/sort.helpers";
import { ITicket } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IAssignee } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import {
  ITicketBoat,
  IBerth,
} from "@wattsonelements/front-fdk/dist/models/Ticket.models";
/**
 * get ticket by user and filter them by priority
 * if user is null,
 */
export const getTicketsByUser = createSelector(
  getOpensTicketsList,
  getUserID,
  (tickets: ITicket[], current: string | null) => {
    if (!current) {
      return []; // no user ? no ticket to display. Backlog wil be handled in another selector
    }
    let list = [...tickets];

    list = list.filter((ticket: ITicket) =>
      (ticket?.assignees as IAssignee[])?.find((agent) => agent.id === current)
    );
    return list;
  }
);
export const getCurrentTicketsByUser = createSelector(
  getTickets,
  getUserID,
  (tickets: ITicket[], current: string | null) => {
    if (!current) {
      return []; // no user ? no ticket to display. Backlog wil be handled in another selector
    }
    let list = [...tickets];

    list = list.filter((ticket: ITicket) =>
      (ticket?.assignees as IAssignee[])?.find((agent) => agent.id === current)
    );
    return list;
  }
);

export const getTicketsByUserAndCurrentCategory = createSelector(
  getTickets,
  getFlatCategories,
  getCurrentCategory,
  getUserID,
  (
    tickets: ITicket[],
    categories: ITicketCategory[],
    parentCategoryId: string | null,
    userId: string
  ) => {
    if (!parentCategoryId) {
      return tickets;
    }
    const listCategoriesConcerned = getCategoriesFromParentId(
      categories,
      parentCategoryId
    );
    const list = ticketsListHasCategories(tickets, [
      ...listCategoriesConcerned,
      parentCategoryId,
    ]);
    return getTicketsForUser(list, userId);
  }
);

export const getTicketsBacklogAndCurrentCategory = createSelector(
  getTickets,
  getFlatCategories,
  getCurrentCategory,
  (
    tickets: ITicket[],
    categories: ITicketCategory[],
    parentCategoryId: string | null
  ) => {
    if (!parentCategoryId) {
      return tickets;
    }
    const listCategoriesConcerned = getCategoriesFromParentId(
      categories,
      parentCategoryId
    );
    const list = ticketsListHasCategories(tickets, [
      ...listCategoriesConcerned,
      parentCategoryId,
    ]);
    return list;
  }
);

export const getTicketsByCurrentCategory = createSelector(
  getTickets,
  getFlatCategories,
  getCurrentCategory,
  (
    tickets: ITicket[],
    categories: ITicketCategory[],
    parentCategoryId: string | null
  ) => {
    if (!parentCategoryId) {
      return tickets;
    }
    const listCategoriesConcerned = getCategoriesFromParentId(
      categories,
      parentCategoryId
    );
    const list = ticketsListHasCategories(tickets, [
      ...listCategoriesConcerned,
      parentCategoryId,
    ]);
    return list;
  }
);

export const getTicketsByUserAndCategory = createSelector(
  getTickets,
  getFlatCategories,
  getUserID,
  (_: any, parentCategoryId: string) => parentCategoryId,
  (
    tickets: ITicket[],
    categories: ITicketCategory[],
    userId: string,
    parentCategoryId: string
  ) => {
    const listCategoriesConcerned = getCategoriesFromParentId(
      categories,
      parentCategoryId
    );
    const list = ticketsListHasCategories(tickets, listCategoriesConcerned);
    return getTicketsForUser(list, userId);
  }
);

export const isCurrentListLoading = createSelector(
  ({ tickets }: RootState) => tickets.listLoading,
  ({ tickets }: RootState) => tickets.firstLoading,
  ({ tickets }: RootState) => tickets.currentList,
  (listLoading, firstLoading, currentList) => {
    return firstLoading || (listLoading && currentList === "listClosed");
  }
);

export const getFilteredTicketList = createSelector(
  getTickets,
  (state: RootState) => state.tickets.filters,
  getCurrentCategory,
  getFlatCategories,
  (
    list: ITicket[],
    filters,
    parentCategoryId: string | null,
    categories: ITicketCategory[]
  ) => {
    let tempList = [...list];

    if (filters.users.length > 0) {
      tempList = getTicketsForUser(tempList, filters.users[0]);
    }

    if (parentCategoryId) {
      const listCategoriesConcerned = getCategoriesFromParentId(
        categories,
        parentCategoryId
      );
      tempList = ticketsListHasCategories(tempList, [
        ...listCategoriesConcerned,
        parentCategoryId,
      ]);
    }
    // TODO add category filtering by name

    return tempList.filter((ticket) => {
      const date = dayjs(ticket.date_created).format(
        i18n.t("dateFormat.longDate", { ns: "common" })
      );
      const str =
        (ticket?.reference || "") +
        (typeof ticket?.boat === "string" || !ticket?.boat
          ? ""
          : (ticket?.boat as ITicketBoat).name) +
        (typeof ticket?.spot === "string" || !ticket?.spot
          ? ""
          : (ticket?.spot as IBerth).name) +
        (ticket?.description || "") +
        (ticket?.title || "") +
        // tempList.map((c) => c.label).join(' ') +
        date +
        ticket.status;
      if (Object.keys(filters.status).length === 0) return [];

      return (
        compareString(str, filters.text) &&
        (filters.category !== "" ? filters.category === ticket?.category : 1) &&
        (Object.keys(filters.status).length > 0
          ? filters.status[ticket.status!]
          : 1)
      );
    });
    // .sort(function (a: Ticket, b: Ticket) {
    //   if (a.priority === TicketPriority.URGENT)
    //     return 1
    //   else return -1;
    // }).sort(function (a: Ticket, b: Ticket) {
    //   if (a.priority === TicketPriority.INTERMEDIATE)
    //     return 1
    //   else return -1;
    // });
  }
);
