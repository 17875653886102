import { FC } from "react"
import { TAAmendment, TABooking } from "../../../core/models/technicalArea.models"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { getDateObjectFromTime } from "../../../_helpers_/datatime.helpers"
import { IonButton, IonIcon } from "@ionic/react"
import { pencil } from "ionicons/icons"
import { TAUpdateModal } from "../form/TAUpdate.modal"
import { AmendmentFormEdit } from "../form/AmendmentEdit.form"

type AmendmentSummaryProps = {
  amendment: TAAmendment
  booking: TABooking
}
export const AmendmentSummary: FC<AmendmentSummaryProps> = ({ amendment, booking }) => {
  const { t } = useTranslation()
  return (<div className="border-2 border-WARN rounded-md p-5 bg-WARN_LIGHT flex gap-2 items-start justify-between">
    <div >
      <h2 className="text-lg font-bold">{t("technicalArea.amendment.titleSummary")} </h2>
      <p>{t("technicalArea.amendment.leavingDate")} : <span className="font-bold">
        {dayjs(amendment.end_date).format(t("dateFormat.shortDate"))}

      </span></p>
      {amendment.end_time &&
        <p>{t("technicalArea.amendment.leavingTime")} : <span className="font-bold">
          {amendment.end_time && getDateObjectFromTime(amendment.end_time).format(t("dateFormat.hours"))}
        </span></p>
      }
      {amendment?.services_to_add && amendment?.services_to_add.length > 0 &&
        <p>
          {t("technicalArea.amendments.servicesAdded")} : <span className="font-bold">{amendment?.services_to_add.map(s => s.name).join(', ')}</span>
        </p>
      }
      {amendment?.services_to_remove && amendment?.services_to_remove.length > 0 &&
        <p>
          {t("technicalArea.amendments.servicesRemoved")} : <span className="font-bold">{amendment?.services_to_remove.map(s => s.name).join(', ')}</span>
        </p>
      }

    </div>
    {/* <IonButton fill="clear" >
      <IonIcon color="warning" icon={pencil} slot="icon-only" ></IonIcon>

    </IonButton> */}
    <TAUpdateModal id="open-update-amendment-modal"
      iconOnly
      iconUpdate={<IonIcon color="warning" icon={pencil} slot="icon-only" ></IonIcon>}
      render={(dimiss) => <AmendmentFormEdit onDone={dimiss} amendment={amendment} booking={booking} />}
    />
  </div>)
}