import { createSelector } from "@reduxjs/toolkit";
import { User } from "../../models/User";
import { RootState } from "../store";

const getUsers = ({ users }: RootState) => users.all;

export const getLocalUserById = createSelector(
  getUsers,
  (_: any, id: string | undefined) => id,
  (users: User[], id: string | undefined) => {
    return users.find((u) => u.id === id);
  }
);
