import React, { ChangeEvent, useState } from "react";
import Btn from "../UI/buttons/Btn";
import { useAppSelector, useAppDispatch } from "../../core/store/hooks";
import { RootState } from "../../core/store/store";
import { logout } from "../../core/store/reducers/Auth";
import { debugAuth } from "../../core/store/reducers/Debug";
import { resetAll } from "../../utils/devDebug";
import { isProd } from "../../utils/env";
import { DebugProtocole } from "./DebugProtocole";
import { DebugState } from "./DebugState";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import { useQAContext } from "../../core/context/QAContext";
import { Button, Chip, TextField } from "@mui/material";
import FDK from "@wattsonelements/front-fdk";
import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import i18n from "../../utils/langs";
import { useTranslation } from "react-i18next";

export function DebugModal({ onClose }: { onClose: () => any }) {
  const usersList = [
    "devagent@wefalco.fr",
    "lila@wefalco.com",
    "agent-benoit.besnard@wefalco.fr",
    "benoit-agent@wefalco.fr",
    "keoma-agent@wefalco.fr",
    "olivier-agent@wefalco.fr",
  ];

  const { toggleQAModal, APP_VERSION, APP_ENV, API_URL, setAPIURL } = useQAContext();
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [apiUrl, setApiUrl] = useState(API_URL);

  const dispatch = useAppDispatch();
  const history = useHistory()
  const { t, i18n } = useTranslation()

  // AUTO LOG
  const [selectedUser, setSelectedUser] = useState(usersList[0]);

  const port = useAppSelector((state) => state.ports.current);
  // const route = useRoute();
  const styles = useStyles();
  if (isProd()) {
    return <></>;
  }

  const apiUrlChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setApiUrl(e.target.value)
  }

  const changeAPIURL = () => {
    if (apiUrl !== API_URL) {
      setAPIURL!(apiUrl)
      if (isLoggedIn)
        logout();
      toggleQAModal!();
      FDK.MainAPI.updateAPIURL(apiUrl);
    }
  }

  const changeLang = (event: any) => {
    console.log("changeLang", event);
    i18n.changeLanguage(event.detail.value)
    // i18n.locale = event.detail.value
    // console.log("changeLang", i18n.locale);
    // i18n.store(en)

  }

  const reset = () => {
    // local state
    setApiUrl(process.env.REACT_APP_API_URL || "")
    if (process.env.REACT_APP_API_URL !== API_URL) {
      // context state
      setAPIURL!(process.env.REACT_APP_API_URL || "")
      if (isLoggedIn)
        logout();
      toggleQAModal!();
      FDK.MainAPI.updateAPIURL(apiUrl);
    }
  }

  return (
    <div className={styles.container}>
      <h2 className="uppercase font-extrabold mb-2">Narwhal Utilities</h2>
      <div className="flex flex-wrap gap-2">
        <Chip color="info" label={`Build version: ${APP_VERSION}`} />
        <Chip color="warning" label={`Build environment: ${APP_ENV}`} />
        <Chip color="default" label={`Port: ${port?.name}`} />
      </div>
      <p className="text-xs p-0 m-0 mt-2 ">with an end backslash</p>
      <TextField
        margin="normal"
        required
        fullWidth
        onChange={apiUrlChanged}
        value={apiUrl}
        id="api_url"
        label="API_URL"
        autoFocus
      />
      <div className="flex gap-2 justify-end">
        <Button color="primary" variant="contained" onClick={changeAPIURL}>Change</Button>
        <Button color="error" variant="outlined" onClick={reset}>Reset</Button>
      </div>
      <IonItem>
        <IonLabel>{t("shared.chooseLang")}
        </IonLabel>

        <IonSelect value={i18n.language} onIonChange={changeLang}>
          <IonSelectOption value="fr">🇫🇷 FR</IonSelectOption>
          <IonSelectOption value="en">🇬🇧 EN</IonSelectOption>
        </IonSelect>
      </IonItem>
      <hr className="py-2 mt-5 border-blue-500" />
      {!isLoggedIn && (
        <>
          {/* TODO */}
          {/* <Picker
            selectedValue={selectedUser}
            onValueChange={(itemValue, itemIndex) => setSelectedUser(itemValue)}
          >
            {usersList.map((user) => {
              return <Picker.Item key={user} label={user} value={user} />;
            })}
          </Picker> */}
          <Btn
            disabled={!selectedUser}
            onClick={() => {
              dispatch(debugAuth({ selectedUser, API_URL }));
            }}
            label="Log in"
          ></Btn>
        </>
      )}
      {isLoggedIn && (
        <Btn
          label="Log out"
          onClick={() => {
            dispatch(logout());
            resetAll(dispatch);
            history.push('/login')
            onClose()
          }}
        ></Btn>
      )}
      <Btn
        label="Reset cache"
        onClick={() => {
          resetAll(dispatch);
        }}
      ></Btn>
      {/* {route.name === "Protocole" && <DebugProtocole></DebugProtocole>} */}
      <DebugState></DebugState>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    marginTop: 15,
    padding: 10,
  },
  header: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    pTransform: "uppercase",
  },
  subHeader: {
    lineHeight: 1,
    marginBottom: 15,
    pAlign: "center",
  },
});
