import { FC } from "react"
import { TagsBadge, TagsBadgeProps } from "../UI/TagsBadge"
import { TA_BOOKING_STATUS } from "../../core/models/technicalArea.models"
import { useTranslation } from "react-i18next"

interface TAStatusChipprops extends Partial<TagsBadgeProps> {
  status: TA_BOOKING_STATUS
}
const statusState: { [key: string]: any } = {
  [TA_BOOKING_STATUS.BOOKED]: {
    color: "grey",
    label: "technicalArea.status.booked"
  },
  [TA_BOOKING_STATUS.CANCELLED]: {
    color: "red",
    label: "technicalArea.status.cancelled"
  },
  [TA_BOOKING_STATUS.BOAT_IN_AREA]: {
    color: "bg-SECONDARY  text-LIGHT",
    label: "technicalArea.status.boat_in_area"
  },
  [TA_BOOKING_STATUS.TO_VALIDATE_BY_BOATER]: {
    color: "grey",
    label: "technicalArea.status.to_validate_by_boater"
  },
  [TA_BOOKING_STATUS.DONE]: {
    color: "bg-LIGHT_GREY text-PRIMARY",
    label: "technicalArea.status.done"
  },
  [TA_BOOKING_STATUS.ONGOING]: {
    color: "bg-SECONDARY text-LIGHT",
    label: "technicalArea.status.ongoing"
  },
  [TA_BOOKING_STATUS.REJECTED]: {
    color: "bg-DANGER text-LIGHT",
    label: "technicalArea.status.rejected"
  },
  [TA_BOOKING_STATUS.REQUESTED]: {
    color: "bg-SECONDARY text-LIGHT",
    label: "technicalArea.status.requested"
  },
  [TA_BOOKING_STATUS.ON_HOLD]: {
    color: "bg-DANGER text-LIGHT",
    label: "technicalArea.status.on_hold"
  }
}
export const TAStatusChip: FC<TAStatusChipprops> = ({ status, ...props }) => {
  const { t } = useTranslation()
  return <TagsBadge {...props} uppercase={false} color={status in statusState ? statusState[status as any].color : "light"} label={t(status in statusState ? statusState[status as any].label : status)} />
}