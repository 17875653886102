import { IonItem } from "@ionic/react";
import classNames from "classnames";
import { FC } from "react";
import { useAppSelector } from "../../../core/store/hooks";
import { TagsBadge } from "../TagsBadge";
import babord from "../../../assets/icons/babord.svg";
import tribord from "../../../assets/icons/tribord.svg";
import { IAgentBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { useTranslation } from "react-i18next";

type BerthItemProps = {
  berth: Partial<IAgentBerth>;
  hideBerthSize?: boolean;
  children?: any;
  size?: "small" | "medium";
  [key: string]: any;
};

export const BerthItem: FC<BerthItemProps> = ({ berth, size, hideBerthSize = false, children, ...props }) => {
  const { t } = useTranslation()
  const { current } = useAppSelector(state => state.ports);

  return (
    <IonItem style={{ "--ion-item-background": "transparent" }} {...props}>
      <div className={classNames("flex justify-between w-full py-1", { "text-sm": size === "small" })}>
        <div className="flex flex-col">
          <div className="pb-2">
            <div className="text-lg font-bold">{berth?.name}</div>
            <div className="text-sm leading-3">
              {t("shared.pontoon")} {berth?.pontoon?.name}
            </div>
          </div>

          {!hideBerthSize && (
            <span className="-mt-1 text-sm">
              {berth?.length && (
                <>
                  {berth?.length} {t(`shared.units.${current?.config.distance_unit}`)}
                </>
              )}
              {berth?.length && berth?.width && " / "}
              {berth?.length && berth?.width && (
                <>
                  {berth?.width} {t(`shared.units.${current?.config.distance_unit}`)}
                </>
              )}
            </span>
          )}
          {children}
        </div>
        <div className="flex justify-center items-center gap-1">
          <div className="mr-3">
            {berth?.catway === "babord" && <img alt="babord" src={babord} />}
            {berth?.catway === "tribord" && <img alt="triboard" src={tribord} />}
          </div>
          {berth?.tags?.map((tag: any) => (
            <TagsBadge color="grey" key={`${berth.id}-tag`} label={tag} />
          ))}
          {berth?.category?.label && <TagsBadge color="grey" uppercase={false} label={berth?.category?.label} />}
          {berth?.contract_type_letter && <TagsBadge color="grey" size="medium" uppercase={false} label={berth?.contract_type_letter} />}
        </div>
      </div>
    </IonItem>
  );
};
