import { TBerthCategory } from "@wattsonelements/front-fdk/dist/models/Berth.models"
import { FC, useEffect, useState } from "react"
import { useGetBerthsForCategoriesQuery } from "../../../core/store/api/dockwalk.api"
import { skipToken } from "@reduxjs/toolkit/query"
import { useAppSelector } from "../../../core/store/hooks"
import { IonButton, IonContent, IonModal, IonSpinner } from "@ionic/react"
import { AdaptaterBerthWithBoatItem, BerthWithBoatItem } from "../../UI/berths/BerthWithBoat.item"
import { AvailableBerthItem } from "../../UI/berths/AvailableBerth.item"
import { useTranslation } from "react-i18next"
import { PlaceInBoatModal } from "../PlaceInBoat.modal"
import { IDockwalkBerth } from "@wattsonelements/front-fdk/dist/models/Dockwalk.models"
import { BerthDetailModal } from "../BerthDetail.modal"

type PairMooringListProps = {
  category: TBerthCategory
}
export const PairMooringList: FC<PairMooringListProps> = ({ category }) => {
  const port = useAppSelector(state => state.ports.current)
  const { t } = useTranslation()
  const {
    isLoading,
    isFetching,
    data
  } = useGetBerthsForCategoriesQuery((category && port?.id) ? { categoryId: category.id, portId: port?.id } : skipToken)
  const [list, setList] = useState((data && data.results) || [])
  const [page, setPage] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [selected, setSelected] = useState<IDockwalkBerth | null>(null)

  useEffect(() => {
    if (!data) return
    if (page > 1) {
      setList([...list, ...data.results])
    } else {
      setList([...data.results])
    }

  }, [data])

  if (isLoading) return <IonSpinner className="mx-auto block" />

  return <div className="flex flex-col gap-2 pb-10">
    {
      isFetching && <IonSpinner className="mx-auto my-2 block" />
    }
    {
      list.map(item => item.is_available ? <AvailableBerthItem
        key={"available" + item.id}
        name={item.name!}
        type={item.contract_type?.label}
        arrivalDetectedAt={item.sensor_occupied_since}
        availableUntil={item.available_until}
        catway={item.catway}
        category={item.category?.label}
        hasReservation={!!item.reserved}
        onClick={() => {
          setSelected(item);
          setOpenModal(true);
        }}
      /> : <AdaptaterBerthWithBoatItem
        key={"notavailable" + item.id}
        berth={item}
        category={port && item.boat?.length ? item.boat?.length + t(`shared.units.${port?.config.distance_unit}`) : ""}
        onClick={() => {
          setSelected(item);
          setOpenModal(true);
        }} />)
    }
    {
      data?.next_page_number &&
      <IonButton onClick={() => {
        setPage(data?.next_page_number)
        // refetch({ portId: port?.id, page:data?.next_page_number })
      }} color="secondary" shape="round" fill="outline">
        {
          isFetching ? <IonSpinner color="secondary" /> : t('shared.loadmore')
        }
      </IonButton>
    }
    {
      selected && (selected.is_available ? <PlaceInBoatModal
        berth={selected} isOpen={openModal}
        close={() => {
          setOpenModal(false)
          setSelected(null)
        }}
      /> : <IonModal
        isOpen={openModal}
        onDidDismiss={() => {
          setOpenModal(false)
          setSelected(null)
        }}
        className="swipe-modal"
        initialBreakpoint={0.75}
        breakpoints={[0, .5, .75, .9]}
      >
        <IonContent><BerthDetailModal boat={selected.boat} boatName={selected.boat?.name} berthId={selected.id} /></IonContent></IonModal>)
    }
  </div>
}