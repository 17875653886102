import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useAppSelector } from '../../core/store/hooks';
import { useQAContext } from '../../core/context/QAContext';
import { versionComparison } from '../../_helpers_/versionComparison';
import { Alert, AlertTitle } from '@mui/material';
import { isPlatform } from '@ionic/react';
import "./AppVersion.scss"
import { useTranslation } from "react-i18next";

export default function AppVersion() {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false);
  const port = useAppSelector((state) => state.ports.current);
  const { APP_VERSION } = useQAContext()

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    if (port?.organization_details
      && port?.organization_details.agent_app_version
      && typeof port.organization_details.agent_app_version === "string"
      && port.organization_details.agent_app_version !== "") {
      let localVersion = APP_VERSION.replace("AG.", "");
      let backVersion = port.organization_details.agent_app_version.replace("AG.", "");
      // if (versionComparison(localVersion, backVersion) || 1) {
      if (versionComparison(localVersion, backVersion) < 0) { // good condition
        setOpen(true);
      }
    }
  }, [port])

  return (
    <Snackbar open={open} onClose={handleClose}>
      <Alert variant="filled" onClose={handleClose} severity="info" sx={{ width: '100%' }}>
        <AlertTitle sx={{ fontWeight: 'bold' }}>Info</AlertTitle>
        <a href={isPlatform('ios') ?
          port?.organization_details?.agent_ios_store_link : port?.organization_details?.agent_android_store_link} target="_blank">
          {t("shared.newVersionSentence")}
        </a>
      </Alert>
    </Snackbar>
  );
}
