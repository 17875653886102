import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import dayjs from "dayjs";

export type TBookingReportItem = {
  text: string;
  type: 'normal' | 'alert';
};

// V2
export enum TA_BOOKING_STATUS {
  REQUESTED = "requested",
  TO_VALIDATE_BY_BOATER = "to-validate-by-boater", // ?
  BOOKED = "booked",
  CANCELLED = "cancelled",
  ON_HOLD = "on-hold", // BLOCKED ? ONGOING_ISSUE ?
  REJECTED = "rejected",
  ONGOING = "ongoing",
  BOAT_IN_AREA = "boat-in-area",
  DONE = "done",
}

export enum UPDATABLE_FIELD {
  MARINA_COMMENT = "marina_comment",
  AGENT_COMMENT = "agent_comment",
  BOOKING_SERVICES = "booking_services",
  START_DATE = "start_date",
  END_DATE = "end_date",
  START_TIME = "start_time",
  END_TIME = "end_time",
  SERVICES = "services",
  AGENTS = "agents",
  STATUS = "status"
}

export type TAService = {
  id: string
  name: string,
  descritipon?: string
}
export type TABooking = {
  id: string,
  services: TAService[],
  attachments: {
    id: UUID,
    kind: "picutre" | string
    url: string
  }[]
  booking_services: TABookingServices[]
  is_leaving: boolean,
  status: TA_BOOKING_STATUS
  marina_comment: string,
  boater_comment: string,
  agent_comment: string
  closing_comment: string,
  start_date: string, // YYYY-MM-DD 
  start_time: string,
  end_date: string, // YYYY-MM-DD
  end_time: string;
  boat: {
    id: UUID,
    name: string,
    width: number,
    length: number,
    registration_number: string,
    draft: number,
    boat_type_icon: {
      dark: string
      light: string
    }
  },
  boater: {
    id: UUID,
    first_name: string,
    last_name: string,
    phone: string,
    email: string
  },
  agents: {
    id: UUID,
    first_name: string,
    last_name: string,
    avatar: string
  }[]

  formatted_logs: TBookingReportItem[];

  config: {
    can_cancel: boolean,
    can_update: boolean,
    distance_unit: string
    // status allowed to change to ?
  }
  creator: UUID
  status_changed_at: string //date
  status_changed_by: UUID
  terms_and_conditions_accepted_at: string //date
  services_done: UUID[]
  files: string[],
  updatable_fields: UPDATABLE_FIELD[]
  update_requests: Pick<TAAmendment, "id" | "status">[]
}

export type TABookingServices = {
  id: UUID,
  is_done: boolean,
  name: string,
  service_id: UUID
}

export type ServicesDonesData = {
  booking_services: {
    id: UUID,
    is_done: boolean,
  }[],
  agent_comment?: string,
  closing_comment?: string,
  boat_in_area: boolean,
  attachments?: string[] | File[] | Blob[]
}

export type TABookingEdit = {
  id?: UUID,
  services: TAService[],
  start_date?: string | dayjs.Dayjs, // YYYY-MM-DD
  start_time?: string | dayjs.Dayjs;
  end_date: string | dayjs.Dayjs, // YYYY-MM-DD
  end_time?: string | dayjs.Dayjs;
}

export type TABookingUpdateData = {
  agent_comment?: string,
  services?: UUID[],
  start_date?: string, // YYYY-MM-DD
  start_time?: string,
  end_date?: string, // YYYY-MM-DD
  end_time?: string,
}


export enum TA_AMENTDMENT_STATUS {
  TO_VALIDATE_BY_BOATER = "to-validate-by-boater",
}
export type TAAmendment = {
  id: UUID,
  created_at: string
  end_date: string
  end_time: string

  services_to_add: Pick<TAService, "name" | "id">[],
  services_to_remove: Pick<TAService, "name" | "id">[],
  status: TA_AMENTDMENT_STATUS
  status_changed_at: string
}