import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { IGenericResponse, TParams } from "@wattsonelements/front-fdk/dist/models/Misc.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { TAAmendment, ServicesDonesData, TABooking, TABookingUpdateData, TAService } from "../../models/technicalArea.models";
import { PaginatedPayload } from "../../../_helpers_/pagination.helpers";

export const getTABookingListToday = createAsyncThunk(
  'technicalArea/booking/today',
  async (params: TParams | undefined = {}, { getState }) => {
    return FDK.MainAPI.API.request<PaginatedPayload<TABooking>>({
      method: "GET",
      url: "/v2/agent/technical-areas/bookings/today",
      params
    }).then(res => res.data)
  }
)

export const getTABookingListTomorrow = createAsyncThunk(
  'technicalArea/booking/tomorrow',
  async (params: TParams | undefined = {}, { getState }) => {
    return FDK.MainAPI.API.request<PaginatedPayload<TABooking>>({
      method: "GET",
      url: "/v2/agent/technical-areas/bookings/tomorrow",
      params
    }).then(res => res.data)
  }
)

export const getTABookingListOnArea = createAsyncThunk(
  'technicalArea/booking/on-zone',
  async (params: TParams | undefined = {}, { getState }) => {
    return FDK.MainAPI.API.request<PaginatedPayload<TABooking>>({
      method: "GET",
      url: "/v2/agent/technical-areas/bookings/on-zone",
      params
    }).then(res => res.data)
  }
)

export const getTABookingDetail = createAsyncThunk(
  'technicalArea/booking/detail',
  async (id: UUID | string, { getState }) => {
    return FDK.MainAPI.API.request<TABooking>({
      method: "GET",
      url: `/v2/agent/technical-areas/bookings/${id}`
    }).then(res => res.data)
  }
)

export const getTAServiceslist = createAsyncThunk(
  'technicalArea/services',
  async (port: UUID) => {
    return FDK.MainAPI.API.request<PaginatedPayload<TAService>>({
      method: "GET",
      url: `/v2/boater/technical-areas/services`,
      params: {
        port_id: port
      }
    }).then(res => res.data.results)
  }
)


// UPDATE
export const updateTABookingDetail = async (id: UUID | string, data: Partial<TABookingUpdateData>) => {
  return FDK.MainAPI.API.request<TABooking>({
    method: "PATCH",
    url: `/v2/agent/technical-areas/bookings/${id}`,
    data
  })
}

// ACTIONS 
export const startTABooking = async (id: UUID | string) => {
  return FDK.MainAPI.API.request<TABooking>({
    method: "POST",
    url: `/v2/agent/technical-areas/bookings/${id}/action/start`
  })
}

export const postServicesAllDone = async (id: UUID | string, data: ServicesDonesData | FormData) => {
  return FDK.MainAPI.API.request<IGenericResponse>({
    method: "POST",
    url: `/v2/agent/technical-areas/bookings/${id}/action/services-done`,
    data
  })
}
export const updateBookingService = async (bookingId: UUID | string, serviceId: UUID | string, isDone: boolean) => {
  return FDK.MainAPI.API.request<{ id: UUID, is_done: boolean }>({
    method: "PATCH",
    url: `/v2/agent/technical-areas/bookings/${bookingId}/services/${serviceId}`,
    data: {
      is_done: isDone
    }
  })
}
export const setBookingToDone = async (id: UUID | string, data?: FormData) => {
  return FDK.MainAPI.API.request({
    method: "POST",
    url: `/v2/agent/technical-areas/bookings/${id}/action/done`,
    data
  })
}
export const setBookingToOnHold = async (id: UUID | string) => {
  return FDK.MainAPI.API.request({
    method: "POST",
    url: `/v2/agent/technical-areas/bookings/${id}/action/block`,

  })
}

export const unblockBooking = async (id: UUID | string) => {
  return FDK.MainAPI.API.request({
    method: "POST",
    url: `/v2/agent/technical-areas/bookings/${id}/action/unblock`,

  })
}

// AMENDMENTS (UPDATE REQUESTS)
export const getAmendments = (bookingId: UUID | string, amendmentId: UUID | string) => {
  // update-requests
  return FDK.MainAPI.API.request<TAAmendment>({
    method: "GET",
    url: `/v2/agent/technical-areas/bookings/${bookingId}/update-requests/${amendmentId}`
  })
}
