import React, { useEffect, useState } from "react";
import AddBtn from "../UI/buttons/AddBtn";
import FieldItem from "../UI/form/FieldItem";
import Textarea from "../UI/form/Textarea";
import ModalEdit from "../UI/ModalEdit";
import { useTranslation } from "react-i18next";
import icon from "../../assets/icons/ui/description.png"

export default function ProtocoleNote({
  value,
  title,
  update,
  editInPlace = false,
  row = 10
}: {
  value: string;
  title?: string;
  update: Function;
  editInPlace?: boolean,
  row?: number
}) {
  const { t } = useTranslation()
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value) {
      setText(value);
    }
  }, [value]);

  const onSave = (isSave: boolean) => {
    console.log("onSave", isSave);

    if (!isSave) {
      setText(value);
    } else {
      update(text);
    }
  };
  const field = title
    ? { icon, title }
    : t("ticket.formFields.description");

  return (
    <FieldItem
      filled={text ? true : false}
      onClick={() => {
        if (!editInPlace) {
          setOpen(!open)
        } else {
          if (!open) {
            setOpen(true)
          }
        }

      }}
      field={field}
    >
      {
        !editInPlace &&
        <>
          <ModalEdit
            onRequestClose={() => { }}
            title={t("ticket.formFields.description")}
            open={open}
            save={onSave}
            setOpen={setOpen}
          >
            <Textarea row={row} placeholder={text} value={text} setValue={setText} />
          </ModalEdit>
          {text !== "" && <p>{text}</p>}</>
      }
      {
        editInPlace && (open || text) &&
        <Textarea row={row} placeholder={text} value={text}
          setValue={(text: string) => {
            update(text)
            setText(text)
          }} />

      }
      {(!text && !open) && (
        <AddBtn
          onClick={() => {
            if (!editInPlace) {
              setOpen(!open)
            } else {
              if (!open) {
                setOpen(true)
              }
            }

          }}
          icon={icon}
        />
      )}
    </FieldItem>
  );
}
