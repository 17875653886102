import { IonTextarea } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
import Input from "./Input";

export default function Textarea(props: any) {
  const { value, setValue, placeholder, row = 10 } = props;
  const inputRef = useRef<any | null>(null);
  const styles = useStyles()
  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  return (
    <IonTextarea
      ref={inputRef}
      className={`${styles.input}`}
      placeholder={placeholder}
      rows={row}
      value={value}
      autofocus
      onBlur={(e) => setValue(e.target.value)}
    ></IonTextarea>
  );
}

const useStyles = createUseStyles({
  input: {
    width: "100%",
    padding: "0 10px",
    // backgroundColor: colors.LIGHT,
    borderRadius: 4,
    borderWidth: 2,
    marginTop: 0,
    borderColor: colors.LIGHT,
    "--padding-top": 0
  },
});
