import { IonButton, IonContent, IonIcon, IonModal, IonTextarea, useIonLoading } from "@ionic/react"
import { useAppDispatch, useAppSelector } from "../../core/store/hooks"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ServicesList } from "./ServicesList"
import { GenericImagePicker } from "../UI/images/GenericImagepicker"
import { ServicesDonesData, TAAmendment, TA_BOOKING_STATUS } from "../../core/models/technicalArea.models"
import { getTABookingDetail, postServicesAllDone, setBookingToOnHold } from "../../core/store/actions/TechnicalArea.actions"
import { IGenericResponse } from "@wattsonelements/front-fdk/dist/models/Misc.models"
import { ActionStatus } from "../UI/ActionStatus"
import { REQUEST_STATUS } from "@wattsonelements/front-fdk/dist/api"
import { changeDetailStatus } from "../../core/store/reducers/TechnicalArea.reducer"
import FieldItem from "../UI/form/FieldItem"
import AddBtn from "../UI/buttons/AddBtn"
import iconComment from "../../assets/icons/ui/description.png"
import { useResultToast } from "../../_helpers_/useResultToast"
import { warning } from "ionicons/icons"


enum STEP_VALIDATION_SERVICES {
  SERVICES,
  REPORT,
  IS_BOAT_IN_WATER,
  END
}
type TAOngoingActionProps = {
  isUpdating: boolean,
  amendment?: TAAmendment | null
}
export const TAOngoingAction: FC<TAOngoingActionProps> = ({ isUpdating, amendment }) => {
  const booking = useAppSelector(state => state.technicalArea.detail)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [present] = useResultToast();
  const [presentLoader, dismissLoader] = useIonLoading();

  const [isOpen, setIsOpen] = useState(false)
  const [step, setStep] = useState(STEP_VALIDATION_SERVICES.SERVICES)
  const [services, setServices] = useState(booking?.booking_services || [])
  const [boatIsGone, setBoatIsGone] = useState<boolean | null>(null)
  const [comment, setComment] = useState("")
  const [showComment, setShowComment] = useState(!!booking?.closing_comment)
  const [files, setFiles] = useState<File[] | Blob[]>([])
  const [response, setResponse] = useState<IGenericResponse>()

  const [isSaving, setIsSaving] = useState(false)
  const [saveStatus, setSaveStatus] = useState<number>(REQUEST_STATUS.IDLE)

  useEffect(() => {
    if (booking) {
      setServices(booking?.booking_services)
      if (comment) return
      console.log("set comment in use effect");
      setComment(previous => previous || booking.closing_comment)
    }
  }, [booking, comment])

  const save = () => {
    if (!booking) return
    setIsSaving(true)
    setSaveStatus(REQUEST_STATUS.PENDING)
    const data: Partial<ServicesDonesData> = {
      booking_services: services,
      attachments: files,
      boat_in_area: !boatIsGone,
      closing_comment: comment
    }
    const getFormData = (object: any) => Object.keys(object).reduce((formData, key: string) => {
      if (Array.isArray(object[key])) {
        for (var i = 0; i < object[key].length; i++) {
          if ((object[key][i] instanceof File) ||
            (object[key][i] instanceof Blob)) {
            formData.append(`${key}`, object[key][i]);
          } else if (typeof object[key][i] === "object") {
            formData.append(`${key}`, JSON.stringify(object[key][i]));
          }
        }
        return formData;
      }
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    const formData = getFormData(data)

    postServicesAllDone(booking.id, formData).then(async (res) => {
      setResponse(res.data)
      setSaveStatus(REQUEST_STATUS.SUCCESS)
      await dispatch(getTABookingDetail(booking.id))
    }, err => {
      console.log(err);
      setSaveStatus(REQUEST_STATUS.ERROR)
    }).finally(() => {
      setIsSaving(false)
    })
  }

  return <div className="flex flex-col gap-2">
    <IonButton disabled={isUpdating} onClick={() => setIsOpen(true)} size="large" color="secondary" shape="round" expand="full">
      {t("technicalArea.detail.actions.services.cta")}
    </IonButton>
    <IonButton
      disabled={isUpdating}
      onClick={() => {
        if (!booking) return
        presentLoader()
        setBookingToOnHold(booking.id)
          .then((async res => {
            dispatch(changeDetailStatus(TA_BOOKING_STATUS.ON_HOLD))
            present({
              message: t("technicalArea.detail.actions.blockSuccess")
            }, "success")
            await dispatch(getTABookingDetail(booking.id))

          }), err => {
            present({
              message: err.data.message || t("technicalArea.detail.actions.blockErreur")
            }, "error")
          }
          )
          .finally(() => {
            dismissLoader()
          })

      }} fill="outline" color="secondary" shape="round" expand="full">
      {t("technicalArea.detail.actions.trouble")}
    </IonButton>
    <IonModal
      isOpen={isOpen}
      handle
      onDidDismiss={() => {
        setIsOpen(false)
        setStep(STEP_VALIDATION_SERVICES.SERVICES)
      }}
      className="bottom-modal"
    // initialBreakpoint={0.75}
    // breakpoints={[0, .5, .75, 1]}
    >
      <IonContent>
        <div className="p-6">
          {
            step === STEP_VALIDATION_SERVICES.SERVICES && booking &&
            <div>
              {amendment && <div className="border-2 flex gap-4 items-center border-WARN rounded-md px-5 py-2 mb-4  bg-WARN_LIGHT ">
                <IonIcon color="warning" className="text-[60px]" icon={warning}></IonIcon>
                <p className="leading-4 text-sm ">{t("technicalArea.detail.actions.services.stepService.hasPendingAmendment")}</p>
              </div>}
              <h2 className="text-2xl font-bold mb-2">
                {t("technicalArea.detail.actions.services.stepServices.title")}
              </h2>
              <p className="text-sm italic">
                *{t("technicalArea.detail.actions.services.all.info")}
              </p>
              {/* <IonCheckbox style={{
                "--border-color": "var(--ion-color-secondary)"
              }} color="secondary" /> */}
              <div className="flex flex-col gap-8 mt-8">
                <div>
                  <h3 className="font-bold text-base">{t('technicalArea.detail.actions.services.stepServices.servicesList')}</h3>
                  <ServicesList
                    startDate={booking.start_date}
                    startTime={booking.start_time}
                    endDate={booking.end_date}
                    endTime={booking.end_time}
                    canEdit
                    showCheckbox
                    showStatus={false}
                    services={services}
                    onChange={(service, checked) => {
                      let tmpService = [...services]
                      let serviceToUpdateIndex = tmpService.findIndex(s => s.id === service.id)
                      if (serviceToUpdateIndex < 0) {
                        throw Error("serviceToUpdate not found in list")
                      }
                      tmpService[serviceToUpdateIndex] = {
                        ...tmpService[serviceToUpdateIndex],
                        is_done: checked
                      }
                      setServices(tmpService)
                    }}
                  />

                </div>
                <IonButton
                  expand="full"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    setStep(STEP_VALIDATION_SERVICES.REPORT)
                  }}
                  shape="round">{t("technicalArea.detail.actions.services.all.next")}</IonButton>

              </div>
            </div>
          }
          {
            step === STEP_VALIDATION_SERVICES.REPORT &&
            <div>
              <h2 className="text-2xl font-bold mb-2">
                {t("technicalArea.detail.actions.services.stepReport.title")}
              </h2>
              <p className="text-sm italic">
                {t("technicalArea.detail.actions.services.all.info")}
              </p>
              <div className="flex flex-col gap-8 mt-8">
                <GenericImagePicker onChange={(attachments) => {
                  setFiles(attachments)
                }} />
                {
                  booking && <FieldItem
                    filled={showComment ? true : false}
                    field={{ title: t("technicalArea.detail.actions.services.comment") }}
                  >
                    {showComment &&
                      <IonTextarea
                        rows={4}
                        value={comment}
                        placeholder={t('technicalArea.detail.commentPlaceholder')}
                        autofocus
                        onIonChange={(e) => {
                          setComment(e.target.value || "")
                        }}
                      ></IonTextarea>
                    }

                    {(!comment && !showComment) && (
                      <AddBtn
                        onClick={() => {
                          setShowComment(true)
                        }}
                        icon={iconComment}
                      />
                    )}
                  </FieldItem>
                }

                <IonButton
                  expand="full"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    setStep(STEP_VALIDATION_SERVICES.IS_BOAT_IN_WATER)
                  }}
                  shape="round">{t("technicalArea.detail.actions.services.all.next")}</IonButton>

              </div>

            </div>
          }
          {
            step === STEP_VALIDATION_SERVICES.IS_BOAT_IN_WATER &&
            <div>
              <h2 className="text-2xl font-bold mb-2">
                {t("technicalArea.detail.actions.services.stepBoat.title")}
              </h2>
              <p className="text-sm italic">
                *{t("technicalArea.detail.actions.services.all.info")}
              </p>
              <div className="flex flex-col gap-8 mt-8">
                <div className="flex flex-col gap-2">
                  <IonButton
                    expand="block"
                    color={boatIsGone === true ? "secondary" : "dark"}
                    fill={boatIsGone === true ? "outline" : "clear"}
                    style={{
                      "--color": "black",
                      '--box-shadow': "0px 4px 20px 0px rgba(0, 0, 0, 0.12)",
                    }}

                    onClick={() => {
                      setBoatIsGone(true)
                    }}
                  >
                    {t("technicalArea.detail.actions.boat.yes")}
                  </IonButton>
                  <IonButton
                    expand="block"
                    color={boatIsGone === false ? "secondary" : "dark"}
                    fill={boatIsGone === false ? "outline" : "clear"}
                    style={{
                      "--color": "black",
                      '--box-shadow': "0px 4px 20px 0px rgba(0, 0, 0, 0.12) ",
                    }}
                    onClick={() => {
                      setBoatIsGone(false)
                    }}>
                    {t("technicalArea.detail.actions.boat.no")}
                  </IonButton>
                </div>
                <IonButton
                  expand="full"
                  color="secondary"
                  disabled={boatIsGone === null}
                  size="large"
                  onClick={() => {
                    save()
                    setStep(STEP_VALIDATION_SERVICES.END)
                  }}
                  shape="round">{t("technicalArea.detail.actions.services.all.save")}</IonButton>
              </div>
            </div>
          }
          {
            step === STEP_VALIDATION_SERVICES.END &&
            <div>

              <ActionStatus
                className="mt-5"
                title={response?.title}
                message={response?.message || ""}
                sub_message={response?.sub_message || ""}
                state={saveStatus}
              />


            </div>
          }

        </div>
      </IonContent>
    </IonModal>
  </div>
}