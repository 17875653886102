import { IonIcon } from "@ionic/react"
import { call, mail } from "ionicons/icons"
import { useTranslation } from "react-i18next";
import { FC } from "react"

type UserContactInfoProps = {
  email?: string
  phone?: string
}
export const UserContactInfo: FC<UserContactInfoProps> = ({ email, phone }) => {
  const { t } = useTranslation()
  return (
    <div className="my-5 flex border-t border-b border-LIGHT_GREY  w-full">
      {email && <a className="flex justify-center gap-2 items-center flex-1 text-sm font-bold uppercase text-SECONDARY py-3 border-r border-LIGHT_GREY"
        href={`mailto:${email}`}>
        <IonIcon color="secondary" icon={mail} /> {t("shared.email")}
      </a>}
      {phone && <a className="flex justify-center gap-2 items-center flex-1 text-sm font-bold uppercase text-SECONDARY py-3"
        href={`tel:${phone}`} >
        <IonIcon color="secondary" icon={call} /> {t("shared.phone")}
      </a>}
    </div>
  )
}