import { Autocomplete, Chip, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { useAppSelector } from "../../../core/store/hooks";
import { TABookingServices, TAService } from "../../../core/models/technicalArea.models";
import { checkmark, checkmarkCircle, checkmarkCircleOutline, closeCircle } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

type ServicesFieldProps = {
  disabled?: boolean
  servicesStates: TABookingServices[]
  getOptionLabel?: (item: any) => string
}
export const ServicesField: FC<ServicesFieldProps> = ({ servicesStates, disabled, getOptionLabel = (item) => item.name }) => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  const { services } = useAppSelector(state => state.technicalArea)

  return (
    <>
      {
        services && services.length > 0 &&
        <Controller
          control={control}
          name="services"
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              disablePortal
              disabled={disabled}
              isOptionEqualToValue={(option, value) => {
                return value.id === option.id
              }}
              id="services-selection"
              options={services}
              multiple
              onChange={((_, values) => {
                console.log("services", values);
                field.onChange(values)
              })}
              value={field.value}
              onBlur={field.onBlur}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={(option) => {
                const isDone = !!servicesStates.find(s => s.service_id === option.id && s.is_done)
                return isDone
              }}
              renderInput={(params) => <TextField
                {...params}
                label={t("technicalArea.amendment.service")} />}
              renderTags={(values: TAService[], getTagProps, ownerState) => {
                console.log(values, ownerState);

                return <div className="flex flex-wrap gap-1">
                  {
                    values.map((value, index) => {
                      const isDone = !!servicesStates.find(s => s.service_id === value.id && s.is_done)
                      return <Chip
                        // icon={isDone ? <IonIcon className="text-lg" color="success" icon={checkmarkCircle} /> : <></>}
                        {...getTagProps({ index })}
                        deleteIcon={isDone ? <IonIcon className="text-lg" color="success" icon={checkmarkCircle} /> : <IonIcon color="dark" className="text-lg" icon={closeCircle} />}
                        disabled={isDone}
                        label={value.name} />
                    })
                  }
                </div>
              }}
            />
          )}
        />
      }

    </>
  )

}