import React, { useEffect, useState } from "react";
import AddBtn from "../../UI/buttons/AddBtn";
import FieldItem from "../../UI/form/FieldItem";
import Textarea from "../../UI/form/Textarea";
import ModalEdit from "../../UI/ModalEdit";
import icon from "../../../assets/icons/ui/description.png"
import { useTranslation } from "react-i18next";

export default function TicketDescription({
  value,
  title,
  update,
}: {
  value: string;
  title?: string;
  update: Function;
}) {
  const { t } = useTranslation()
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value) {
      setText(value);
    }
  }, [value]);

  const onSave = (isSave: boolean) => {
    if (!isSave) {
      setText(value);
    } else {
      update({ description: text });
    }
  };
  const field = title
    ? { icon, title }
    : { icon, title: t("ticket.formFields.description") };

  return (
    <FieldItem
      filled={text ? true : false}
      onClick={() => setOpen(!open)}
      field={field}
    >
      <>

        <ModalEdit
          onRequestClose={() => { }}
          title={t("ticket.formFields.description")}
          open={open}
          save={onSave}
          setOpen={setOpen}
        >
          <Textarea placeholder={text} value={text} setValue={setText} />
        </ModalEdit>
        {text !== "" && <span>{text}</span>}
        {!text && (
          <AddBtn
            onClick={() => setOpen(!open)}
            icon={icon}
          />
        )}
      </>
    </FieldItem>
  );
}
