import { IonSpinner, ToastOptions, useIonToast } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";
import classNames from "classnames";
import { alertCircle, checkmarkCircle, closeOutline, informationCircle, syncOutline } from "ionicons/icons";


export const useResultToast: (isOverBottomMenu?: boolean) => [
  (options: ToastOptions & HookOverlayOptions, status?: "error" | "success" | "saving") => Promise<void>,
  () => Promise<void>
] = (isOverBottomMenu) => {
  const [presentToaster, dismiss] = useIonToast();
  const defaultByStatus = {
    "error": {
      icon: alertCircle,
      color: "danger"
    },
    "success": {
      icon: checkmarkCircle,
      color: "secondary"
    },
    "saving": {
      icon: syncOutline,
      color: "secondary"
    },
    "default": {
      icon: informationCircle,
      color: "secondary"
    }
  }

  const present = (options: ToastOptions & HookOverlayOptions, status?: "error" | "success" | "saving") => {
    const statusOptions = status && status in defaultByStatus ? defaultByStatus[status] : defaultByStatus.default
    return presentToaster({
      icon: statusOptions.icon,
      color: statusOptions.color,
      duration: 30000,
      ...options,
      cssClass: classNames("save-toast", options?.cssClass || "", {
        "toast-over-bottom-menu": isOverBottomMenu
      }),
      position: "bottom",
      buttons: status === "saving" ? [] : [{ icon: closeOutline, role: 'cancel' }]
    })
  }

  return [present, dismiss]
}