import { useTranslation } from "react-i18next"
import FieldItem from "../../UI/form/FieldItem"
import icon from "../../../assets/icons/ui/spot.png"
import AddBtn from "../../UI/buttons/AddBtn"
import FDK from "@wattsonelements/front-fdk"
import { useAppSelector } from "../../../core/store/hooks"
import { FC, useEffect, useState } from "react"
import { IPortArea } from "@wattsonelements/front-fdk/dist/models/Port.models"
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models"
import ModalEdit from "../../UI/ModalEdit"
import SelectList from "../../UI/form/SelectList"

type AreaSelectProps = {
  value: { id: UUID, name: string };
  update: Function;
}

export const AreaSelect: FC<AreaSelectProps> = ({ value, update }) => {
  const { t } = useTranslation()
  const port = useAppSelector(state => state.ports.current)
  const [areasList, setAreasList] = useState<IPortArea[]>([])
  const [area, setArea] = useState<Partial<IPortArea> | null | undefined>(value)
  const [open, setOpen] = useState(false);


  useEffect(() => {
    if (port) {
      FDK.Agent.PortAPI.getAreas(port.id).then((res) => {
        setAreasList(res.data.results)
        resetArea()
      }, err => {

      })
    }
  }, [port, value])

  const resetArea = () => {
    if (!value?.name) {
      const tmp = areasList.find(a => a.id === value?.id)
      if (tmp) {
        setArea(tmp)
      }
    } else {
      setArea(value)
    }
  }

  // TODO GET AREAS LIST
  return (
    <FieldItem className="flex-1" onClick={() => {
      console.log("click ???")
      setOpen(true)
    }}
      field={{ title: t("ticket.formFields.area"), icon }}>
      <ModalEdit
        save={(isSave: boolean) => {
          if (isSave) {
            update(area)
          } else {
            resetArea()
          }

        }}
        title={t("ticket.formFields.area")}
        open={open}
        setOpen={setOpen}
      >

        <SelectList
          isSelected={(item: IPortArea) => {
            return item.id === area?.id
          }}
          transformText={(item: IPortArea) =>
            `${item.name}`
          }
          data={areasList}
          setdata={(area: IPortArea, add: boolean) => {
            setArea(add ? area : null)
          }}
        >

        </SelectList>
        { }
      </ModalEdit>
      {
        !area ? <AddBtn
          icon={icon}
        /> : <span className="block my-2"> {area?.name}</span>
      }

    </FieldItem>
  )
}