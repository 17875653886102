import { Geometry } from "./Geometry";
import { Intervention } from "./Intervention";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

// status = {
//     "closed": "fermé",
//     "open": "ouvert",
//     "ongoing": "attribué",
//     "blocked": "bloqué",
//     "archived": "archivé",
// }
export enum TicketStatus {
  OPEN = "open",
  CLOSE = "closed",
  ONGOING = "ongoing",
  BLOCKED = "blocked",
  ARCHIVED = "archived",
}
export enum TicketPriority {
  URGENT = "urgent",
  INTERMEDIATE = "intermediate",
  NORMAL = "normal",
}

/**
 * Short Boat
 */
interface Boat {
  id: string;
  name: string;
  spot_id?: string;
}
/**
 * Short Plug
 */
interface Plug {
  id: string;
  name: string;
}

/**
 * Short spot
 */
interface Spot {
  id: string;
  name: string;
  pontoon_name: string;
  boat_expected?: Boat;
}

export interface ShortUser {
  id: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  avatar?: string;
  phone?: string;
}

export interface Assignee {
  id: string;
  agent?: string;
  Intervention?: string;
}

export interface TicketMessage {
  id?: string;
  date_created?: string;
  answer_required?: boolean;
  message_from?: string;
  message_to?: string;
  ticket?: string;
  ticket_message: string;
  system_message?: boolean;
  files?: string[];
}
export interface Ticket {
  id: string;
  reference?: string;
  date_created: Date | string;
  title?: string;
  description: string;
  category: string;
  localization?: Geometry;
  status?: TicketStatus;
  priority: TicketPriority;
  port: string;
  boat?: Boat;
  spot?: Spot;
  plug?: Plug;
  creator?: ShortUser;
  boater?: ShortUser;
  ticket_intervention: Intervention[];
  ticket_message: TicketMessage[];
}

export enum TICKET_FIELDS {
  BOAT = "boat",
  SPOT = "spot",
  AREA = "area",
  EVENT_DATE = "event_date",
  DEADLINE = "deadline",
  TIME_SPENT = "time_spent",
  KIND = "kind"
}

export type TicketConfig = {
  port_id: UUID,
  ticket_displayed_fields: string | TICKET_FIELDS
  ticket_kinds: {
    id: UUID,
    name: string
  }[]
}