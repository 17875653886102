import { PushNotifications } from '@capacitor/push-notifications';
import { getPlatforms, isPlatform } from '@ionic/react';
import FDK from '@wattsonelements/front-fdk';

export const postFCMToken = async (token: string) => {
  return FDK.MainAPI.API({
    method: "post",
    url: `v1/fcmDevices`,
    data: {
      registration_id: token,
      type: isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "",
    },
  });
};

export const addListeners = async () => {
  if (!isPlatform("ios") && !isPlatform("android")) {
    console.log("Web not implemented");
    return
  }
  await PushNotifications.addListener('registration', token => {
    console.log('Registration token: ', token);
    // send Token to API
    postFCMToken(token.value)
  });

  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });
}

export const registerNotifications = async () => {
  if (isPlatform("ios") || isPlatform("android")) {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

}

export const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
}

export async function registerForPushNotificationsAsync() {
  let token;
  // TODO

  // if (Constants.isDevice) {
  //   const { status: existingStatus } =
  //     await Notifications.getPermissionsAsync();
  //   let finalStatus = existingStatus;
  //   if (existingStatus !== "granted") {
  //     const { status } = await Notifications.requestPermissionsAsync();
  //     finalStatus = status;
  //   }
  //   if (finalStatus !== "granted") {
  //     alert("Failed to get push token for push notification!");
  //     return;
  //   }
  //   token = (await Notifications.getExpoPushTokenAsync()).data;
  //   console.log(token);
  // } else {
  //   alert("Must use physical device for Push Notifications");
  // }

  // if (Platform.OS === "android") {
  //   Notifications.setNotificationChannelAsync("default", {
  //     name: "default",
  //     importance: Notifications.AndroidImportance.MAX,
  //     vibrationPattern: [0, 250, 250, 250],
  //     lightColor: "#FF231F7C",
  //   });
  // }

  return token;
}
