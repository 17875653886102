import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { IAttachment } from "@wattsonelements/front-fdk/dist/models/Attachment.models";

export interface DocumentFromPicker {
  name: string;
  file:File,
  preview: string
  isUploading: boolean
}

export interface AttachmentState {
  toUpload: File[];
  uploading: DocumentFromPicker[];
  uploaded: IAttachment[];
  error: DocumentFromPicker[];
}
const initialState = {
  toUpload: [],
  uploading: [],
  uploaded: [],
  error: [],
} as AttachmentState;

export const postDocument = createAsyncThunk(
  "attachments/attachment",
  async ({file, ...arg}: {file:File | any, name:string, preview: string}, { dispatch }) => {
    const fd = new FormData();
    const localFile = {...arg, isUploading: true, isLocal:true}
    fd.append("file", file);
    dispatch(addToUploading(localFile));
    return FDK.Agent.TicketsModule.postAttachment(fd).then(
      (res) => {
        dispatch(moveToUploaded({ ...localFile, ...res.data, isUploading:false, name: localFile.name, }));
        console.log("ATTACHMENT SUCCESS", { ...localFile, ...res.data, url: res.data.file, isUploading:false, name: localFile.name, });
        return { file: res.data, url: res.data.file, name :file.name } as { file: IAttachment };
      },
      (error) => {
        return { error, file };
      }
    );
  }
);

export const uploadQueue = createAsyncThunk(
  "attachments/update/queue",
  async (ticketId, {dispatch, getState} ) => {
    console.log(getState());
    // get state.toToUpload
    // copy it and empty it
    // batch send images
    // move them to uploaded ?
    // save them to ticket
  }
)

export const slice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    moveToUploaded: (state, { payload }) => {
      console.log("move to uploaded", payload);
      
      state.uploading = state.uploading.filter(
        (f) => f.name !== payload.name
      );
      state.uploaded.push({ ...payload});
    },
    removeFromUploaded: (state, { payload }) => {
      console.log("removeFromUploaded",payload, state.uploaded);
      
      state.uploaded = state.uploaded.filter((file) => file.id !== payload);      
    },
    resetAttachment: (state) => {
      return { ...initialState };
    },
    addToUploading: (state, { payload }) => {
      state.uploading.push(payload);
    },
    addToToUpload:(state, { payload }) => {
      state.toUpload.push(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postDocument.fulfilled, (state, { payload }) => {
        // state.uploading.push(payload.file)
      })
      .addCase(postDocument.rejected, (state, { payload }) => {
        // ???
        console.log("rejected", payload);
        
        state.uploading.find((file) => file.name === (payload as any).file.name);
      })
      .addCase(uploadQueue.fulfilled, (state, {payload}) => {
        console.log("uploadQueue.fulfilled",payload);
        
      })
  },
});

export default slice.reducer;
export const {
  addToUploading,
  moveToUploaded,
  removeFromUploaded,
  resetAttachment,
} = slice.actions;
