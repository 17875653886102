import * as React from "react";

import { Route, Redirect } from "react-router";
import { useAppSelector } from "../core/store/hooks";
import { RootState } from "../core/store/store";


interface IProps {
  exact?: boolean;
  path?: string;
  component: React.ComponentType<any>;
}

export default function PublicRoute({
  exact = false,
  path,
  component
}: IProps) {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (isLoggedIn === false) {
          return <Route {...props} component={component} />;
        } else if (isLoggedIn === true) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard/opens",
                state: { from: props.location }
              }}
            />
          );
        } else {
          return null;
        }
      }}
    />
  );
}
