import React, { ReactElement, ReactNode, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../core/store/hooks";
import { setCurrentUser } from "../../core/store/reducers/Users";
import { useEffect } from "react";
import { IonButton, IonContent, IonIcon, IonModal, IonSearchbar } from "@ionic/react";
import Avatar from "../UI/users/Avatar";
import { updateFilter } from "../../core/store/reducers/Tickets";
import { checkmarkCircle, logOutOutline } from "ionicons/icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IUserAgent } from "@wattsonelements/front-fdk/dist/models/User.models";
import { logout } from "../../core/store/reducers/Auth";
import { useHistory } from "react-router";
import { resetAll } from "../../utils/devDebug";

type DashboardHeaderProps = {
  search?: (e: any) => any,
  size?: "small" | "medium",
  searchPlaceholder?: string,
  children?: ReactNode | ReactElement | ReactElement[]
  disableSearch?: boolean
  searchText?: string | null
}
enum AVATAR_SIZE {
  medium = 90,
  small = 30
}
export default function DashboardHeader(props: DashboardHeaderProps) {
  const { t } = useTranslation()
  const { children, size = "medium", search, searchText = "", disableSearch = false, searchPlaceholder = t("shared.search") } = props
  const dispatch = useAppDispatch();
  const history = useHistory()
  const users = useAppSelector((state) => state.users.all);
  const selected = useAppSelector((state) => state.users.current);
  const loading = useAppSelector((state) => state.users.loading);
  const [open, setOpen] = useState(false)
  const [text, setText] = useState(searchText)

  const handleImage401 = (_error: any, src: string) => {
    if (src && !loading) {
      console.log("image error");
      // dispatch(getAgents());
    }
  };

  useEffect(() => {
    console.log("searchText", searchText, text);

    setText(searchText)
  }, [searchText])

  useEffect(() => {
    if (!loading && users.length === 0) {
      // dispatch(getAgents());
    }
  }, [dispatch, loading]);

  const stopDoubleScroll = async (e: any) => {
    e.stopPropagation()
  }

  return (
    <div className="bg-PRIMARY flex flex-col pt-10">
      <div className={classNames("z-20 flex gap-2", { "p-4 pb-0": size === "medium", "px-4 pb-1": size === "small" })}>
        {
          size === "small" &&
          <div onClick={() => setOpen(true)} role='button' className="z-30 rounded-full w-fit h-fit bg-white border-white p-2 flex">
            <div className="rounded-full drop-shadow-md w-fit border-white border-4 flex">
              <Avatar size={AVATAR_SIZE[size]} src={selected?.picture} />
            </div>
          </div>
        }

        <div className="flex text-white gap-4">
          <div className={classNames("m-2 flex leading-3 flex-col text-white", { "ml-[125px]": size === "medium" })}>
            <span className="font-bold text-white text-xl leading-6">{selected?.first_name} {selected?.last_name}</span>
            <span className="font-bold text-white text-sm leading-3">{selected?.job_title}</span>
          </div>
        </div>
      </div>
      <div className={classNames("bg-LIGHT flex min-h-[10px] rounded-t-2xl px-4 py-1", {})}>
        {
          size === "medium" &&
          <div onClick={() => setOpen(true)} role='button' className="z-30 rounded-full mt-[-65px] w-fit h-fit bg-white border-white p-2 flex">
            <div className="rounded-full drop-shadow-md w-fit border-white border-4 flex">
              <Avatar size={AVATAR_SIZE[size]} src={selected?.picture} />
            </div>
          </div>
        }
        {
          !!search && <div className="w-full">
            <IonSearchbar value={text} disabled={disableSearch} placeholder={searchPlaceholder} onIonChange={(e) => {
              search(e)
              setText(e.detail.value || "")
            }
            } style={{ '--border-radius': "40px", padding: "4px 8px" }}></IonSearchbar>
          </div>
        }
        {children}

      </div>
      <IonModal
        className="swipe-modal"
        isOpen={open}
        color="secondary"
        // onAnimationIteration={animIteration}
        onDidDismiss={() => setOpen(false)}
        initialBreakpoint={0.5}
        breakpoints={[0, .5, .75, .9]}
      >
        <IonContent
          //  onScroll={stopDoubleScroll} 
          //   onClick={stopDoubleScroll} 
          onTouchMove={stopDoubleScroll}
        //   onDrag={stopDoubleScroll} 
        //   onDragCapture={stopDoubleScroll}
        //   onDragStart={stopDoubleScroll}
        >
          <div className="flex gap-3 mx-3 pb-3 border-b-2 border-gray-200">
            {
              selected && <UserItem className="my-1  w-full" user={selected} isSelected={true} onSelect={() => { setOpen(false) }} />
            }
            <IonButton
              shape="round"
              color="secondary"
              size="large"
              onClick={() => {
                dispatch(logout());
                resetAll(dispatch);
                history.push('/login')
              }}
            >
              <IonIcon color="light" icon={logOutOutline} ></IonIcon>
            </IonButton>
          </div>
          <h2 className="font-bold m-3 text-xl text-center">{t("users.changeUser")}</h2>

          <div
            className="flex flex-col gap-3 my-3 mx-3 h-full overflow-auto pb-[55vh]">
            {
              users.filter(u => u.id !== selected?.id).map(user => {
                return <UserItem key={`agent-${user.id}`} user={user} isSelected={false} onSelect={() => { setOpen(false) }} />
              }
              )
            }
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
}

const UserItem = ({ user, isSelected, onSelect, className }: { user: IUserAgent, isSelected: boolean, onSelect: () => void, className?: string }) => {
  const dispatch = useAppDispatch();
  return (
    <div className={classNames("flex gap-2 items-center rounded-full pr-4 ", className, { "bg-LIGHT border-r-4 drop-shadow-md border-r-SECONDARY": isSelected })}>
      <Avatar src={user.picture} />
      <div onClick={() => {
        onSelect()
        dispatch(setCurrentUser(user))
        setTimeout(() => dispatch(updateFilter({ users: [user.id] })), 100);
        // setOpen(false)
      }} className="flex flex-col justify-center flex-auto">
        <span className="font-bold text-lg leading-6">{user?.first_name} {user?.last_name}</span>
        {user.job_title &&
          <span className="text-sm leading-3">{user?.job_title}</span>
        }
      </ div>
      {
        isSelected &&
        <IonIcon size="large" color="secondary" icon={checkmarkCircle} />
      }
    </div>
  )
}