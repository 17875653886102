import dayjs from "dayjs"
import i18n from "../utils/langs"

/**
 * 
 * @param time format 00:00
 * @param date format lisible par dayjs
 */
export const getDateObjectFromTime = (time: string, date: string | dayjs.Dayjs = dayjs()) => {
    const [hour, minute] = time.split(":")
    return dayjs(date).hour(parseInt(hour)).minute(parseInt(minute))
}


export const durationDisplay = (min: string | dayjs.Dayjs, max: string | dayjs.Dayjs = dayjs()) => {
    let minutes = dayjs(max).diff(dayjs(min), "minutes")
    let dateFormat = i18n.t("dateFormat.m",)
    let days = 0
    if (Math.abs(minutes) < 60) {
        return `${String(minutes).padStart(2, '0')}${dateFormat} `
    }

    let hours = dayjs(max).diff(dayjs(min), "hour")
    dateFormat = hours + i18n.t("dateFormat.h") + ((minutes % 60 > 0) ? String(minutes % 60).padStart(2, '0') : "")

    if (Math.abs(hours) > 24) {
        days = dayjs(max).diff(dayjs(min), "day")
        dateFormat = days + i18n.t("dateFormat.d")
    }
    let months = dayjs(max).diff(dayjs(min), "month")
    if (Math.abs(days) >= 30) {
        dateFormat = months + " " + i18n.t("dateFormat.month")
    }
    // TODO be careful with the max variable choice
    if (Math.abs(months) >= 12) {
        dateFormat = dayjs(max).format(i18n.t("dateFormat.shortDate"))
    }
    return dateFormat
}

/**
 * merge a date and time string
 * @param date date from api like YYYY-MM-DD
 * @param time hh:mm:ss
 * @returns 
 */
export const addTimeToDate = (date: string, time: string) => {
    if (!time) return dayjs(date)
    const [h, m] = time.split(":")
    return dayjs(date).hour(parseInt(h)).minute(parseInt(m))
}

export const getIsYesterdayOrTomorrowOrToday = (date: string | dayjs.Dayjs,) => {
    const newDate = dayjs(date)
    if (!newDate.isValid()) return false
    if (newDate.isSame(dayjs(), "day")) {
        return "shared.today"
    }
    if (newDate.isSame(dayjs().add(-1, "day"), "day")) {
        return "shared.yesterday"
    }
    if (newDate.isSame(dayjs().add(1, "day"), "day")) {
        return "shared.tomorrow"
    }
    return false
}