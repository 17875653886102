import { FC, useRef, useState } from "react"
import FieldItem from "../../UI/form/FieldItem"
import { useTranslation } from "react-i18next"
import { IonSelect, IonSelectOption } from "@ionic/react"
import { useAppSelector } from "../../../core/store/hooks"
import AddBtn from "../../UI/buttons/AddBtn"
import { list } from "ionicons/icons"

type TypefieldProps = {
  value: any,
  update: (value: any) => void
}
export const Typefield: FC<TypefieldProps> = ({ value, update = () => { } }) => {
  const { t } = useTranslation()
  const config = useAppSelector(state => state.tickets.config)
  const [readableValue, setReadableValue] = useState<string>((config && config?.ticket_kinds.find(tk => tk.id === value)?.name) || "")
  const [open, setOpen] = useState(false)
  const selectRef = useRef<HTMLIonSelectElement>(null)

  return <FieldItem
    filled={false}
    onClick={() => {
      setOpen(true)
      selectRef.current?.click()
    }}
    field={{ title: t("ticket.formFields.type"), icon: list }}>
    {(!value || !readableValue) && <AddBtn
      onClick={() => setOpen(!open)}
      ionIcon={list}
    />}
    {
      readableValue && <span className="py-3">
        {readableValue}
      </span>
    }

    <IonSelect
      className="hidden"
      cancelText={t("shared.cancel")}
      value={value}
      ref={selectRef}
      interface="action-sheet"
      onIonChange={(e) => {
        console.log("kind", e.detail.value);
        if (value === e.detail.value) return
        update(e.detail.value)
        setReadableValue((config && config?.ticket_kinds.find(tk => tk.id === e.detail.value)?.name) || "")
      }}
      placeholder={t("ticket.formFields.type")}>
      {
        config?.ticket_kinds.map(t => <IonSelectOption
          key={t.id}
          value={t.id}>
          {t.name}
        </IonSelectOption>)
      }
      <IonSelectOption
        value={null}>
        {t("ticket.noKind")}
      </IonSelectOption>

    </IonSelect>
  </FieldItem>
}