export const markerGeojson = (onlyData: boolean, icon: string, coordinates: number[], width: number) => {
  let tmp = {
    'type': 'FeatureCollection',
    'features': [
      {
        // feature for Mapbox DC
        'type': 'Feature',
        'properties': {
          'width': width,
          'icon': icon,
          'anchor': { x: 10, y: 10 },
          'id': 'icon',
          'clickable': false,
          'draggable': true
        },
        'geometry': {
          'type': 'Point',
          'coordinates': coordinates
        }
      }
    ]
  };
  if (onlyData)
    return tmp;
  return {
    'type': 'geojson',
    'data': tmp
  };
};
