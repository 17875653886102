import React, { useEffect, useState } from "react";
import AddBtn from "../../UI/buttons/AddBtn";
import FieldItem from "../../UI/form/FieldItem";
import Input from "../../UI/form/Input";
import ModalEdit from "../../UI/ModalEdit";
import { useTranslation } from "react-i18next";
import icon from "../../../assets/icons/ui/description.png"

export default function TicketTitle({
  value,
  title,
  update,
  maxLength = 40
}: {
  value: string;
  title?: string;
  update: Function;
  maxLength?: number;
}) {
  const { t } = useTranslation()
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false)
  useEffect(() => {
    if (value) {
      setText(value);
    }
  }, [value]);

  const changeCB = (val: string) => {
    setText(val)
    if (val.length > maxLength) {
      setError(true)
    } else
      setError(false)
  }

  const onSave = (isSave: boolean) => {
    if (!isSave) {
      setText(value || '');
    } else {
      update({ title: text || '' });
    }
  };
  return (
    <FieldItem
      filled={text ? true : false}
      onClick={() => setOpen(!open)}
      field={{ title: title || t("ticket.formFields.title"), icon }}>
      <>
        <ModalEdit
          enableValidation={!error}
          onRequestClose={() => { }}
          title={t("ticket.formFields.title")}
          open={open}
          save={onSave}
          setOpen={setOpen}
        >
          <Input
            autofocus
            style={{ width: "100%" }}
            placeholder={text}
            value={text}
            onChangeText={changeCB}
          />
          {error &&
            <span className="mt-2 text-DANGER text-md text-center">{t("ticket.formFields.titleError")}</span>}
        </ModalEdit>
        {text !== "" && <span>{text}</span>}
        {!text && (
          <AddBtn
            onClick={() => setOpen(!open)}
            icon={icon}
          />
        )}
      </>
    </FieldItem>
  );
}
