import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "../store";
import FDK from "@wattsonelements/front-fdk";
import { ISpot } from "@wattsonelements/front-fdk/dist/models/Spot.models";
import { IBerthDetail } from "@wattsonelements/front-fdk/dist/models/Berth.models";

export interface SpotsState {
  all: ISpot[];
  selected: null | IBerthDetail;
  spotLoading: boolean;
  searchResult: ISpot[];
  searchError: string;
  searchLoading: boolean;
  spotDetailError: boolean
}

const initialState = {
  all: [],
  selected: null,
  spotLoading: false,
  searchResult: [],
  searchError: "",
  searchLoading: false,
  spotDetailError: false
} as SpotsState;

export const getSpotDetail = createAsyncThunk(
  "spot/detail",
  async (id: string) => {
    return FDK.Agent.BerthModule.getBerthById(id, { order: "stopover_first" }).then((res) => {
      return res.data;
    });
  }
);

export const getSpots = createAsyncThunk(
  "spots/get",
  async (_args, { getState }) => {
    return FDK.Agent.BerthModule.getBerthsList().then((res) => {
      return res.data;
    });
  }
);

export const searchSpots = createAsyncThunk(
  "spots/search",
  async (name: string) => {
    return FDK.Agent.BerthModule.searchByBerthName(name).then((res) => {
      console.log("searchBySpotName", res.data);

      return res.data;
    });
  }
);

export const slice = createSlice({
  name: "spots",
  initialState,
  reducers: {
    resetError(state) {
      state.searchError = "";
    },
    resetSearch(state) {
      state.searchError = initialState.searchError;
      state.searchLoading = initialState.searchLoading;
      state.searchResult = initialState.searchResult;
    },
    resetSpots() {
      return { ...initialState };
    },
    resetSelected(state) {
      state.selected = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpotDetail.pending, (state, { payload }) => {
        state.spotLoading = true;
        state.spotDetailError = false
      })
      .addCase(getSpotDetail.rejected, (state, { payload }) => {
        state.spotDetailError = true
        state.spotLoading = false;
      })
      .addCase(getSpotDetail.fulfilled, (state, { payload }) => {
        state.spotLoading = false;
        state.selected = payload;
      })
      .addCase(getSpots.rejected, (state, { payload }) => { })
      .addCase(searchSpots.pending, (state, { payload }) => {
        state.searchLoading = true;
        state.searchError = "";
      })
      .addCase(searchSpots.fulfilled, (state, { payload }) => {
        state.searchResult = payload.results;
        state.searchLoading = false;
        state.searchError = "";
        if (payload.length === 0) {
          state.searchError = "ticket.spotListError.404";
        }
      })
      .addCase(searchSpots.rejected, (state, { payload }) => {
        if (!payload) {
          state.searchError = "shared.unknownError";
        } else {
          switch ((payload as ApiError).status) {
            case 404:
              state.searchError = "ticket.spotListError.404";
              break;
            case 500:
              state.searchError = "ticket.spotListError.500";
              break;
            default:
              state.searchError = "shared.unknownError";
              break;
          }
        }

        state.searchResult = [];
        state.searchLoading = false;
      });
  },
});

export default slice.reducer;
export const { resetError, resetSearch, resetSpots, resetSelected } = slice.actions;
